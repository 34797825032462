import React, { useRef } from "react";
import { useState } from "react";
import { useEffect } from "react";
import useInterval from "use-interval";
import { GetTableNameWiseChartDataByDate, TableNameWiseLast24HoursData, getLastInstantaneouseData ,getStartAndEndDateByTable,GetTableNameWiseChartDataByStartDateEndDate, TableNameWiseLast24HoursDataSelectedDate,getGSMMonthlyConsumption,getGSMMonthlyConsumptionByDate,getScriptCodeIdWiseConfigretionData,onModemConfigByGSMConsumer,GetInstVoltAndCuruntData,getGSMDailyConsumptionByConsumerId,getGSMDailyConsumptionByConsumerIdAndSletedate, HoursWiseConsumptionGraphBytableName} from '../../Commoncomponet/Utils'
import moment from 'moment';
import { BootstrapTooltip,socket } from '../../Commoncomponet/common';
import ChartLoaders from '../../Commoncomponet/Loaders/ChartLoader';
import Chart from "react-apexcharts";
import clockPending from '../../assets/img/icons8-clock.gif'
import {
	Chart as ChartJS,
	CategoryScale,
	LinearScale,
	PointElement,
	LineElement,
	Title,
	Tooltip,
	Filler,
	Legend,
	Decimation, TimeScale
} from 'chart.js';
import './Deshboard.css'
import zoomPlugin from 'chartjs-plugin-zoom';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Bar,Line } from 'react-chartjs-2';
import { DatePicker, Modal, Space } from "antd";
import swal from 'sweetalert2';
import { ResponsiveHeatMap } from '@nivo/heatmap';
import realDatas from './Data.json'
import { scaleLinear } from 'd3-scale';
window.Swal = swal;

export default function Meter_GSM_dashboard(props) {
	const colors = ["#67b7dcd9", "#6794dcd9", "#6771dcd9", "#8067dcd9", "#a367dcd9", "#c767dcd9", "#dc67ced9", "#6771dcd9"]
    const chartRef=useRef()
    const chartInstaRef=useRef()
    const chartRefLine = useRef();
    const chartRefPC=useRef(null)
  const {ColumnName}=props
    const [InstantaneousData,setInstantaneousData]=useState({})
    const [LoadData,setLoadData]=useState({total:[],date:[],res_code:''})
    const [MonthlyChart,setMonthlyChart]=useState({total:[],date:[],res_code:''})
    const [DailyChart,setDailyChart]=useState({total:[],date:[],res_code:''})
    const [Loader,setLoader]=useState(false)
	const [isModalVisibleMonth, setisModalVisibleMonth] = useState(false)
	const [ModelHeader, setModelHeader] = useState('')
	const [expireDate, setExpireDate] = useState({})
	const [isModalVisible, setIsModalVisible] = useState(false);
	const [ChartLoader, setChartLoader] = useState(false)
	const [Instantaneous15Chart, setInstantaneous15Chart] = useState({ total: [], date: [] })
	const [dates, setDates] = useState({ start: '', end: '' })
  const [modal,setModal]=useState(false)
  const [MqttMsg,setMqttMsg]=useState('')
  const [time, setTime] = useState(60);
const [run, setRun] = useState(false);
const [modalMsg,setmodalMsg]=useState(false)
const [pendingBuffer,setpendingBuffer]=useState('')
	var [CommandJson,setCommandJson]=useState({})
    var [CommandmsgJson,setCommandmsgJson]=useState({})
    const [CommandStatus,setCommandStatus]=useState({})
	const [FlagMsg,setFlagMsg]=useState(false)
  const [modelTitle,setmodelTitle]=useState('')
  const [APhase,setAPhase]=useState({RA:[],YA:[],RA:[],date:[]})
  const [VPhase,setVPhase]=useState({RV:[],YV:[],RV:[],date:[]})
  const [ExpanVolt,setExpanVolt]=useState({RV:[],YV:[],RV:[],date:[]})
  const [PhaseLoader,setPhaseLoader]=useState(false)
	const [REQ_CONS_Count,setREQ_CONS_Count]= useState({ REQ_CONS_MIN: [],REQ_CONS_MAX:[],REQ_CONS_AVG:[], date: [] })
	const [DailyUnit,setDailyUnit]= useState({ total: [], date: [] })

	const mytime = useRef(null)
  const [Status,setStatus]=useState()
    ChartJS.register(
      CategoryScale,
      LinearScale,
      PointElement,
      LineElement,
      Title,
      Tooltip,
      Filler,
      Legend,
      zoomPlugin, ChartDataLabels,
      Decimation, TimeScale
    );


    // const ongetScriptCodeIdWiseConfigretionData=async()=>{
    //   const data=await getScriptCodeIdWiseConfigretionData(props?.GSMdata?.SCRIPTDECODE_ID,{tableName:props?.GSMdata?.UNIQUE_TABLE_NAME?.GSM_BUFFER,type:'INSTANTANEOUS'})
    //   if(data.success===true){
    //     const CMD=JSON.parse(data?.data?.data[0]?.METER_QUERY_CMD||'{}')
    //   }else{

    //   }
    // }

    const columnWiseData=(value,column,header)=>{
      const col= ColumnName[header]?.filter((item)=>(item?.Column==column))[0]||{}

        if(col?.Float==true){
          if(col?.Division==true){
              if(col?.MF==true&&props?.GSMdata?.MF)	{
                 return (value*props?.GSMdata?.MF/col?.DivisionNumber).toFixed(col?.FloatPoint)
              }else{
                return (value/col?.DivisionNumber).toFixed(col?.FloatPoint)
              }
          }else{
              if(col?.MF==true&&props?.GSMdata?.MF){
                return (value*props?.GSMdata?.MF).toFixed(col?.FloatPoint)
              }else{
                return (value).toFixed(col?.FloatPoint)
              }
          }
      
      }else{
          if(col?.MF==true&&props?.GSMdata?.MF){
            if(col?.Division==true){
                return  (value*props?.GSMdata?.MF)/col?.DivisionNumber
            }else{
            return value*props?.GSMdata?.MF
            }
          }else{
              if(col?.Division==true){
                  if(col?.MF==true&&props?.GSMdata?.MF){
                    return  (value*props?.GSMdata?.MF)/col?.DivisionNumber
                  }else{
                    return  value/col?.DivisionNumber
                  }
              }else{
                return value
              }
          }
      }
    }

    const GetLastINSdata=async()=>{
        setLoader(true)

        const data=await getLastInstantaneouseData({tableName:props?.GSMdata?.UNIQUE_TABLE_NAME?.INSTANTANEOUS})
        if(data.success==true){
            const OBJ_COL={Register$1_0_1_8_0_255 :'EMT_PENRG_CUM_IMP_WH',Register$1_0_9_8_0_255 :'EMT_QENRG_CUM_IMP_VAH',Register$1_0_1_7_0_255 :'EMT_PPWR_AVG_W',Register$1_0_3_7_0_255 :'EMT_QPWR_AVG_VAR',Register$1_0_9_7_0_255 :'EMT_SPWR_AVG_VA',Register$1_0_32_7_0_255 :'EMT_VOLT_RPH_V',Register$1_0_52_7_0_255 :'EMT_VOLT_YPH_V',Register$1_0_72_7_0_255 :'EMT_VOLT_BPH_V',Register$1_0_31_7_0_255 :'EMT_CUR_RPH_A',Register$1_0_51_7_0_255 :'EMT_CUR_YPH_A',Register$1_0_71_7_0_255 :'EMT_CUR_BPH_A',Register$1_0_13_7_0_255 :'EMT_PF_AVG',Register$1_0_14_7_0_255 :'EMT_FREQ_HZ'}
            var arrayofData=data?.data?.data[0]||{}
            Object.entries(OBJ_COL).map(([key,value])=>{
             arrayofData[value]= columnWiseData(arrayofData[value],key,'INSTANTANEOUS')
            })
            setInstantaneousData(arrayofData)
            setLoader(false)
        }else{
            setLoader(false)
            setStatus(data?.err?.response?.status)
        }
    }

    const GetTableNameWiseLast24HoursData=async()=>{
      const data=await TableNameWiseLast24HoursData({tableName:props?.GSMdata?.UNIQUE_TABLE_NAME?.LOAD_SURVEY,colName:'Clock$0_0_1_0_0_255'})
      if(data.success==true){

        var arrayofData=data?.data?.data?.map((item)=>{
         return columnWiseData(item?.EMT_PENRG_BE_IMP_WH,'Register$1_0_1_29_0_255','LOAD_SURVEY')
        })
        setLoadData({total:arrayofData,date:data.data.data.map((item) => (moment.utc(item.date).format("DD-MM-YYYY hh:mm:ss A"))),res_code:'200'})

      }else{
        setLoadData({total:[],date:[],res_code:data?.err?.response?.status})
      }
  }

  const OnGetInstVoltAndCuruntData=async()=>{
    setPhaseLoader(true)
    const data=await GetInstVoltAndCuruntData({tableName:props?.GSMdata?.UNIQUE_TABLE_NAME?.INSTANTANEOUS})
    if(data.success==true){

      setAPhase({RA:data?.data?.data?.map((item)=>{
      return columnWiseData(item?.EMT_CUR_RPH_A,'Register$1_0_31_7_0_255','INSTANTANEOUS')

        }),YA:data?.data?.data?.map((item)=>{
     return columnWiseData(item?.EMT_CUR_BPH_A,'Register$1_0_51_7_0_255','INSTANTANEOUS')

    }),BA:data?.data?.data?.map((item)=>{
     return columnWiseData(item?.EMT_CUR_YPH_A,'Register$1_0_71_7_0_255','INSTANTANEOUS')

    }),date:data?.data?.data?.map((item) => (moment.utc(item.EMT_TS_INST).format("DD-MM-YYYY hh:mm:ss A"))),res_code:'200'})
      setVPhase({RV:data?.data?.data?.map((item)=>{
     return columnWiseData(item?.EMT_VOLT_RPH_V,'Register$1_0_32_7_0_255','INSTANTANEOUS')

      }),YV:data?.data?.data?.map((item)=>{
     return columnWiseData(item?.EMT_VOLT_YPH_V,'Register$1_0_52_7_0_255','INSTANTANEOUS')
      }),BV:data?.data?.data?.map((item)=>{
     return columnWiseData(item?.EMT_VOLT_BPH_V,'Register$1_0_72_7_0_25','INSTANTANEOUS')
      }),date:data?.data?.data?.map((item) => (moment.utc(item.EMT_TS_INST).format("DD-MM-YYYY hh:mm:ss A"))),res_code:'200'})
      setPhaseLoader(false)
    }else{
      setAPhase({total:[],date:[],res_code:data?.err?.response?.status})
      setVPhase({total:[],date:[],res_code:data?.err?.response?.status})
      setPhaseLoader(false)
    }
}

  const onGetTablemonthWiseChartDataByDate = async (tableName, SCRIPTDECODE_ID) => {
		setMonthlyChart({total:[],date:[],res_code:''})

		const data = await getGSMMonthlyConsumption({ date: 8, tableName: props?.GSMdata?.UNIQUE_TABLE_NAME?.MONTH_BILL, scriptid: SCRIPTDECODE_ID, col: 'Register$0_0_0_1_2_255', fild: 'Register$1_0_1_8_0_255',TYPE:'' })
		if (data.success === true) {
      const TotalData=data.data.data
			var DateArray=[]
			TotalData.map((item,id) => {
				if(id!=0){DateArray.push(moment.utc(TotalData[id-1]?.date).format("DD-MM-YY")+' To '+moment.utc(item.date).format("DD-MM-YY"))
			}})
			var totalArray=[]
			TotalData.map((item,id) => {
				if(id!=0){
					if(item.lengths!=null){
					if(item.lengths!=0){
							totalArray.push(((item.lengths-TotalData[id-1]?.lengths)>0?(item.lengths-TotalData[id-1]?.lengths):0).toFixed(3))
				}else{
					totalArray.push(0)
				}
				}
      }
			})

      var arrayofData=totalArray?.map((item)=>{
      return  columnWiseData(item,'Register$1_0_1_8_0_255','MONTH_BILL')
      })
			setMonthlyChart({ total: arrayofData, date: DateArray,res_code:'200' })
		} else {
			console.log('Something Went Wrong')
			setMonthlyChart({total:[],date:[],res_code:data?.err?.response?.status})
		}
	}
	const onGetTabledailyWiseChartDataByDate = async (tableName, SCRIPTDECODE_ID) => {
		setDailyChart({total:[],date:[],res_code:''})
    const column_info= ColumnName?.INSTANTANEOUS?.filter((item)=>(item?.Column=='Register$1_0_1_8_0_255'))[0]||{}

		const data = await getGSMDailyConsumptionByConsumerId({ UNIQUE_ID: props?.GSMdata?.UNIQUE_ID,column_info:column_info,MF:props?.GSMdata?.MF })
		if (data.success === true) {
      const TotalData=data.data.data
			setDailyChart({ total: TotalData.map((item)=>(item?.CUM_IMP_WH?item?.CUM_IMP_WH?.toFixed(3):0)), date: TotalData.map((item)=>(moment(item.date,'YYYY-MM-DD').add(-1,'days').format("DD-MM-YYYY"))) })
      setDailyUnit({ total: TotalData.map((item)=>(item?.UNIT_RATE?item?.UNIT_RATE?.toFixed(3):0)), date: TotalData.map((item)=>(moment(item.date,'YYYY-MM-DD').add(-1,'days').format("DD-MM-YYYY"))) })
			setREQ_CONS_Count({REQ_CONS_MIN: TotalData.map((item)=>(item?.REQ_CONS_MIN?item?.REQ_CONS_MIN?.toFixed(3):0)),REQ_CONS_MAX:TotalData.map((item)=>(item?.REQ_CONS_MAX?item?.REQ_CONS_MAX?.toFixed(3):0)),REQ_CONS_AVG:TotalData.map((item)=>(item?.REQ_CONS_AVG?item?.REQ_CONS_AVG?.toFixed(3):0)), date: TotalData.map((item)=>(moment(item.date,'YYYY-MM-DD').add(-1,'days').format("DD-MM-YYYY")))})
		} else {
			console.log('Something Went Wrong')
			setDailyChart({total:[],date:[],res_code:data?.err?.response?.status})

		}
	}

  

    useEffect(()=>{
        GetLastINSdata()
        GetTableNameWiseLast24HoursData()
        onGetTablemonthWiseChartDataByDate()
        onGetTabledailyWiseChartDataByDate()
        OnGetInstVoltAndCuruntData()
        onHoursWiseConsumptionGraphBytableName()
    },[ColumnName])
    const sum = (arr) => {
      const result = arr.reduce((total, currentValue) => total = total + currentValue, 0);
      return result
    }
    const handleCancelMonth = () => {
      setisModalVisibleMonth(false);
      setChartLoader(false)
      setInstantaneous15Chart({ total: [], date: [] })
    }

    const onOpenModal = (tableName, col, header) => {
      ongetStartAndEndDateByTable(tableName, col)
      setModelHeader(header)
    }

    const ongetStartAndEndDateByTable = async (tableName, col,modal) => {
      document.body.style.cursor = 'Went'
      const data = await getStartAndEndDateByTable({ tableName: tableName, col: col })
      if (data.success === true) {
        setExpireDate(data.data)
        if(!modal){
        setIsModalVisible(true)
        }
        document.body.style.cursor = 'default'
      } else {
        console.log("Something Went Wrong")
        document.body.style.cursor = 'default'
      }
    }
    const Monthlytate = {
      options: {
        legend: {
          show: false,
  
        },
        yaxis:{
          labels: {
            show: true,
          },
          title:{text:'KWH',style:{fontWeight:200}}
        },
        plotOptions: {
          bar: {
            distributed: true,
            borderRadius: 5,
            borderRadiusApplication: 'end',
            dataLabels: {
              position: 'top',
              offsetY: 30,
              fontSize: '10px'
            },
  
          }
        },
        dataLabels: {
          offsetY: 10
        },
        xaxis: {
          floating: false,
          categories: MonthlyChart.date,
          labels: {
            show: true,
            // rotate: -90,
            rotateAlways: true,
            offsetX: 0,
            offsetY: 3,
          },
          // tickPlacement: 'on'
        },
        fill: {
          type: 'bar',
          gradient: {
            shade: 'dark',
            type: "vertical",
            shadeIntensity: 0.5,
            gradientToColors: undefined, // optional, if not defined - uses the shades of same color in series
            inverseColors: true,
            opacityFrom: 1,
            opacityTo: 1,
            stops: [0, 50, 100],
            colorStops: []
          }
        },
        chart: {
          toolbar: {
            show: false
          }
        },
        noData: {
          text: "Loading...",
          align: 'center',
          verticalAlign: 'middle',
          offsetX: 0,
          offsetY: 0,
          style: {
            color: "#000000",
            fontSize: '14px',
            fontFamily: "Helvetica"
          },
        },
        tooltip: {
          shared: true,
          intersect: false,
          custom: function ({ series, seriesIndex, dataPointIndex, w }) {
            console.log(series, seriesIndex, dataPointIndex, w,w?.config?.xaxis?.categories[dataPointIndex]);
            return `<div style={{padding:'5px'}}>`+w?.config?.xaxis?.categories[dataPointIndex]+`</br>`+series[0][dataPointIndex]+' KWH'+`</div>`
          }
        },
        colors: colors
        // colors: [
        //     "#99ccdd", "#e9cbff", "#ffcfcb", "#99ccee", "#99bbee", "#aabbee", "#aaddee", "#a4daef"
        // ]
      },
  
      series: [
        {
          name: "Monthbill",
          data: MonthlyChart.total
        }
      ]
    };
    const Dailystate = {
      options: {
        legend: {
          show: false,
  
        },
        yaxis:{
          labels: {
            show: true,
          },
          title:{text:'KWH',style:{fontWeight:200}}
        },
        plotOptions: {
          bar: {
            distributed: true,
            borderRadius: 5,
            borderRadiusApplication: 'end',
            dataLabels: {
              position: 'top',
              offsetY: 30,
              fontSize: '10px'
            },
  
          }
        },
        dataLabels: {
          offsetY: 10
        },
        xaxis: {
          floating: false,
          categories: DailyChart.date,
          labels: {
            show: true,
            rotate: -90,
            rotateAlways: true,
            offsetX: 0,
            offsetY: 3,
          },
          // tickPlacement: 'on'
        },
        fill: {
          type: 'bar',
          gradient: {
            shade: 'dark',
            type: "vertical",
            shadeIntensity: 0.5,
            gradientToColors: undefined, // optional, if not defined - uses the shades of same color in series
            inverseColors: true,
            opacityFrom: 1,
            opacityTo: 1,
            stops: [0, 50, 100],
            colorStops: []
          }
        },
        chart: {
          toolbar: {
            show: false
          }
        },
        noData: {
          text: "Loading...",
          align: 'center',
          verticalAlign: 'middle',
          offsetX: 0,
          offsetY: 0,
          style: {
            color: "#000000",
            fontSize: '14px',
            fontFamily: "Helvetica"
          },
        },
        tooltip: {
          shared: true,
          intersect: false,
          custom: function ({ series, seriesIndex, dataPointIndex, w }) {
            console.log(series, seriesIndex, dataPointIndex, w,w?.config?.xaxis?.categories[dataPointIndex]);
            return `<div style={{padding:'5px'}}>`+w?.config?.xaxis?.categories[dataPointIndex]+`</br>`+series[0][dataPointIndex]+' KWH'+`</div>`
          }
        },
        colors: colors
        // colors: [
        //     "#99ccdd", "#e9cbff", "#ffcfcb", "#99ccee", "#99bbee", "#aabbee", "#aaddee", "#a4daef"
        // ]
      },
  
      series: [
        {
          name: "Dailyload",
          data: DailyChart.total
        }
      ]
    };

    const Loadsuravaystate = {
      options:{
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          x: {
            // type: 'time',
            ticks: {
    
              maxRotation: 90,
              minRotation: 90,
              font: {
                size: 10
              },
    
            }
          },
    
          y: {
            beginAtZero: true,
            title: {
              display: true,
              text: 'KWH'
            }
          },
        },
        plugins: {
          legend: {
            position: 'bottom',
            labels: {
              pointStyleWidth: 10,
              usePointStyle: true,
              pointStyle: 'rectRounded'
            }
          },
          tooltip: {
            mode: 'index',
            intersect: false,
            callbacks: {
              label: (item) => item?.formattedValue + ' KWH'
            }
          },
          zoom: {
            zoom: {
              wheel: {
                enabled: true // SET SCROOL ZOOM TO TRUE
              },
              mode: "x",
            },
            pan: {
              enabled: true,
              mode: "x",
            }
          },
          datalabels: {
            align: 'end',
            anchor: 'end',
            clamp: true,
            color: "black",
            padding: {
              top: 0,
              bottom: 0
            },
            font: function (context) {
              var w = context.chart.width;
              return {
                size: w < 512 ? 10 : 12,
    
              };
            },
    
          }
        },
        layout: {
          padding: {
            left: 50,
            right: 50,
            top: 20
          }
        },
      },
  
      series: {
        labels: LoadData.date,
		datasets: [
			{
				label: 'Loadsurvey',
				backgroundColor: ["rgba(103, 183, 220, 0.85)", "rgba(103, 148, 220, 0.85)", "rgba(103, 113, 220, 0.85)", "rgba(128, 103, 220, 0.85)", "rgba(163, 103, 220, 0.85)", "rgba(199, 103, 220, 0.85)", "rgba(220, 103, 206, 0.85)", "rgba(103, 113, 220, 0.85)"],
				data: LoadData.total,
			},
		],
  } 
    };

    const optionsPC = {
      // parsing: false,
      responsive: true,
      maintainAspectRatio: false,
      interaction: {
        mode: "index",
        intersect: false,
        },
  
      // parsing: false,
      plugins: {
        decimation: {
          enabled: true,
          algorithm: 'lttb',
          samples: 800,
          threshold: 800
        },
        legend: {
          position: 'bottom',
          labels: {
            pointStyleWidth: 10,
            usePointStyle: true,
            pointStyle: 'rectRounded'
          }
        },
        zoom: {
          zoom: {
            wheel: {
              enabled: true,
              modifierKey: 'ctrl' // SET SCROOL ZOOM TO TRUE
            },
            mode: "x",
          },
          pan: {
            enabled: true,
            mode: "x",
          }
        },
        datalabels: {
          display:false,
          backgroundColor: function (context) {
            return context.dataset.borderColor;
          },
          borderRadius: 4,
          align: 'end',
          anchor: 'end',
          clamp: true,
          color: "white",
          padding: {
            top: 0,
            bottom: 0
          },
          font: function (context) {
            var w = context.chart.width;
            return {
              size: w < 512 ? 10 : 12,
  
            };
          },
          tooltips: {
            mode: "index",
            intersect: false,
            },
            hover: {
            mode: "nearest",
            intersect: true,
            }
  
        }
      },
      elements: {
        line: {
          fill: true,
          tension: 0.3
        }
      },
      layout: {
        padding: {
          top: 20
        }
      },
    };

    const dataPC = {
      labels: REQ_CONS_Count?.date,
      datasets: [
        {
          label: "PCMIN (KWH)",
          data: REQ_CONS_Count?.REQ_CONS_MIN,
          fill: false,
          borderColor: 'rgb(53, 162, 235)',
          backgroundColor: (context) => {
            const ctx = context.chart.ctx;
            const gradient = ctx.createLinearGradient(0, 0, 0, 400);
            gradient.addColorStop(0, "rgba(53, 162, 235, 0.8)");
            gradient.addColorStop(1, "rgba(53, 162, 235, 0)");
            return gradient;
            // return pattern.draw('dot', gradient)
          },
          
          // backgroundColor: 'rgba(53, 162, 235, 0.5)',
        },
        {
          label: "PCMAX (KWH)",
          data: REQ_CONS_Count?.REQ_CONS_MAX,
          fill: false,
          borderColor: 'rgb(225, 29, 72',
          backgroundColor: (context) => {
            const ctx = context.chart.ctx;
            const gradient = ctx.createLinearGradient(0, 0, 0, 400);
            gradient.addColorStop(0, "rgb(254, 205, 211)");
            gradient.addColorStop(1, "rgb(254, 205, 211)");
            return gradient;
            // return pattern.draw('dot', gradient)
          },
          // backgroundColor: 'rgba(53, 162, 235, 0.5)',
        },
        {
          label: "PCAVG (KWH)",
          data: REQ_CONS_Count?.REQ_CONS_AVG,
          fill: false,
          borderColor: 'rgb(29, 78, 216)',
          backgroundColor: (context) => {
            const ctx = context.chart.ctx;
            const gradient = ctx.createLinearGradient(0, 0, 0, 400);
            gradient.addColorStop(0, "rgb(191, 219, 254)");
            gradient.addColorStop(1, "rgb(191, 219, 254)");
            return gradient;
            // return pattern.draw('dot', gradient)
          },
          // backgroundColor: 'rgba(53, 162, 235, 0.5)',
        },
        {
          label: "Consumption (KWH)",
          data: DailyChart.total,
          fill: false,
          borderColor: 'rgb(8,145, 178)',
          backgroundColor: (context) => {
            const ctx = context.chart.ctx;
            const gradient = ctx.createLinearGradient(0, 0, 0, 400);
            gradient.addColorStop(0, "rgb(207, 250, 254)");
            gradient.addColorStop(1, "rgb(207, 250, 254)");
            return gradient;
            // return pattern.draw('dot', gradient)
          },
          // backgroundColor: 'rgba(53, 162, 235, 0.5)',
        }
      ]
    };

    const expandChartoptions = {
		responsive: true,
		maintainAspectRatio: false,
		scales: {
			x: {
				// type: 'time',
				ticks: {

					maxRotation: 90,
					minRotation: 90,
					font: {
						size: 10
					},

				}
			},

			y: {
        title: {
				  display: true,
				  text: 'KWH'
				},
				beginAtZero: true
			},
		},
		plugins: {
			legend: {
				position: 'bottom',
				labels: {
					pointStyleWidth: 10,
					usePointStyle: true,
					pointStyle: 'rectRounded'
				}
			},
      tooltip: {
        mode: 'index',
        intersect: false,
        callbacks: {
          label: (item) => item?.formattedValue + ' KWH'
        }
      },
			zoom: {
				zoom: {
					wheel: {
						enabled: true // SET SCROOL ZOOM TO TRUE
					},
					mode: "x",
				},
				pan: {
					enabled: true,
					mode: "x",
				}
			},
			datalabels: {
				align: 'end',
				anchor: 'end',
				clamp: true,
				color: "black",
				padding: {
					top: 0,
					bottom: 0
				},
				font: function (context) {
					var w = context.chart.width;
					return {
						size: w < 512 ? 10 : 12,

					};
				},

			}
		},
		layout: {
			padding: {
				left: 50,
				right: 50,
				top: 20
			}
		},
	};
  const Instantaneous15state = {
		options: {
			legend: {
				show: false,

			},

			plotOptions: {
				bar: {
					distributed: true,
					borderRadius: 5,
					borderRadiusApplication: 'end',
					dataLabels: {
						position: 'top',
						offsetY: 10,
						fontSize: '10px'
					},
					
				}
			},
			dataLabels: {
				offsetY: 10
			},
			xaxis: {
				floating: false,
				categories: Instantaneous15Chart.date,
				labels: {
					show: true,
					rotate: -90,
					rotateAlways: true,
					offsetX: 0,
					offsetY: 3,
				},
				// tickPlacement: 'on'
			},
			fill: {
				type: 'bar',
				gradient: {
					shade: 'dark',
					type: "vertical",
					shadeIntensity: 0.5,
					gradientToColors: undefined, // optional, if not defined - uses the shades of same color in series
					inverseColors: true,
					opacityFrom: 1,
					opacityTo: 1,
					stops: [0, 50, 100],
					colorStops: []
				}
			},
			chart: {
				toolbar: {
					show: true
				}
			},
			noData: {
				text: "Loading...",
				align: 'center',
				verticalAlign: 'middle',
				offsetX: 0,
				offsetY: 0,
				style: {
					color: "#000000",
					fontSize: '14px',
					fontFamily: "Helvetica"
				},
			},
			colors: colors
			// colors: [
			//     "#99ccdd", "#e9cbff", "#ffcfcb", "#99ccee", "#99bbee", "#aabbee", "#aaddee", "#a4daef"
			// ]
		},

		series: [
			{
				name: ModelHeader,
				data: Instantaneous15Chart.total
			}
		]
	};

  const optionsLine = {
		// parsing: false,
		responsive: true,
		maintainAspectRatio: false,
		

		// parsing: false,
		plugins: {
			decimation: {
				enabled: true,
				algorithm: 'lttb',
				samples: 800,
				threshold: 800
			},
			legend: {
				position: 'bottom',
				labels: {
					pointStyleWidth: 10,
					usePointStyle: true,
					pointStyle: 'rectRounded'
				}
			},
			zoom: {
				zoom: {
					wheel: {
						enabled: true,
						modifierKey: 'ctrl' // SET SCROOL ZOOM TO TRUE
					},
					mode: "x",
				},
				pan: {
					enabled: true,
					mode: "x",
				}
			},
			datalabels: {
				backgroundColor: function (context) {
					return context.dataset.borderColor;
				},
				borderRadius: 4,
				align: 'end',
				anchor: 'end',
				clamp: true,
				color: "white",
				padding: {
					top: 0,
					bottom: 0
				},
				font: function (context) {
					var w = context.chart.width;
					return {
						size: w < 512 ? 10 : 12,

					};
				},

			}
		},
		elements: {
			line: {
				fill: true,
				tension: 0.3
			}
		},
		layout: {
			padding: {
				top: 20
			}
		},
	};
  const DailyUnitOption = {
    options: {
      legend: {
        show: false,
      },

      plotOptions: {
        bar: {
          distributed: true,
          borderRadius: 5,
          borderRadiusApplication: 'end',
          dataLabels: {
            position: 'top',
            offsetY: 30,
            fontSize: '10px'
          },

        }
      },
      dataLabels: {
        offsetY: 10
      },
      xaxis: {
        floating: false,
        categories: DailyUnit.date,
        labels: {
          show: true,
          rotate: -90,
          rotateAlways: true,
          offsetX: 0,
          offsetY: 3,
        },
        // tickPlacement: 'on'
      },
      yaxis:{
        labels: {
          show: true,
        },
        title:{text:'Rupee',style:{fontWeight:200}}
      },
      fill: {
        type: 'bar',
        gradient: {
          shade: 'dark',
          type: "vertical",
          shadeIntensity: 0.5,
          gradientToColors: undefined, // optional, if not defined - uses the shades of same color in series
          inverseColors: true,
          opacityFrom: 1,
          opacityTo: 1,
          stops: [0, 50, 100],
          colorStops: []
        }
      },
      chart: {
        toolbar: {
          show: false
        }
      },
      noData: {
        text: "Loading...",
        align: 'center',
        verticalAlign: 'middle',
        offsetX: 0,
        offsetY: 0,
        style: {
          color: "#000000",
          fontSize: '14px',
          fontFamily: "Helvetica"
        },
      },
      colors: colors
      // colors: [
      //     "#99ccdd", "#e9cbff", "#ffcfcb", "#99ccee", "#99bbee", "#aabbee", "#aaddee", "#a4daef"
      // ]
    },

    series: [
      {
        name: "Rupee",
        data: DailyUnit?.total
      }
    ]
  };
  const dataLine = {
		labels: APhase.date.map((item)=>([moment(item,'DD-MM-YYYY hh:mm:ss A').format('DD-MM-YYYY'),moment(item,'DD-MM-YYYY hh:mm:ss A').format('hh:mm:ss A')])),
		datasets: [
			{
				label: "Current IR",
				data: APhase.RA,
				fill: false,
				borderColor: 'rgb(225, 29, 72)',
				
			},
			{
				label: "Current IY",
				data: APhase.YA,
				fill: false,
				borderColor: 'rgb(253 224 71)'
			},
			{
				label: "Current IB",
				data: APhase.BA,
				fill: false,
				borderColor: 'rgb(29, 78, 216)',
			}
		]
	};

  const dataVoltageLine = {
		labels: VPhase.date.map((item)=>([moment(item,'DD-MM-YYYY hh:mm:ss A').format('DD-MM-YYYY'),moment(item,'DD-MM-YYYY hh:mm:ss A').format('hh:mm:ss A')])),
		datasets: [
      {
				label: "Voltage VR",
				data: VPhase.RV,
				fill: false,
				borderColor: 'rgb(225, 29, 72)'
			},
			{
				label: "Voltage VY",
				data: VPhase.YV,
				fill: false,
				borderColor: 'rgb(253 224 71)'
			},
			{
				label: "Voltage VB",
				data: VPhase.BV,
				fill: false,
				borderColor: 'rgb(29, 78, 216)'
			}
		]
	};

  const expandChartCurrent={
		labels: Instantaneous15Chart.date.map((item)=>([moment(item,'DD-MM-YYYY hh:mm:ss A').format('DD-MM-YYYY'),moment(item,'DD-MM-YYYY hh:mm:ss A').format('hh:mm:ss A')])),
		datasets: [
			{
				label: "Current IR",
				data: Instantaneous15Chart.RA,
				fill: false,
				borderColor: 'rgb(225, 29, 72)',
				
			},
			{
				label: "Current IY",
				data: Instantaneous15Chart.YA,
				fill: false,
				borderColor: 'rgb(253 224 71)'
			},
			{
				label: "Current IB",
				data: Instantaneous15Chart.BA,
				fill: false,
				borderColor: 'rgb(29, 78, 216)',
			}
		]
	};


  const expandChartVoltage={
		labels: ExpanVolt.date.map((item)=>([moment(item,'DD-MM-YYYY hh:mm:ss A').format('DD-MM-YYYY'),moment(item,'DD-MM-YYYY hh:mm:ss A').format('hh:mm:ss A')])),
		datasets: [
			{
				label: "Voltage VR",
				data: ExpanVolt.RV,
				fill: false,
				borderColor: 'rgb(225, 29, 72)',
				
			},
			{
				label: "Voltage VY",
				data: ExpanVolt.YV,
				fill: false,
				borderColor: 'rgb(253 224 71)'
			},
			{
				label: "Voltage VB",
				data: ExpanVolt.BV,
				fill: false,
				borderColor: 'rgb(29, 78, 216)',
			}
		]
	};
	const expandChartData = {
		labels: Instantaneous15Chart.date,
		datasets: [
			{
				label: ModelHeader,
				backgroundColor: ["rgba(103, 183, 220, 0.85)", "rgba(103, 148, 220, 0.85)", "rgba(103, 113, 220, 0.85)", "rgba(128, 103, 220, 0.85)", "rgba(163, 103, 220, 0.85)", "rgba(199, 103, 220, 0.85)", "rgba(220, 103, 206, 0.85)", "rgba(103, 113, 220, 0.85)"],
				data: Instantaneous15Chart.total,
			},
		],
	};

  const ExdataLine = {
		labels: Instantaneous15Chart?.date,
		datasets: [
			{
				label: "PCMIN",
				data: Instantaneous15Chart?.REQ_CONS_MIN,
				fill: false,
				borderColor: 'rgb(53, 162, 235)',
				backgroundColor: (context) => {
					const ctx = context.chart.ctx;
					const gradient = ctx.createLinearGradient(0, 0, 0, 400);
					gradient.addColorStop(0, "rgba(53, 162, 235, 0.8)");
					gradient.addColorStop(1, "rgba(53, 162, 235, 0)");
					return gradient;
					// return pattern.draw('dot', gradient)
				},
				
				// backgroundColor: 'rgba(53, 162, 235, 0.5)',
			},
			{
				label: "PCMAX",
				data: Instantaneous15Chart?.REQ_CONS_MAX,
				fill: false,
				borderColor: 'rgb(225, 29, 72',
				backgroundColor: (context) => {
					const ctx = context.chart.ctx;
					const gradient = ctx.createLinearGradient(0, 0, 0, 400);
					gradient.addColorStop(0, "rgb(254, 205, 211)");
					gradient.addColorStop(1, "rgb(254, 205, 211)");
					return gradient;
					// return pattern.draw('dot', gradient)
				},
				// backgroundColor: 'rgba(53, 162, 235, 0.5)',
			},
			{
				label: "PCAVG",
				data: Instantaneous15Chart?.REQ_CONS_AVG,
				fill: false,
				borderColor: 'rgb(29, 78, 216)',
				backgroundColor: (context) => {
					const ctx = context.chart.ctx;
					const gradient = ctx.createLinearGradient(0, 0, 0, 400);
					gradient.addColorStop(0, "rgb(191, 219, 254)");
					gradient.addColorStop(1, "rgb(191, 219, 254)");
					return gradient;
					// return pattern.draw('dot', gradient)
				},
				// backgroundColor: 'rgba(53, 162, 235, 0.5)',
			},
			{
				label: "Consumption",
				data: Instantaneous15Chart?.IMP_WH,
				fill: false,
				borderColor: 'rgb(8,145, 178)',
				backgroundColor: (context) => {
					const ctx = context.chart.ctx;
					const gradient = ctx.createLinearGradient(0, 0, 0, 400);
					gradient.addColorStop(0, "rgb(207, 250, 254)");
					gradient.addColorStop(1, "rgb(207, 250, 254)");
					return gradient;
					// return pattern.draw('dot', gradient)
				},
				// backgroundColor: 'rgba(53, 162, 235, 0.5)',
			}
		]
	};

    const onChange = (date, dateString) => {
      setDates({ ...dates, start: dateString })
    };
    const onChanges = (date, dateString) => {
      setDates({ ...dates, end: dateString })
    };
    const myChart = chartRef.current;
    let midValue = 0
    
    useEffect(() => {
      console.log('myChart',myChart)
      if (myChart != undefined || myChart != null) {
        const xMin = myChart.scales.x.min;
        const xMax = myChart.scales.x.max;
        console.log("minMax == ", xMin, xMax)
        midValue = xMax / 2
      }
      setTimeout(() => {
      if (myChart != undefined || myChart != null) {
        myChart.zoomScale('x', { min: midValue - 20, max: midValue + 20 }, 'default')
       
        console.log("midaVAlue in setTimeout == ", Math.round(midValue))
      }
      },10)
    }, [myChart])

    const chartLoad=chartInstaRef.current

    const zoomIn = () => {
      if (chartLoad != undefined || chartLoad != null) {
        chartLoad.zoom(1.2);
      }
    }
    const zoomOut = () => {
      if (chartLoad != undefined || chartLoad != null) {
        chartLoad.zoom(0.8);
      }
    }
  
    const panLeft = () => {
      if (chartLoad != undefined || chartLoad != null) {
        chartLoad.pan({ x: 400 }, undefined, 'default');
      }
    }
    const panRight = () => {
      if (chartLoad != undefined || chartLoad != null) {
        chartLoad.pan({ x: -400 }, undefined, 'default');
      }
    }
    useEffect(() => {
      console.log('chartLoad',chartLoad)
      if (chartLoad != undefined || chartLoad != null) {
        const xMin = chartLoad.scales.x.min;
        const xMax = chartLoad.scales.x.max;
        console.log("minMax == ", xMin, xMax)
        midValue = xMax / 2
      }
      if (chartLoad != undefined || chartLoad != null) {
        chartLoad.zoomScale('x', { min: midValue - 10, max: midValue + 10 }, 'default')
        
        console.log("midaVAlue in setTimeout == ", Math.round(midValue))
      }
    }, [chartLoad])

    const handleCancel = () => {
      setIsModalVisible(false);
      setInstantaneous15Chart({ total: [], date: [] })
      setChartLoader(false)
      setExpanVolt({RV:[],YV:[],RV:[],date:[]})

    };


    const onGetDateWiseChart = async () => {
      setChartLoader(true)
      var tableName = ''
      var col = ''
      var fild=''
      if (ModelHeader == 'Instantaneous') {
        tableName = props?.GSMdata.UNIQUE_TABLE_NAME.INSTANTANEOUS
        col = 'Clock$0_0_1_0_0_255'
      } else if (ModelHeader == 'Heartbeat') {
        tableName = props?.GSMdata.UNIQUE_TABLE_NAME.HEARTBEAT
        col = 'SCR_TS_ENTRY'
      } else if (ModelHeader == 'Monthbill') {
        tableName = props?.GSMdata.UNIQUE_TABLE_NAME.MONTH_BILL
        col = 'Register$0_0_0_1_2_255'
        fild='Register$1_0_1_8_0_255' 
      } else if (ModelHeader == 'Dailyload') {
        tableName = props?.GSMdata.UNIQUE_TABLE_NAME.DAILY_LOAD
        col = 'Clock$0_0_1_0_0_255'
        fild='Register$1_0_1_8_0_255' 

      } else if (ModelHeader == 'Loadsurvey') {
        tableName = props?.GSMdata.UNIQUE_TABLE_NAME.LOAD_SURVEY
        col = 'Clock$0_0_1_0_0_255'
        fild='Register$1_0_1_29_0_255' 

      }else if (ModelHeader == 'Instantaneous Voltage') {
        tableName = props?.GSMdata.UNIQUE_TABLE_NAME.INSTANTANEOUS
        col = 'Clock$0_0_1_0_0_255'
        fild='Register$1_0_32_7_0_255 as EMT_VOLT_RPH_V,Register$1_0_52_7_0_255 as EMT_VOLT_YPH_V,Register$1_0_72_7_0_255 as EMT_VOLT_BPH_V,Clock$0_0_1_0_0_255 as date' 

      }else if (ModelHeader == 'Instantaneous Current') {
        tableName = props?.GSMdata.UNIQUE_TABLE_NAME.INSTANTANEOUS
        col = 'Clock$0_0_1_0_0_255'
        fild='Register$1_0_31_7_0_255 as EMT_CUR_RPH_A,Register$1_0_51_7_0_255 as EMT_CUR_YPH_A,Register$1_0_71_7_0_255 as EMT_CUR_BPH_A,Clock$0_0_1_0_0_255 as date' 
      }
      else if (ModelHeader == 'Daily Consumtion History') {
        tableName = props?.GSMdata.UNIQUE_TABLE_NAME.DAILY_LOAD
        col = 'Clock$0_0_1_0_0_255'
      }
      if(ModelHeader == 'Loadsurvey'){
        const data = await TableNameWiseLast24HoursDataSelectedDate({ startDate: ModelHeader == 'Monthbill' ? dates.start : moment(dates.start, "DD-MM-YYYY").format("YYYY-MM-DD"), endDate: ModelHeader == 'Monthbill' ? dates.end : moment(dates.end, "DD-MM-YYYY").format("YYYY-MM-DD"), fild: fild||'count(*)', colName: col, scriptid: props?.GSMdata?.SCRIPTDECODE_ID, tableName: tableName })
        if (data.success === true) {
         
          setInstantaneous15Chart({ total: data.data.data.map((item) => (columnWiseData(item.EMT_PENRG_BE_IMP_WH,col,'LOAD_SURVEY'))), date: data.data.data.map((item) => (ModelHeader == 'Monthbill' ? moment(item.date, "YYYY-MM").format("MMMM-YYYY") : moment.utc(item.date).format("DD-MM-YYYY hh:mm:ss A"))) })
          setChartLoader(false)
    
        } else {
          console.log('Something Went Wrong')
          setChartLoader(false)
        }
      }else if(ModelHeader == 'Instantaneous Current'||ModelHeader == 'Instantaneous Voltage'){
        const data=await GetInstVoltAndCuruntData({ startDate: moment(dates.start, "DD-MM-YYYY").format("YYYY-MM-DD 00:00:00"), endDate: moment(dates.end, "DD-MM-YYYY").format("YYYY-MM-DD 00:00:00"),type:'expand', fild: fild, col: col, scriptid: props?.GSMdata?.SCRIPTDECODE_ID, tableName: tableName })
        if(data.success==true){
          if(ModelHeader == 'Instantaneous Voltage'){
            setExpanVolt({RV:data?.data?.data?.map((item)=>(columnWiseData(item?.EMT_VOLT_RPH_V,col,'INSTANTANEOUS'))),YV:data?.data?.data?.map((item)=>(columnWiseData(item?.EMT_VOLT_YPH_V,col,'INSTANTANEOUS'))),BV:data?.data?.data?.map((item)=>(columnWiseData(item?.EMT_VOLT_BPH_V,col,'INSTANTANEOUS'))),date:data.data.data.map((item) => (moment.utc(item.EMT_TS_INST).format("DD-MM-YYYY hh:mm:ss A"))),res_code:'200'})
          }else{
        setInstantaneous15Chart({RA:data?.data?.data?.map((item)=>(columnWiseData(item?.EMT_CUR_RPH_A,col,'INSTANTANEOUS'))),YA:data?.data?.data?.map((item)=>(columnWiseData(item?.EMT_CUR_YPH_A,col,'INSTANTANEOUS'))),BA:data?.data?.data?.map((item)=>(columnWiseData(item?.EMT_CUR_BPH_A,col,'INSTANTANEOUS'))),date:data.data.data.map((item) => (moment.utc(item.EMT_TS_INST).format("DD-MM-YYYY hh:mm:ss A")))})
          }
      setChartLoader(false)

        }else{
          setChartLoader(false)

        }
      }else if(ModelHeader == 'Monthbill'){
        var tableName = props?.GSMdata.UNIQUE_TABLE_NAME?.MONTH_BILL
		var col = 'Register$0_0_0_1_2_255'
		const data = await getGSMMonthlyConsumptionByDate({ startDate: moment(dates.start, "DD-MM-YYYY").add(-1,'days').format("YYYY-MM-DD"), endDate: moment(dates.end, "DD-MM-YYYY").format("YYYY-MM-DD"), fild: 'Register$1_0_1_29_0_255 as lengths,Register$0_0_0_1_2_255 as date', col: col, scriptid: props?.GSMdata?.SCRIPTDECODE_ID, tableName: tableName })
		if (data.success === true) {
      const TotalData=data.data.data
        var DateArray=[]
        TotalData.map((item,id) => {
          if(id!=0){DateArray.push(moment.utc(TotalData[id-1]?.date).format("DD-MM-YYYY")+'-'+moment.utc(item.date).format("DD-MM-YYYY"))
        }})
        var totalArray=[]
        TotalData.map((item,id) => {
          if(id!=0){
            if(item.lengths!=null){
            if(item.lengths!=0){
                totalArray.push(columnWiseData(((item.lengths-TotalData[id-1]?.lengths)>0?(item.lengths-TotalData[id-1]?.lengths):0).toFixed(3),col,'MONTH_BILL'))
          }else{
            totalArray.push(0)
          }
          }
        }
        })
			setInstantaneous15Chart({ total: totalArray, date: DateArray})
      setChartLoader(false)

		} else {
			console.log('Something Went Wrong')
      setChartLoader(false)

		}
      }
        else{
          const column_info= ColumnName?.INSTANTANEOUS?.filter((item)=>(item?.Column=='Register$1_0_1_8_0_255'))[0]||{}

          const data = await getGSMDailyConsumptionByConsumerIdAndSletedate({ start: moment(dates.start, "DD-MM-YYYY").format("YYYY-MM-DD"), end: moment(dates.end, "DD-MM-YYYY").format("YYYY-MM-DD"), UNIQUE_ID: props?.GSMdata?.UNIQUE_ID,column_info:column_info,MF:props?.GSMdata?.MF})
          if (data.success === true) {
            const TotalData=data.data.data
           if(ModelHeader=='Daily Consumption History'){
              setInstantaneous15Chart({ total: TotalData.map((item)=>(item?.CUM_IMP_WH?.toFixed(3))), date: TotalData.map((item)=>(moment(item.date,'YYYY-MM-DD').add(-1,'days').format("DD-MM-YYYY"))) })
           }else if(ModelHeader=='Consumption Graph'){
            setInstantaneous15Chart({REQ_CONS_MIN: TotalData.map((item)=>(item?.REQ_CONS_MIN?item?.REQ_CONS_MIN?.toFixed(3):0)),REQ_CONS_MAX:TotalData.map((item)=>(item?.REQ_CONS_MAX?item?.REQ_CONS_MAX?.toFixed(3):0)),REQ_CONS_AVG:TotalData.map((item)=>(item?.REQ_CONS_AVG?item?.REQ_CONS_AVG?.toFixed(3):0)),IMP_WH:TotalData.map((item)=>(item?.CUM_IMP_WH?.toFixed(3))), date: TotalData.map((item)=>(moment(item.date,'YYYY-MM-DD').add(-1,'days').format("DD-MM-YYYY")))})
           }else if(ModelHeader=='Consumption In Rupees'){
            setInstantaneous15Chart({ total: TotalData.map((item)=>(item?.UNIT_RATE?.toFixed(2))), date: TotalData.map((item)=>(moment(item.date,'YYYY-MM-DD').add(-1,'days').format("DD-MM-YYYY"))) })
           }else{
            setInstantaneous15Chart({ total: data.data.data.map((item) => (item.lengths)), date: data.data.data.map((item) => (ModelHeader == 'Monthbill' ? moment(item.date, "YYYY-MM").format("MMMM-YYYY") : moment(item.date, "YYYY-MM-DD").format("DD-MM-YYYY"))) })
           }
            setChartLoader(false)
  
      } else {
        console.log('Something Went Wrong')
        setChartLoader(false)
      }
    }
    }
    useEffect(()=>{
      socket.on('mqttbufferresponse',(massges)=>{
        console.log(massges);
        setCommandStatus(massges)
        
      })
      socket.on('mqttcommandresponse',(massges)=>{
        console.log(massges,CommandJson,massges.MSGID==CommandJson.MSGID,massges.SCRIPTDECODE_ID==CommandJson.SCRIPTDECODE_ID,massges.UNIQUE_ID==CommandJson.UNIQUE_ID,massges.CMD_TYPE==CommandJson.CMD_TYPE,massges.DRXTYPE,!massges.STATUS);
        setCommandmsgJson(massges)
      })
    },[])
    const BfferError=(msg)=>{
          console.log(msg.MSGID,CommandJson.MSGID);
          if(msg.MMD_ID_MSG==CommandJson.MMD_ID_MSG){
          swal.fire('', msg?.SCR_STATUS_CMD_ADD, 'error');
          setCommandStatus('')
          setFlagMsg(true)
          setRun(false);
          setTime(0)
          setModal(false)
      }
      }
      useEffect(()=>{
          if(CommandStatus?.SCR_STATUS_CMD_ADD!='Added In Buffer'&&Object.keys(CommandStatus).length>0){
              BfferError(CommandStatus)
        clearTimeout(mytime.current);
          mytime.current = null
          }else if(CommandStatus?.SCR_STATUS_CMD_ADD=='Added In Buffer'){
        setTime(60)
      setRun(true);
      clearTimeout(mytime.current);
          mytime.current = null
      }
      },[CommandStatus])
    const getPopdata=(massges)=>{
      console.log(massges,CommandJson);
    if(massges.MMD_ID_MSG==CommandJson.MMD_ID_MSG&&massges.SCRIPTDECODE_ID==CommandJson.SCRIPTDECODE_ID&&massges.UNIQUE_ID==CommandJson.UNIQUE_ID&&massges.SCR_ID_CMD_TYPE==CommandJson.SCR_ID_CMD_TYPE&&massges.MMD_ID_DRXTYPE&&!massges.STATUS){
        setTime(0)
        setRun(false);
         setModal(false)
         GetLastINSdata()
        
      }
    }
    
    useEffect(()=>{
        if(Object.keys(CommandmsgJson).length>0){
        getPopdata(CommandmsgJson)
        }
    },[CommandmsgJson])
    useInterval(
      () => {
        if (time <= 1) {
          setRun(false);
          setModal(false)
        
        }
        setTime((preState) => preState - 1);
      },
      time && run ? 1000 : null
    );
    
    function zeroPad(num) {
      var str = String(num);
      if (str.length < 2) {
        return "0" + str;
      }
    
      return str;
    }
    
    const CloseModel=()=>{
      setCommandmsgJson({})
      setCommandStatus({})
      setFlagMsg(true)
      setRun(false);
      setTime(0)
      setModal(false)
    //   swal.fire('', 'Command Add In Buffer', 'success');
  
    }

    const ongetScriptCodeIdWiseConfigretionData=async()=>{
      const data=await getScriptCodeIdWiseConfigretionData(props?.GSMdata?.SCRIPTDECODE_ID,{tableName:props?.GSMdata?.UNIQUE_TABLE_NAME?.GSM_BUFFER,type:'INSTANTANEOUS'})
      if(data.success===true){
        const CMD=JSON.parse(data?.data?.data[0]?.METER_QUERY_CMD||'{}')
          const obj={MMD_ID_CMD:124,SCR_ID_CMD_TYPE:'INSTANTANEOUS',"MMD_ID_OBIS_CODE": CMD?.INSTANTANEOUS?.OBIS,"MMD_ID_OBIS_OBJTYPE": CMD?.INSTANTANEOUS?.OBJ_TYPE,UNIQUE_ID:props?.GSMdata?.UNIQUE_ID,MMD_ID_IMEI:props?.GSMdata?.IMEI,SCRIPTDECODE_ID:props?.GSMdata?.SCRIPTDECODE_ID,MMD_ID_MSG:new Date().getTime()+1,MMD_ID_OBIS_READ_METHOD: CMD?.INSTANTANEOUS?.READ_METHOD,GSM_BUFFER_TABLE:props?.GSMdata?.UNIQUE_TABLE_NAME?.GSM_BUFFER}
  
            setFlagMsg(false)
            setmodelTitle(obj.SCR_ID_CMD_TYPE)
                    setCommandJson(obj)
  
            swal.fire({
              title: "Are you sure?",
              text: "This will initiate downlink",
              icon: "warning",
              showCancelButton: true,
            }).then(async (willDelete) => {
              if (willDelete.isConfirmed) {
            setModal(true)
            ;
          
            const data=await onModemConfigByGSMConsumer({config_info:JSON.stringify(obj)})
            if(data.success===true){
            mytime.current=setTimeout(() => {
              if(Object.keys(CommandStatus).length===0)
                         {
                           setCommandStatus({SCR_STATUS_CMD_ADD:'Time Out'})
                         }
                         },5000)
            }else{
             
            }
          }
          })
        
        
      }
    }

    const realdata = (data) => {
      const result = {};
  
      data.forEach(entry => {
          const dateTime = new Date(entry['HOUR']);
          const date = moment(dateTime.toISOString().split('T')[0],'YYYY-MM-DD').format('DD-MM-YYYY');
          const hour = dateTime.getUTCHours();
  
          const kwh =columnWiseData(entry['Total_KWh_Import'],'Register$1_0_1_29_0_255','LOAD_SURVEY');
  
          if (!result[date]) {
              result[date] = Array(24).fill(0);
          }
  
          result[date][hour] = kwh;
      });
  
      return result;
  }

    const FinalSet = (data) => {
      console.log(data);
  
      const Finaldata = [];
  
      for (let hour = 23; hour >= 0; hour--) {
  
          const dayData = { id: hour };
  
          Object.keys(data).forEach((element,index) => {
  
              if (index === 0) {
                  dayData[`data`] = []
              }
              dayData[`data`] = [
                  ...dayData[`data`],
                  {
                      "x": element,
                      "y": data[element][hour]
                  }
  
              ];
          })
  
          Finaldata.push(dayData);
      }
  
      return Finaldata;
  }
    // const FirstData = realdata(realDatas);

    const [dataHourKWH,setdataHourKWH] = useState([])

    const onHoursWiseConsumptionGraphBytableName=async()=>{
      const data=await HoursWiseConsumptionGraphBytableName({tableName:props?.GSMdata?.UNIQUE_TABLE_NAME?.LOAD_SURVEY,colName:'Clock$0_0_1_0_0_255'})
      if(data.success==true){
        const ConvertedData=realdata(data?.data?.data)
        setdataHourKWH(FinalSet(ConvertedData))

      }else{
        setdataHourKWH([])
      }
    }

  return (
    <>

<Modal  visible={modal} footer='' maskClosable={false} onCancel={() => (CloseModel())} width={500}>
    <h5 class="modal-title text-center" id="meter_data_mqtt_info_txt">Getting {modelTitle} Command</h5>
	<h5 class="modal-title text-center mt-2 " id="meter_data_mqtt_info_txt">{!CommandStatus?.SCR_STATUS_CMD_ADD&&CommandStatus?.SCR_STATUS_CMD_ADD!='Added In Buffer'?<img className='mr-2' src={clockPending} height='20px' width={'20px'}></img>:CommandStatus?.SCR_STATUS_CMD_ADD!='Added In Buffer'?<span><i className='fas fa-times mr-2' style={{color:'red'}}></i></span>:<span><i className='fas fa-check mr-2' style={{color:'green'}}></i></span>}{!CommandStatus?.SCR_STATUS_CMD_ADD?'Command Pending':CommandStatus?.SCR_STATUS_CMD_ADD}</h5>
    <div class=""> 
   { CommandStatus?.SCR_STATUS_CMD_ADD=='Added In Buffer'? <div class="text-center" style={{marginTop:"10px",fontSize: "20px"}}>
                     <label style={{fontWeight:"400"}}>Waiting For Respose</label>
                     <div id="meter_data_mqtt_timer_status" style={{fontSize: "18px"}}>{`${zeroPad(time)}`} seconds left</div>
                  </div>:''}
               </div>
               <div class="text-center" style={{marginTop:"20px"}}>
                  <input type="button" class="btn btn-danger " value="Close" id="meter_data_mqtt_cancel_button" name="meter_data_mqtt_cancel_button" onClick={CloseModel}/>
               </div>
            </Modal>
            <Modal  visible={modalMsg} footer='' maskClosable={false} onCancel={() => (setmodalMsg(false))} width={500}>
			{MqttMsg}
            </Modal>
<Modal title={ModelHeader} visible={isModalVisible} footer='' maskClosable={false} onCancel={handleCancel} width={1800}>
			<div className="d-flex tw-justify-between tw-items-center">
					<div className="d-flex tw-justify-center tw-items-end">
					<Space direction="horizontal">
						<div className="d-flex flex-column">Start In
							<DatePicker onChange={onChange} format='DD-MM-YYYY' name='start' disabledDate={(current) => {
								return (moment(expireDate.start_date).add(-1, 'days') >= current) || (moment() <= current);
							}} /></div>
						<div className="d-flex flex-column" >End In
							<DatePicker onChange={onChanges} name='end' format='DD-MM-YYYY' disabledDate={(current) => {
								return (moment(expireDate.start_date).add(-1, 'days') >= current) || (moment() <= current);
							}} /></div>
					</Space>
					<a className={`btn btn-danger text-white ml-3 mt-3 ${ChartLoader ? 'btn-progress disabled' : ''}`} onClick={() => (onGetDateWiseChart())}>Submit</a>
				</div>
				{Instantaneous15Chart?.date?.length > 0||ExpanVolt?.date?.length && <div>
						<div>
							<div className="tw-mb-1">
								<small className='tw-text-red-300'><sup className="tw-text-sm !tw-top-0">*</sup> User can also do Zoom and Scroll using their mouse</small>
							</div>
							<div className="tw-flex tw-justify-end tw-items-center">
								<button className={`tw-rounded-lg tw-bg-indigo-500 tw-border-transparent text-white tw-mx-1 tw-p-1`} onClick={zoomIn}><BootstrapTooltip title="ZoomIn">
									<div className="tw-flex tw-items-center fa-lg">
										<i class="fas fa-search-plus" aria-hidden="true"></i>
									</div>
								</BootstrapTooltip>
								</button>
								<button className={`tw-rounded-lg tw-bg-indigo-500 tw-border-transparent text-white tw-mx-1 tw-p-1`} onClick={zoomOut}>
									<BootstrapTooltip title='ZoomOut'>
										<div className="tw-flex tw-items-center fa-lg">
											<i class="fas fa-search-minus" aria-hidden="true"></i>
										</div>
									</BootstrapTooltip>
								</button>

								<button className="tw-rounded-lg tw-bg-indigo-500 tw-border-transparent text-white tw-mx-1 tw-py-1 tw-px-2" onClick={panLeft}>
									<BootstrapTooltip title='Left scroll'>
										<div className="tw-flex tw-items-center fa-lg">
											<i class="fas fa-caret-left" aria-hidden="true"></i>
										</div>
									</BootstrapTooltip>
								</button>
								<button className="tw-rounded-lg tw-bg-indigo-500 tw-border-transparent text-white tw-mx-1 tw-py-1 tw-px-2" onClick={panRight}>
									<BootstrapTooltip title='Right scroll'>
										<div className="tw-flex tw-items-center fa-lg">
											<i class="fas fa-caret-right" aria-hidden="true"></i>
										</div>
									</BootstrapTooltip>
								</button>
							</div>

						</div>

					</div>}
				</div>
				{Instantaneous15Chart?.total?.length > 0 && Instantaneous15Chart?.date?.length > 0 ? <div className="tw-mt-2 tw-h-[560px] tw-max-h-screen tw-px-5"><Bar ref={chartInstaRef} options={expandChartoptions} data={expandChartData} /> </div> : ''}
        {Instantaneous15Chart?.RA?.length > 0 && Instantaneous15Chart?.date?.length > 0 ? <div className="tw-mt-2 tw-h-[560px] tw-max-h-screen tw-px-5"><Line ref={chartInstaRef} options={optionsLine} data={expandChartCurrent} /> </div> : ''}
        {ExpanVolt?.RV?.length > 0 && ExpanVolt?.date?.length > 0 ? <div className="tw-mt-2 tw-h-[560px] tw-max-h-screen tw-px-5"><Line ref={chartInstaRef} options={optionsLine} data={expandChartVoltage} /> </div> : ''}
        {ModelHeader=='Consumption Graph'&&Instantaneous15Chart?.date?.length > 0&&<div className="tw-mt-2 tw-h-[560px] tw-max-h-screen tw-px-5"><Line ref={chartInstaRef} options={optionsLine} data={ExdataLine} /> </div>}
			</Modal>
      <Modal title={ModelHeader} visible={isModalVisibleMonth} maskClosable={false} footer='' onCancel={handleCancelMonth} width={1000}>
				<div className="d-flex">
					<Space direction="horizontal">
						<div className="d-flex flex-column">Start In
							<DatePicker onChange={onChange} format='MM-YYYY' name='start' picker="month" disabledDate={(current) => current.isAfter(moment())}/></div>
						<div className="d-flex flex-column" >End In
							<DatePicker onChange={onChanges} name='end' format='MM-YYYY' picker="month" disabledDate={(current) => current.isAfter(moment())}/></div>
					</Space>
					<a className={`btn btn-danger text-white ml-3 mt-3 ${ChartLoader ? 'btn-progress disabled' : ''}`} onClick={onGetDateWiseChart}>Submit</a>
				</div>
				{Instantaneous15Chart?.total?.length > 0 && Instantaneous15Chart?.date?.length > 0 && sum(Instantaneous15Chart.total) > 0 ? <Chart options={Instantaneous15state.options} type="bar" series={Instantaneous15state.series}
					height={"500"} /> : ''}
			</Modal>
    <div class="row">
    <div class="col-css-custom col-lg-3 col-md-6 col-sm-6 col-12 " >
        <div className='card card-statistic-1 tw-h-[93%]'>
          <div class='d-flex tw-justify-between mb-2'><h5 class='ml-3 mt-4' >Meter Instantaneous</h5><span class='btn btn-primary mr-2 mt-4' onClick={ongetScriptCodeIdWiseConfigretionData}>Get Data</span></div>
                {Loader===true?<div className="tw-flex tw-justify-center tw-items-center tw-min-h-[315px]"><ChartLoaders /></div>:Status==503?<h4 className="tw-flex tw-justify-center tw-items-center tw-min-h-[300px]">Table Not Found</h4>:Object.keys(InstantaneousData||{}).length==0?<h4 className="tw-flex tw-justify-center tw-items-center tw-min-h-[300px]">Data Not Found</h4>:<><div class=" d-flex mt-4 mb-3" style={{flexDirection:'row',justifyContent:'center'}}>
                  <div class="card-icon l-bg-red_light tw-font-bold !tw-w-[95px] !tw-h-[95px]" style={{lineHeight:'70px'}}> 
                   <div className='mb-1' style={{height:'10px'}}>{(InstantaneousData?.EMT_CUR_RPH_A)?.toFixed(3)} A</div>
                    <div>{(InstantaneousData?.EMT_VOLT_RPH_V)?.toFixed(3)} V</div>
                  </div>
                  <div class="ml-3 card-icon l-bg-yello_light tw-font-bold !tw-w-[95px] !tw-h-[95px]" style={{lineHeight:'70px'}}>
                  <div className='mb-1' style={{height:'10px'}}>{(InstantaneousData?.EMT_CUR_YPH_A)?.toFixed(3)} A</div>
                  <div>{(InstantaneousData?.EMT_VOLT_YPH_V)?.toFixed(3)} V</div>
                  </div>
                  <div class="ml-3 card-icon l-bg-blue_light tw-font-bold !tw-w-[95px] !tw-h-[95px]" style={{lineHeight:'70px'}}>
                  <div className='mb-1' style={{height:'10px'}}>{(InstantaneousData?.EMT_CUR_BPH_A)?.toFixed(3)} A</div>
                  <div>{(InstantaneousData?.EMT_VOLT_BPH_V)?.toFixed(3)} V</div>
                  </div>
                  </div>
                  <div className='d-flex tw-flex-row mt-3 tw-justify-center' >
                  <h4><a class="ml-2 badge badge-primary text-light">PF : {(Number(InstantaneousData?.EMT_PF_AVG))?.toFixed(3) }</a></h4>
                  <h4><a class="ml-2 badge badge-secondary text-light">{(InstantaneousData?.EMT_FREQ_HZ)?.toFixed(3)  } Hz</a></h4>
                  
                  </div>
                  <div className='d-flex tw-flex-row mt-2 tw-justify-center'>
                    <h4><a class="ml-2 badge badge-success text-light">{(InstantaneousData?.EMT_PPWR_AVG_W)?.toFixed(3)} KW</a></h4>
                  <h4><a  class="ml-2 badge badge-danger text-light">{(InstantaneousData?.EMT_PENRG_CUM_IMP_WH)?.toFixed(3) } KWH</a></h4>
                  </div>
                  <div className='d-flex tw-flex-row mt-2 tw-justify-center'>
                    <h4><a class="ml-2 badge badge-info text-dark" style={{fontSize:'13px'}}>Billing Date Time: {moment.utc(InstantaneousData?.MONTH_BILL_TS).format('DD-MM-YYYY hh:m:ss A')}</a></h4>
                  </div>
                  <div className='d-flex tw-flex-row mt-2 tw-justify-end mr-4' >
                  <BootstrapTooltip title={InstantaneousData.EMT_TS_INST?moment.utc(InstantaneousData.EMT_TS_INST).format("DD-MM-YYYY hh:mm:ss A"):''} placement="top"><td>{InstantaneousData?.EMT_TS_INST?moment.utc(InstantaneousData.EMT_TS_INST).add(-moment().utcOffset(), "m").fromNow('ss:hh:mm'):''}</td></BootstrapTooltip>
                </div></>}
                </div>
                
              </div>

              <div class="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-xs-12">
								<div class="card card-success">
									<div class="card-header" style={{ padding: "10px 15px" }}>
										<h4><span className="mr-2">Daily Consumption History</span><BootstrapTooltip title='Find Data In Table gsm_daily_load_monitoring_day_wise_graph' placement='top'><span><i className='fas fa-info-circle info_circle'></i></span></BootstrapTooltip></h4>
										<div class="card-header-action">
											<a class="btn btn-info text-white" data-toggle="tooltip" data-placement="Top" title="Expand" onClick={() => onOpenModal(props?.GSMdata.UNIQUE_TABLE_NAME.DAILY_LOAD, 'Clock$0_0_1_0_0_255', 'Daily Consumption History')}>Expand</a>
										</div>
									</div>
									<div class="card-body" style={{ padding: "0px" }}>
										{DailyChart.date.length > 0 && DailyChart.total.length > 0  ? <Chart options={Dailystate.options} type="bar" series={Dailystate.series} height={"300"} /> :  DailyChart?.res_code==503?<h4 className="tw-flex tw-justify-center tw-items-center tw-min-h-[300px]">Table Not Found</h4>: <div className="tw-flex tw-justify-center tw-items-center tw-min-h-[315px]"><ChartLoaders /></div>}
									</div>
									<p class="text-right" style={{ marginRight: "20px", lineHeight: "10px" }}>Bar chart</p>
								</div>
							</div>

              <div class="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-xs-12">
								<div class="card card-success">
									<div class="card-header" style={{ padding: "10px 15px" }}>
										<h4><span className="mr-2">Month Consumption History</span><BootstrapTooltip title='Find Data In MONTH_BILL table(column EMT_PENRG_CUM_IMP_WH) Register$0_0_0_1_2_255 Wise' placement='top'><span><i className='fas fa-info-circle info_circle'></i></span></BootstrapTooltip></h4>
										<div class="card-header-action">
											<a class="btn btn-info text-white" data-toggle="tooltip" data-placement="Top" title="Expand" onClick={() => {
												onOpenModal(props?.GSMdata.UNIQUE_TABLE_NAME.MONTH_BILL, 'Register$0_0_0_1_2_255', 'Monthbill')
											}}>Expand</a>
										</div>
									</div>
									<div class="card-body" style={{ padding: "0px" }}>
										{MonthlyChart.date.length > 0 && MonthlyChart.total.length > 0 ? <Chart options={Monthlytate.options} type="bar" series={Monthlytate.series} height={"300"} /> :  MonthlyChart?.res_code==503?<h4 className="tw-flex tw-justify-center tw-items-center tw-min-h-[300px]">Table Not Found</h4>:MonthlyChart?.res_code!='200'?<h4 className="tw-flex tw-justify-center tw-items-center tw-min-h-[300px]">Data Not Found</h4>: <div className="tw-flex tw-justify-center tw-items-center tw-min-h-[315px]"><ChartLoaders /></div>}
									</div>
									<p class="text-right" style={{ marginRight: "20px", lineHeight: "10px" }}>Bar chart</p>
								</div>
							</div>

              <div class="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-xs-12">
								<div class="card card-success">
									<div class="card-header" style={{ padding: "10px 15px" }}>
										<h4><span className="mr-2">Consumption Graph</span><BootstrapTooltip title='Find Data In Table gsm_daily_load_monitoring_day_wise_graph' placement='top'><span><i className='fas fa-info-circle info_circle'></i></span></BootstrapTooltip></h4>
										<div class="card-header-action">
											<a class="btn btn-info text-white" data-toggle="tooltip" data-placement="Top" title="Expand" onClick={() => onOpenModal('gsm_daily_load_monitoring_day_wise_graph', 'CREATED_AT', 'Consumption Graph')}>Expand</a>
										</div>
									</div>
									<div class="card-body" style={{ padding: "0px" }}>
                  {REQ_CONS_Count?.date.length > 0  ? <div class='tw-h-[316px]'><Line ref={chartRefPC} options={optionsPC} data={dataPC} /></div> : <div className="d-flex tw-h-[316px]" style={{ justifyContent: 'center' }}><ChartLoaders /></div>}
									</div>
									<p class="text-right" style={{ marginRight: "20px", lineHeight: "10px" }}>Bar chart</p>
								</div>
							</div>
              <div class="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-xs-12">
								<div class="card card-success">
									<div class="card-header" style={{ padding: "10px 15px" }}>
										<h4><span className="mr-2">Consumption In Rupees</span><BootstrapTooltip title='Find Data In gsm_daily_load_monitoring_day_wise_graph table' placement='top'><span><i className='fas fa-info-circle info_circle'></i></span></BootstrapTooltip></h4>
										<div class="card-header-action">
										<a class="btn btn-info text-white" data-toggle="tooltip" data-placement="Top" title="Expand" onClick={() => onOpenModal('gsm_daily_load_monitoring_day_wise_graph', 'CREATED_AT', 'Consumption In Rupees')}>Expand</a>
										</div>
									</div>
									<div class="card-body" style={{ padding: "0px" }}>
										{DailyUnit?.date?.length > 0 && DailyUnit?.total?.length > 0 ? <Chart options={DailyUnitOption.options} type="bar" series={DailyUnitOption.series} height={"300"} /> : DailyUnit?.res_code==503?<h4 className="tw-flex tw-justify-center tw-items-center tw-min-h-[300px]">Table Not Found</h4>: <div className="tw-flex tw-justify-center tw-items-center tw-min-h-[315px]"><ChartLoaders /></div>}
									</div>
									<p class="text-right" style={{ marginRight: "20px", lineHeight: "10px" }}>Bar chart</p>
								</div>
							</div>
              {/* <div class="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-xs-12">
								<div class="card card-success">
									<div class="card-header" style={{ padding: "10px 15px" }}>
										<h4><span className="mr-2">Hours wise KWH*</span><BootstrapTooltip title='Find Data In INSTANTANEOUS table(column Register$1_0_31_7_0_255,Register$1_0_51_7_0_255,Register$1_0_71_7_0_255) Clock$0_0_1_0_0_255 Wise' placement='top'><span><i className='fas fa-info-circle info_circle'></i></span></BootstrapTooltip></h4>
										<div class="card-header-action">
											
										</div>
									</div>
									<div class="card-body" style={{height:'500px' }}>
                    <ResponsiveHeatMap
                    data={dataHourKWH}
                    margin={{ top: 0, right: 20, bottom: 80, left: 50 }}
                    forceSquare={false}
                    axisTop={null}
                    axisRight={null}
                    labelTextColor={'#949494'}
                    axisBottom={{
                        orient: 'bottom',
                        tickSize: 5,
                        tickPadding: 5,
                        tickRotation: -90,
                        legend: 'Date',
                        legendPosition: 'middle',
                        legendOffset: 70,
                        format: d => d
                    }}
                    
                    xOuterPadding={0.10}
                    xInnerPadding={0.05}
                    yOuterPadding={0.15}
                    yInnerPadding={0.15}
                    axisLeft={{
                        orient: 'left',
                        tickSize: 5,
                        tickPadding: 5,
                        tickRotation: 0,
                        legend: 'Hours',
                        legendPosition: 'middle',
                        legendOffset: -30
                    }}
                    cellOpacity={1}
                    colors={{
                        type: 'sequential',
                        scheme: 'blues',
                        divergeAt:0.25
                    }}
                    
                    cellBorderColor = "#00FF00"
                    defs={[
                        {
                            id: 'lines',
                            type: 'patternLines',
                            background: 'inherit',
                            color: 'rgba(0, 0, 0, 0.1)',
                            rotation: -45,
                            lineWidth: 4,
                            spacing: 14
                        }
                    ]}
                    fill={[{ id: 'lines' }]}
                    animate={true}
                    motionStiffness={80}
                    motionDamping={9}
                    hoverTarget="cell"
                    cellHoverOthersOpacity={0.50}
                    cellShape={({ width, height, value }) => (
                        <rect
                            x={-width / 2}
                            y={-height / 2}
                            width={width}
                            height={height * 2} 
                            fill="currentColor"
                            strokeWidth={0}
                        />
                    )}
                />
									</div>
									<p class="text-right" style={{ marginRight: "20px", lineHeight: "10px" }}>Bar chart</p>
								</div>
							</div> */}
              <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
								<div class="card card-success">
									<div class="card-header" style={{ padding: "10px 15px" }}>
										<h4><span className="mr-2">Instantaneous Current</span><BootstrapTooltip title='Find Data In INSTANTANEOUS table(column Register$1_0_31_7_0_255,Register$1_0_51_7_0_255,Register$1_0_71_7_0_255) Clock$0_0_1_0_0_255 Wise' placement='top'><span><i className='fas fa-info-circle info_circle'></i></span></BootstrapTooltip></h4>
										<div class="card-header-action">
											<a class="btn btn-info text-white" data-toggle="tooltip" data-placement="Top" title="Expand" onClick={() => onOpenModal(props?.GSMdata.UNIQUE_TABLE_NAME.DAILY_LOAD, 'Clock$0_0_1_0_0_255', 'Instantaneous Current')}>Expand</a>
										</div>
									</div>
									<div class="card-body" style={{height:'400px' }}>
										{PhaseLoader == false  ? PhaseLoader==false&&APhase?.date.length===0?<h4 className="tw-flex tw-justify-center tw-items-center tw-min-h-[300px]">Data Not Found</h4>:<Line ref={chartRefLine} options={optionsPC} data={dataLine} /> :  APhase?.res_code==503?<h4 className="tw-flex tw-justify-center tw-items-center tw-min-h-[300px]">Table Not Found</h4>: <div className="tw-flex tw-justify-center tw-items-center tw-min-h-[315px]"><ChartLoaders /></div>}
									</div>
									<p class="text-right" style={{ marginRight: "20px", lineHeight: "10px" }}>Bar chart</p>
								</div>
							</div>

              <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
								<div class="card card-success">
									<div class="card-header" style={{ padding: "10px 15px" }}>
										<h4><span className="mr-2">Instantaneous Voltage</span><BootstrapTooltip title='Find Data In INSTANTANEOUS table(column Register$1_0_32_7_0_255,Register$1_0_52_7_0_255,Register$1_0_72_7_0_255) Clock$0_0_1_0_0_255 Wise' placement='top'><span><i className='fas fa-info-circle info_circle'></i></span></BootstrapTooltip></h4>
										<div class="card-header-action">
											<a class="btn btn-info text-white" data-toggle="tooltip" data-placement="Top" title="Expand" onClick={() => onOpenModal(props?.GSMdata.UNIQUE_TABLE_NAME.DAILY_LOAD, 'Clock$0_0_1_0_0_255', 'Instantaneous Voltage')}>Expand</a>
										</div>
									</div>
									<div class="card-body" style={{height:'400px' }}>
										{PhaseLoader==false  ?PhaseLoader==false&&APhase?.date.length===0?<h4 className="tw-flex tw-justify-center tw-items-center tw-min-h-[300px]">Data Not Found</h4>: <Line  options={optionsPC} data={dataVoltageLine} /> :  APhase?.res_code==503?<h4 className="tw-flex tw-justify-center tw-items-center tw-min-h-[300px]">Table Not Found</h4>: <div className="tw-flex tw-justify-center tw-items-center tw-min-h-[315px]"><ChartLoaders /></div>}
									</div>
									<p class="text-right" style={{ marginRight: "20px", lineHeight: "10px" }}>Bar chart</p>
								</div>
							</div>

              <div class="col-12">
								<div class="card card-info">
									<div class="card-header" style={{ padding: "10px 15px" }}>
										<h4><span className="mr-2">Loadsurvey</span><BootstrapTooltip title='Find Data In LOAD_SURVEY table(column EMT_PENRG_CUM_IMP_WH) Clock$0_0_1_0_0_255 Wise' placement='top'><span><i className='fas fa-info-circle info_circle'></i></span></BootstrapTooltip></h4>
										<div class="card-header-action">
											<a class="btn btn-info text-white" data-toggle="tooltip" data-placement="Top" title="Expand" onClick={() => onOpenModal(props?.GSMdata.UNIQUE_TABLE_NAME.LOAD_SURVEY, 'Clock$0_0_1_0_0_255', 'Loadsurvey')}>Expand</a>
										</div>
									</div>
									<div class="card-body pl-2 pr-2" style={{ padding: "0px" }}>
										{LoadData?.res_code==503?<h4 className="tw-flex tw-justify-center tw-items-center tw-min-h-[300px]">Table Not Found</h4>:LoadData.date.length > 0 && LoadData.total.length > 0  ?<div className="tw-mt-2 tw-h-[400px] tw-max-h-screen tw-px-5"><Bar ref={chartRef} options={Loadsuravaystate.options} data={Loadsuravaystate.series} /></div>  : LoadData?.res_code==503?<h4 className="tw-flex tw-justify-center tw-items-center tw-min-h-[300px]">Table Not Found</h4>: LoadData?.res_code=='200'?<h4 className="tw-flex tw-justify-center tw-items-center tw-min-h-[300px]">Data Not Found</h4>:<div className="tw-flex tw-justify-center tw-items-center tw-min-h-[315px]"><ChartLoaders /></div>}
									</div>
									<p class="text-right" style={{ marginRight: "20px", lineHeight: "10px" }}>Bar chart</p>
								</div>
							</div>

              
    </div>
    </>
  )
}
