import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { BootstrapTooltip } from "../../Commoncomponet/common";
import { getRolesById, getnotification_receiver,getAll_Active_alarm_template ,getUsersList,getAccount} from "../../Commoncomponet/Utils";
import chroma from 'chroma-js';
import moment from 'moment'
import * as $ from "jquery";
import dt from "datatables.net-dt";
import Pagination from "../../Commoncomponet/Pagination";
$.fn.DataTable = dt;
export default function Notify_Receiver() {

  const [tagsData,setTagsData]=useState([])
const [filterrolesData,setfilterRolesData]=useState([])
const [loader,setLoader]=useState(false)
const [permision,setpermision]=useState({})
const userInfo = JSON.parse(window.localStorage.getItem("user_info"));
const [activeTab, setActiveTab] = useState("Notification");
const btnColor = ['#4f5ece', '#f59e0b', '#fc544b', '#3abaf4', '#54ca68', '#191d21', '#7c5295', '#4f5ece']
const [PageSize, setPageSize] = useState(50);
const [numberofPage, setnumberofPage] = useState(1);
const [currentPage, setCurrentPage] = useState(1);
const [UserList,setUserList]=useState([])
  const [AlarmList,setAlarmList]=useState([])
	const [AccounData,setAccountData]=useState([])

const history=useHistory()
const onHandaleRoles = async () => {
	const data = await getRolesById(userInfo?.ROLE);
	if (data.success === true) {
		const obj = data.data.data[0];
		setpermision({ ...obj, ROLES_PERMISSIONS_CATEGORY0: JSON.parse(obj.ROLES_PERMISSIONS_CATEGORY0), ROLES_PERMISSIONS_CATEGORY5: JSON.parse(obj.ROLES_PERMISSIONS_CATEGORY5), ROLES_PERMISSIONS_CATEGORY2: JSON.parse(obj.ROLES_PERMISSIONS_CATEGORY2), ROLES_PERMISSIONS_CATEGORY3: JSON.parse(obj.ROLES_PERMISSIONS_CATEGORY3), ROLES_PERMISSIONS_CATEGORY1: JSON.parse(obj.ROLES_PERMISSIONS_CATEGORY1), ROLES_PERMISSIONS_CATEGORY4: JSON.parse(obj.ROLES_PERMISSIONS_CATEGORY4) });
	}else{
		console.log('Something Went Wrong')
	}
}

  const ongetTagsData=async()=>{
    setLoader(true);
	const firstPageIndex = (currentPage - 1) * PageSize;
		const lastPageIndex = firstPageIndex + PageSize;
		$(".loader").fadeOut("slow");
const data=await getnotification_receiver({ start: firstPageIndex, end: PageSize })
if(data.success===true){
  setTagsData(data.data.data)
  setnumberofPage(data.data.total)

  setLoader(false)

}else{
  console.log('somthing Went wrong')
  setLoader(false)
}
  }

  

  useEffect(() => {
	onHandaleRoles()
	 ongetUsersList()
	 ongetalarm_template()
	 ongetTagsData()

   }, []);

   useEffect(()=>{
	onHandaleSelection()
 
 },[permision])

 const onHandaleSelection=async()=>{
    const data=await getAccount()
    if(data.success===true){
      const options = []
			const optionArray = data?.data?.data.filter((data) => (permision?.ROLES_PERMISSIONS_CATEGORY0?.CATEGORY0_view?.filter((items) => (items === data.UNIQUE_ID)).length > 0||userInfo?.ROLE==='ROLE1661856938676'))
			optionArray.map((item) => (options.push({ value: item?.UNIQUE_ID, label: item?.CATEGORY0 })))
			setAccountData(options);
    }else{
      console.log('somthing Went wrong')
    }
  }

   const ongetalarm_template=async()=>{
	const data=await getAll_Active_alarm_template()
	if(data.success==true){
	   setAlarmList(data.data.data)
	}else{
 
	}
  }
   const ongetUsersList = async () => {
	const data = await getUsersList()
	if (data.success === true) {
		console.log("Data = ", data.data.data)
		setUserList(data.data.data)
	} else {
		console.log('something Went wrong')
	}
 }

  const onHandaleChangeComponet=(item)=>{
		history.push({ pathname: `/AddNotifyReceiver`, state: {...item,permision} })
	}
	return (
		<div>
    {loader ? (
						<div className="main-content">
							<div class="loader"></div>
						</div>
					) : (
						""
					)}
            
				
				<section class="section">
                
					<div class="section-body">
						<div class="row">
							<div class="col-12">
								<div class="card">
									<div class="card-header">
										<h4><span className="mr-2">Notification Receiver</span><BootstrapTooltip title='Table Name is notification_receiver_index' placement='top'><span><i className='fas fa-info-circle info_circle'></i></span></BootstrapTooltip></h4>
										<div class="card-header-action">
										<Link to={`/AddNotifyReceiver`} className="btn btn-info text-white mr-2">
												<BootstrapTooltip title="Add">
													<span>
														<i
															className="fas fa-plus text-white"
															style={{ fontSize: "16px", paddingLeft: "5px", paddingRight: "5px" }}
														></i>
													</span>
												</BootstrapTooltip>
											</Link>
										</div>
									</div>
									<div class="card-body">
                                    <div className="table-responsive">
												
												<table id="table_id" className="table border table-borderless text-center table-striped font-12">
													<thead>
											
												<tr>
													<th className="text-center" style={{ maxWidth: "100px" }}>sr_no</th>
													<th className="text-center">unique_id</th>
													<th className="text-center" style={{ minWidth: "130px" }}>created_at</th>
													<th className="text-center" style={{ minWidth: "100px" }}>created_by</th>
													<th className="text-center" style={{ minWidth: "130px" }}>updated_at</th>
													<th className="text-center" style={{ minWidth: "100px" }}>updated_by</th>
													<th className="text-center">account_unique_id</th>
													<th className="text-center">users_unique_id</th>
													<th className="text-center">Medium</th>
													<th className="text-center">notification_type_id</th>
													<th className="text-center" style={{ maxWidth: "100px" }}>status</th>
													
												</tr>
											</thead>
											<tbody>
												{tagsData &&
													tagsData.map((item, id) => (
														<tr style={{ height: "4rem" }} className="trheigth" key={id} >
															<td className="text-center"  style={{cursor:'pointer',color:'#3abaf4'}} onClick={()=>onHandaleChangeComponet(item)}>{item.sr_no}</td>
															<td className="text-center">{item.unique_id}</td>
															<td className="text-center">{moment.utc(item.created_at).format('DD-MM-YYYY hh:mm:ss A')}</td>
															<td className="text-center">{item.created_by}</td>
															<td className="text-center">{moment.utc(item.updated_at).format('DD-MM-YYYY hh:mm:ss A')}</td>
															<td className="text-center">{item.updated_by}</td>
															<td className="text-center">{AccounData.filter((acount)=>JSON.parse(item.account_unique_id||'[]').includes(acount.value)).map((filter_cat)=>(filter_cat.label)).join(',')}</td>
															<td className="text-center">{UserList.filter((acount)=>JSON.parse(item.users_unique_id||'[]').includes(acount.value)).map((filter_cat)=>(filter_cat.label)).join(',')}</td>
															<td className="text-center">{item.median}</td>
															<td className="text-center">{AlarmList.filter((acount)=>JSON.parse(item.notification_type_id||'[]').includes(acount.notification_type_id)).map((filter_cat)=>(filter_cat.notification_title)).join(',')}</td>
															
															{item.status===1?<td className="text-center">
																			<div className="badge badge-success badge-shadow text-white">Active</div>
																		</td>:<td className="text-center">
																			<div className="badge badge-danger badge-shadow text-white">Inactive</div>
																		</td>}
														</tr>
													))}
											</tbody>
										</table>
										<>
													<div className="d-flex justify-content-between">
														<div>Total Number Of Row {numberofPage}</div>
														<div class="dataTables_length" id="table_id_length">
													<label>
														Show{" "}
														<select
															name="table_id_length"
															aria-controls="table_id"
															class=""
															onChange={(e) => setPageSize(e.target.value)}
															value={PageSize}
														>
															<option value={10}>10</option>
															<option value={25}>25</option>
															<option value={50}>50</option>
															<option value={100}>100</option>
														</select>{" "}
														entries
													</label>
												</div>
														<Pagination
															className="pagination-bar"
															currentPage={currentPage}
															totalCount={numberofPage || 1}
															pageSize={PageSize}
															onPageChange={(page) => setCurrentPage(page)}
														/>
													</div>
												</>
									</div>
                  </div>
								</div>
							</div>
						</div>
					</div>
				</section>
			</div>
		
	);
}
