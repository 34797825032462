import moment from "moment";
import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { Link } from "react-router-dom";
import { getAccount, getAreaById, getCircleById, getDivisionById, getGaById, getMeterDashGSMChart, getTotalConsumptionInMeterDashboardBySelectedDate, getMeterLoraChartByDateSlection, getProfilNameByAccountId, getRolesById, getStartAndEndDateByTable, getSubdevisionById, gsm_monitor_day_wise_graph_details, LoraMeterDailyDataScripUpdate, onGetMeterdashboardLetestTime, MonitoringScriptStatus, MeterDailyDataScripUpdate,getTotalConsumptionInMeterDashboard, gsm_daily_load_monitoring_day_wise_graph_details, getScriptdecodeId } from "../../Commoncomponet/Utils";
import {
	withScriptjs,
	withGoogleMap,
	GoogleMap,
	Marker,
	InfoWindow
} from "react-google-maps"
import mapicon from '../../assets/img/triangle-green.svg'
import mapRedicon from '../../assets/img/triangle-red.svg'
import { red } from "@mui/material/colors";
import { padding } from "@mui/system";
import { DatePicker, Modal, Space, Spin } from "antd";
import './Dashboard.css'
import Select from 'react-select';
import { BootstrapTooltip, exportCSVFile, exportExcelDownload, socket } from "../../Commoncomponet/common";
import loaderGif from '../../assets/img/loading_chart.gif'
import jQuery from 'jquery'
import { useRef } from 'react';
import {
	Chart as ChartJS,
	CategoryScale,
	LinearScale,
	PointElement,
	LineElement,
	Title,
	Tooltip,
	Filler,
	Legend,
	Decimation, TimeScale
} from 'chart.js';
import $ from 'jquery'
import { Bar,Line, getElementsAtEvent, getElementAtEvent, getDatasetAtEvent } from 'react-chartjs-2';
import zoomPlugin from 'chartjs-plugin-zoom';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { useMemo } from "react";
import makeAnimated from 'react-select/animated';
import { Info } from "react-feather";
import swal from 'sweetalert2';
import { toast } from "react-toastify";
window.Swal = swal;

export default function GSM_Meter_dashboard() {
	const chartRef = useRef();
	const chartRefKWH = useRef();
	const chartRefRupees = useRef();
	const chartRefLine = useRef();
	const GSMDATList = ['DAILY_SUCCESS_LIST', 'DAILY_FAIL_LIST','INACTIVE_CONS_LIST','UNDER_MAINTANANCE_CONS_LIST','PENDING_CONFIGURATION_CONS_LIST']
	const [Profile, setProfile] = useState({ account: "", ga: "", circle: "", division: "", subdivision: "", area: "" });
	const [SubdevisionData, setSubdevisionData] = useState([]);
	const [DivisionData, setDivisionData] = useState([]);
	const [CircleData, setCircleData] = useState([]);
	const [GaData, setGaData] = useState([]);
	const [AccounData, setAccountData] = useState([]);
	const [AreaData, setAreaData] = useState([]);
	const userInfo = JSON.parse(window.localStorage.getItem("user_info"));
	const [permision, setpermision] = useState({});
	const [AllGSMChartData, setAllGSMChartData] = useState({ IMP_WH_COUNT: [], SAVING_WH_COUNT: [],SAVING_RS_COUNT: [], SRNO: [] });
	const [loader, setLoader] = useState(false);
	const [dateInLong, setDateInLong] = useState([]);
	const [expireDate, setExpireDate] = useState({})
	const [isExpandedModalOpen, setisExpandedModalOpen] = useState(false)
	const [expandedModelHeader, setExpandedModelHeader] = useState('')
	const [isModalVisible, setIsModalVisible] = useState(false);
	const [ModelId, setModelId] = useState('')
	const [TableData, setTableDataInApi] = useState([])
	const [ColorText, setColorText] = useState('')
	const [report, setReport] = useState({ value: '', name: '', start_date: '', end_date: '' })
	const [ismodel, setModel] = useState(false)
	const [reportType, setReportType] = useState('')
	const [ReportLoader,setReportLoader]=useState(false)
	const [ModalLoad,setModalLoad]=useState(false)
	const [DateCon,setDateCon]=useState([])
	const [Column,setColumn]=useState([])

	ChartJS.register(
		CategoryScale,
		LinearScale,
		PointElement,
		LineElement,
		Title,
		Tooltip,
		Filler,
		Legend,
		zoomPlugin, ChartDataLabels,
		Decimation, TimeScale
	);
	const handleCancelIsmodal = () => {
		setModel(false);
	};
	const onHandaleSelection = async () => {
		const data = await getAccount();
		if (data.success === true) {
			setAccountData(data.data.data);
		} else {
			console.log("somthing Went wrong");
		}
	};
	const handleCancel = () => {
		setIsModalVisible(false);
		setTableDataInApi([])
	};
	const onHandaleViewGSM = (id) => {
		window.open(`/view_existing_consumer_gsm/${id}`, '_blank')
	}
	const onHandaleRoles = async () => {
		const data = await getRolesById(userInfo?.ROLE);
		if (data.success === true) {
			const obj = data.data.data[0];
			setpermision({
				...obj,
				ROLES_PERMISSIONS_CATEGORY0: JSON.parse(obj.ROLES_PERMISSIONS_CATEGORY0),
				ROLES_PERMISSIONS_CATEGORY5: JSON.parse(obj.ROLES_PERMISSIONS_CATEGORY5),
				ROLES_PERMISSIONS_CATEGORY2: JSON.parse(obj.ROLES_PERMISSIONS_CATEGORY2),
				ROLES_PERMISSIONS_CATEGORY3: JSON.parse(obj.ROLES_PERMISSIONS_CATEGORY3),
				ROLES_PERMISSIONS_CATEGORY1: JSON.parse(obj.ROLES_PERMISSIONS_CATEGORY1),
				ROLES_PERMISSIONS_CATEGORY4: JSON.parse(obj.ROLES_PERMISSIONS_CATEGORY4),
				ROLES_OTHER_PERMISSIONS: JSON.parse(obj.ROLES_OTHER_PERMISSIONS)
			});
			const permissionViewCard = JSON.parse(obj?.ROLES_OTHER_PERMISSIONS)
			const infoRolesObj = { ROLES: userInfo?.ROLE, Profile: { CATEGORY0: JSON.parse(obj.ROLES_PERMISSIONS_CATEGORY0).CATEGORY0_view, CATEGORY1: JSON.parse(obj.ROLES_PERMISSIONS_CATEGORY1).CATEGORY1_view, CATEGORY2: JSON.parse(obj.ROLES_PERMISSIONS_CATEGORY2).CATEGORY2_view, CATEGORY3: JSON.parse(obj.ROLES_PERMISSIONS_CATEGORY3).CATEGORY3_view, CATEGORY4: JSON.parse(obj.ROLES_PERMISSIONS_CATEGORY4).CATEGORY4_view, CATEGORY5: JSON.parse(obj.ROLES_PERMISSIONS_CATEGORY5).CATEGORY5_view } }
			onHandaleSelection()
			ongetAllGSMChartByDate(infoRolesObj)
		}
	}

	const ongetScriptdecodeId=async()=>{
		const data=await getScriptdecodeId()
		if(data.success==true){
			setColumn(data?.data?.data)
		}else{

		}
	}

	useEffect(()=>{
		// ongetScriptdecodeId()
	},[])

	const columnWiseData=(value,column,scriptcodeid,MF)=>{
		const col= JSON.parse(Column.filter((item)=>(item?.NAME==scriptcodeid))[0]?.COLUMN_NAME||'{}')?.DAILY_LOAD?.filter((item)=>(item?.Column==column))[0]||{}
		console.log(col,value,column,scriptcodeid,MF)
		  if(col?.Float==true){
			if(col?.Division==true){
				if(col?.MF==true&&MF)	{
				   return (value*MF/col?.DivisionNumber).toFixed(col?.FloatPoint)
				}else{
				  return (value/col?.DivisionNumber).toFixed(col?.FloatPoint)
				}
			}else{
				if(col?.MF==true&&MF){
				  return (value*MF).toFixed(col?.FloatPoint)
				}else{
				  return (value).toFixed(col?.FloatPoint)
				}
			}
		
		}else{
			if(col?.MF==true&&MF){
				if(col?.Division==true){
					return  (value*MF)/col?.DivisionNumber
			  }else{
				return value*MF
				}
			  }else{
				if(col?.Division==true){
					if(col?.MF==true&&MF){
					  return  (value*MF)/col?.DivisionNumber
					}else{
					  return  value/col?.DivisionNumber
					}
				}else{
				  return value
				}
			}
		}
	  }

	useEffect(() => {
		ongetScriptdecodeId()

		jQuery.event.special.touchstart = {
			setup: function (_, ns, handle) {
				if (ns.includes("noPreventDefault")) {
					this.addEventListener("touchstart", handle, { passive: false });
				} else {
					this.addEventListener("touchstart", handle, { passive: true });
				}
			}
		};
		onHandaleRoles()
		$(function () {
			$('[data-toggle="tooltip"]').tooltip()
		})
	}, []);
	const ongetAllGSMChartByDate = async (info) => {
		setAllGSMChartData({ IMP_WH_COUNT: [], SAVING_WH_COUNT: [], SRNO: [] })

		setLoader(true);
		setLoader(false);
		const data = await getTotalConsumptionInMeterDashboard(info || {});
		if (data.success === true) {
			const ChartArray = data.data.data;
			const obj = { IMP_WH_COUNT: ChartArray.slice(-7).map((item) => (item?.IMP_WH_COUNT||0)),DAILY_FAIL:ChartArray.slice(-7).map((item) => (item?.DAILY_FAIL||0)),INACTIVE_CONS:ChartArray.slice(-7).map((item) => (item?.INACTIVE_CONS||0)),PENDING_CONFIGURATION_CONS:ChartArray.slice(-7).map((item) => (item?.PENDING_CONFIGURATION_CONS||0)),UNDER_MAINTANANCE_CONS:ChartArray.slice(-7).map((item) => (item?.UNDER_MAINTANANCE_CONS||0)), SRNO: ChartArray.slice(-7).map((item) => (item?.SRNO)),DAILY_SUCCESS:ChartArray.slice(-7).map((item) => (item?.DAILY_SUCCESS||0)),REQ_CONS_MIN:ChartArray.map((item) => (item?.REQ_CONS_MIN||0)),REQ_CONS_MAX:ChartArray.map((item) => (item?.REQ_CONS_MAX||0)),REQ_CONS_AVG:ChartArray.map((item) => (item?.REQ_CONS_AVG||0)),CONSUMPTION:ChartArray.map((item) => (item?.IMP_WH_COUNT||0)),SAVING_RS_COUNT_NULL:ChartArray.slice(-7).map((item) => (item?.SAVING_RS_COUNT_NULL||0)), SAVING_RS_COUNT: ChartArray.slice(-7).map((item) => (item?.SAVING_RS_COUNT||0)),SAVING_RS_COUNT_MINUS:ChartArray.slice(-7).map((item) => (item?.SAVING_RS_COUNT_MINUS||0)) }
			setAllGSMChartData(obj)
			const DateArray = ChartArray.map((item,id) => ([item.CREATED_AT.split('T')[1]?moment.utc(item.CREATED_AT).format('DD-MM-YYYY'):item.CREATED_AT]))
			const DateArrayConsumption = ChartArray.slice(-7).map((item) => ([item.CREATED_AT.split('T')[1]?moment.utc(item.CREATED_AT).format('DD-MM-YYYY'):item.CREATED_AT]))
			setDateCon(DateArrayConsumption);
			setDateInLong(DateArray);
		} else {
			console.log("something Went wrong");
			setLoader(false);
		}
	};
	const ongetGaById = async (id) => {
		const data1 = await getGaById(id);
		if (data1.success === true) {
			setGaData(data1.data.data);
		}
	};

	const ongetCircle = async (id) => {
		const data1 = await getCircleById(id);
		if (data1.success === true) {
			setCircleData(data1.data.data);
		}
	};
	const ongetSubdevisionById = async (id) => {
		const data1 = await getSubdevisionById(id);
		if (data1.success === true) {
			setSubdevisionData(data1.data.data);
		}
	};
	const ongetDivisionById = async (id) => {
		const data3 = await getDivisionById(id);
		if (data3.success === true) {
			setDivisionData(data3.data.data);
		}
	};
	const ongetArea = async (id) => {
		const data3 = await getAreaById(id);
		if (data3.success === true) {
			setAreaData(data3.data.data);
		}
	};
	const [NameObj, setNameObj] = useState({})
	const ongetProfilNameByAccountId = async (id) => {
		const data = await getProfilNameByAccountId({ ID: id })
		if (data.success === true) {
			setNameObj(JSON.parse(data?.data?.data[0]?.PROFILE_NAME || '{}'))
		} else {
			console.log('something Went wrong')
		}
	}
	useEffect(() => {
		if (Profile.account !== "") {
			ongetGaById(Profile.account);
			setProfile({ ...Profile, ga: "", circle: "", division: "", subdivision: "", area: "" });
			setCircleData([]);
			setSubdevisionData([]);
			setDivisionData([]);
			setAreaData([])
			// onChangesProfileWiseSerch({ CATEGORY0: Profile.account, ROLES: userInfo?.ROLE, serach: true, Profile: { CATEGORY0: permision.ROLES_PERMISSIONS_CATEGORY0.CATEGORY0_view, CATEGORY1: permision.ROLES_PERMISSIONS_CATEGORY1.CATEGORY1_view, CATEGORY2: permision.ROLES_PERMISSIONS_CATEGORY2.CATEGORY2_view, CATEGORY3: permision.ROLES_PERMISSIONS_CATEGORY3.CATEGORY3_view, CATEGORY4: permision.ROLES_PERMISSIONS_CATEGORY4.CATEGORY4_view, CATEGORY5: permision.ROLES_PERMISSIONS_CATEGORY5.CATEGORY5_view } });
			ongetProfilNameByAccountId(Profile.account)

		} else {
			setProfile({ ...Profile, ga: "", circle: "", division: "", subdivision: "", area: "" });
			setGaData([]);
			setCircleData([]);
			setSubdevisionData([]);
			setDivisionData([]);
			setAreaData([]);
			if (Object.keys(permision).length > 0) {
				// onChangesProfileWiseSerch({ CATEGORY0: '', CATEGORY1: '', CATEGORY2: '', CATEGORY3: '', CATEGORY4: '', CATEGORY5: '', ROLES: userInfo?.ROLE, Profile: { CATEGORY0: permision.ROLES_PERMISSIONS_CATEGORY0.CATEGORY0_view, CATEGORY1: permision.ROLES_PERMISSIONS_CATEGORY1.CATEGORY1_view, CATEGORY2: permision.ROLES_PERMISSIONS_CATEGORY2.CATEGORY2_view, CATEGORY3: permision.ROLES_PERMISSIONS_CATEGORY3.CATEGORY3_view, CATEGORY4: permision.ROLES_PERMISSIONS_CATEGORY4.CATEGORY4_view, CATEGORY5: permision.ROLES_PERMISSIONS_CATEGORY5.CATEGORY5_view } });
			}
			setNameObj({})
		}
	}, [Profile.account]);

	useEffect(() => {
		if (Profile.ga !== "") {
			ongetCircle(Profile.ga);
			setProfile({ ...Profile, circle: "", division: "", subdivision: "", area: "" });
			setSubdevisionData([]);
			setDivisionData([]);
			setAreaData([]);
			// onChangesProfileWiseSerch({ CATEGORY0: Profile.account, CATEGORY1: Profile.ga, ROLES: userInfo?.ROLE, serach: true, Profile: { CATEGORY0: permision.ROLES_PERMISSIONS_CATEGORY0.CATEGORY0_view, CATEGORY1: permision.ROLES_PERMISSIONS_CATEGORY1.CATEGORY1_view, CATEGORY2: permision.ROLES_PERMISSIONS_CATEGORY2.CATEGORY2_view, CATEGORY3: permision.ROLES_PERMISSIONS_CATEGORY3.CATEGORY3_view, CATEGORY4: permision.ROLES_PERMISSIONS_CATEGORY4.CATEGORY4_view, CATEGORY5: permision.ROLES_PERMISSIONS_CATEGORY5.CATEGORY5_view } });
		} else {
			setProfile({ ...Profile, circle: "", division: "", subdivision: "", area: "" });
			setCircleData([]);
			setDivisionData([]);
			setSubdevisionData([]);
			setAreaData([]);
			if (Object.keys(permision).length > 0) {
				// onChangesProfileWiseSerch({ CATEGORY0: Profile.account, ROLES: userInfo?.ROLE, serach: true, Profile: { CATEGORY0: permision.ROLES_PERMISSIONS_CATEGORY0.CATEGORY0_view, CATEGORY1: permision.ROLES_PERMISSIONS_CATEGORY1.CATEGORY1_view, CATEGORY2: permision.ROLES_PERMISSIONS_CATEGORY2.CATEGORY2_view, CATEGORY3: permision.ROLES_PERMISSIONS_CATEGORY3.CATEGORY3_view, CATEGORY4: permision.ROLES_PERMISSIONS_CATEGORY4.CATEGORY4_view, CATEGORY5: permision.ROLES_PERMISSIONS_CATEGORY5.CATEGORY5_view } });
			}

		}
	}, [Profile.ga]);

	useEffect(() => {
		if (Profile.circle !== "") {
			ongetDivisionById(Profile.circle);
			setProfile({ ...Profile, division: "", subdivision: "", area: "" });
			setAreaData([]);
			setSubdevisionData([]);
			// onChangesProfileWiseSerch({ CATEGORY0: Profile.account, CATEGORY1: Profile.ga, CATEGORY2: Profile.circle, ROLES: userInfo?.ROLE, serach: true, Profile: { CATEGORY0: permision.ROLES_PERMISSIONS_CATEGORY0.CATEGORY0_view, CATEGORY1: permision.ROLES_PERMISSIONS_CATEGORY1.CATEGORY1_view, CATEGORY2: permision.ROLES_PERMISSIONS_CATEGORY2.CATEGORY2_view, CATEGORY3: permision.ROLES_PERMISSIONS_CATEGORY3.CATEGORY3_view, CATEGORY4: permision.ROLES_PERMISSIONS_CATEGORY4.CATEGORY4_view, CATEGORY5: permision.ROLES_PERMISSIONS_CATEGORY5.CATEGORY5_view } });

		} else {
			setProfile({ ...Profile, division: "", subdivision: "", area: "" });
			setAreaData([]);
			setDivisionData([]);
			setSubdevisionData([]);
			if (Object.keys(permision).length > 0) {
				// onChangesProfileWiseSerch({ CATEGORY0: Profile.account, CATEGORY1: Profile.ga, ROLES: userInfo?.ROLE, serach: true, Profile: { CATEGORY0: permision.ROLES_PERMISSIONS_CATEGORY0.CATEGORY0_view, CATEGORY1: permision.ROLES_PERMISSIONS_CATEGORY1.CATEGORY1_view, CATEGORY2: permision.ROLES_PERMISSIONS_CATEGORY2.CATEGORY2_view, CATEGORY3: permision.ROLES_PERMISSIONS_CATEGORY3.CATEGORY3_view, CATEGORY4: permision.ROLES_PERMISSIONS_CATEGORY4.CATEGORY4_view, CATEGORY5: permision.ROLES_PERMISSIONS_CATEGORY5.CATEGORY5_view } });
			}

		}
	}, [Profile.circle]);

	useEffect(() => {
		if (Profile.division !== "") {
			ongetSubdevisionById(Profile.division);
			setAreaData([]);
			setProfile({ ...Profile, subdivision: "", area: "" });
			// onChangesProfileWiseSerch({ CATEGORY0: Profile.account, CATEGORY1: Profile.ga, CATEGORY2: Profile.circle, CATEGORY3: Profile.division, ROLES: userInfo?.ROLE, serach: true, Profile: { CATEGORY0: permision.ROLES_PERMISSIONS_CATEGORY0.CATEGORY0_view, CATEGORY1: permision.ROLES_PERMISSIONS_CATEGORY1.CATEGORY1_view, CATEGORY2: permision.ROLES_PERMISSIONS_CATEGORY2.CATEGORY2_view, CATEGORY3: permision.ROLES_PERMISSIONS_CATEGORY3.CATEGORY3_view, CATEGORY4: permision.ROLES_PERMISSIONS_CATEGORY4.CATEGORY4_view, CATEGORY5: permision.ROLES_PERMISSIONS_CATEGORY5.CATEGORY5_view } });
		} else {
			setProfile({ ...Profile, subdivision: "", area: "" });
			setSubdevisionData([]);
			setAreaData([]);
			if (Object.keys(permision).length > 0) {
				// onChangesProfileWiseSerch({ CATEGORY0: Profile.account, CATEGORY1: Profile.ga, CATEGORY2: Profile.circle, ROLES: userInfo?.ROLE, serach: true, Profile: { CATEGORY0: permision.ROLES_PERMISSIONS_CATEGORY0.CATEGORY0_view, CATEGORY1: permision.ROLES_PERMISSIONS_CATEGORY1.CATEGORY1_view, CATEGORY2: permision.ROLES_PERMISSIONS_CATEGORY2.CATEGORY2_view, CATEGORY3: permision.ROLES_PERMISSIONS_CATEGORY3.CATEGORY3_view, CATEGORY4: permision.ROLES_PERMISSIONS_CATEGORY4.CATEGORY4_view, CATEGORY5: permision.ROLES_PERMISSIONS_CATEGORY5.CATEGORY5_view } });
			}
		}
	}, [Profile.division]);

	useEffect(() => {
		if (Profile.subdivision !== "") {
			ongetArea(Profile.subdivision);
			setProfile({ ...Profile, area: "" });
			// onChangesProfileWiseSerch({ CATEGORY0: Profile.account, CATEGORY1: Profile.ga, CATEGORY2: Profile.circle, CATEGORY3: Profile.division, CATEGORY4: Profile.subdivision, ROLES: userInfo?.ROLE, serach: true, Profile: { CATEGORY0: permision.ROLES_PERMISSIONS_CATEGORY0.CATEGORY0_view, CATEGORY1: permision.ROLES_PERMISSIONS_CATEGORY1.CATEGORY1_view, CATEGORY2: permision.ROLES_PERMISSIONS_CATEGORY2.CATEGORY2_view, CATEGORY3: permision.ROLES_PERMISSIONS_CATEGORY3.CATEGORY3_view, CATEGORY4: permision.ROLES_PERMISSIONS_CATEGORY4.CATEGORY4_view, CATEGORY5: permision.ROLES_PERMISSIONS_CATEGORY5.CATEGORY5_view } });
		} else {
			setProfile({ ...Profile, area: "" });
			setAreaData([]);
			if (Object.keys(permision).length > 0) {
				// onChangesProfileWiseSerch({ CATEGORY0: Profile.account, CATEGORY1: Profile.ga, CATEGORY2: Profile.circle, CATEGORY3: Profile.division, ROLES: userInfo?.ROLE, serach: true, Profile: { CATEGORY0: permision.ROLES_PERMISSIONS_CATEGORY0.CATEGORY0_view, CATEGORY1: permision.ROLES_PERMISSIONS_CATEGORY1.CATEGORY1_view, CATEGORY2: permision.ROLES_PERMISSIONS_CATEGORY2.CATEGORY2_view, CATEGORY3: permision.ROLES_PERMISSIONS_CATEGORY3.CATEGORY3_view, CATEGORY4: permision.ROLES_PERMISSIONS_CATEGORY4.CATEGORY4_view, CATEGORY5: permision.ROLES_PERMISSIONS_CATEGORY5.CATEGORY5_view } });
			}
		}
	}, [Profile.subdivision]);

	useEffect(() => {
		if (Profile.area !== "") {
			// onChangesProfileWiseSerch({ CATEGORY0: Profile.account, CATEGORY1: Profile.ga, CATEGORY2: Profile.circle, CATEGORY3: Profile.division, CATEGORY4: Profile.subdivision, CATEGORY5: Profile.area, ROLES: userInfo?.ROLE, serach: true, Profile: { CATEGORY0: permision.ROLES_PERMISSIONS_CATEGORY0.CATEGORY0_view, CATEGORY1: permision.ROLES_PERMISSIONS_CATEGORY1.CATEGORY1_view, CATEGORY2: permision.ROLES_PERMISSIONS_CATEGORY2.CATEGORY2_view, CATEGORY3: permision.ROLES_PERMISSIONS_CATEGORY3.CATEGORY3_view, CATEGORY4: permision.ROLES_PERMISSIONS_CATEGORY4.CATEGORY4_view, CATEGORY5: permision.ROLES_PERMISSIONS_CATEGORY5.CATEGORY5_view } });
		} else {
			if (Object.keys(permision).length > 0) {
				// onChangesProfileWiseSerch({ CATEGORY0: Profile.account, CATEGORY1: Profile.ga, CATEGORY2: Profile.circle, CATEGORY3: Profile.division, ROLES: userInfo?.ROLE, serach: true, Profile: { CATEGORY0: permision.ROLES_PERMISSIONS_CATEGORY0.CATEGORY0_view, CATEGORY1: permision.ROLES_PERMISSIONS_CATEGORY1.CATEGORY1_view, CATEGORY2: permision.ROLES_PERMISSIONS_CATEGORY2.CATEGORY2_view, CATEGORY3: permision.ROLES_PERMISSIONS_CATEGORY3.CATEGORY3_view, CATEGORY4: permision.ROLES_PERMISSIONS_CATEGORY4.CATEGORY4_view, CATEGORY5: permision.ROLES_PERMISSIONS_CATEGORY5.CATEGORY5_view } });
			}
		}
	}, [Profile.area]);

	const onProfileWiseSearchClick = () => {
		onChangesProfileWiseSerch({ CATEGORY0: Profile.account, CATEGORY1: Profile.ga, CATEGORY2: Profile.circle, CATEGORY3: Profile.division, CATEGORY4: Profile.subdivision, CATEGORY5: Profile.area, ROLES: userInfo?.ROLE, serach: true, Profile: { CATEGORY0: permision.ROLES_PERMISSIONS_CATEGORY0.CATEGORY0_view, CATEGORY1: permision.ROLES_PERMISSIONS_CATEGORY1.CATEGORY1_view, CATEGORY2: permision.ROLES_PERMISSIONS_CATEGORY2.CATEGORY2_view, CATEGORY3: permision.ROLES_PERMISSIONS_CATEGORY3.CATEGORY3_view, CATEGORY4: permision.ROLES_PERMISSIONS_CATEGORY4.CATEGORY4_view, CATEGORY5: permision.ROLES_PERMISSIONS_CATEGORY5.CATEGORY5_view } });
	}

	const onChangesProfileWiseSerch = (info) => {
		ongetAllGSMChartByDate(info)

	}
	const dataForChartJs = {
		labels: DateCon.map((item)=>([moment(item,'DD-MM-YYYY').add(-1,'days').format('DD-MM-YYYY'),moment(item,'DD-MM-YYYY').add(-1,'days').format('(ddd)')])),
		datasets:[
			
			// {
			// 	label: "Daily Consumption(KWH)",
			// 	data: AllGSMChartData?.IMP_WH_COUNT,
			// 	barPercentage: 1,
			// 	categoryPercentage: 0.6,
			// 	backgroundColor: 'rgb(0, 104, 138)',
			// 	borderRadius: 5,
			// },
			{
				label: "Daily Success(Count)",
				data: AllGSMChartData?.DAILY_SUCCESS,
				barPercentage: 1,
				categoryPercentage: 0.6,
				backgroundColor: 'rgb(0, 104, 138)',
				borderRadius: 5,
			},
			{
				label: "Daily Fail(Count)",
				data: AllGSMChartData?.DAILY_FAIL,
				barPercentage: 1,
				categoryPercentage: 0.6,
				backgroundColor: 'rgb(51 181 229)',
				borderRadius: 5,
			},
			{
				label: "Inactive(Count)",
				data: AllGSMChartData?.INACTIVE_CONS,
				barPercentage: 1,
				categoryPercentage: 0.6,
				backgroundColor: 'rgb(255 69 96)',
				borderRadius: 5,
			},
			{
				label: "Under Maintanance(Count)",
				data: AllGSMChartData?.UNDER_MAINTANANCE_CONS,
				barPercentage: 1,
				categoryPercentage: 0.6,
				backgroundColor: 'rgb(0 143 251)',
				borderRadius: 5,
			},
			{
				label: "Pending Configuration(Count)",
				data: AllGSMChartData?.PENDING_CONFIGURATION_CONS,
				barPercentage: 1,
				categoryPercentage: 0.6,
				backgroundColor: 'rgb(254 217 145)',
				borderRadius: 5,
			}
		],
	};

    const SAVING_WH_COUNTChartJs = {
		labels: DateCon,
		datasets:[
			{
				label: "Power Saving In KWH(KWH)",
				data: AllGSMChartData?.SAVING_WH_COUNT,
				barThickness:25,
				backgroundColor: 'rgb(0, 104, 138)',
				borderRadius: 5,
			},
			{
				label: "0 Power Saving(Count)",
				data: AllGSMChartData?.SAVING_WH_COUNT_MINUS,
				barThickness:25,
				backgroundColor: 'rgb(76 181 215)',
				borderRadius: 5,
			}
		],
	};

    const SAVING_RS_COUNTChartJs = {
		labels: DateCon.map((item)=>([moment(item,'DD-MM-YYYY').add(-1,'days').format('DD-MM-YYYY'),moment(item,'DD-MM-YYYY').add(-1,'days').format('(ddd)')])),
		datasets:[
			{
				label: "Consumption In Rupees(Rupees)",
				data: AllGSMChartData?.SAVING_RS_COUNT,
				barThickness:25,
				backgroundColor: 'rgb(0, 104, 138)',
				borderRadius: 5,
			},
			{
				label: "0 Consumption In Rupees(Count)",
				data: AllGSMChartData?.SAVING_RS_COUNT_MINUS,
				barThickness:25,
				backgroundColor: 'rgb(76 181 215)',
				borderRadius: 5,
			},
			{
				label: "null Unit Rate",
				data: AllGSMChartData?.SAVING_RS_COUNT_NULL,
				barThickness:25,
				backgroundColor: 'rgb(255 69 96)',
				borderRadius: 5,
			}
		],
	};
	const optionsForChartJS = {
		responsive: true,
		maintainAspectRatio: false,
		scales: {
			y: {
				title: {
				  display: true,
				  text: 'KWH/Count'
				}
			  },
			x: {

				ticks: {
					maxRotation: 90,

				}
			}
		},
		animation: false,
		// animation: {
		// 	duration: 1000,
		// 	easing: 'easeInBounce',
		// 	onComplete: () => {
		// 		delayed = true;
		// 	},
		// 	delay: (context) => {
		// 		let delay = 0;
		// 		if (context.type === 'data' && context.mode === 'default' && !delayed) {
		// 			delay = context.dataIndex * 300 + context.datasetIndex * 100;
		// 		}
		// 		return delay;
		// 	},
		// },

		plugins: {
			legend: {
				position: 'bottom',
				fullSize: false,
				labels: {
					pointStyleWidth: 10,
					usePointStyle: true,
					pointStyle: 'rectRounded',
					padding: 5,
					font: function (context) {
						var w = context.chart.width;
						return {
							size: w < 512 ? 8 : 11,

						};
					},
				}
			},
			zoom: {
				zoom: {
					wheel: {
						enabled: true,
						modifierKey: 'ctrl' // SET SCROOL ZOOM TO TRUE
					},
					mode: "x",
				},
				pan: {
					enabled: true,
					mode: "x",
				}
			},
			datalabels: {
				align: 'end',
				anchor: 'end',
				clamp: true,
				color: "black",
				padding: {
					top: 0,
					bottom: 0
				},
				font: function (context) {
					var w = context.chart.width;
					return {
						size: w < 512 ? 10 : 12,

					};
				},

			}
		},
		layout: {
			padding: {
				left: 50,
				right: 50,
				top: 20
			}
		},
	};

	const optionsLine = {
		 // parsing: false,
		 responsive: true,
		 maintainAspectRatio: false,
		 interaction: {
		   mode: "index",
		   intersect: false,
		   },
	 
		 // parsing: false,
		 plugins: {
		   decimation: {
			 enabled: true,
			 algorithm: 'lttb',
			 samples: 800,
			 threshold: 800
		   },
		   legend: {
			 position: 'bottom',
			 labels: {
			   pointStyleWidth: 10,
			   usePointStyle: true,
			   pointStyle: 'rectRounded'
			 }
		   },
		   zoom: {
			 zoom: {
			   wheel: {
				 enabled: true,
				 modifierKey: 'ctrl' // SET SCROOL ZOOM TO TRUE
			   },
			   mode: "x",
			 },
			 pan: {
			   enabled: true,
			   mode: "x",
			 }
		   },
		   datalabels: {
			 display:false,
			 backgroundColor: function (context) {
			   return context.dataset.borderColor;
			 },
			 borderRadius: 4,
			 align: 'end',
			 anchor: 'end',
			 clamp: true,
			 color: "white",
			 padding: {
			   top: 0,
			   bottom: 0
			 },
			 font: function (context) {
			   var w = context.chart.width;
			   return {
				 size: w < 512 ? 10 : 12,
	 
			   };
			 },
			 tooltips: {
			   mode: "index",
			   intersect: false,
			   },
			   hover: {
			   mode: "nearest",
			   intersect: true,
			   }
	 
		   }
		 },
		 elements: {
		   line: {
			 fill: true,
			 tension: 0.3
		   }
		 },
		 layout: {
		   padding: {
			 top: 20
		   }
		 },
	};

	const dataLine = {
		labels: dateInLong.map((item)=>([moment(item,'DD-MM-YYYY').add(-1,'days').format('DD-MM-YYYY'),moment(item,'DD-MM-YYYY').add(-1,'days').format('(ddd)')])),
		datasets: [
			{
				label: "PCMIN (KWH)",
				data: AllGSMChartData?.REQ_CONS_MIN,
				fill: false,
				borderColor: 'rgb(53, 162, 235)',
				backgroundColor: (context) => {
					const ctx = context.chart.ctx;
					const gradient = ctx.createLinearGradient(0, 0, 0, 400);
					gradient.addColorStop(0, "rgba(53, 162, 235, 0.8)");
					gradient.addColorStop(1, "rgba(53, 162, 235, 0)");
					return gradient;
					// return pattern.draw('dot', gradient)
				},
				
				// backgroundColor: 'rgba(53, 162, 235, 0.5)',
			},
			{
				label: "PCMAX (KWH)",
				data: AllGSMChartData?.REQ_CONS_MAX,
				fill: false,
				borderColor: 'rgb(225, 29, 72',
				backgroundColor: (context) => {
					const ctx = context.chart.ctx;
					const gradient = ctx.createLinearGradient(0, 0, 0, 400);
					gradient.addColorStop(0, "rgb(254, 205, 211)");
					gradient.addColorStop(1, "rgb(254, 205, 211)");
					return gradient;
					// return pattern.draw('dot', gradient)
				},
				// backgroundColor: 'rgba(53, 162, 235, 0.5)',
			},
			{
				label: "PCAVG (KWH)",
				data: AllGSMChartData?.REQ_CONS_AVG,
				fill: false,
				borderColor: 'rgb(29, 78, 216)',
				backgroundColor: (context) => {
					const ctx = context.chart.ctx;
					const gradient = ctx.createLinearGradient(0, 0, 0, 400);
					gradient.addColorStop(0, "rgb(191, 219, 254)");
					gradient.addColorStop(1, "rgb(191, 219, 254)");
					return gradient;
					// return pattern.draw('dot', gradient)
				},
				// backgroundColor: 'rgba(53, 162, 235, 0.5)',
			},
			{
				label: "Consumption (KWH)",
				data: AllGSMChartData?.CONSUMPTION,
				fill: false,
				borderColor: 'rgb(8,145, 178)',
				backgroundColor: (context) => {
					const ctx = context.chart.ctx;
					const gradient = ctx.createLinearGradient(0, 0, 0, 400);
					gradient.addColorStop(0, "rgb(207, 250, 254)");
					gradient.addColorStop(1, "rgb(207, 250, 254)");
					return gradient;
					// return pattern.draw('dot', gradient)
				},
				// backgroundColor: 'rgba(53, 162, 235, 0.5)',
			}
		]
	};

	const optionsForRupee = {
		responsive: true,
		maintainAspectRatio: false,
		scales: {
			y: {
				title: {
				  display: true,
				  text: 'Rupee/Count'
				}
			  },
			x: {

				ticks: {
					maxRotation: 90,

				}
			}
		},
		animation: false,
		// animation: {
		// 	duration: 1000,
		// 	easing: 'easeInBounce',
		// 	onComplete: () => {
		// 		delayed = true;
		// 	},
		// 	delay: (context) => {
		// 		let delay = 0;
		// 		if (context.type === 'data' && context.mode === 'default' && !delayed) {
		// 			delay = context.dataIndex * 300 + context.datasetIndex * 100;
		// 		}
		// 		return delay;
		// 	},
		// },

		plugins: {
			legend: {
				position: 'bottom',
				fullSize: false,
				labels: {
					pointStyleWidth: 10,
					usePointStyle: true,
					pointStyle: 'rectRounded',
					padding: 5,
					font: function (context) {
						var w = context.chart.width;
						return {
							size: w < 512 ? 8 : 11,

						};
					},
				}
			},
			zoom: {
				zoom: {
					wheel: {
						enabled: true,
						modifierKey: 'ctrl' // SET SCROOL ZOOM TO TRUE
					},
					mode: "x",
				},
				pan: {
					enabled: true,
					mode: "x",
				}
			},
			datalabels: {
				align: 'end',
				anchor: 'end',
				clamp: true,
				color: "black",
				padding: {
					top: 0,
					bottom: 0
				},
				font: function (context) {
					var w = context.chart.width;
					return {
						size: w < 512 ? 10 : 12,

					};
				},

			}
		},
		layout: {
			padding: {
				left: 50,
				right: 50,
				top: 20
			}
		},
	};

	const onClickKWHHistory = (event) => {
		// console.log(getElementsAtEvent(chartRef.current, event));
		const { datasetIndex, index } = getElementAtEvent(chartRef.current, event)[0] || {};
		console.log(datasetIndex, index)
		// console.log((getElementAtEvent(chartRef.current, event)[0]));
		const DATA_point = GSMDATList[datasetIndex]
		if (DATA_point) {

			onHandalGsmChart(GSMDATList[datasetIndex], AllGSMChartData.SRNO[index], false,moment(DateCon[index],'DD-MM-YYYY').add(-1,'days').format('DD-MM-YYYY'),index,true)

		}
	}

	const onClickPowKWH = (event) => {
		// console.log(getElementsAtEvent(chartRef.current, event));
		const { datasetIndex, index } = getElementAtEvent(chartRefKWH.current, event)[0] || {};
		console.log(datasetIndex, index)
		// console.log((getElementAtEvent(chartRef.current, event)[0]));
		const DATA_point = GSMDATList[datasetIndex]
		if (DATA_point) {

			onHandalGsmChart('Power Saving In KWH', AllGSMChartData.SRNO[index], false,DateCon[index],datasetIndex)

		}
	}

	const onClickPowRupee = (event) => {
		// console.log(getElementsAtEvent(chartRef.current, event));
		const { datasetIndex, index } = getElementAtEvent(chartRefRupees.current, event)[0] || {};
		console.log(datasetIndex, index)
		// console.log((getElementAtEvent(chartRef.current, event)[0]));
		const DATA_point = GSMDATList[datasetIndex]
		if (DATA_point) {
			onHandalGsmChart('Consumption In Rupees', AllGSMChartData.SRNO[index], false,moment(DateCon[index],'DD-MM-YYYY').add(-1,'days').format('DD-MM-YYYY'),datasetIndex)
		}
	}

	const onHandalGsmChart = async (col, srno, changeTable,DATE,index,isCol) => {
		setIsModalVisible(true)
		setModalLoad(true)
		setModelId(`${col}`+`(${DATE})`)
		const data = await gsm_daily_load_monitoring_day_wise_graph_details({ colName: col,isCol:isCol, SRNO: srno, changeTable: changeTable, CATEGORY0: Profile.account, CATEGORY1: Profile.ga, CATEGORY2: Profile.circle, CATEGORY3: Profile.division, CATEGORY4: Profile.subdivision, CATEGORY5: Profile.area, ROLES: userInfo?.ROLE, serach: Profile.account === '' && Profile.ga === '' && Profile.circle === '' && Profile.division === '' && Profile.subdivision === '' && Profile.area === '' ? false : true, Profile: { CATEGORY0: permision.ROLES_PERMISSIONS_CATEGORY0.CATEGORY0_view, CATEGORY1: permision.ROLES_PERMISSIONS_CATEGORY1.CATEGORY1_view, CATEGORY2: permision.ROLES_PERMISSIONS_CATEGORY2.CATEGORY2_view, CATEGORY3: permision.ROLES_PERMISSIONS_CATEGORY3.CATEGORY3_view, CATEGORY4: permision.ROLES_PERMISSIONS_CATEGORY4.CATEGORY4_view, CATEGORY5: permision.ROLES_PERMISSIONS_CATEGORY5.CATEGORY5_view } })
		if (data.success === true) {
			if(col=='Power Saving In KWH'){
				var arrrayofobj=[]
				JSON.parse(data?.data?.data[0]?.DAILY_SUCCESS_LIST || '[]').map((item) => {
					const COS_INFO=JSON.parse(data?.data?.data[0]?.Consumer_Details||'[]').filter((info)=>(info.UNIQUE_ID==item.UNIQUE_ID));

					const SAVING_WH_COUNT=COS_INFO.length>0?COS_INFO[0].BURNING_KWH?((Number(COS_INFO[0].BURNING_KWH)-(columnWiseData(item.Register$1_0_1_8_0_255,'Register$1_0_1_8_0_255',item?.SCRIPTDECODE_ID,item?.MF)))):0:0;
					if(index==0){
						arrrayofobj.push({ UNIQUE_ID: item?.UNIQUE_ID, DEVICE_SERIAL_NO: item?.DEVICE_SERIAL_NO, address_compony_name: item?.address_compony_name, Consumption: SAVING_WH_COUNT>0?SAVING_WH_COUNT.toFixed(3):0 })
				}else{
					if(SAVING_WH_COUNT<=0){
						arrrayofobj.push({ UNIQUE_ID: item?.UNIQUE_ID, DEVICE_SERIAL_NO: item?.DEVICE_SERIAL_NO, address_compony_name: item?.address_compony_name, Consumption: 0 })
					}
				}
				})
				setTableDataInApi(arrrayofobj)
			}else if(col=='Consumption In Rupees'){
				var arrrayofobj=[]
				JSON.parse(data?.data?.data[0]?.DAILY_SUCCESS_LIST || '[]').map((item) => {
					const COS_INFO=JSON.parse(data?.data?.data[0]?.Consumer_Details||'[]').filter((info)=>(info.UNIQUE_ID==item.UNIQUE_ID));

					const SAVING_RS_COUNT=COS_INFO.length>0?COS_INFO[0].UNIT_RATE?((Number(COS_INFO[0].UNIT_RATE)*(columnWiseData(item.Register$1_0_1_8_0_255,'Register$1_0_1_8_0_255',item?.SCRIPTDECODE_ID,item?.MF)))):0:0
					if(index==0){
						arrrayofobj.push({ UNIQUE_ID: item?.UNIQUE_ID, DEVICE_SERIAL_NO: item?.DEVICE_SERIAL_NO, address_compony_name: item?.address_compony_name, RSSI: item?.HEARTBEAT_LAST_DATA?.GSM_RSSI, Consumption: SAVING_RS_COUNT>0?SAVING_RS_COUNT.toFixed(3):0 })
				}if(index==2){
					if(COS_INFO[0].UNIT_RATE===null){
					arrrayofobj.push({ UNIQUE_ID: item?.UNIQUE_ID, DEVICE_SERIAL_NO: item?.DEVICE_SERIAL_NO, address_compony_name: item?.address_compony_name, RSSI: item?.HEARTBEAT_LAST_DATA?.GSM_RSSI })
					}
				}else{
					if(SAVING_RS_COUNT<=0&&COS_INFO[0].UNIT_RATE!=null){
						arrrayofobj.push({ UNIQUE_ID: item?.UNIQUE_ID, DEVICE_SERIAL_NO: item?.DEVICE_SERIAL_NO, address_compony_name: item?.address_compony_name, Consumption: 0 })
						//consumption unit_rate null and kwh 0
					}
				}
				})
				setTableDataInApi(arrrayofobj)
				//consumption data in rupees
			}else{
				setTableDataInApi(JSON.parse(data?.data?.data[0]?.DAILY_SUCCESS_LIST || '[]').map((item) => ({ UNIQUE_ID: item?.UNIQUE_ID, DEVICE_SERIAL_NO: item?.DEVICE_SERIAL_NO, address_compony_name: item?.address_compony_name, RSSI: item?.HEARTBEAT_LAST_DATA?.GSM_RSSI,Consumption: columnWiseData(item.Register$1_0_1_8_0_255,'Register$1_0_1_8_0_255',item?.SCRIPTDECODE_ID,item?.MF)})))
				}
		setModalLoad(false)

		} else {
			console.log('something Went wrong')
		setModalLoad(false)

		}
	}

	const onExpandButton = (tableName, col, header) => {
		if (header == 'Installation Date Wise Chart') {
			setExpireDate({ start_date: '', endDate: new Date(Date.now() + 1 * 24 * 60 * 60 * 1000) })
			setisExpandedModalOpen(true)
		}
		else {
			ongetStartAndEndDateByTable(tableName, col)
		}
		// onGetTableNameWiseChartDataBy15Date()
		setExpandedModelHeader(header)
	}

	const ongetStartAndEndDateByTable = async (tableName, col) => {
		console.log("Wanted to check if this is calling or not")
		document.body.style.cursor = 'Went'
		const data = await getStartAndEndDateByTable({ tableName: tableName, col: col })
		if (data.success === true) {

			console.log("Expiry Dates == ", data.data)
			setExpireDate(data.data)
			setisExpandedModalOpen(true)
			document.body.style.cursor = 'default'
		} else {
			console.log("something Went wrong")
			document.body.style.cursor = 'default'
		}
	}
	function getSecondsTodayBydate(ts) {
		var now = moment().add(-moment().utcOffset(), "m").format("DD/MM/YYYY HH:mm:ss");
		var then = moment.utc(ts).add(-moment().utcOffset(), "m").format("DD/MM/YYYY HH:mm:ss");
		return moment(now, "DD/MM/YYYY HH:mm:ss").diff(moment(then, "DD/MM/YYYY HH:mm:ss"), 'second')
	}

	var [CommandJson, setCommandJson] = useState({})
	var [CommandmsgJson, setCommandmsgJson] = useState({})
	var [Status, setStatus] = useState('')
	useEffect(() => {
		socket.on('mqttdailydatamonitorresponse', (massges) => {
			console.log(massges);
			setCommandmsgJson(massges)
		})
		onMonitoringScriptStatus()
	}, []);
	function getSecondsTodayBydate(ts) {
		var now = moment().add(-moment().utcOffset(), "m").format("DD/MM/YYYY HH:mm:ss");
		var then = moment.utc(ts).add(-moment().utcOffset(), "m").format("DD/MM/YYYY HH:mm:ss");
		return moment(now, "DD/MM/YYYY HH:mm:ss").diff(moment(then, "DD/MM/YYYY HH:mm:ss"), 'second')
	}

	const onMonitoringScriptStatus = async () => {
		const data = await MonitoringScriptStatus()
		if (data.success == true) {
			setCommandJson(data.data.meterdata)
		} else {

		}
	}

	const onMeterDailyDataScripUpdate = async () => {
		swal.fire({
			title: "Are you sure?",
			text: "This will initiate downlink",
			icon: "warning",
			showCancelButton: true,
		}).then(async (willDelete) => {
			if (willDelete.isConfirmed) {
				const data = await MeterDailyDataScripUpdate()
				if (data.success == true) {
					setCommandJson(data.data.meterdata)
				} else {

				}
			}
		})
	}

	const onLetestTime = async () => {
		const data = await onGetMeterdashboardLetestTime()
		if (data.success) {
			setStatus(data?.data?.data[0]?.ENTERYTS)
		} else {

		}
	}

	useEffect(() => {
		console.log(CommandmsgJson, CommandJson);
		if (CommandmsgJson?.REFRESH_STATUS == 'START' && CommandJson?.MSGID == CommandmsgJson?.MSGID) {
			swal.fire('', 'Script Started', 'success')
		} else if(CommandmsgJson?.REFRESH_STATUS == "SUCCESS" && CommandJson?.MSGID == CommandmsgJson?.MSGID){
			swal.fire('', CommandmsgJson?.REFRESH_STATUS, 'success')
		}else if (CommandmsgJson?.REFRESH_STATUS != "SUCCESS" && CommandJson?.MSGID && CommandJson?.MSGID == CommandmsgJson?.MSGID) {
			if (CommandmsgJson?.REFRESH_STATUS == 'IDLE') {
				setStatus('')
				onLetestTime()
			} else if (CommandmsgJson?.REFRESH_STATUS == 'RUNNING') {
				setStatus('Pending')
			} else {
				setStatus('')
				swal.fire('', CommandmsgJson?.REFRESH_STATUS, 'error')
			}
		}
	}, [CommandmsgJson])
	
	const [expandedChartData, setexpandedChartData] = useState({ total:{IMP_WH_COUNT: [], SAVING_WH_COUNT: [],SAVING_RS_COUNT:[], SRNO: []},date:[]} )
	const [dates, setDates] = useState({ start: '', end: '' })
	const [ChartLoader, setChartLoader] = useState(false)
	
	const onGetexInstantData = async () => {
		const x = new Date(moment(dates.start,'DD-MM-YYYY').format('YYYY-MM-DD'));
const y = new Date(moment(dates.end,'DD-MM-YYYY').format('YYYY-MM-DD'));
console.log(x>y,x,y);
            if(x>y){
            return	toast.warn('"Start Date" Must be Less than or Equal to "End Date"')
            }
		setChartLoader(true)
		const data = await getTotalConsumptionInMeterDashboardBySelectedDate({ start: moment(dates.start, "DD-MM-YYYY").format("YYYY-MM-DD"), end: moment(dates.end, "DD-MM-YYYY").format("YYYY-MM-DD"), CATEGORY0: Profile.account, CATEGORY1: Profile.ga, CATEGORY2: Profile.circle, CATEGORY3: Profile.division, CATEGORY4: Profile.subdivision, CATEGORY5: Profile.area, ROLES: userInfo?.ROLE, serach: Profile.account === '' && Profile.ga === '' && Profile.circle === '' && Profile.division === '' && Profile.subdivision === '' && Profile.area === '' ? false : true, Profile: { CATEGORY0: permision.ROLES_PERMISSIONS_CATEGORY0.CATEGORY0_view, CATEGORY1: permision.ROLES_PERMISSIONS_CATEGORY1.CATEGORY1_view, CATEGORY2: permision.ROLES_PERMISSIONS_CATEGORY2.CATEGORY2_view, CATEGORY3: permision.ROLES_PERMISSIONS_CATEGORY3.CATEGORY3_view, CATEGORY4: permision.ROLES_PERMISSIONS_CATEGORY4.CATEGORY4_view, CATEGORY5: permision.ROLES_PERMISSIONS_CATEGORY5.CATEGORY5_view } })
		if (data.success === true) {
			const ChartArray = data.data.data;
			var obj = { }
			if(expandedModelHeader=='Daily Data Count'){
				obj = { Consumption: ChartArray.map((item) => (item?.IMP_WH_COUNT||0)),INACTIVE_CONS: ChartArray.map((item) => (item?.INACTIVE_CONS||0)),UNDER_MAINTANANCE_CONS: ChartArray.map((item) => (item?.UNDER_MAINTANANCE_CONS||0)),PENDING_CONFIGURATION_CONS: ChartArray.map((item) => (item?.PENDING_CONFIGURATION_CONS||0)),DAILY_FAIL: ChartArray.map((item) => (item?.DAILY_FAIL||0)), SRNO: ChartArray.map((item) => (item?.SRNO)),DAILY_SUCCESS:ChartArray.map((item) => (item?.DAILY_SUCCESS||0)) }
			}else if(expandedModelHeader=='Power Saving In KWH'){
				obj = { Consumption: ChartArray.map((item) => (item?.SAVING_WH_COUNT||0)), COUNT_MINUS: ChartArray.map((item) => (item?.SAVING_WH_COUNT_MINUS||0)), SRNO: ChartArray.map((item) => (item?.SRNO)) }
			
			}else if(expandedModelHeader=='Consumption Graph'){
				obj = { REQ_CONS_MIN: ChartArray.map((item) => (item?.REQ_CONS_MIN||0)), REQ_CONS_MAX: ChartArray.map((item) => (item?.REQ_CONS_MAX||0)),REQ_CONS_AVG:ChartArray.map((item) => (item?.REQ_CONS_AVG)),Consumption: ChartArray.map((item) => (item?.IMP_WH_COUNT||0)), SRNO: ChartArray.map((item) => (item?.SRNO)) }
			}else{
				obj = { Consumption: ChartArray.map((item) => (item?.SAVING_RS_COUNT||0)),COUNT_MINUS:ChartArray.map((item) => (item?.SAVING_RS_COUNT_MINUS||0)),COUNT_NULL:ChartArray.map((item) => (item?.SAVING_RS_COUNT_NULL||0)), SRNO: ChartArray.map((item) => (item?.SRNO)) }
				
			}
			setexpandedChartData({ total: obj, date: data.data.data.map((item) => (item.CREATED_AT.split('T')[1]?moment.utc(item.CREATED_AT).add(-1,'days').format('DD-MM-YYYY'):moment(item.CREATED_AT,'DD-MM-YYYY').add(-1,'days').format('DD-MM-YYYY')))})
			setChartLoader(false)
		} else {
			console.log('something Went wrong')
			setChartLoader(false)
		}
	}

	useEffect(() => {
		console.log(CommandmsgJson, CommandJson);
		if (CommandmsgJson?.REFRESH_STATUS == 'START' && CommandJson?.MSGID == CommandmsgJson?.MSGID) {
			swal.fire('', 'Script Started', 'success')
		} else if (CommandJson?.MSGID && CommandJson?.MSGID == CommandmsgJson?.MSGID) {
			if (CommandmsgJson?.REFRESH_STATUS == 'IDLE') {
				setStatus('')
				onLetestTime()
			} else if (CommandmsgJson?.REFRESH_STATUS == 'RUNNING') {
				setStatus('Pending')
			} else {
				setStatus('')
				swal.fire('', CommandmsgJson?.REFRESH_STATUS, 'error')
			}
		}
	}, [CommandmsgJson])

	const exInstantData = {
		labels: expandedChartData?.date,
		datasets: [
			{
				label: expandedModelHeader,
				data: expandedChartData?.total?.Consumption,
				backgroundColor: 'rgb(0, 104, 138)',
				barPercentage: 1.0,
				borderRadius: 5,
			},
			{
				label: '0 Power Saving(Count)',
				data: expandedChartData?.total?.COUNT_MINUS,
				backgroundColor: 'rgb(76 181 215)',
				barPercentage: 1.0,
				borderRadius: 5,
			},{
				label: expandedModelHeader=='Consumption In Rupees'?'null Unit Rate':'null Burning KWH',
				data: expandedChartData?.total?.COUNT_NULL,
				backgroundColor: 'rgb(255 69 96)',
				barPercentage: 1.0,
				borderRadius: 5,
			}
		],
		
	};
	const exInstantKWHHistory = {
		labels: expandedChartData?.date.map((item)=>(moment(item,'DD-MM-YYYY').add(-1,'days').format('DD-MM-YYYY'))),
		datasets: [
			// {
			// 	label: "Daily Consumption(KWH)",
			// 	data: expandedChartData?.total?.Consumption,
			// 	backgroundColor: 'rgb(0, 104, 138)',
			// 	barPercentage: 1.0,
			// 	borderRadius: 5,
			// },
			{
				label: "Daily Success(count)",
				data: expandedChartData?.total?.DAILY_SUCCESS,
				backgroundColor: 'rgb(0, 104, 138)',
				barPercentage: 1.0,
				borderRadius: 5,
			},
			{
				label: "Daily Fail(Count)",
				data: expandedChartData?.total?.DAILY_FAIL,
				backgroundColor: 'rgb(51 181 229)',
				barPercentage: 1.0,
				borderRadius: 5,
			},
			{
				label: "Inactive(Count)",
				data: expandedChartData?.total?.INACTIVE_CONS,
				backgroundColor: 'rgb(255 69 96)',
				barPercentage: 1.0,
				borderRadius: 5,
			},
			{
				label: "Under Maintanace(Count)",
				data: expandedChartData?.total?.UNDER_MAINTANANCE_CONS,
				backgroundColor: 'rgb(0 143 251)',
				barPercentage: 1.0,
				borderRadius: 5,
			},
			{
				label: "Pending Configuration(Count)",
				data: expandedChartData?.total?.PENDING_CONFIGURATION_CONS,
				backgroundColor: 'rgb(254 217 145)',
				barPercentage: 1.0,
				borderRadius: 5,
			}
		],
		
	};

	const exConsumption = {
		labels: expandedChartData?.date.map((item)=>(moment(item,'DD-MM-YYYY').add(-1,'days').format('DD-MM-YYYY'))),
		datasets: [
			{
				label: "PCMIN",
				data: expandedChartData?.total?.REQ_CONS_MIN,
				fill: false,
				borderColor: 'rgb(53, 162, 235)',
				backgroundColor: (context) => {
					const ctx = context.chart.ctx;
					const gradient = ctx.createLinearGradient(0, 0, 0, 400);
					gradient.addColorStop(0, "rgba(53, 162, 235, 0.8)");
					gradient.addColorStop(1, "rgba(53, 162, 235, 0)");
					return gradient;
					// return pattern.draw('dot', gradient)
				},
				// backgroundColor: 'rgba(53, 162, 235, 0.5)',
			},
			{
				label: "PCMAX",
				data: expandedChartData?.total?.REQ_CONS_MAX,
				fill: false,
				borderColor: 'rgb(225, 29, 72',
				backgroundColor: (context) => {
					const ctx = context.chart.ctx;
					const gradient = ctx.createLinearGradient(0, 0, 0, 400);
					gradient.addColorStop(0, "rgb(254, 205, 211)");
					gradient.addColorStop(1, "rgb(254, 205, 211)");
					return gradient;
					// return pattern.draw('dot', gradient)
				},
				// backgroundColor: 'rgba(53, 162, 235, 0.5)',
			},
			{
				label: "PCAVG",
				data: expandedChartData?.total?.REQ_CONS_AVG,
				fill: false,
				borderColor: 'rgb(29, 78, 216)',
				backgroundColor: (context) => {
					const ctx = context.chart.ctx;
					const gradient = ctx.createLinearGradient(0, 0, 0, 400);
					gradient.addColorStop(0, "rgb(191, 219, 254)");
					gradient.addColorStop(1, "rgb(191, 219, 254)");
					return gradient;
					// return pattern.draw('dot', gradient)
				},
				// backgroundColor: 'rgba(53, 162, 235, 0.5)',
			},
			{
				label: "Consumption",
				data: expandedChartData?.total?.Consumption,
				fill: false,
				borderColor: 'rgb(8,145, 178)',
				backgroundColor: (context) => {
					const ctx = context.chart.ctx;
					const gradient = ctx.createLinearGradient(0, 0, 0, 400);
					gradient.addColorStop(0, "rgb(207, 250, 254)");
					gradient.addColorStop(1, "rgb(207, 250, 254)");
					return gradient;
					// return pattern.draw('dot', gradient)
				},
				// backgroundColor: 'rgba(53, 162, 235, 0.5)',
			}
		]
	};

	const optionsExpanded = useMemo(() => ({
		// parsing: false,
		responsive: true,
		maintainAspectRatio: false,
		scales: {
			x: {
				// type: 'time',
				ticks: {
					maxRotation: 90,
					minRotation: 90,
					font: {
						size: 10
					},
				}
			},

			y: {
				beginAtZero: true
			},
		},

		// parsing: false,
		plugins: {
			// decimation: {
			// 	enabled: true,
			// 	algorithm: 'lttb',
			// 	samples: 800,
			// 	threshold: 800
			// },
			legend: {
				position: 'bottom',
				labels: {
					pointStyleWidth: 10,
					usePointStyle: true,
					pointStyle: 'rectRounded'
				}
			},
			zoom: {
				zoom: {
					// onZoomComplete: ({
					// 	chart
					// }) => {
					// 	const xMin = chart.scales.x.getLabelForValue(chart.scales.x.min);
					// 	const xMax = chart.scales.x.getLabelForValue(chart.scales.x.max);
					// 	const yMin = chart.scales.y.min;
					// 	const yMax = chart.scales.y.max;
					// 	if (chart.scales.x._valueRange === chart.data.labels.length) {
					// 		setzoomOutEnable(false)
					// 		console.log("disable Zoom Out")
					// 	} else {
					// 		setzoomOutEnable(true)
					// 	}
					// 	if (chart.scales.x.min === chart.scales.x.max) {
					// 		setzoomInEnable(false)
					// 		console.log("disable Zoom in")
					// 	}
					// 	else {
					// 		setzoomInEnable(true)
					// 	}
					// 	// console.log("x values min max == ", chart.scales.x.min, chart.scales.x.max)
					// 	// console.log("Zoom === ", xMin, xMax, yMin, yMax)
					// },
					wheel: {
						enabled: true,
					},
					mode: "x",
				},
				pan: {
					enabled: true,
					mode: "x",
				}
			},
			datalabels: {
				align: 'end',
				anchor: 'end',
				clamp: true,
				color: "black",
				padding: {
					top: 0,
					bottom: 0
				},
				font: function (context) {
					var w = context.chart.width;
					var max = context.chart.scales.x.ticks.length
					return {
						// size: w < 512 ? 8 : 10,
						size: max < 15 ? 10 : 8
					};
				},

			}
		},
		layout: {
			padding: {
				top: 20
			}
		},
	}), [expandedChartData])
	const [zoomInEnable, setzoomInEnable] = useState(false)
	const [zoomOutEnable, setzoomOutEnable] = useState(false)
	const expandChartRef = useRef()
	const myChart = expandChartRef.current;
	let midValue = 0
	if (myChart != undefined || myChart != null) {
		const xMin = myChart.scales.x.min;
		const xMax = myChart.scales.x.max;
		console.log("minMax == ", xMin, xMax)
		midValue = xMax / 2
	}

	const zoomIn = () => {
		if (myChart != undefined || myChart != null) {
			myChart.zoom(1.2);
		}
	}
	const zoomOut = () => {
		if (myChart != undefined || myChart != null) {
			myChart.zoom(0.8);
		}
	}

	const panLeft = () => {
		if (myChart != undefined || myChart != null) {
			myChart.pan({ x: 400 }, undefined, 'default');
		}
	}
	const panRight = () => {
		if (myChart != undefined || myChart != null) {
			myChart.pan({ x: -400 }, undefined, 'default');
		}
	}
	useEffect(() => {
		if (myChart != undefined || myChart != null) {
			myChart.zoomScale('x', { min: midValue - 3, max: midValue + 3 }, 'default')
			console.log("midaVAlue in setTimeout == ", Math.round(midValue))
			// myChart.zoom(1.2);
			// myChart.pan({
			// 	x: 500
			// }, undefined, 'default');

			// myChart.pan({
			// 	x: - (Number.MAX_SAFE_INTEGER)
			// }, undefined, 'default');

		}
	}, [myChart])

	const onChange = (date, dateString) => {
		setDates({ ...dates, start: dateString })
	};
	const onChanges = (date, dateString) => {
		setDates({ ...dates, end: dateString })
	};

	const handleExpandedModal = () => {
		setisExpandedModalOpen(false);
		setexpandedChartData({ total: {}, date: [] })
		
		if (myChart != undefined) {
			myChart.destroy()
		}
		setChartLoader(false)
		setDates({ start: '', end: '' })
	}

	const onClickEpand = (event) => {
		// console.log(getElementsAtEvent(chartRef.current, event));
		const { datasetIndex, index } = getElementAtEvent(expandChartRef.current, event)[0] || {};
		// console.log(datasetIndex, index)
		// console.log((getElementAtEvent(chartRef.current, event)[0]));
		const DATA_point = GSMDATList[datasetIndex]
		if (DATA_point) {
			onHandalGsmChart(GSMDATList[datasetIndex], expandedChartData?.total?.SRNO[index], false,expandedChartData?.date[index])

		}
	}

	const ongetGenretedAllGSMReport=async()=>{
		if(report.start_date!==''&&report.end_date!==''&&reportType!=''){
			const x = new Date(moment(report.start_date,'DD-MM-YYYY').format('YYYY-MM-DD'));
        const y = new Date(moment(report.end_date,'DD-MM-YYYY').format('YYYY-MM-DD'));
        console.log(x>y,x,y);
              if(x>y){

              return	toast.warn('"Start Date" Must be Less than or Equal to "End Date"')
              }
		// setReportLoader(true)
		// const data = await getMeterLoraChartByDateSlection({ start: moment(report.start_date, "DD-MM-YYYY").format("YYYY-MM-DD"), end: moment(report.end_date, "DD-MM-YYYY").format("YYYY-MM-DD"), CATEGORY0: Profile.account, CATEGORY1: Profile.ga, CATEGORY2: Profile.circle, CATEGORY3: Profile.division, CATEGORY4: Profile.subdivision, CATEGORY5: Profile.area, ROLES: userInfo?.ROLE, serach: Profile.account === '' && Profile.ga === '' && Profile.circle === '' && Profile.division === '' && Profile.subdivision === '' && Profile.area === '' ? false : true, Profile: { CATEGORY0: permision.ROLES_PERMISSIONS_CATEGORY0.CATEGORY0_view, CATEGORY1: permision.ROLES_PERMISSIONS_CATEGORY1.CATEGORY1_view, CATEGORY2: permision.ROLES_PERMISSIONS_CATEGORY2.CATEGORY2_view, CATEGORY3: permision.ROLES_PERMISSIONS_CATEGORY3.CATEGORY3_view, CATEGORY4: permision.ROLES_PERMISSIONS_CATEGORY4.CATEGORY4_view, CATEGORY5: permision.ROLES_PERMISSIONS_CATEGORY5.CATEGORY5_view } })
		// if (data.success === true) {
		// 	const ChartArray = data.data.data;
		// 	var Reportdata=[]
		// 	var headers = {"Date":'Date',"Daily Success":'Daily Success',"Daily Fail":'Daily Fail',"Inactive":'Inactive',"Undermaintanace":'Undermaintanace',"Pending Configuration":'Pending Configuration',"Total Consumer":'Total Consumer','Performance(%)':'Performance(%)','Filter':'Filter'}
		// 	const filter_obj={CATEGORY0: AccounData.filter((item)=>item?.UNIQUE_ID==Profile.account)[0]?.CATEGORY0||'', CATEGORY1: GaData.filter((item)=>item?.CATEGORY1_UNIQUE_ID==Profile.ga)[0]?.CATEGORY1_NAME||'', CATEGORY2: CircleData.filter((item)=>item?.CATEGORY2_UNIQUE_ID==Profile.circle)[0]?.CATEGORY2_NAME||'', CATEGORY3: DivisionData.filter((item)=>item?.CATEGORY3_UNIQUE_ID==Profile.division)[0]?.CATEGORY3_NAME||'', CATEGORY4:  SubdevisionData.filter((item)=>item?.CATEGORY4_UNIQUE_ID==Profile.subdivision)[0]?.CATEGORY4_NAME||'', CATEGORY5: AreaData.filter((item)=>item?.UNIQUE_ID==Profile.area)[0]?.NAME||''}
		// 	ChartArray.map((item,id) => {
		// 		var TotalC=item?.daily_success+item?.daily_fail+item?.inactive_count+item?.undermantan_count+item?.pendding_count
		// 		Reportdata.push({"Date":item.CREATED_AT.split('T')[1]?moment.utc(item.CREATED_AT).format('DD-MM-YYYY'):item.CREATED_AT,"Daily Success":item?.daily_success||0,"Daily Fail":item?.daily_fail||0,"Inactive":item?.inactive_count||0,"Undermaintanace":item?.undermantan_count||0,"Pending Configuration":item?.pendding_count||0,"Total Consumer":TotalC,"Performance(%)":parseFloat((item?.daily_success*100)/(TotalC-(item?.inactive_count+item?.undermantan_count+item?.pendding_count))).toFixed(2),'Filter':JSON.stringify(filter_obj)})
		// 		if(id==(data.data.data.length-1)){
		// 			if(reportType==='csv'){
		// 				exportCSVFile(headers,Reportdata,`Lorawan_Meter_Daily_Data_Count_`+'('+report.start_date+')'+'_'+'('+report.end_date+')')
		// setReportLoader(false)
		// 			 }else{
		// 				exportExcelDownload(Reportdata,`Lorawan_Meter_Daily_Data_Count_`+'('+report.start_date+')'+'_'+'('+report.end_date+')')
		// setReportLoader(false)
		// 			 }
		// 		}
		// 	})
			
		// 	// setChartLoader(false)
		// } else {
		// 	console.log('something Went wrong')
		// 	// setChartLoader(false)
		// 	setReportLoader(false)
		// }
	}
	}

	const Download_Logs=()=>{
        exportExcelDownload(TableData.map((item,id)=>({"SRNO":id + 1,"Company/Client Name":item?.address_compony_name,"Modem Serial No":item?.DEVICE_SERIAL_NO})),ModelId+(new Date().valueOf()))
    }
	return (
		<>

<Modal title="Generate Reports" visible={ismodel} footer='' onCancel={handleCancelIsmodal} width={1500}>
				<section className="section">
					<div className="section-body">
						<div className="row">
							<div className="col-12">
									<div className="form-row" style={{ marginLeft: "10px" }}>
										
										<><div className="form-group col-md-3" id="strdate">
											<label for="inputPassword4">Start Date :<span className='text-danger'>*</span> </label><br></br>
											<DatePicker onChange={(e, dateString) => setReport({ ...report, start_date: dateString })} format='DD-MM-YYYY' name='start' style={{ width: '100%', height: '40px' }}
												disabledDate={(current) => {
													return current.isAfter(moment())
												}} />
										</div>
											<div className="form-group col-md-3" id="enddate" >
												<label for="inputPassword4">End Date :<span className='text-danger'>*</span> </label><br></br>
												<DatePicker onChange={(e, dateString) => setReport({ ...report, end_date: dateString })} format='DD-MM-YYYY' name='start' style={{ width: '100%', height: '40px' }}
													disabledDate={(current) => {
														return current.isAfter(moment())
													}} />
											</div></>
										<div className="form-group col-md-3" id="grpeui">
											<label for="inputPassword4">Extension :<span className='text-danger'>*</span> </label>
											<select className="form-control form-select" name="reportextension" onChange={(e) => (setReportType(e.target.value))} value={reportType}>
												<option value="">Select Extension</option>
												<option value="xls">XLS</option>
												<option value="csv">CSV</option>
											</select>
										</div>
									</div>
									<p style={{ marginLeft: "25px" }} className="text-danger">Note : * Indicates Mandatory Fields</p>
									<div className="text-left">
										<input type="submit" name="submit" className={`btn btn-danger mr-2 ${ReportLoader==true?'disabled btn-progress':''}`} value="Generate" id="submitvn" onClick={ongetGenretedAllGSMReport} />
										<Link className="btn btn-light text-dark" onClick={handleCancelIsmodal}>Cancel</Link>
									</div>
							</div>
						</div>
					</div>
				</section>
			</Modal>
		<Modal title={expandedModelHeader} visible={isExpandedModalOpen} maskClosable={false} footer='' onCancel={handleExpandedModal} width={1500}>

<div className="d-flex tw-justify-between tw-items-center">
	<div className="d-flex tw-justify-center tw-items-end">
		<Space direction="horizontal">
				<div className="d-flex flex-column">Start In
					<DatePicker value={dates?.start !== '' ? moment(dates?.start, "DD-MM-YYYY") : null} onChange={onChange} format='DD-MM-YYYY' name='start' disabledDate={(current) => {
						return (moment(expireDate.start_date).add(-1, 'days') >= current) || (moment.utc(expireDate.end_date).add(0, 'days') <= current);
					}} /></div>
				<div className="d-flex flex-column" >End In
					<DatePicker value={dates?.end !== '' ? moment(dates?.end, "DD-MM-YYYY") : null} onChange={onChanges} name='end' format='DD-MM-YYYY' disabledDate={(current) => {
						return (moment(expireDate.start_date).add(-1, 'days') >= current) || (moment.utc(expireDate.end_date).add(0, 'days') <= current);
					}} /></div>
			</Space>

		<div>
			<a className={`btn btn-danger text-white ml-3 ${ChartLoader ? 'btn-progress disabled' : ''}`} onClick={() => (onGetexInstantData())}>Submit</a>
		</div>

	</div>

	{expandedChartData.date.length > 0 ?
		<div>
			<div className="tw-mb-1">
				<small className='tw-text-red-300'><sup className="tw-text-sm !tw-top-0">*</sup> You can also do Zoom and Scroll using your mouse</small>
			</div>
			<div className="tw-flex tw-justify-end tw-items-center">
				<button className={`tw-rounded-lg tw-bg-indigo-500 tw-border-transparent text-white tw-mx-1 tw-p-1`} disabled={zoomInEnable} onClick={zoomIn}><BootstrapTooltip title="ZoomIn">
					<div className="tw-flex tw-items-center fa-lg">
						<i class="fas fa-search-plus" aria-hidden="true"></i>
					</div>
				</BootstrapTooltip>
				</button>
				<button className={`tw-rounded-lg tw-bg-indigo-500 tw-border-transparent text-white tw-mx-1 tw-p-1`} disabled={zoomOutEnable} onClick={zoomOut}>
					<BootstrapTooltip title='ZoomOut'>
						<div className="tw-flex tw-items-center fa-lg">
							<i class="fas fa-search-minus" aria-hidden="true"></i>
						</div>
					</BootstrapTooltip>
				</button>

				<button className="tw-rounded-lg tw-bg-indigo-500 tw-border-transparent text-white tw-mx-1 tw-py-1 tw-px-2" onClick={panLeft}>
					<BootstrapTooltip title='Left scroll'>
						<div className="tw-flex tw-items-center fa-lg">
							<i class="fas fa-caret-left" aria-hidden="true"></i>
						</div>
					</BootstrapTooltip>
				</button>
				<button className="tw-rounded-lg tw-bg-indigo-500 tw-border-transparent text-white tw-mx-1 tw-py-1 tw-px-2" onClick={panRight}>
					<BootstrapTooltip title='Right scroll'>
						<div className="tw-flex tw-items-center fa-lg">
							<i class="fas fa-caret-right" aria-hidden="true"></i>
						</div>
					</BootstrapTooltip>
				</button>
			</div>

		</div>
		: ''}
</div>

{expandedChartData.date.length > 0 &&expandedModelHeader!='Consumption Graph' ? <div className=" tw-mt-2 tw-h-[560px] tw-max-h-screen tw-px-5"><Bar ref={expandChartRef} options={optionsExpanded} data={expandedModelHeader=='Daily Data Count'?exInstantKWHHistory:exInstantData}/> </div> : ''}

{expandedChartData.date.length > 0 && expandedModelHeader=='Consumption Graph' ?<div className=" tw-mt-2 tw-h-[560px] tw-max-h-screen tw-px-5"><Line ref={expandChartRef} options={optionsLine} data={exConsumption} /></div>:''}

{expandedChartData.date.length == 0 ?<h4 className="text-center">Data Not Found</h4>:''}

</Modal>
			<Modal title={<div className="d-flex"><h5 style={{ color: ColorText }}>{ModelId}</h5>{TableData.length > 0?<span className='btn btn-primary text-white ml-2' onClick={Download_Logs}><i className='fas fa-download'></i></span>:''}{TableData.length > 0?<Link to={{  pathname: `All-GSM`,state: { detail: TableData.map((data)=>(data?.UNIQUE_ID)),title:ModelId }  }}><span className='btn btn-primary text-white ml-2'><i className='fas fa-up-right-from-square'></i></span></Link>:''}</div>} maskClosable={false} className="my-modal-class" visible={isModalVisible} footer='' onCancel={handleCancel} width={1000} >
				<div>
					<div className="modal-table">
					{ModalLoad?(
                                        <div className="example">
                                            <Spin size="large" />
                                        </div>
                                    ):<table className="table border text-center table-borderless" id="table-1">
							<thead style={{ position: 'sticky', top: '0', background: '#fff' }}>
								<tr>
									<th className="text-center">SR NO.</th>
									<th >Company/Client Name</th>
									<th>Modem Serial No</th>
									{ModelId.split('(')[0]=='Consumption In Rupees'||ModelId.split('(')[0]=='Power Saving In KWH'||ModelId.split('(')[0]=='DAILY_SUCCESS_LIST'?<th>{ModelId.split('(')[0]=='Daily Data Count'?'KWH':ModelId.split('(')[0]=='Power Saving In KWH'?'KWH':ModelId.split('(')[0]=='Consumption In Rupees'?'Rupees':'KWH'}</th>:''}
								</tr>
							</thead>
							<tbody id="tbody">
								<>
									{TableData.length > 0 ?
										TableData.map((data, id) => (
											<tr style={{ height: "4rem", cursor: 'pointer' }} className="trheigth even:tw-bg-gray-100 hover:tw-bg-[#D8D8D8]" key={id} onClick={() => onHandaleViewGSM(data?.UNIQUE_ID)}>
												<td className="text-center">{id + 1}</td>
												<td>{data?.address_compony_name}</td>
												<td>{data?.DEVICE_SERIAL_NO}</td>
												{ModelId.split('(')[0]=='Consumption In Rupees'||ModelId.split('(')[0]=='Power Saving In KWH'||ModelId.split('(')[0]=='DAILY_SUCCESS_LIST'?<td>{data?.Consumption}</td>:''}
												
											</tr>
										)) : <tr></tr>}
								</>
							</tbody>
						</table>}
						{TableData.length ==0&& !ModalLoad && <h4 className="text-center">No Data Found</h4>}

					</div>
				</div>
			</Modal>
			<section class="section">
				<div class="section-body">
					<div class="row">
						<div class="col-12">
							<div class="card ">
								<div class="card-body tw-flex tw-items-center tw-gap-3" style={{ padding: "2px 12px" }}>
									<div class="tw-flex tw-gap-2"
										style={{

											borderColor: "#e3e6fc !important",
											position: "relative",
											marginTop: "5px",
										}}>
										<div class="tw-w-full tw-min-w-[13rem]">
											<div class="form-group">
												<label htmlFor="inputPassword4">
												Account<span class="text-danger">*</span>
												</label>
												{/* <Select name='Consumertype' isMulti options={AccounData.map((item, id) =>
																	permision?.ROLES_PERMISSIONS_CATEGORY0?.CATEGORY0_view?.filter(
																		(items) => items === item.UNIQUE_ID
																	).length > 0 || userInfo?.ROLE === 'ROLE1661856938676' ? (
																		{value:item.UNIQUE_ID,label:item.CATEGORY0}
																	) :{}
																)} onChange={(e) => { setProfile({ ...Profile, account: e.map((item) => (item.value)) }) }} /> */}
												<select
													class="form-control form-select accountoga ga_calueacc"
													name="accountid"
													required=""
													id="accountid"
													onChange={(e) => setProfile({ ...Profile, account: e.target.value })}
													value={Profile?.account}

												>
													<option value="">Select Account</option>
													{AccounData &&
														AccounData.map((item, id) =>
															permision?.ROLES_PERMISSIONS_CATEGORY0?.CATEGORY0_view?.filter(
																(items) => items === item.UNIQUE_ID
															).length > 0 || userInfo?.ROLE === 'ROLE1661856938676' ? (
																<option key={id} value={item.UNIQUE_ID}>{item.CATEGORY0}</option>
															) : (
																""
															)
														)}
												</select>
											</div>
										</div>
										{Profile.account !== "" && (
											<div class="tw-w-full tw-min-w-[12rem] gagrp">
												<div class="form-group">
													<label htmlFor="inputPassword4">{NameObj?.CATEGORY1 || 'CATEGORY1'}</label>
													<select
														class="form-control form-select gatocircle ga_calue"
														name="address_location_d"
														id="address_location_d"
														onChange={(e) => setProfile({ ...Profile, ga: e.target.value })}
														value={Profile?.ga}
													>
														<option value="">Select {NameObj?.CATEGORY1 || 'CATEGORY1'}</option>
														{GaData &&
															GaData.map((item, id) =>
																permision?.ROLES_PERMISSIONS_CATEGORY1?.CATEGORY1_view?.filter(
																	(items) => items === item.CATEGORY1_UNIQUE_ID
																).length > 0 || userInfo?.ROLE === 'ROLE1661856938676' ? (
																	<option key={id} value={item.CATEGORY1_UNIQUE_ID}>{item.CATEGORY1_NAME}</option>
																) : (
																	""
																)
															)}
													</select>
												</div>
											</div>
										)}
										{Profile.ga !== "" && Profile.account !== "" && (
											<div class="tw-w-full tw-min-w-[12rem] circlegrp">
												<div class="form-group">
													<label htmlFor="inputPassword4">{NameObj?.CATEGORY2 || 'CATEGORY2'}</label>
													<select
														class="form-control circletodivision form-select"
														name="circle_id"
														onChange={(e) => setProfile({ ...Profile, circle: e.target.value })}
														value={Profile?.circle}
													>
														<option value="">Select {NameObj?.CATEGORY2 || 'CATEGORY2'}</option>
														{CircleData &&
															CircleData.map((item, id) =>
																permision?.ROLES_PERMISSIONS_CATEGORY2?.CATEGORY2_view?.filter(
																	(items) => items === item.CATEGORY2_UNIQUE_ID
																).length > 0 || userInfo?.ROLE === 'ROLE1661856938676' ? (
																	<option key={id} value={item.CATEGORY2_UNIQUE_ID}>{item.CATEGORY2_NAME}</option>
																) : (
																	""
																)
															)}
													</select>
												</div>
											</div>
										)}
										{Profile.circle !== "" && Profile.ga !== "" && Profile.account !== "" && (
											<div class="tw-w-full tw-min-w-[12rem] divisiongrp">
												<div class="form-group">
													<label htmlFor="inputPassword4">{NameObj?.CATEGORY3 || 'CATEGORY3'}</label>
													<select
														class="form-control divisiontosubdivision form-select"
														name="division_id"
														id="address_location_d"
														onChange={(e) => setProfile({ ...Profile, division: e.target.value })}
														value={Profile?.division}
													>
														<option value="">Select {NameObj?.CATEGORY3 || 'CATEGORY3'}</option>
														{DivisionData &&
															DivisionData.map((item, id) =>
																permision?.ROLES_PERMISSIONS_CATEGORY3?.CATEGORY3_view?.filter(
																	(items) => items === item.CATEGORY3_UNIQUE_ID
																).length > 0 || userInfo?.ROLE === 'ROLE1661856938676' ? (
																	<option key={id} value={item.CATEGORY3_UNIQUE_ID}>{item.CATEGORY3_NAME}</option>
																) : (
																	""
																)
															)}
													</select>
												</div>
											</div>
										)}
										{Profile.division !== "" && Profile.circle !== "" && Profile.ga !== "" && Profile.account !== "" && (
											<div class="tw-w-full tw-min-w-[12rem] subdivisiongrp">
												<div class="form-group">
													<label htmlFor="inputPassword4">{NameObj?.CATEGORY4 || 'CATEGORY4'}</label>
													<select
														class="form-control subdivisiontoarea form-select"
														name="sub_division_id"
														id="address_location_d"
														onChange={(e) => setProfile({ ...Profile, subdivision: e.target.value })}
														value={Profile?.subdivision}
													>
														<option value="">Select {NameObj?.CATEGORY4 || 'CATEGORY4'}</option>
														{SubdevisionData &&
															SubdevisionData.map((item, id) =>
																permision?.ROLES_PERMISSIONS_CATEGORY4?.CATEGORY4_view?.filter(
																	(items) => items === item.CATEGORY4_UNIQUE_ID
																).length > 0 || userInfo?.ROLE === 'ROLE1661856938676' ? (
																	<option key={id} value={item.CATEGORY4_UNIQUE_ID}>
																		{item.CATEGORY4_NAME}
																	</option>
																) : (
																	""
																)
															)}
													</select>
												</div>
											</div>
										)}
										{Profile.subdivision !== "" &&
											Profile.division !== "" &&
											Profile.circle !== "" &&
											Profile.ga !== "" &&
											Profile.account !== "" && (
												<div class="tw-w-full tw-min-w-[12rem] areagrp">
													<div class="form-group">
														<label htmlFor="inputPassword4">{NameObj?.CATEGORY5 || 'CATEGORY5'}</label>
														<select
															class="form-control form-select lastarea cust_ara"
															name="area_id"
															id="area_id"
															onChange={(e) => setProfile({ ...Profile, area: e.target.value })}
															value={Profile?.area}
														>
															<option value="">Select {NameObj?.CATEGORY5 || 'CATEGORY5'}</option>
															{AreaData &&
																AreaData.map((item, id) =>
																	permision?.ROLES_PERMISSIONS_CATEGORY5?.CATEGORY5_view?.filter(
																		(items) => items === item.UNIQUE_ID
																	).length > 0 || userInfo?.ROLE === 'ROLE1661856938676' ? (
																		<option key={id} value={item.UNIQUE_ID}>{item.CATEGORY5_NAME}</option>
																	) : (
																		""
																	)
																)}
														</select>
													</div>
												</div>
											)}
									</div>
									<div>
										<button class="btn btn-primary" onClick={onProfileWiseSearchClick}> <i className="fa fa-search"></i> </button>
									</div>
								</div>
							</div>

						</div>
					</div>
					<div className="row">
						<div class="col-12">
							<div class="card card-danger">
								<div class="row">
									<div class="col-12 ">
										<div class="card-header"  >
											<h4><span className="mr-2">Daily Data Count</span><BootstrapTooltip title='Find Data In gsm_daily_load_monitoring_day_wise_graph' placement='top'><span><i className='fas fa-info-circle info_circle'></i></span></BootstrapTooltip></h4>
											<div class="card-header-action">
												<div class="card-header-action">
													<span className="mr-2">{Status == 'Pending' ? 'Script Running' : moment.utc(Status).add(-moment().utcOffset(), "m").fromNow('ss:hh:mm')}</span>
													<a class="btn btn-primary text-white mr-2" data-toggle="tooltip" data-placement="Top" title="Refresh" onClick={onMonitoringScriptStatus}><i class="fas fa-refresh" aria-hidden="true"></i></a>
													<a class="btn btn-warning text-white mr-2" data-toggle="tooltip" data-placement="Top" title="Run" onClick={onMeterDailyDataScripUpdate}>Run</a>

													<a class="btn btn-info text-white mr-2" data-toggle="tooltip" data-placement="Top" title="Expand" onClick={() => { onExpandButton('gsm_daily_load_monitoring_day_wise_graph', 'CREATED_AT', 'Daily Data Count') }} >Expand</a>

													{/* <button class="btn btn-info " onClick={()=>(setModel(true))}>
                      <i class="fa fa-download" aria-hidden="true"></i>
                      </button> */}
												</div>
											</div>
										</div>

										<div class="card-body heartbeatspace " style={{ position: "relative", height: "395px" }}>
											{AllGSMChartData?.IMP_WH_COUNT.length > 0  ? <Bar ref={chartRef} options={optionsForChartJS} data={dataForChartJs} onClick={onClickKWHHistory}/> : <div className="d-flex" style={{ justifyContent: 'center' }}><img class="loader_center text-center" src={loaderGif} /></div>}
										</div>
										<p class="text-right" style={{ marginRight: "40px", lineHeight: "10px" }}>Bar chart</p>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div className="row">
						<div class="col-12">
							<div class="card card-danger">
								<div class="row">
									<div class="col-12 ">
										<div class="card-header"  >
											<h4><span className="mr-2">Consumption Graph</span><BootstrapTooltip title='Find Data In gsm_daily_load_monitoring_day_wise_graph' placement='top'><span><i className='fas fa-info-circle info_circle'></i></span></BootstrapTooltip></h4>
											<div class="card-header-action">
												<div class="card-header-action">
													<a class="btn btn-info text-white mr-2" data-toggle="tooltip" data-placement="Top" title="Expand" onClick={() => { onExpandButton('gsm_daily_load_monitoring_day_wise_graph', 'CREATED_AT', 'Consumption Graph') }} >Expand</a>
												</div>
											</div>
										</div>

										<div class="card-body heartbeatspace " style={{ position: "relative", height: "400px" }}>
											{AllGSMChartData?.IMP_WH_COUNT.length > 0  ? <Line ref={chartRefLine} options={optionsLine} data={dataLine} /> : <div className="d-flex" style={{ justifyContent: 'center' }}><img class="loader_center text-center" src={loaderGif} /></div>}
										</div>
										<p class="text-right" style={{ marginRight: "40px", lineHeight: "10px" }}>Bar chart</p>
									</div>
								</div>
							</div>
						</div>
					</div>
                    {/* <div className="row">
						<div class="col-12">
							<div class="card card-danger">
								<div class="row">
									<div class="col-12 ">
										<div class="card-header"  >
											<h4><span className="mr-2">Power Saving In KWH</span><BootstrapTooltip title='Find Data In gsm_daily_load_monitoring_day_wise_graph' placement='top'><span><i className='fas fa-info-circle info_circle'></i></span></BootstrapTooltip></h4>
											<div class="card-header-action">
													<a class="btn btn-info text-white mr-2" data-toggle="tooltip" data-placement="Top" title="Expand" onClick={() => { onExpandButton('gsm_daily_load_monitoring_day_wise_graph', 'CREATED_AT', 'Power Saving In KWH') }}>Expand</a>
													
												</div>
										</div>

										<div class="card-body heartbeatspace " style={{ position: "relative", height: "395px" }}>
											{AllGSMChartData?.IMP_WH_COUNT.length > 0  ? <Bar ref={chartRefKWH} options={optionsForChartJS} data={SAVING_WH_COUNTChartJs} onClick={onClickPowKWH}/> : <div className="d-flex" style={{ justifyContent: 'center' }}><img class="loader_center text-center" src={loaderGif} /></div>}
										</div>
										<p class="text-right" style={{ marginRight: "40px", lineHeight: "10px" }}>Bar chart</p>
									</div>
								</div>
							</div>
						</div>
					</div>*/}
                    <div className="row">
						<div class="col-12">
							<div class="card card-danger">
								<div class="row">
									<div class="col-12 ">
										<div class="card-header"  >
											<h4><span className="mr-2">Consumption In Rupees</span><BootstrapTooltip title='Find Data In gsm_daily_load_monitoring_day_wise_graph' placement='top'><span><i className='fas fa-info-circle info_circle'></i></span></BootstrapTooltip></h4>
											<div class="card-header-action">
													<a class="btn btn-info text-white mr-2" data-toggle="tooltip" data-placement="Top" title="Expand" onClick={() => { onExpandButton('gsm_daily_load_monitoring_day_wise_graph', 'CREATED_AT', 'Consumption In Rupees') }}>Expand</a>
													
												</div>
										</div>

										<div class="card-body heartbeatspace " style={{ position: "relative", height: "395px" }}>
											{AllGSMChartData?.IMP_WH_COUNT.length > 0  ? <Bar ref={chartRefRupees}  options={optionsForRupee} data={SAVING_RS_COUNTChartJs} onClick={onClickPowRupee}/> : <div className="d-flex" style={{ justifyContent: 'center' }}><img class="loader_center text-center" src={loaderGif} /></div>}
										</div>
										<p class="text-right" style={{ marginRight: "40px", lineHeight: "10px" }}>Bar chart</p>
									</div>
								</div>
							</div>
						</div>
					</div> 
				</div>
			</section>
		</>
	)
}