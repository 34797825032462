import { DatePicker, Modal, Space, Spin } from "antd";
import moment from "moment";
import React, { useRef } from "react";
import { useState } from "react";
import { useEffect } from "react";
import useInterval from "use-interval";
import Pagination from "../../../../Commoncomponet/Pagination";
import { getConfigretionCMD_TYPEWisePendding_Count_buffer, getConfigretionCMD_TYPEWisePendding_Data_buffer, getHeardbeatData, getScriptCodeIdWiseColumnName, getScriptCodeIdWiseConfigretionData, getStartAndEndDateByTable, onModemConfigByGSMConsumer, reportCreacteInTableName } from "../../../../Commoncomponet/Utils";
import swal from 'sweetalert2';
import {BootstrapTooltip, exportCSVFile, exportExcelDownload, socket} from '../../../../Commoncomponet/common'
import clockPending from '../../../../assets/img/icons8-clock.gif'
import { CSVLink } from "react-csv";
import * as XLSX from 'xlsx';
import ReportJson from '../reportType.json'
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
window.Swal = swal;
export default function Instantaneous(props) {
	const [FlagMsg,setFlagMsg]=useState(false)
  const [modelTitle,setmodelTitle]=useState('')
  const [modal,setModal]=useState(false)
    const [MqttMsg,setMqttMsg]=useState('')
    const [time, setTime] = useState(60);
	const [run, setRun] = useState(false);
  const [modalMsg,setmodalMsg]=useState(false)
  const [Read1Msg,setRead1Msg]=useState(false)
  const [Read2Msg,setRead2Msg]=useState(false)
  const [cmd,setcmd]=useState({})
  const [Index,setIndex]=useState()

	const [PageSize, setPageSize] = useState(50);
	const [numberofPage, setnumberofPage] = useState(1);
	const [currentPage, setCurrentPage] = useState(1);
	const [heartData, setHeartData] = useState([]);
	const [loader, setLoader] = useState(false);
	const [isModalVisible, setIsModalVisible] = useState(false);
	const [AllData, setAllData] = useState({});
	const [rowData, setRowData] = useState({});
	const [ErrorData, setErrorData] = useState({});
	const [DupData, setDupData] = useState({});
	const [isModalError, setIsModalError] = useState(false);
	const [isModalDup, setIsModalDup] = useState(false);
    const [ColumnName,setColumnName]=useState({})
	const [dates,setDates]=useState({start:'',end:''})
	const [isModalrow, setIsModalRow] = useState(false);
    const [pendingBuffer,setpendingBuffer]=useState('')
	var [CommandJson,setCommandJson]=useState({})
    var [CommandmsgJson,setCommandmsgJson]=useState({})
    const [CommandStatus,setCommandStatus]=useState('')
	const [ReportLoader,setReportLoader]=useState(false)
	const [sortField,setsortField]=useState('Clock$0_0_1_0_0_255')
	const [sortDirection,setsortDirection]=useState('desc')
	const [UI_LOOKUP,setUI_LOOKUP]=useState({})

	const mytime = useRef(null)

	const ongetConfigretionCMD_TYPEWisePendding_Count_buffer=async()=>{
		const data=await getConfigretionCMD_TYPEWisePendding_Count_buffer({CONSUMER_ID:props.GSMdata.UNIQUE_ID,CMD_TYPE:'INSTANTANEOUS',tableName:props?.GSMdata?.UNIQUE_TABLE_NAME?.GSM_BUFFER})
		if(data.success===true){
			setpendingBuffer(data.data.data)
		}else{
			console.log('Something Went Wrong')
		}
	}
    const ongetScriptCodeIdWiseColumnName=async()=>{
        const data=await getScriptCodeIdWiseColumnName(props?.GSMdata?.SCRIPTDECODE_ID)
        if(data.success===true){
            if(data?.data?.data?.length>0){
            setColumnName(JSON.parse(data?.data?.data[0]?.COLUMN_NAME||'{}'))
			setUI_LOOKUP(JSON.parse(data?.data?.data[0]?.UI_LOOKUP||'{}'))

            }
        }else{
console.log('Something Went Wrong');
        }
    }
	const [StatusCode,setStatusCode]=useState('')

	const ongetHeardbeatData = async () => {
		if (heartData.length === 0) {
			setLoader(true);
		}
		const firstPageIndex = (currentPage - 1) * PageSize;
		const lastPageIndex = firstPageIndex + PageSize;
		const data = await getHeardbeatData({ start: firstPageIndex, end: PageSize }, { tableName: props?.GSMdata?.UNIQUE_TABLE_NAME?.INSTANTANEOUS,colName:sortField,sortDirection:sortDirection });
		if (data.success === true) {
			setHeartData(data.data.data);
			setnumberofPage(data.data.total);
			setLoader(false);
		} else {
			console.log("Something Went Wrong");
			setLoader(false);
			setStatusCode(data?.err?.response?.status)

		}
	};

	useEffect(()=>{
		ongetHeardbeatData()
	},[sortDirection,sortField])
	useEffect(() => {
		ongetHeardbeatData();
	  }, [currentPage, PageSize]);
	useEffect(()=>{
		socket.on('mqttbufferresponse',(massges)=>{
			console.log(massges);
			setCommandStatus(massges)
			
		})
		socket.on('mqttcommandresponse',(massges)=>{
			console.log(massges,CommandJson,massges.MSGID==CommandJson.MSGID,massges.SCRIPTDECODE_ID==CommandJson.SCRIPTDECODE_ID,massges.UNIQUE_ID==CommandJson.UNIQUE_ID,massges.CMD_TYPE==CommandJson.CMD_TYPE,massges.DRXTYPE,!massges.STATUS);
			setCommandmsgJson(massges)
		})
	},[])
	const BfferError=(msg)=>{
        console.log(msg.MSGID,CommandJson.MSGID);
        if(msg.MMD_ID_MSG==CommandJson.MMD_ID_MSG){
        swal.fire('', msg?.SCR_STATUS_CMD_ADD, 'error');
        setCommandStatus('')
        setFlagMsg(true)
        setRun(false);
        setTime(0)
        setModal(false)
    }
    }
    useEffect(()=>{
        if(CommandStatus?.SCR_STATUS_CMD_ADD!='Added In Buffer'&&Object.keys(CommandStatus).length>0){
            BfferError(CommandStatus)
			clearTimeout(mytime.current);
				mytime.current = null
        }else if(CommandStatus?.SCR_STATUS_CMD_ADD=='Added In Buffer'){
			setTime(60)
		setRun(true);
		clearTimeout(mytime.current);
				mytime.current = null
		}
    },[CommandStatus])
	const getPopdata=(massges)=>{
		console.log(massges,CommandJson);
	if(massges.MMD_ID_MSG==CommandJson.MMD_ID_MSG&&massges.SCRIPTDECODE_ID==CommandJson.SCRIPTDECODE_ID&&massges.UNIQUE_ID==CommandJson.UNIQUE_ID&&massges.SCR_ID_CMD_TYPE==CommandJson.SCR_ID_CMD_TYPE&&massges.MMD_ID_DRXTYPE&&!massges.STATUS){
			setTime(0)
			setRun(false);
		   setModal(false)
		   if(FlagMsg===false){
		   setmodalMsg(true)
		   setMqttMsg(JSON.stringify(massges))
			}
			
		}
	}
	
	useEffect(()=>{
			if(Object.keys(CommandmsgJson).length>0){
			getPopdata(CommandmsgJson)
			}
	},[CommandmsgJson])
	const ongetScriptCodeIdWiseConfigretionData=async()=>{
		const data=await getScriptCodeIdWiseConfigretionData(props?.GSMdata?.SCRIPTDECODE_ID,{tableName:props?.GSMdata?.UNIQUE_TABLE_NAME?.GSM_BUFFER,type:'INSTANTANEOUS'})
		if(data.success===true){
			const CMD=JSON.parse(data?.data?.data[0]?.METER_QUERY_CMD||'{}')
			if(CMD?.INSTANTANEOUS?.READ_METHOD===3){
				const obj={MMD_ID_CMD:124,SCR_ID_CMD_TYPE:'INSTANTANEOUS',"MMD_ID_OBIS_CODE": CMD?.INSTANTANEOUS?.OBIS,"MMD_ID_OBIS_OBJTYPE": CMD?.INSTANTANEOUS?.OBJ_TYPE,UNIQUE_ID:props?.GSMdata?.UNIQUE_ID,MMD_ID_IMEI:props?.GSMdata?.IMEI,SCRIPTDECODE_ID:props?.GSMdata?.SCRIPTDECODE_ID,MMD_ID_MSG:new Date().getTime()+1,MMD_ID_OBIS_READ_METHOD: CMD?.INSTANTANEOUS?.READ_METHOD,GSM_BUFFER_TABLE:props?.GSMdata?.UNIQUE_TABLE_NAME?.GSM_BUFFER}

				  setFlagMsg(false)
				  setmodelTitle(obj.SCR_ID_CMD_TYPE)
				//   alert(JSON.stringify(obj))
                  setCommandJson(obj)

				  swal.fire({
					  title: "Are you sure?",
					  text: "This will initiate downlink",
					  icon: "warning",
					  showCancelButton: true,
				  }).then(async (willDelete) => {
					  if (willDelete.isConfirmed) {
				  setModal(true)
				  ;
			  
				  const data=await onModemConfigByGSMConsumer({config_info:JSON.stringify(obj)})
				  if(data.success===true){
					mytime.current=setTimeout(() => {
						if(Object.keys(CommandStatus).length===0)
											 {
												 setCommandStatus({SCR_STATUS_CMD_ADD:'Time Out'})
											 }
											 },5000)
				  }else{
					 
				  }
			  }
			  })
			}else if(CMD?.INSTANTANEOUS?.READ_METHOD===2){
				setcmd(CMD)
setRead1Msg(true)
			}else if(CMD?.INSTANTANEOUS?.READ_METHOD===1){
				setcmd(CMD)
				setRead2Msg(true)
			}
			
		}
	}
	useInterval(
	  () => {
		  if (time <= 1) {
			  setRun(false);
			  setModal(false)
			
		  }
		  setTime((preState) => preState - 1);
	  },
	  time && run ? 1000 : null
	);
	
	function zeroPad(num) {
	  var str = String(num);
	  if (str.length < 2) {
		  return "0" + str;
	  }
	
	  return str;
	}
	
	const CloseModel=()=>{
		setCommandmsgJson({})
    setCommandStatus({})
	  setFlagMsg(true)
	  setRun(false);
	  setTime(0)
	  setModal(false)
	//   swal.fire('', 'Command Add In Buffer', 'success');

	}
	

	const onSubmitCommand=()=>{
        var obj
        if(cmd?.INSTANTANEOUS?.READ_METHOD===1){
setRead2Msg(false)

            obj=  {MMD_ID_CMD:124,SCR_ID_CMD_TYPE:'INSTANTANEOUS',"MMD_ID_OBIS_CODE": cmd?.INSTANTANEOUS?.OBIS,"MMD_ID_OBIS_OBJTYPE": cmd?.INSTANTANEOUS?.OBJ_TYPE,UNIQUE_ID:props?.GSMdata?.UNIQUE_ID,IMEI:props?.GSMdata?.MMD_ID_IMEI,SCRIPTDECODE_ID:props?.GSMdata?.SCRIPTDECODE_ID,MMMD_ID_MSGSGID:new Date().getTime()+1,MMD_ID_START_INDEX:parseInt(Index),MMD_CNT_INDEX:1,MMD_ID_OBIS_READ_METHOD: cmd?.INSTANTANEOUS?.READ_METHOD,GSM_BUFFER_TABLE:props?.GSMdata?.UNIQUE_TABLE_NAME?.GSM_BUFFER}
        }else{
setRead1Msg(false)

            obj=  {MMD_ID_CMD:124,SCR_ID_CMD_TYPE:'INSTANTANEOUS',"MMD_ID_OBIS_CODE": cmd?.INSTANTANEOUS?.OBIS,"MMD_ID_OBIS_OBJTYPE": cmd?.INSTANTANEOUS?.OBJ_TYPE,UNIQUE_ID:props?.GSMdata?.UNIQUE_ID,MMD_ID_IMEI:props?.GSMdata?.IMEI,SCRIPTDECODE_ID:props?.GSMdata?.SCRIPTDECODE_ID,MMD_ID_MSG:new Date().getTime()+1,MMD_TS_START:new Date(dates?.start).getTime(),MMD_TS_END:new Date(dates?.end).getTime(),MMD_ID_OBIS_READ_METHOD: cmd?.INSTANTANEOUS?.READ_METHOD,GSM_BUFFER_TABLE:props?.GSMdata?.UNIQUE_TABLE_NAME?.GSM_BUFFER}
        }
				  setFlagMsg(false)
				  setmodelTitle(obj.SCR_ID_CMD_TYPE)
				//   alert(JSON.stringify(obj))
                  setCommandJson(obj)

				  swal.fire({
					  title: "Are you sure?",
					  text: "This will initiate downlink",
					  icon: "warning",
					  showCancelButton: true,
				  }).then(async (willDelete) => {
					  if (willDelete.isConfirmed) {
				  setModal(true)
				
			  
				  const data=await onModemConfigByGSMConsumer({config_info:JSON.stringify(obj)})
				  if(data.success===true){
					mytime.current=setTimeout(() => {
						if(Object.keys(CommandStatus).length===0)
											 {
												 setCommandStatus({SCR_STATUS_CMD_ADD:'Time Out'})
											 }
											 },5000)
				  }else{
					 
				  }
                }
                })
    }
	const onChange = (date, dateString) => {
		setDates({...dates,start:dateString})
		  };
		  const onChanges = (date, dateString) => {
		setDates({...dates,end:dateString})
		  };
	const handleCancel = () => {
		setIsModalVisible(false);
	};
	useEffect(() => {
        ongetScriptCodeIdWiseColumnName()
		ongetHeardbeatData();
		ongetConfigretionCMD_TYPEWisePendding_Count_buffer()
	}, []);

	useEffect(() => {
		ongetHeardbeatData();
	}, [currentPage, PageSize]);

	const onAllShowData = (item) => {
		setIsModalVisible(true);
		setAllData(item);
	};
	const onRowDate = (row) => {
		setRowData(row);
		setIsModalRow(true);
	};
	const onDuplicate = (dupli) => {
		setDupData(dupli);
		setIsModalDup(true);
	};
	const onError = (err) => {
		setErrorData(err);
		setIsModalError(true);
	};

	

	const [BufferPageSize, setBufferPageSize] = useState(50)
	const [BuffernumberofPage, setBuffernumberofPage] = useState(0)
	const [BuffercurrentPage, setBufferCurrentPage] = useState(1);
    const [BufferPenddingData,setBufferPenddingData]=useState([])
    const [modalBufferDetails,setmodalBufferDetails]=useState(false)

	const onBufferPenddingData=async(col)=>{
		setmodelTitle(col)
	
		setmodalBufferDetails(true)
		const firstPageIndex = (BuffercurrentPage - 1) * BuffercurrentPage;
	
		const data=await getConfigretionCMD_TYPEWisePendding_Data_buffer({CONSUMER_ID:props.GSMdata.UNIQUE_ID,CMD_TYPE:col,start:firstPageIndex,end:BufferPageSize,tableName:props?.GSMdata?.UNIQUE_TABLE_NAME?.GSM_BUFFER})
		if(data.success===true){
	setBuffernumberofPage(data.data.Total)
	setBufferPenddingData(data.data.data)
		}else{
		console.log('Something Went Wrong')
	
		}
	}
	
	useEffect(() => {
		if(BufferPenddingData.length>0){
			onBufferPenddingData()
		}
	 }, [BuffercurrentPage, BufferPageSize])

	 const [ismodel, setModel] = useState(false)
     const [reportType, setReportType] = useState('')
     const [report, setReport] = useState({ value: '', name: '',start_date:'',end_date:'' })
     const csvLink = useRef();
     const [CsvData,setCsvData]=useState([])
	 const [ExpireDate,setExpireDate]=useState({})

	 const ongetStartAndEndDateByTable = async (tableName, col) => {
		 console.log("Wanted to check if this is calling or not")
		 document.body.style.cursor = 'Went'
		 const data = await getStartAndEndDateByTable({ tableName: props?.GSMdata?.UNIQUE_TABLE_NAME?.INSTANTANEOUS, col: 'Clock$0_0_1_0_0_255' })
		 if (data.success === true) {
 
			 console.log("Expiry Dates == ", data.data)
			 setExpireDate(data.data)
			 setModel(true)
			 document.body.style.cursor = 'default'
		 } else {
			 console.log("something Went wrong")
			 document.body.style.cursor = 'default'
		 }
	 }
     const getreportCreacteInTableName=async(Iscsv)=>{
         if(report?.value!=''&&reportType!=''){
			if(report?.value!='Full_Table'){
				if(report?.start_date&&report?.end_date){
					const x = new Date(moment(report.start_date,'DD-MM-YYYY').format('YYYY-MM-DD'));
		const y = new Date(moment(report.end_date,'DD-MM-YYYY').format('YYYY-MM-DD'));
		console.log(x>y,x,y);
					if(x>y){
					return	toast.warn('"Start Date" Must be Less than or Equal to "End Date"')
					}
				}else{
					return	toast.warn('Please Select "Start Date" And "End Date"')
				}
			}
			setReportLoader(true)
             const data=await reportCreacteInTableName({tableName:props?.GSMdata?.UNIQUE_TABLE_NAME?.INSTANTANEOUS,start_date:moment(report?.start_date,'DD-MM-YYYY').format('YYYY-MM-DD'),end_date:moment(report?.end_date,'DD-MM-YYYY').format('YYYY-MM-DD'),col:'Clock$0_0_1_0_0_255',IsCondition:report?.value=='Full_Table'?false:true})
             if(data.success==true){
                 var arrayofData=[]
                 var headers = {}
				 if(data.data.data.length==0){
					setReportLoader(false)
					toast.warn('Data Not Found')
						}
						const Download_data=data.data.data
            if(report?.value=='view_table_info'){
                
 
            data.data.data.map((item,id)=>{
                    arrayofData[id]={}
                     ColumnName?.INSTANTANEOUS?.length>0&&ColumnName?.INSTANTANEOUS
                        ?.map((col)=>{
                        if(col?.Display==true&&Download_data[0]&&Download_data[0][col?.Column]!==undefined){
                            headers[col.Column]=col.Header
                            if(col?.DataType=='DATETIME')
                            {
                                arrayofData[id][col.Header]=item[col.Column]?moment.utc(item[col.Column]).format("DD-MM-YYYY hh:mm:ss A"):''
                            }else{
                                if(col?.Float==true){
                                    if(col?.Division==true){
                                        if(col?.MF==true&&props?.GSMdata?.MF)	{
                                            arrayofData[id][col.Header]=(item[col.Column]*props?.GSMdata?.MF/col?.DivisionNumber).toFixed(col?.FloatPoint)
                                        }else{
                                            arrayofData[id][col.Header]=(item[col.Column]/col?.DivisionNumber).toFixed(col?.FloatPoint)
                                        }
                                    }else{
                                        if(col?.MF==true&&props?.GSMdata?.MF){
                                            arrayofData[id][col.Header]=(item[col.Column]*props?.GSMdata?.MF).toFixed(col?.FloatPoint)
                                        }else{
                                            arrayofData[id][col.Header]=(arrayofData[id][col.Header]=item[col.Column]).toFixed(col?.FloatPoint)
                                        }
                                    }
                                
                                }else{
                                    if(col?.MF==true&&props?.GSMdata?.MF){
											if(col?.Division==true){
												arrayofData[id][col.Header]=(item[col.Column]*props?.GSMdata?.MF)/col?.DivisionNumber
											}else{
                                            arrayofData[id][col.Header]=item[col.Column]*props?.GSMdata?.MF
											}
                                        }else{
                                        if(col?.Division==true){
                                            if(col?.MF==true&&props?.GSMdata?.MF){
                                                arrayofData[id][col.Header]=(item[col.Column]*props?.GSMdata?.MF)/col?.DivisionNumber
                                            }else{
                                                arrayofData[id][col.Header]=item[col.Column]/col?.DivisionNumber
                                            }
                                        }else{
                                            arrayofData[id][col.Header]=arrayofData[id][col.Header]=item[col.Column]
                                        }
                                    }
                                }
                            }
                        }
                        
                    })
                    if(id==(data.data.data.length-1)){
                    console.log(id);
 
                        if(reportType==='csv'){
                            exportCSVFile(headers,arrayofData,`INSTANTANEOUS_${props?.GSMdata?.UNIQUE_ID}`)
		setReportLoader(false)

                        //  setCsvData(data.data.data)
                        //  csvLink.current.link.click();
                         }else{
                            exportExcelDownload(arrayofData,`INSTANTANEOUS_${props?.GSMdata?.UNIQUE_ID}`)
		setReportLoader(false)

                         }
                    }
                })
            }else if(report?.value=='All_table_info'||report?.value=='Full_Table'){
                data.data.data.map((item,id)=>{
                    arrayofData[id]={}
                     ColumnName?.INSTANTANEOUS?.length>0&&ColumnName?.INSTANTANEOUS
                        ?.map((col)=>{
							if(Download_data[0]&&Download_data[0][col?.Column]!==undefined){
                            headers[col.Column]=col.Header
                                headers[col.Column]=col.Header
                                if(col?.DataType=='DATETIME')
                                {
                                    arrayofData[id][col.Header]=item[col.Column]?moment.utc(item[col.Column]).format("DD-MM-YYYY hh:mm:ss A"):''
                                }else{
                                    if(col?.Float==true){
                                        if(col?.Division==true){
                                            if(col?.MF==true&&props?.GSMdata?.MF)	{
                                                arrayofData[id][col.Header]=(item[col.Column]*props?.GSMdata?.MF/col?.DivisionNumber).toFixed(col?.FloatPoint)
                                            }else{
                                                arrayofData[id][col.Header]=(item[col.Column]/col?.DivisionNumber).toFixed(col?.FloatPoint)
                                            }
                                        }else{
                                            if(col?.MF==true&&props?.GSMdata?.MF){
                                                arrayofData[id][col.Header]=(item[col.Column]*props?.GSMdata?.MF).toFixed(col?.FloatPoint)
                                            }else{
                                                arrayofData[id][col.Header]=(arrayofData[id][col.Header]=item[col.Column]).toFixed(col?.FloatPoint)
                                            }
                                        }
                                    
                                    }else{
                                        if(col?.MF==true&&props?.GSMdata?.MF){
											if(col?.Division==true){
												arrayofData[id][col.Header]=(item[col.Column]*props?.GSMdata?.MF)/col?.DivisionNumber
											}else{
                                            arrayofData[id][col.Header]=item[col.Column]*props?.GSMdata?.MF
											}
                                        }else{
                                            if(col?.Division==true){
                                                if(col?.MF==true&&props?.GSMdata?.MF){
                                                    arrayofData[id][col.Header]=(item[col.Column]*props?.GSMdata?.MF)/col?.DivisionNumber
                                                }else{
                                                    arrayofData[id][col.Header]=item[col.Column]/col?.DivisionNumber
                                                }
                                            }else{
                                                arrayofData[id][col.Header]=arrayofData[id][col.Header]=item[col.Column]
                                            }
                                        }
                                    }
                                }
							}
                    })
 
                    if(id==(data.data.data.length-1)){
                        if(reportType==='csv'){
                            exportCSVFile(headers,arrayofData,`INSTANTANEOUS_${props?.GSMdata?.UNIQUE_ID}`)
		setReportLoader(false)

                        //  setCsvData(data.data.data)
                        //  csvLink.current.link.click();
                         }else{
                            exportExcelDownload(arrayofData,`INSTANTANEOUS_${props?.GSMdata?.UNIQUE_ID}`)
		setReportLoader(false)

                         }
                    }
                })
            }
            
            
         }else{
             console.log('Something Went Wrong')
		setReportLoader(false)

         }
        }else{
			return	toast.warn('Please Enter Mandatory Fields')
		}
     }
 
    
     const handleCancelReport = () => {
        setModel(false);
    };
 
    const onHandalReport = (e,name) => {
        setReport({ value: e.target.value, name: name })
    }

	const onSort = (field) => {
        if (sortField === field) {
            setsortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
        } else {
            setsortField(field);
            setsortDirection('asc');
        }
    };
	return (
		<>
		<Modal title="Generate Reports" visible={ismodel} footer='' onCancel={handleCancelReport} width={1500}>
				<section className="section">
					<div className="section-body">
						<div className="row">
							<div className="col-12">
									<div className="form-row" style={{ marginLeft: "10px" }}>
										<div className="form-group col-md-3" id="grpeui">
											<label for="inputPassword4 ">Report Type :<span className='text-danger'>*</span> </label>
											<select className="form-control form-select" name="reporttype" required="required" onChange={(e)=>onHandalReport(e)}>
												<option value="">Select Report Type</option>
												{ReportJson?.REPORT_TYPE.map((item)=>(<option value={item?.TYPE}>{item?.NAME}</option>))}
											</select>
										</div>
										{report?.value!=''&&report?.value!='Full_Table'?<><div className="form-group col-md-3" id="strdate">
											<label for="inputPassword4">Start Date :<span className='text-danger'>*</span> </label><br></br>
											<DatePicker onChange={(e, dateString) => setReport({ ...report, start_date: dateString })} format='DD-MM-YYYY' name='start' style={{ width: '100%', height: '40px' }}
												disabledDate={(current) => {
													return (moment.utc(ExpireDate.start_date).add(-moment().utcOffset(), "m").add(-1, 'days') >= current) || (moment(ExpireDate.end_date).add(-moment().utcOffset(), "m").add(1, 'days') <= current);
												}} />
										</div>
											<div className="form-group col-md-3" id="enddate" >
												<label for="inputPassword4">End Date :<span className='text-danger'>*</span> </label><br></br>
												<DatePicker onChange={(e, dateString) => setReport({ ...report, end_date: dateString })} format='DD-MM-YYYY' name='start' style={{ width: '100%', height: '40px' }}
													disabledDate={(current) => {
														return (moment.utc(ExpireDate.start_date).add(-moment().utcOffset(), "m").add(-1, 'days') >= current) || (moment(ExpireDate.end_date).add(-moment().utcOffset(), "m").add(1, 'days') <= current);
													}} />
											</div></>:''}
										<div className="form-group col-md-3" id="grpeui">
											<label for="inputPassword4">Extension :<span className='text-danger'>*</span> </label>
											<select className="form-control form-select" name="reportextension" onChange={(e) => (setReportType(e.target.value))} value={reportType}>
												<option value="">Select Extension</option>
												<option value="xls">XLS</option>
												<option value="csv">CSV</option>
											</select>
										</div>
									</div>
									<p style={{ marginLeft: "25px" }} className="text-danger">Note : * Indicates Mandatory Fields</p>
									<div className="text-left">
										<input type="submit" name="submit" className={`btn btn-danger mr-2 ${ReportLoader==true?'disabled btn-progress':''}`} value="Generate" id="submitvn"  onClick={()=>getreportCreacteInTableName()}/>
										<Link className="btn btn-light text-dark" onClick={handleCancelReport}>Cancel</Link>
									</div>
							</div>
						</div>
					</div>
				</section>
			</Modal>
		<Modal title={modelTitle+'('+(BuffernumberofPage?BuffernumberofPage:0)+')'}  visible={modalBufferDetails} className="my-modal-class" footer='' maskClosable={false} onCancel={() => (setmodalBufferDetails(false))} width={1500}>

<div className="table-responsive mt-2">
								<div id="table-1_wrapper" class="dataTables_wrapper no-footer">
									<div class="dataTables_length ml-2" id="table_id_length">
										<label>
											Show{" "}
											<select name="table_id_length " aria-controls="table_id" class="" onChange={(e) => (setBufferPageSize(e.target.value))} value={BufferPageSize}>
												<option value={10}>10</option>
												<option value={25}>25</option>
												<option value={50}>50</option>
												<option value={100}>100</option>
											</select>{" "}
											entries
										</label>
									</div>
									
									<table id="table_id" className="tableCustom table table-striped">
										<thead>
											<tr>
											
											{ ColumnName?.GSM_BUFFER?.length>0&&ColumnName?.GSM_BUFFER?.map((col)=>(col?.Display==true?<th className=""><BootstrapTooltip title={col?.Column}><span>{col?.Header}</span></BootstrapTooltip></th>:''))}
											</tr>
										</thead>
										<tbody>
											{BufferPenddingData.length > 0 && BufferPenddingData.map((item, id) => (
												<tr className="trheigth" >
												<td>{item?.SRNO}</td>
												{ ColumnName?.GSM_BUFFER?.length>0&&ColumnName?.GSM_BUFFER?.map((col)=>(col?.Display==false?'':col?.DataType=='DATETIME'?<td style={{ minWidth: "100px" }}>{item[col.Column]?moment.utc(item[col.Column]).format("DD-MM-YYYY hh:mm:ss A"):''}</td>:col.Column=='SCR_STATUS_CMD'?<td>{item?.SCR_STATUS_CMD==1? <div class="badge badge-success badge-shadow">Success</div>:item?.SCR_CNT_CMD_RETRY<0&&item?.SCR_STATUS_CMD==0?<div class="badge badge-warning badge-shadow">Waiting For Downlink</div>:item?.SCR_STATUS_CMD==2?<div class="badge badge-info badge-shadow">Canceled By User</div>:item?.SCR_STATUS_CMD==-1?<div class="badge badge-danger badge-shadow">Timeout</div>:item?.SCR_CNT_CMD_RETRY>=0&&item?.SCR_STATUS_CMD==0?<div class="badge badge-primary text-white badge-shadow">Waiting For Uplink</div>:''}</td>:col.Column=='SCR_ID_CMD_ADDED_BY'?<td>{item?.SCR_ID_CMD_ADDED_BY===0?<div class="badge badge-warning badge-shadow">Script</div>:<div class="badge badge-success badge-shadow">User</div>}</td>:<td>{item[col.Column]}</td>))}
												</tr>
											))}
										</tbody>
									</table>
								</div>
							</div>
							<><div className='d-flex justify-content-between'>
								<div>Total Number Of Row {BuffernumberofPage||0}</div><Pagination
									className="pagination-bar"
									currentPage={BuffercurrentPage}
									totalCount={BuffernumberofPage || 0}
									pageSize={BufferPageSize}
									onPageChange={(page) => setBufferCurrentPage(page)}
								/></div></>
</Modal>
		  <Modal  visible={modal} footer='' maskClosable={false} onCancel={() => (CloseModel())} width={500}>
    <h5 class="modal-title text-center" id="meter_data_mqtt_info_txt">Getting {modelTitle} Command</h5>
	<h5 class="modal-title text-center mt-2 " id="meter_data_mqtt_info_txt">{!CommandStatus?.SCR_STATUS_CMD_ADD&&CommandStatus?.SCR_STATUS_CMD_ADD!='Added In Buffer'?<img className='mr-2' src={clockPending} height='20px' width={'20px'}></img>:CommandStatus?.SCR_STATUS_CMD_ADD!='Added In Buffer'?<span><i className='fas fa-times mr-2' style={{color:'red'}}></i></span>:<span><i className='fas fa-check mr-2' style={{color:'green'}}></i></span>}{!CommandStatus?.SCR_STATUS_CMD_ADD?'Command Pending':CommandStatus?.SCR_STATUS_CMD_ADD}</h5>
    <div class=""> 
   { CommandStatus?.SCR_STATUS_CMD_ADD=='Added In Buffer'? <div class="text-center" style={{marginTop:"10px",fontSize: "20px"}}>
                     <label style={{fontWeight:"400"}}>Waiting For Respose</label>
                     <div id="meter_data_mqtt_timer_status" style={{fontSize: "18px"}}>{`${zeroPad(time)}`} seconds left</div>
                  </div>:''}
               </div>
               <div class="text-center" style={{marginTop:"20px"}}>
                  <input type="button" class="btn btn-danger " value="Close" id="meter_data_mqtt_cancel_button" name="meter_data_mqtt_cancel_button" onClick={CloseModel}/>
               </div>
            </Modal>
            <Modal  visible={modalMsg} footer='' maskClosable={false} onCancel={() => (setmodalMsg(false))} width={500}>
			{MqttMsg}
            </Modal>
			<Modal title="Row Data" visible={Read1Msg} footer="" onCancel={() => setRead1Msg(false)} width={1000}>
			<div className="d-flex">
			<Space direction="horizontal">
		<div className="d-flex flex-column">Start In
    <DatePicker onChange={onChange} format='DD-MM-YYYY HH:mm:ss' name='start'  /></div>
	<div className="d-flex flex-column" >End In
    <DatePicker onChange={onChanges} name='end' format='DD-MM-YYYY HH:mm:ss' /></div>
	</Space>
	<a className={`btn btn-danger text-white ml-3 mt-3 `} onClick={onSubmitCommand}>Submit</a>
	</div>
			
			</Modal>
			<Modal title="Row Data" visible={Read2Msg} footer="" onCancel={() => setRead2Msg(false)} width={1000}>
			<div class="form-row">
					<div class="form-group col-md-6">
						<label for="inputState">Index:</label>
						<input type="text" class="form-control" id="" name="FirstName" placeholder="Enter Index" onChange={(e)=>setIndex(e.target.value)} value={Index}/>
					</div>
					</div>
	<a className={`btn btn-danger text-white ml-3 `} onClick={onSubmitCommand}>Submit</a>

			</Modal>
			<Modal title="Error Data" visible={isModalError} footer="" onCancel={() => setIsModalError(false)} width={1000}>
				{ErrorData}
			</Modal>
			<Modal title="Duplicate Data" visible={isModalDup} footer="" onCancel={() => setIsModalDup(false)} width={1000}>
				{DupData}
			</Modal>
			<Modal title="Row Data" visible={isModalrow} footer="" onCancel={() => setIsModalRow(false)} width={1000}>
				{rowData}
			</Modal>
			<Modal title="All Data" visible={isModalVisible} onOk={() => setIsModalVisible(false)} footer="" onCancel={handleCancel} width={1000}>
				<div class="table-responsive">
					<table class="table table-striped" id="tabledata" style={{ width: "100%" }}>
						<thead>
							<tr>
								<th style={{ width: "50%" }}>Data</th>
								<th style={{ width: "50%" }}>Value</th>
							</tr>
						</thead>
						<tbody>
						{ ColumnName?.INSTANTANEOUS?.map((col)=>(col?.DataType=='DATETIME'?<tr>
								<td style={{ width: "50%" }}>{col?.Column}</td>
								<td style={{ width: "50%" }}>{AllData[col?.Column]?moment.utc(AllData[col?.Column]).format("DD-MM-YYYY hh:mm:ss A"):''}</td>
							</tr>:<tr>
								<td style={{ width: "50%" }}>{col?.Column}</td>
								<td style={col?.Column=='UNITS'||col?.Column=='ERRORBIN'||col?.Column=='RAWDATA'?{ maxWidth: '500px'}:{ width: "50%" }}>{AllData[col?.Column]}</td>
							</tr>))}
							
						</tbody>
					</table>
				</div>
			</Modal>
			<section className="card-diff-section">
				<div className="section-body">
					<div className="row">
						<div className="col-12">
							<div className="card">
								<div className="card-header">
									<h4><BootstrapTooltip title={`Table Name is ${props?.GSMdata?.UNIQUE_TABLE_NAME?.INSTANTANEOUS}`} placement='top'><span><i className='fas fa-info-circle info_circle'></i></span></BootstrapTooltip></h4>
									<div className="card-header-action">
										<div id="filtergrm">
										<button type="button" id="mqtt_get_cmd_402" class="btn btn-warning mr-1" onClick={()=>(onBufferPenddingData('INSTANTANEOUS'))}>Queue({pendingBuffer})</button>
											<button type="button" id="mqtt_get_cmd_301" class="btn btn-info mr-1" onClick={ongetScriptCodeIdWiseConfigretionData}>
												Get Data
											</button>
											<a class="btn btn-info text-white mr-1" data-toggle="tooltip" data-placement="bottom" title="Refresh" onClick={()=>{ongetHeardbeatData()
											ongetConfigretionCMD_TYPEWisePendding_Count_buffer()}}>
												<i class="fa fa-refresh" aria-hidden="true"></i>
											</a>
											<button class="btn btn-info" onClick={()=>ongetStartAndEndDateByTable(true)}>
                      <i class="fa fa-download" aria-hidden="true"></i>
                      </button>
										</div>
									</div>
								</div>
								<div className="card-body">
									{loader && (
										<div className="example">
											<Spin size="large" />
										</div>
									)}
									{heartData.length === 0 && !loader ? StatusCode==503?<h4 className="text-center">Table Not Found</h4> :<h4 className="text-center">No Data Found</h4>:''}
									{heartData.length > 0 && (
										<div className="table-responsive tw-h-screen tw-max-h-screen">
											<div id="table-1_wrapper" class="dataTables_wrapper no-footer">
												
												<table id="table_id" className="table text-center border table-borderless font-10 table-striped">
													<thead className='tw-sticky tw-z-50 tw-top-0 tw-bg-gray-100'>
														<tr className="tw-border-b text-center">
														{ ColumnName?.INSTANTANEOUS?.length>0&&ColumnName?.INSTANTANEOUS?.map((col)=>(col?.Display==true?heartData[0]&&heartData[0][col?.Column]===undefined?'':<BootstrapTooltip title={col?.OBIS?<><span>OBIS: {col?.OBIS}</span><br/><span>Column: {col?.Column}</span></>:col?.Column}><th onClick={()=>onSort(col?.Column)}><div className='tw-flex tw-items-center tw-justify-center'>
                                        <div >{col?.Header}</div>
                                        <div>{sortField === col?.Column && (sortDirection === 'asc' ? ' ▲' : ' ▼')}</div>
                                    </div></th></BootstrapTooltip>:''))}
															{/* <th className="text-center ">SRNO</th>
															<th>IMEI</th>
															<th>VD</th>
															<th>MSGID</th>
															<th>CMD</th>
															<th>POTP</th>
															<th>COTP</th>
															<th>DRXTYPE</th>
															<th>OBIS</th>
															<th>OBJTYPE</th>
															<th>READ_METHOD</th>
															<th>DATA_INTERVAL</th>
															<th>MCOMST</th>
															<th>ASN</th>
															<th>MREADST3</th>
															<th>MREADST2</th>
															<th style={{ minWidth: "100px" }}>METERTS</th>
															<th>IR</th>
															<th>IY</th>
															<th>IB</th>
															<th>VRN</th>
															<th>VYN</th>
															<th>VBN</th>
															<th>PFR</th>
															<th>PFY</th>
															<th>PFB</th>
															<th>PFAVGBLP</th>
															<th>FRQ</th>
															<th>APOW</th>
															<th>POW</th>
															<th>RPOW</th>
															<th>KWHIMP</th>
															<th>KWHEXP</th>
															<th>KVAHIMP</th>
															<th>KVAHEXP</th>
															<th>PWR_OFF_CNT</th>
															<th>GPWR_OFF_DRU</th>
															<th>TC</th>
															<th>BLCNT</th>
															<th>CPROG_CNT</th>
															<th style={{ minWidth: "100px" }}>MTBLDT</th>
															<th>PWR_ON_DRU</th>
															<th>KVARH1</th>
															<th>KVARH2</th>
															<th>KVARH3</th>
															<th>KVARH4</th>
															<th style={{ minWidth: "100px" }}>ENTRYTS</th>
															<th>DUPLICATEBIN</th>
															<th>ERRORBIN</th>
															<th>DATASTATUS</th>
															<th>MISSEDCOUNT</th>
															<th>ERRORCOUNT</th>
															<th>DUPLICATECOUNT</th> */}
															<th>ACTIONS</th>
														</tr>
													</thead>
													<tbody>
														{heartData.length > 0 &&
															heartData.map((item, id) => (
																<tr className="trheigth">
                                                                { ColumnName?.INSTANTANEOUS?.length>0&&ColumnName?.INSTANTANEOUS?.map((col)=>(col?.Display==false?'':heartData[0]&&heartData[0][col?.Column]===undefined?'':col?.DataType=='DATETIME'?<td style={{ minWidth: "100px" }}>{item[col.Column]
																?moment.utc(item[col.Column]).format("DD-MM-YYYY hh:mm:ss A"):''}</td>:item[col.Column]==null?<td>{item[col.Column]}</td>:col?.UI_LOOKUP?<><BootstrapTooltip title={item[col?.Column]}><td>{UI_LOOKUP[col?.UI_LOOKUP]&&UI_LOOKUP[col?.UI_LOOKUP][item[col?.Column]]?.Name||item[col?.Column]}</td></BootstrapTooltip></>:
																col?.Float==true?col?.Division==true?
																col?.MF==true&&props?.GSMdata?.MF?
																<td style={col.Column=='UNITS'?{minWidth: '400px'}:
															col.Column=='ERRORBIN'?{ maxWidth: '400px'}:{}}>{(item[col.Column]*props?.GSMdata?.MF/col?.DivisionNumber).toFixed(col?.FloatPoint)}</td>:
															col?.MF==true&&props?.GSMdata?.MF?
															<td style={col.Column=='UNITS'?{minWidth: '400px'}:
															col.Column=='ERRORBIN'?{ maxWidth: '400px'}:{}}>{(item[col.Column]*props?.GSMdata?.MF).toFixed(col?.FloatPoint)}</td>:
															<td style={col.Column=='UNITS'?{minWidth: '400px'}:
															col.Column=='ERRORBIN'?{ maxWidth: '400px'}:{}}>{(item[col.Column]/col?.DivisionNumber).toFixed(col?.FloatPoint)}</td>:
															col?.MF==true&&props?.GSMdata?.MF?
																<td style={col.Column=='UNITS'?{minWidth: '400px'}:
															col.Column=='ERRORBIN'?{ maxWidth: '400px'}:{}}>{(item[col.Column]*props?.GSMdata?.MF).toFixed(col?.FloatPoint)}</td>:
															<td style={col.Column=='UNITS'?{minWidth: '400px'}:
															col.Column=='ERRORBIN'?{ maxWidth: '400px'}:{}}>{col?.FloatPoint!=null||col?.FloatPoint!=undefined?item[col.Column].toFixed(col?.FloatPoint):item[col.Column]}</td>:
															col?.Division==true?
															col?.MF==true&&props?.GSMdata?.MF?
															<td style={col.Column=='UNITS'?{minWidth: '400px'}:
															col.Column=='ERRORBIN'?{ maxWidth: '400px'}:{}}>{(item[col.Column]*props?.GSMdata?.MF)/col?.DivisionNumber}</td>:
															col?.MF==true&&props?.GSMdata?.MF?
															<td style={col.Column=='UNITS'?{minWidth: '400px'}:
															col.Column=='ERRORBIN'?{ maxWidth: '400px'}:{}}>{(item[col.Column]*props?.GSMdata?.MF)}</td>:
															<td style={col.Column=='UNITS'?{minWidth: '400px'}:
															col.Column=='ERRORBIN'?{ maxWidth: '400px'}:{}}>{item[col.Column]/col?.DivisionNumber}</td>:col?.MF==true&&props?.GSMdata?.MF?
															<td style={col.Column=='UNITS'?{minWidth: '400px'}:
															col.Column=='ERRORBIN'?{ maxWidth: '400px'}:{}}>{(item[col.Column]*props?.GSMdata?.MF)}</td>:
															<td style={col.Column=='UNITS'?{minWidth: '400px'}:col.Column=='ERRORBIN'?{ maxWidth: '400px'}:{}}>{item[col.Column]}</td>
															))}
																	{/* <td>{item?.SRNO}</td>
																	<td>{item?.IMEI}</td>
																	<td>{item?.VD}</td>
																	<td>{item?.MSGID}</td>
																	<td>{item?.CMD}</td>
																	<td>{item?.POTP}</td>
																	<td>{item?.COTP}</td>
																	<td>{item?.DRXTYPE}</td>
																	<td>{item?.OBIS}</td>
																	<td>{item?.OBJTYPE}</td>
																	<td>{item?.READ_METHOD}</td>
																	<td>{item?.DATA_INTERVAL}</td>
																	<td>{item?.MCOMST}</td>
																	<td>{item?.ASN}</td>
																	<td>{item?.MREADST3}</td>
																	<td>{item?.MREADST2}</td>
																	<td>{moment.utc(item?.METERTS).format("DD/MM/YYYY hh:mm:ss A")}</td>
																	<td>{item?.IR}</td>
																	<td>{item?.IY}</td>
																	<td>{item?.IB}</td>
																	<td>{item?.VRN}</td>
																	<td>{item?.VYN}</td>
																	<td>{item?.VBN}</td>
																	<td>{item?.PFR}</td>
																	<td>{item?.PFY}</td>
																	<td>{item?.PFB}</td>
																	<td>{item?.PFAVGBLP}</td>
																	<td>{item?.FRQ}</td>
																	<td>{item?.APOW}</td>
																	<td>{item?.POW}</td>
																	<td>{item?.RPOW}</td>
																	<td>{item?.KWHIMP}</td>
																	<td>{item?.KWHEXP}</td>
																	<td>{item?.KVAHIMP}</td>
																	<td>{item?.KVAHEXP}</td>
																	<td>{item?.PWR_OFF_CNT}</td>
																	<td>{item?.GPWR_OFF_DRU}</td>
																	<td>{item?.TC}</td>
																	<td>{item?.BLCNT}</td>
																	<td>{item?.CPROG_CNT}</td>
																	<td>{moment.utc(item?.MTBLDT).format("DD/MM/YYYY hh:mm:ss A")}</td>
																	<td>{item?.PWR_ON_DRU}</td>
																	<td>{item?.KVARH1}</td>
																	<td>{item?.KVARH2}</td>
																	<td>{item?.KVARH3}</td>
																	<td>{item?.KVARH4}</td>
																	<td>{moment.utc(item?.ENTRYTS).format("DD/MM/YYYY hh:mm:ss A")}</td>
																	<td>{item?.DUPLICATEBIN}</td>
																	<td>{item?.ERRORBIN}</td>
																	<td>{item?.DATASTATUS}</td>
																	<td>{item?.MISSEDCOUNT}</td>
																	<td>{item?.ERRORCOUNT}</td>
																	<td>{item?.DUPLICATECOUNT}</td> */}
																	<td style={{ minWidth: '100px' }}>
																		<a class="action-icon text-success" onClick={() => onAllShowData(item)}>
																			<span data-toggle="tooltip" data-placement="bottom" title="All Data">
																				<i class="fa fa-database fa-lg font-13" aria-hidden="true"></i>
																			</span>
																		</a>
																		&nbsp;
																		<a class="action-icon text-info" onClick={() => onRowDate(item?.SCR_INFO_RAWDATA)}>
																			<span data-toggle="tooltip" data-placement="bottom" title="Raw Data">
																				<i class="fa fa-info-circle fa-lg font-13" aria-hidden="true"></i>
																			</span>
																		</a>
																		&nbsp;
																		<a
																			class="action-icon text-danger"
																			onClick={() => onDuplicate(item?.SCR_INFO_DUPLICATEBIN)}
																		>
																			<span
																				data-toggle="tooltip"
																				data-placement="bottom"
																				title="Duplicate Data"
																			>
																				<i class="fa fa-clone fa-lg font-13" aria-hidden="true"></i>
																			</span>
																		</a>
																		&nbsp;
																		<a class="action-icon text-dark" onClick={() => onError(item?.SCR_INFO_ERRORBIN)}>
																			<span data-toggle="tooltip" data-placement="bottom" title="Error Data">
																				<i class="fa fa-exclamation-triangle fa-lg font-13" aria-hidden="true"></i>
																			</span>
																		</a>
																	</td>
																</tr>
															))}
													</tbody>
												</table>
												
											</div>
										</div>
									)}
<>
													<div className="d-flex justify-content-between">
														<div>Total Number Of Row {numberofPage}</div>
														<div class="dataTables_length" id="table_id_length">
													<label>
														Show
														<select
															name="table_id_length"
															aria-controls="table_id"
															class=""
															onChange={(e) => setPageSize(e.target.value)}
															value={PageSize}
														>
															<option value={10}>10</option>
															<option value={25}>25</option>
															<option value={50}>50</option>
															<option value={100}>100</option>
														</select>{" "}
														entries
													</label>
												</div>
														<Pagination
															className="pagination-bar"
															currentPage={currentPage}
															totalCount={numberofPage || 1}
															pageSize={PageSize}
															onPageChange={(page) => setCurrentPage(page)}
														/>
													</div>
												</>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</>
	);
}
