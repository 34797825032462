import React, { useEffect, useState } from "react";
import { Users } from "react-feather";
import { Link, useHistory } from "react-router-dom";
import { BootstrapTooltip, exportCSVFile, exportExcelDownload, socket } from "../../Commoncomponet/common";
import Pagination from "../../Commoncomponet/Pagination";
import { CheckModemStatus, DownloadModemConsumerDetailsByFilter, InsertModemDetails, ModemCredentialManage, UpdateModemDetails, UpdateModemTest_data, activeandinactive, getAllCredentialList, getAllModem, getCountUseToConsumerInIMEI, getModemConsumerDetails, getModemConsumerDetailsByFilter, getModemConsumerDetailsById, getModemSearchTextConsumerDetails } from "../../Commoncomponet/Utils";
import { Modal } from "antd";
import Select from 'react-select';
import MultiInput from "../../Commoncomponet/MultiInput/MultiInput";
import GoogleLoader from "../../Commoncomponet/Loaders/GoogleLoader";
import exports from '../../assets/img/export.png'
import classNames from "classnames";
import swal from "sweetalert2";
import { Slide, toast } from "react-toastify";
import clockPending from '../../assets/img/icons8-clock.gif'
import moment from "moment";
import { jsPDF } from "jspdf";
import chroma from "chroma-js";

window.Swal = swal;

export default function MobileDetails() {
	const userInfo = JSON.parse(window.localStorage.getItem("user_info"));

	const [PageSize, setPageSize] = useState(50)
	const [numberofPage, setnumberofPage] = useState(1)
	const [currentPage, setCurrentPage] = useState(1);
	const history = useHistory()
	const [ModLoader, setModLoader] = useState(false)
	const [isModalVisible, setIsModalVisible] = useState(false);
	const [Filter, setFilter] = useState({ ModemList: [], IMEI: [], DSRNO: [], PASRNO: [] })
	const [ModemData, setModemData] = useState([])
	const [filterLoader, setfilterLoader] = useState(false)
	const [searchTerms, setSearchTerms] = useState([]);
	const [modemDList, setmodemDList] = useState([])
	const [serachLoader, setSerachLoader] = useState(false)
	const [ModemFilter, setModemFilter] = useState('All')
	const [isSideExapnded, setIsSideExapnded] = useState(false)
	const [SideDetails, setSideDetails] = useState({})
	const [isModemDetailsModel, setisModemDetailsModel] = useState(false)
	const [flagBtn, setflegBtn] = useState(false);
	const [Validation, setValidation] = useState({});
	const [inputs, setInputs] = useState({ modemId: '', IMEI: '', DEVICE_SERIAL_NO: '', PANEL_SERIAL_NO: '', CREATED_BY: userInfo?.UNIQUE_ID, Consumer_assign: '', CREDENTIALS_ID: '' })
	const [CreateTableStatus, setCreateTableStatus] = useState({})
	const [responseDetail, setresponseDetail] = useState({})
	const [reqestDetail, setreqestDetail] = useState({})
	const [ModemCstatus, setModemCstatus] = useState({ credential_set: false, reboot: false })
	const [ModemCredential, setModemCredential] = useState([])
	const [ModemResult, setModemResult] = useState(false)
	const [isModemCredModel, setisModemCredModel] = useState(false)
	const [IsUpdate, setUpdate] = useState(false)
	const [ModemStatus, setModemStatus] = useState({})
	const [CopyModal, setCopyModal] = useState(false)
	const [CopyDetails, setCopyDetails] = useState({})
	const [DownloadData, setDownloadData] = useState({ PROD_NAME: '', SKU: '', PENAL_VER: '' })
	const [DownloadModal, setisDownloadModal] = useState(false)
	const [DownloadInfo, setDownloadInfo] = useState({})
	const [API_SEND_DATAModal, setAPI_SEND_DATAModal] = useState(false)
	const [ApiSendData, setApiSendData] = useState([])
	const [ModalJson, setModalJson] = useState({})
	const [ismodifid, setismodifid] = useState(false)
	const [activeTab, setActiveTab] = useState("Test Result");
	const btnColor = ['#4f5ece', '#f59e0b', '#fc544b', '#3abaf4', '#54ca68', '#191d21', '#7c5295', '#4f5ece']
	const [isLoadSubmit, setisLoadSubmit] = useState(false)


	const ongetModemConsumerDetails = async (ModemFilter) => {
		setModLoader(true)

		const firstPageIndex = (currentPage - 1) * PageSize;
		const data = await getModemConsumerDetails({ start: firstPageIndex, end: PageSize, ModemFilter: ModemFilter })
		if (data?.success === true) {
			setmodemDList(data.data.data)
			setnumberofPage(data.data.Row_Count)
			setModLoader(false)

		} else {
			setModLoader(false)

			console.log('something wait wrong')
		}
	}
	const ongetAllModem = async () => {
		const data6 = await getAllModem(true);
		if (data6.success === true) {
			const options = []
			data6?.data?.data?.map((item) => (options.push({ value: item?.MODEM_UNIQUE_ID, label: item?.MODEM_PROFILE_NAME, TEST_RESULT_UI_LOOKUP: item?.TEST_RESULT_UI_LOOKUP })))
			setModemData(options);
		} else {
			console.log("somthing Went wrong");
		}
	}
	const ongetCredentialList = async () => {
		const data6 = await getAllCredentialList({});
		if (data6.success === true) {
			setModemCredential(data6?.data?.data?.filter((item) => (item?.status == 1)))
		} else {
			console.log("somthing Went wrong");
		}
	}
	useEffect(() => {
		ongetModemConsumerDetails(ModemFilter)
		ongetAllModem()
		ongetCredentialList()
		socket.on('mqttModemCredential', (massges) => {
			console.log(massges, reqestDetail)
			setresponseDetail(massges)

		})
		socket.on('mqttModemStatus', (massges) => {
			console.log(massges)

			setModemStatus(massges)
		})
	}, [])

	useEffect(() => {
		if ((responseDetail?.MMD_ID_CMD == 16 || responseDetail?.MMD_ID_CMD == 14) && responseDetail?.MMD_ID_MSG == reqestDetail?.MMD_ID_MSG) {
			SentReboot()
			setModemCstatus({ credential_set: true, reboot: false })
		} else if (responseDetail?.MMD_ID_CMD == 11 && responseDetail?.MMD_ID_MSG == reqestDetail?.MMD_ID_MSG) {
			handaleModemDetails(true)
			setModemCstatus({ credential_set: true, reboot: true })
		}
	}, [responseDetail])

	const SentReboot = async () => {
		const data = await ModemCredentialManage({ CREDENTIALS_ID: inputs?.CREDENTIALS_ID, credential_type: 'reboot', IMEI: inputs?.IMEI })
		if (data.success == true) {
			setreqestDetail(JSON.parse(data.data.data))
			setModemResult(true)
			setflegBtn(false)

		} else {
			toast.error('Something Went Wrong')
			setflegBtn(false)
		}
	}

	useEffect(() => {
		ongetModemConsumerDetails(ModemFilter)
	}, [currentPage, PageSize])

	const UpdateRolesUser = async (item, is_list_update) => {
		// history.push({ pathname: `/AddModemDetails`, state: item })
		onGetModemStatus(item?.C4_IMEI)
		const data = await getModemConsumerDetailsById(item.C2_UNIQUE_ID)
		if (data.success == true) {
			setIsSideExapnded(true)
			const modem_details = data.data.data[0]
			setSideDetails(modem_details)
			if (is_list_update) {
				setmodemDList(modemDList.map(info => info?.C2_UNIQUE_ID == SideDetails?.UNIQUE_ID ? { ...info, C4_IMEI: modem_details?.IMEI, C5_DEVICE_SERIAL_NO: modem_details?.DEVICE_SERIAL_NO, C3_MODEM_TYPE: modem_details?.MODEM_TYPE, C6_PANEL_SERIAL_NO: modem_details?.PANEL_SERIAL_NO, STATUS: modem_details?.STATUS, credential_name: modem_details?.credential_name } : info))
			}
		} else {
			toast.error('Data fetch Error')
		}
	}


	const ongetModemConsumerDetailsByFilter = async () => {
		setfilterLoader(true)
		const firstPageIndex = (currentPage - 1) * PageSize;
		const data = await getModemConsumerDetailsByFilter({ start: firstPageIndex, end: PageSize, Filter_Obj: Filter })
		if (data?.success === true) {
			setmodemDList(data.data.data)
			setfilterLoader(false)
			setIsModalVisible(false)
			setnumberofPage(data.data.Row_Count)
		} else {
			setfilterLoader(false)
			console.log('something Went wrong')
		}
	}

	useEffect(() => {
		ongetModemConsumerDetails(ModemFilter)
	}, [ModemFilter])
	const onDownloadModemConsumerDetailsByFilter = async (type, individual_filter) => {
		const data = await DownloadModemConsumerDetailsByFilter({ Filter_Obj: type == 'individual' ? individual_filter : Filter })
		if (data?.success === true) {

			const excel_data = data.data.data.map((item) => {
				if (item?.TEST_RESULT == 1) {
					let obje_data = Object.entries({...JSON.parse(ModemData.filter((moditem) => (moditem.value === item?.MODEM_TYPE))[0]?.TEST_RESULT_UI_LOOKUP || '{}')})
					console.log(obje_data)
					var final_obj = { 'SRNO': item?.SRNO }
					// 'Manufacturing Number':JSON.parse(item?.TEST_DATA||'{}')?.item?.MMD_ID_MFGNO,  'Wi-Fi MAC Address':item?.WIFIMAC?.match(/.{2}/g)?.join(':'), 'IMEI Number':item?.IMEI, 'Status, Process, Connectivity, Power LED Works?':'', 'All Phase Input Supply Check?':'', 'GSM Red, Green and Blue LED working ?':'','GSM Signal Strength Value with Antenna At Manufacturing Dept. Test Table (Value : 25 to 31) (in Display)':JSON.parse(item?.TEST_DATA||'{}')?.MMD_INFO_GSM_RSSI,'GSM Signal Strength Value without Antenna At Manufacturing Dept.  Test Table (Value : >13)(in Display)':'','Heartbeat Received on Portal?':'',"EEPROM Works? (In Portal Heartbeat Data)":JSON.parse(item?.TEST_DATA||'{}')?.MMD_ALERT_EEPROM_COM,"DLMS Meter Communicate Using RS232?":JSON.parse(item?.TEST_DATA||'{}')?.MMD_MCOM_STATUS,"SPI Flash Working? (In Portal Heartbeat Data)":JSON.parse(item?.TEST_DATA||'{}')?.MMD_SPI_FLASH_SECTOR_SIZE,"WiFi AP Works?":'',"MCP IC Test?":JSON.parse(item?.TEST_DATA||'{}')?.MCP_TEST,"Power Backup Module Working Check?":''
					obje_data.map(([key, value]) => {
						if (value.type == 'null') {
							final_obj[value.dname] = ''
						} else if (value.type == 'datetime') {
							final_obj[value.dname] = JSON.parse(item?.TEST_DATA || '{}')[key] ? moment(JSON.parse(item?.TEST_DATA || '{}')[key], 'YYYY-MM-DD HH:mm:ss').format('DD-MM-YYYY hh:mm:ss A') : ''
						} else if (value?.type == 'const') {
							final_obj[value.dname] = value?.value
						} else {
							console.log(value?.UI_LOOKUP, JSON.parse(item?.TEST_DATA || '{}'), key)
							final_obj[value.dname] = value?.UI_LOOKUP ? value?.UI_LOOKUP[JSON.parse(item?.TEST_DATA || '{}')[key]] && value?.UI_LOOKUP[JSON.parse(item?.TEST_DATA || '{}')[key]]["name"] || JSON.parse(item?.TEST_DATA || '{}')[key] : JSON.parse(item?.TEST_DATA || '{}')[key]
						}
					})
					return {...final_obj,TESTED_AT:moment.utc(item?.TESTED_AT).format('DD-MM-YYYY hh:mm:ss A'),TEST_RESULT:item?.TEST_RESULT}
				}
			})
			console.log(excel_data)
			if (excel_data.filter(item => item != undefined).length > 0) {
				exportExcelDownload(excel_data.filter(item => item != undefined), `Modem_Details`)
			} else {
				toast.error('Modem Test Data not found')
			}

		} else {
			console.log('something Went wrong')
		}
	}

	const onHandalSearchMulti = (newSearchTerms) => {
		setSearchTerms(newSearchTerms);

	};

	const onItemCleared = (clearedIndex) => {
		// Create a new array without the cleared item
		const newSearchTerms = searchTerms.filter((_, index) => index !== clearedIndex);
		// Update the search results based on the new search terms
		onHandalSearchMulti(newSearchTerms);
	};
	const onClearAllItems = () => {
		// Reset the search results to the original data
		onHandalSearchMulti([]);

	};

	const onHandalSearchMultiFilter = (newSearchTerms, key) => {
		setFilter({ ...Filter, [key]: newSearchTerms });

	};

	const onItemClearedFilter = (clearedIndex, key) => {
		// Create a new array without the cleared item
		const newSearchTerms = Filter[key].filter((_, index) => index !== clearedIndex);
		// Update the search results based on the new search terms
		onHandalSearchMultiFilter(newSearchTerms, key);
	};
	const onClearAllItemsFilter = (key) => {
		// Reset the search results to the original data
		onHandalSearchMultiFilter([], key);

	};

	const onHandalSerch = async () => {
		console.log("OnMultiSearch = ", searchTerms)
		setSerachLoader(true)
		if (searchTerms.length > 0) {
			const firstPageIndex = (currentPage - 1) * PageSize;

			const data = await getModemSearchTextConsumerDetails({ text: searchTerms, start: firstPageIndex, end: PageSize, Filter_Obj: Filter })
			if (data?.success === true) {
				setmodemDList(data.data.data)
				setSerachLoader(false)

				setnumberofPage(data.data.Row_Count)
			} else {
				setSerachLoader(false)

				console.log('something Went wrong')
			}
		} else {
			ongetModemConsumerDetails(ModemFilter)
			setSerachLoader(false)
		}
	}

	const onInActive = async () => {
		swal.fire({
			title: "Are You Sure InActive Modem?",
			icon: "warning",
			showCancelButton: true,
		}).then(async (willDelete) => {
			if (willDelete.isConfirmed) {
				const dataCount = await getCountUseToConsumerInIMEI(SideDetails?.IMEI)
				if (dataCount.success) {
					if (dataCount.data.data == 0) {
						const data = await activeandinactive({ tableName: 'group_modem_details_index', colName: 'STATUS', value: '0', matchId: 'UNIQUE_ID' }, SideDetails?.UNIQUE_ID)
						if (data.success === true) {
							toast.success('Success Inactive Modem')
							UpdateRolesUser({ C4_IMEI: SideDetails?.IMEI, C2_UNIQUE_ID: SideDetails?.UNIQUE_ID }, true)

						} else {
							toast.error('something Went wrong')
						}
					} else {
						swal.fire({
							title: `${dataCount.data.data} Consumer Assign This Modem`,
							icon: "error",
						})
					}
				} else {

				}
			}
		})
	}
	const onActive = async () => {
		swal.fire({
			title: "Are You Sure Modem?",
			// text: "Once deleted, you will not be able to recover this User Details!",
			icon: "warning",
			showCancelButton: true,
		}).then(async (willDelete) => {
			if (willDelete.isConfirmed) {
				const data = await activeandinactive({ tableName: 'group_modem_details_index', colName: 'STATUS', value: '1', matchId: 'UNIQUE_ID' }, SideDetails?.UNIQUE_ID)
				if (data.success === true) {
					toast.success('Success Active Modem')
					UpdateRolesUser({ C4_IMEI: SideDetails?.IMEI, C2_UNIQUE_ID: SideDetails?.UNIQUE_ID }, true)
				} else {
					toast.error('something Went wrong')
				}
			}
		})
	}

	const onUpdateModemDetils = async () => {
		setisModemDetailsModel(true)
		setUpdate(true)
		setInputs({ modemId: SideDetails?.MODEM_TYPE, IMEI: SideDetails?.IMEI, DEVICE_SERIAL_NO: SideDetails?.DEVICE_SERIAL_NO, PANEL_SERIAL_NO: SideDetails?.PANEL_SERIAL_NO, CREATED_BY: userInfo?.UNIQUE_ID, Consumer_assign: SideDetails?.CONSUMER_ASSIGN, CREDENTIALS_ID: SideDetails?.CREDENTIALS_ID })
	}
	const onUpdateModemCredDetils = async () => {
		setUpdate(true)
		setisModemCredModel(true)
		setInputs({ modemId: SideDetails?.MODEM_TYPE, IMEI: SideDetails?.IMEI, DEVICE_SERIAL_NO: SideDetails?.DEVICE_SERIAL_NO, PANEL_SERIAL_NO: SideDetails?.PANEL_SERIAL_NO, CREATED_BY: userInfo?.UNIQUE_ID, Consumer_assign: SideDetails?.CONSUMER_ASSIGN, CREDENTIALS_ID: SideDetails?.CREDENTIALS_ID })
	}
	const validation = (name, value) => {
		switch (name) {
			case "modemId":
				if (!value) {
					return "Please input modemId!";
				} else {
					return "";
				}
			case "IMEI":
				if (!value) {
					return "Please input IMEI!";
				} else {
					return "";
				}
			case "DEVICE_SERIAL_NO":
				if (!value) {
					return "Please input Last DEVICE SERIAL NO!";
				} else {
					return "";
				}
			case "FirstName":
				if (!value) {
					return "Please input First Name!";
				} else {
					return "";
				}

			default: {
				return null;
			}
		}
	};
	const onsubmitHandale = async (e) => {
		e.preventDefault()
		var allErrors = {}
		setflegBtn(true)
		Object.keys(inputs).forEach((key) => {
			const error = validation(key, inputs[key]);
			if (error && error.length) {
				allErrors[key] = error;
			}
		});
		if (Object.keys(allErrors).length) {
			setflegBtn(false)
			return setValidation(allErrors);
		} else {
			handaleModemDetails()

		}
	}
	const onMangaeModemCredential = async () => {
		const data = await ModemCredentialManage({ CREDENTIALS_ID: inputs?.CREDENTIALS_ID, credential_type: inputs?.CREDENTIALS_ID ? 'set' : 'default', IMEI: SideDetails?.IMEI, DEVICE_SERIAL_NO: SideDetails?.DEVICE_SERIAL_NO, MODEM_TYPE: SideDetails?.MODEM_TYPE })
		if (data.success == true) {
			setreqestDetail(JSON.parse(data.data.data))
			setModemResult(true)
			setflegBtn(false)

		} else {
			toast.error('Something Went Wrong')
			setflegBtn(false)
		}
	}



	const handaleModemDetails = async (isUpdateCredential) => {
		if (IsUpdate == false) {
			const data = await InsertModemDetails(inputs)
			if (data.success === true) {
				toast.success('Success Add Modem Details')
				setflegBtn(false)
				setisModemDetailsModel(false)
				setInputs({ modemId: '', IMEI: '', DEVICE_SERIAL_NO: '', PANEL_SERIAL_NO: '', CREATED_BY: userInfo?.UNIQUE_ID, Consumer_assign: '', CREDENTIALS_ID: '' })
				setUpdate(false)
				// history.push('/All-GSM')
				// UpdateRolesUser({C4_IMEI:SideDetails?.IMEI,C2_UNIQUE_ID:SideDetails?.UNIQUE_ID})
			} else if (data.err.response.status === 409) {
				toast.error("Duplicat Value Not Allow");
				setflegBtn(false)

			} else {
				toast.error('Something Went Wrong')
				setflegBtn(false)
			}
		} else {
			const data = await UpdateModemDetails({ ...inputs, isUpdateCredential: isUpdateCredential }, SideDetails?.UNIQUE_ID)
			if (data.success === true) {
				toast.success('Update Modem Details Success')
				setflegBtn(false)
				setisModemDetailsModel(false)

				setInputs({ modemId: '', IMEI: '', DEVICE_SERIAL_NO: '', PANEL_SERIAL_NO: '', CREATED_BY: userInfo?.UNIQUE_ID, Consumer_assign: '', CREDENTIALS_ID: '' })
				// history.push('/All-GSM')
				setUpdate(false)
				UpdateRolesUser({ C4_IMEI: SideDetails?.IMEI, C2_UNIQUE_ID: SideDetails?.UNIQUE_ID }, true)

				onCloseMassg()

			} else if (data.err.response.status === 409) {
				toast.error("Duplicat Value Not Allow");
				setflegBtn(false)
			} else {
				toast.error('Something Went Wrong')
				setflegBtn(false)
			}
		}
	}

	const onHandalModal = (item, data) => {
		setAPI_SEND_DATAModal(true)
		setApiSendData(JSON.parse(item))
		//details for model identity
		setModalJson({ DEVICE_SERIAL_NO: data?.C5_DEVICE_SERIAL_NO, IMEI: data?.C4_IMEI, MODEM_TYPE: data?.C3_MODEM_TYPE,UNIQUE_ID:data?.C2_UNIQUE_ID})
	}

	const onCloseMassg = () => {
		setflegBtn(false)
		setModemResult(false)
		setCreateTableStatus({})
		setreqestDetail({})
		setModemCstatus({ credential_set: false, reboot: false })
	}

	const onGetModemStatus = async (IMEI) => {
		await CheckModemStatus(IMEI)
	}

	const onHandalCopyModal = (item) => {
		// setCopyModal(true)
		toast.success('Copied', {
			position: toast.POSITION.TOP_CENTER,
			transition: Slide,
			theme: "colored",
			autoClose: 1000,
		});
		const epoch = Math.round(new Date().getTime() / 1000)
		setCopyDetails({ ...item, EPOCH: epoch })
		navigator.clipboard.writeText(`${item?.C5_DEVICE_SERIAL_NO}\t${item?.C4_IMEI}\t${item?.WIFIMAC?.match(/.{2}/g)?.join(':')}\t${epoch}`)
	}

	const onHandalGenratePDF = (item, data) => {
		setisDownloadModal(true)
		setDownloadInfo(data)
	}

	const onUpdateModemTest_data=async()=>{
		setisLoadSubmit(true)
		const data=await UpdateModemTest_data({TEST_DATA:ApiSendData},ModalJson?.UNIQUE_ID)
		if(data.success===true){
			toast.success('Update Success')
		setisLoadSubmit(false)
		setismodifid(false)
		setAPI_SEND_DATAModal(false)
		ongetModemConsumerDetails(ModemFilter)
		}else{
			toast.error('Server Issue')
		setisLoadSubmit(false)
		}
	}

	const downloadPdf = () => {
		const doc = new jsPDF();

		// Set up the font and size for the title
		doc.setFontSize(11);
		doc.setFont(undefined, "bold");
		doc.text("Internal Test Report", 105, 20, null, null, "center");

		// Product Details
		doc.setFontSize(11);
		doc.setFont(undefined, "normal");
		const productDetails = [
			["Product Name", DownloadData?.PROD_NAME],
			["SKU", DownloadData?.SKU],
			["Panel Version", DownloadData?.PENAL_VER],
			["Modem ID", DownloadInfo?.C5_DEVICE_SERIAL_NO],
			["Panel ID", DownloadInfo?.C6_PANEL_SERIAL_NO],
		];
		doc.autoTable({
			startY: 30,
			head: [],
			body: productDetails,
			theme: "grid"
		})
		// productDetails.forEach((detail, index) => {
		//   doc.text(detail[0], 20, 35 + index * 5);
		//   doc.text(detail[1], 70, 35 + index * 5);
		// });
		const data_test = JSON.parse(DownloadInfo?.TEST_DATA || '[]')?.reverse()
		// Define the table columns and rows
		const columns = ["Parameters", "Results / Data"];
		const rows = [
			["IMEI Number (System Generate)", DownloadInfo?.C4_IMEI],
			["WiFi MAC (System Generate)", DownloadInfo?.WIFIMAC?.match(/.{2}/g)?.join(':')],
			["GSM Red, Green and Blue Led Working (Manual Input)", ''],
			["IPV6 Setting in Modem", data_test?.IPV4V6],
			["Internal DOOR Switch Working (Manual Input)", data_test?.EXTERNAL_DOOR],
			["External DOOR Switch Working (Manual Input)", data_test?.INTERNAL_DOOR],
			["RS232 Working (Manual Testing)", ''],
			["DEEP SLEEP CURRENT Value in uA (Manual Input)", ''],
			["DEVICETS", data_test?.DEVICETS],
			["STINDEX", data_test?.STINDEX],
			["BAT_PER", data_test?.BAT_PER],
			["GSM_RSSI", data_test?.GSM_RSSI],
			["DWKPCNT", data_test?.DWKPCNT],
			["SIM_NUM", data_test?.SIM_NUM],
			["VERSTR", data_test?.VERSTR],
			["I2CSTATUS", data_test?.I2CSTATUS],
			["SIM_NWKP", data_test?.SIM_NWKP],
			["GSM_BAND", data_test?.GSM_BAND],
			["TOTALDUPTIME", data_test?.TOTALDUPTIME],
			["RTC Check", ''],
			["EEPROM Check", ''],
			["RSSI Check", ''],
			["Current Consumption Check", ''],
			["IMEI Match", ''],
			["WIFI MAC Match", ''],
			["System Verification", ''],
		];

		doc.setFontSize(11);
		doc.setFont(undefined, "bold");
		doc.text("Functional Test", 105, 80, null, null, "center");
		console.log(rows)
		// Add the table with autoTable
		doc.autoTable({
			startY: doc.lastAutoTable.finalY + 15 || 70,
			head: [columns],
			body: rows,
			theme: "grid",
			styles: {
				fontSize: 10,
				cellPadding: 1,
				overflow: "linebreak",
			},
			headStyles: {
				fillColor: [255, 255, 0], // Yellow color
				textColor: [0, 0, 0], // Black text
				halign: "center", // Center align headers
			},
			columnStyles: {
				0: { cellWidth: "auto" },
				1: { cellWidth: "auto", halign: "right" }, // Right align the second column
			},
		});

		// Save the PDF
		doc.save("InternalTestReport.pdf");
	}
	const onDownloadModemConsumerDetailsByFilterPDF = async (item, data) => {

		const modalJson = { DEVICE_SERIAL_NO: data?.C5_DEVICE_SERIAL_NO, IMEI: data?.C4_IMEI, MODEM_TYPE: data?.C3_MODEM_TYPE, UNIQUE_ID: data?.C2_UNIQUE_ID }
		const apiSendData = JSON.parse(item);

		// exportTableToPDF(modelJson,apiSendData,false);
		const doc = new jsPDF();

		// Set up the document
		doc.setFontSize(16);
		doc.setFont(undefined, "bold");
		doc.text("Internal Test Report", 105, 15, null, null, "center");

		// Prepare the table data
		const columns = ["Parameters", "Results / Data"];
		const rows = [];

		const testResultLookup = JSON.parse(ModemData.filter((item) => (item.value === modalJson?.MODEM_TYPE))[0]?.TEST_RESULT_UI_LOOKUP || '{}');

		// Function to determine cell color
		const getCellColor = (key, value, apiData) => {
			if (apiData?.EEROR?.includes(key)) return '#fc544b';
			if (value?.type === 'datetime') return '#000000';
			if (value?.UI_LOOKUP) {
				return value?.UI_LOOKUP[apiData[key]]?.color || '#fc544b';
			}
			return '#54ca68';
		};

		// Function to get cell value
		const getCellValue = (key, value, apiData) => {
			const cellData = apiData[key];
			if (value?.type === 'datetime' && cellData) {
				return moment(cellData, 'YYYY-MM-DD HH:mm:ss').format('DD-MM-YYYY hh:mm:ss A');
			}
			if (value?.type === 'const') return apiData?.value;
			if (value?.UI_LOOKUP) {
				return value?.UI_LOOKUP[cellData]?.name || cellData;
			}
			if (typeof cellData === 'object') {
				return ''
			}

			return cellData;
		};


		// Add rows for non-null and non-select types
		Object.entries(testResultLookup).forEach(([key, value]) => {
			if (value?.type !== 'null' && value?.type !== 'select') {
				const cellValue = getCellValue(key, value, apiSendData);
				const cellColor = getCellColor(key, value, apiSendData);
				rows.push([
					{ content: value?.dname, styles: { cellWidth: '50%' } },
					{ content: cellValue, styles: { cellWidth: '50%', textColor: cellColor } }
				]);
			}
		});

		// Add rows for select types
		Object.entries(testResultLookup).forEach(([key, value]) => {
			if (value?.type === 'select') {
				if (!ismodifid || (ismodifid && value?.type === 'select')) {
					const cellValue = getCellValue(key, value, apiSendData);
					const cellColor = getCellColor(key, value, apiSendData);
					rows.push([
						{ content: value?.dname, styles: { cellWidth: '50%' } },
						{ content: cellValue, styles: { cellWidth: '50%', textColor: cellColor } }
					]);
				}
			}
		});

		// Add the table with autoTable
		doc.autoTable({
			startY: 25,
			head: [columns],
			body: rows,
			theme: "grid",
			styles: {
				fontSize: 10,
				cellPadding: 2,
				overflow: "linebreak",
				lineColor: [0, 0, 0], // Set border color to black

			},
			headStyles: {
				fillColor: [200, 200, 200],
				textColor: [0, 0, 0],
				lineWidth: 0.2,
				halign: "center",
			},
			columnStyles: {
				0: { cellWidth: 'auto' },
				1: { cellWidth: 'auto' },
			},
		});

		// Save the PDF
		doc.save("InternalTestReport.pdf");

	}
	
	return (
		<>
			<Modal visible={ModemResult} footer='' maskClosable={false} onCancel={() => (onCloseMassg())} width={500}>
				<h5 class="modal-title text-center" id="meter_data_mqtt_info_txt">Getting Response For Modem</h5>
				<h5 class="modal-title text-center mt-2 " id="meter_data_mqtt_info_txt">{ModemCstatus?.credential_set == false ? <img className='mr-2' src={clockPending} height='20px' width={'20px'}></img> : ModemCstatus?.credential_set == true ? <span><i className='fas fa-check mr-2' style={{ color: 'green' }}></i></span> : <span><i className='fas fa-times mr-2' style={{ color: 'red' }}></i></span>} Set Modem Credential{ModemCstatus?.credential_set == true ? `(Success)` : ''}</h5>
				<h5 class="modal-title text-center mt-2 " id="meter_data_mqtt_info_txt">{ModemCstatus?.reboot == false ? <img className='mr-2' src={clockPending} height='20px' width={'20px'}></img> : ModemCstatus?.reboot == true ? <span><i className='fas fa-check mr-2' style={{ color: 'green' }}></i></span> : <span><i className='fas fa-times mr-2' style={{ color: 'red' }}></i></span>} Modem Reboot {ModemCstatus?.reboot == true ? `(Success)` : ''}</h5>
				<div class="">
				</div>
				<div class="text-center" style={{ marginTop: "20px" }}>
					{/* <input type="button" class="btn btn-danger " value="Close" id="meter_data_mqtt_cancel_button" name="meter_data_mqtt_cancel_button" onClick={()=>onCloseMassg()}/> */}
				</div>
			</Modal>

			<Modal title={<><b>IMEI: </b><span> {DownloadInfo?.C4_IMEI}</span></>} visible={DownloadModal} footer='' onCancel={() => { setisDownloadModal(false) }} width={800}>
				<div class="form-row">
					<div class="form-group col-md-4">
						<label for="inputPassword4">Product Name :</label>
						<input type="text" className="form-control" name="IMEI" id="checktext7" placeholder="Product Name" onChange={(e) => {
							setDownloadData({ ...DownloadData, PROD_NAME: e.target.value })
						}} />
					</div>
					<div class="form-group col-md-4">
						<label for="inputPassword4">SKU:</label>
						<input type="text" className="form-control" name="IMEI" id="checktext7" placeholder="SKU" onChange={(e) => {
							setDownloadData({ ...DownloadData, SKU: e.target.value })
						}} />
					</div>
					<div class="form-group col-md-4">
						<label for="inputPassword4">Panel Version:</label>
						<input type="text" className="form-control" name="IMEI" id="checktext7" placeholder="Panel Version" onChange={(e) => {
							setDownloadData({ ...DownloadData, PENAL_VER: e.target.value })
						}} />
					</div>
				</div>
				<div class="text-left mt-2">
					<button class={`btn btn-danger `} value="Filter" name="filter_customer_form" onClick={downloadPdf}>Download</button>
				</div>
			</Modal>
			<Modal title="" visible={API_SEND_DATAModal} footer='' onCancel={() => {
				setAPI_SEND_DATAModal(false)
				setApiSendData([])
				setModalJson({})
				setisLoadSubmit(false)
				setismodifid(false)
			}} width={800}>
				<div class="row">
					<div class="d-flex tw-border-b-0 " style={{ justifyContent: "space-between" }}>
						<ul class="nav nav-pills tw-gap-2" role="tablist" style={{ lineHeight: "20px", alignItems: "center" }}>
							<li>
								<button
									style={{
										backgroundColor: activeTab === "Test Result" ? chroma(btnColor[0]).alpha(0.4).css() : '',
										color: activeTab === "Test Result" ? chroma(btnColor[0]).darken(1.5).css() : btnColor[0],
										borderBottom: `1px solid ${btnColor[0]}`,
										boxShadow: activeTab === "Test Result" ? `0 2px 8px -3px ${btnColor[0]}` : '',
									}}
									className={`tw-font-semibold tw-transition-all tw-bg-transparent tw-border-t-0 tw-border-r-0 tw-border-l-0 ml-2 tw-px-2 tw-mx-1 tw-my-1 tw-py-1 md:tw-px-3 md:tw-m-0 md:tw-py-2 focus:tw-outline-none ${activeTab === "Test Result" ? 'tw-rounded' : 'hover:tw-bg-gray-100 hover:tw-rounded'}`}
									data-toggle="tab"
									href="#home"
									role="tab"
									aria-controls="home"
									aria-selected="true"
									onClick={() => setActiveTab("Test Result")}
								>
									Test Result
								</button>
							</li>
							<li>
								<button
									style={{
										backgroundColor: activeTab === "Raw Data" ? chroma(btnColor[2]).alpha(0.4).css() : '',
										color: activeTab === "Raw Data" ? chroma(btnColor[2]).darken(1.5).css() : btnColor[2],
										borderBottom: `1px solid ${btnColor[2]}`,
										boxShadow: activeTab === "Raw Data" ? `0 2px 8px -3px ${btnColor[2]}` : '',
									}}
									className={`tw-font-semibold tw-transition-all tw-bg-transparent tw-border-t-0 tw-border-r-0 tw-border-l-0 ml-2 tw-px-2 tw-mx-1 tw-my-1 tw-py-1 md:tw-px-3 md:tw-m-0 md:tw-py-2 focus:tw-outline-none ${activeTab === "Raw Data" ? 'tw-rounded' : 'hover:tw-bg-gray-100 hover:tw-rounded'}`}
									id="profile-tab"
									data-toggle="tab"
									href="#profile"
									role="tab"
									aria-controls="profile"
									aria-selected="false"
									onClick={() => setActiveTab("Raw Data")}
								>
									Raw Data
								</button>
							</li>
						</ul>
					</div>
				</div>
				{activeTab == 'Test Result' && <div class="table-responsive">
					<div className="mt-2 d-flex">
						<div ><b>DEVICE SERIAL NO: </b>{ModalJson?.DEVICE_SERIAL_NO} , </div>
						<div className="ml-2"><b>IMEI: </b>{ModalJson?.IMEI}</div>
					</div>
					<div className="tw-float-right">
						<div className="btn btn-primary" onClick={() => setismodifid(true)}>
							<span>
								<i class="fas fa-edit  text-white"></i>
							</span>
						</div>
					</div>
					<table class="table table-striped mt-4" id="tabledata" style={{ width: "100%" }}>
						<thead>
							<tr>
								<th style={{ width: "50%" }}>Fields</th>
								<th style={{ width: "50%" }}>Value</th>
							</tr>
						</thead>
						<tbody>
							{
								<>

									{Object.entries(JSON.parse(ModemData.filter((item) => (item.value === ModalJson?.MODEM_TYPE))[0]?.TEST_RESULT_UI_LOOKUP || '{}')).map(([key, value]) => (
										value?.type != 'null'&&value?.type != 'select' ? <tr>
											<td style={{ width: "50%", height: "30px" }}>{value?.dname}</td>
											<td style={{ width: "50%", height: "30px", color: ApiSendData?.EEROR?.includes(key) ? '#fc544b' : value?.type == 'datetime' ? ApiSendData[key] ? moment(ApiSendData[key], 'YYYY-MM-DD HH:mm:ss').format('DD-MM-YYYY hh:mm:ss A') : value?.type == 'const' ? ApiSendData?.value : ApiSendData[key] : value?.UI_LOOKUP ? value?.UI_LOOKUP[ApiSendData[key]] && value?.UI_LOOKUP[ApiSendData[key]]["color"] || '#fc544b' : '#54ca68' }}>{value?.UI_LOOKUP ? value?.UI_LOOKUP[ApiSendData[key]] && value?.UI_LOOKUP[ApiSendData[key]]["name"] || ApiSendData[key] : ApiSendData[key]}</td>
										</tr>
											: ''
									))}
									{Object.entries(JSON.parse(ModemData.filter((item) => (item.value === ModalJson?.MODEM_TYPE))[0]?.TEST_RESULT_UI_LOOKUP || '{}')).map(([key, value]) => (
										!ismodifid&&value?.type != 'null'&&value?.type == 'select'?<tr>
											<td style={{ width: "50%", height: "30px" }}>{value?.dname}</td>
											<td style={{ width: "50%", height: "30px", color: value?.type == 'select'&&ismodifid ? '' : ApiSendData?.EEROR?.includes(key) ? '#fc544b' : value?.type == 'datetime' ? ApiSendData[key] ? moment(ApiSendData[key], 'YYYY-MM-DD HH:mm:ss').format('DD-MM-YYYY hh:mm:ss A') : value?.type == 'const' ? ApiSendData?.value : ApiSendData[key] : value?.UI_LOOKUP ? value?.UI_LOOKUP[ApiSendData[key]] && value?.UI_LOOKUP[ApiSendData[key]]["color"] || '#fc544b' : '#54ca68' }}>{value?.UI_LOOKUP ? value?.UI_LOOKUP[ApiSendData[key]] && value?.UI_LOOKUP[ApiSendData[key]]["name"] || ApiSendData[key] : ApiSendData[key]}</td>
										</tr>:ismodifid&&value?.type != 'null'&&value?.type == 'select'?<tr>
											<td style={{ width: "50%", height: "30px" }}>{value?.dname}</td>
											<td style={{ width: "50%", height: "30px", color: value?.type == 'select'&&ismodifid? '' : ApiSendData?.EEROR?.includes(key) ? '#fc544b' : value?.type == 'datetime' ? ApiSendData[key] ? moment(ApiSendData[key], 'YYYY-MM-DD HH:mm:ss').format('DD-MM-YYYY hh:mm:ss A') : value?.type == 'const' ? ApiSendData?.value : ApiSendData[key] : value?.UI_LOOKUP ? value?.UI_LOOKUP[ApiSendData[key]] && value?.UI_LOOKUP[ApiSendData[key]]["color"] || '#fc544b' : '#54ca68' }}>{value?.type == 'select' ? <select onChange={(e)=>(setApiSendData({...ApiSendData,[key]:e.target.value}))}><option value=""></option>{Object.entries(value?.UI_LOOKUP || {}).map(([keys, values]) => (<option value={keys}>{values?.name}</option>))}</select> : value?.UI_LOOKUP ? value?.UI_LOOKUP[ApiSendData[key]] && value?.UI_LOOKUP[ApiSendData[key]]["name"] || ApiSendData[key] : ApiSendData[key]}</td>
										</tr>:''
									))}

								</>
							}
						</tbody>
					</table>
					{ismodifid && <div class="text-left mt-2">
						<input type="submit" class={`btn btn-danger ${isLoadSubmit == true ? 'disabled btn-progress' : ''}`} value="Submit" name="filter_customer_form" onClick={onUpdateModemTest_data} />
						<Link class="btn btn-light text-dark ml-2" onClick={() => {
							setismodifid(false)
						}}>
							Reset
						</Link>
					</div>}
				</div>}
				{activeTab == 'Raw Data' && <pre> {JSON.stringify(ApiSendData, null, 2)}</pre>}
			</Modal>
			<Modal title="Filter" visible={isModalVisible} footer="" onCancel={() => setIsModalVisible(false)} width={800}>
				<div class="form-row">
					<div class="form-group col-md-6">
						<label for="inputPassword4">Modem Name:</label>
						<Select name='Modem_Profile' options={ModemData} isMulti onChange={(e) => {
							setFilter({ ...Filter, ModemList: e.map((item) => (item.value)) })
						}} />
					</div>
					<div class="form-group col-md-6">
						<label for="inputPassword4">IMEI:</label>
						<MultiInput
							placeholder="search and enter..."
							onChange={(e) => onHandalSearchMultiFilter(e, "IMEI")}
							maxItems={null} // Allow any number of items
							onClear={(e) => onItemClearedFilter(e, "IMEI")}
							onClearAll={(e) => onClearAllItemsFilter(e, "IMEI")}
						/>

					</div>
					<div class="form-group col-md-6">
						<label for="inputPassword4">Device Serial No:</label>
						<MultiInput
							placeholder="search and enter..."
							onChange={(e) => onHandalSearchMultiFilter(e, "DSRNO")}
							maxItems={null} // Allow any number of items
							onClear={(e) => onItemClearedFilter(e, "DSRNO")}
							onClearAll={(e) => onClearAllItemsFilter(e, "DSRNO")}
						/>

					</div>
					<div class="form-group col-md-6">
						<label for="inputPassword4">Panel Serial No:</label>
						<MultiInput
							placeholder="search and enter..."
							onChange={(e) => onHandalSearchMultiFilter(e, "PASRNO")}
							maxItems={null} // Allow any number of items
							onClear={(e) => onItemClearedFilter(e, "PASRNO")}
							onClearAll={(e) => onClearAllItemsFilter(e, "PASRNO")}
						/>

					</div>
				</div>
				<div class="text-left mt-2">
					<input type="submit" class={`btn btn-danger ${filterLoader == true ? 'disabled btn-progress' : ''}`} value="Filter" name="filter_customer_form" onClick={ongetModemConsumerDetailsByFilter} />
					<Link class="btn btn-light text-dark ml-2" onClick={() => {
						setIsModalVisible(false)
						setFilter({ ModemList: [], IMEI: '', DSRNO: '', PASRNO: '' })
					}}>
						Reset
					</Link>
				</div>
			</Modal>
			<Modal title={IsUpdate ? "Update Modem Details" : "Add Modem Details"} visible={isModemDetailsModel} footer="" onCancel={() => {
				setisModemDetailsModel(false)
				setInputs({ modemId: '', IMEI: '', DEVICE_SERIAL_NO: '', PANEL_SERIAL_NO: '', CREATED_BY: userInfo?.UNIQUE_ID, Consumer_assign: '', CREDENTIALS_ID: '' })
				setUpdate(false)
			}} width={1000}>
				<div class="form-row" style={{ marginLeft: "10px" }}>
					<div class="form-group col-md-3" id="grpeui">
						<label for="inputPassword4">Modem Profile: <span class="text-danger">*</span></label>
						<select class="form-control form-select modemtypename anothermodemname" required="" name="modem_profile_id" id="modem_profile_id" onChange={(e) => (setInputs({ ...inputs, modemId: e.target.value }))} value={inputs.modemId} >
							<option value="">Select Modem</option>
							{ModemData && ModemData.map((item) => (<option value={item?.value}>{item?.label}</option>))}
						</select>
						<span class="text-danger" id="imeirepeterror" style={{ fontSize: "10px" }}>
							{Validation?.modemId}
						</span>
					</div>
					<div class="form-group col-md-3">
						<label for="inputPassword4">
							IMEI:<span class="text-danger">*</span>
						</label>
						<input
							class="form-control "
							id="deviceIMEI"
							name="deviceIMEI"
							required=""
							type="text"
							placeholder="IMEI"
							maxlength="16"
							onChange={(e) => (setInputs({ ...inputs, IMEI: e.target.value }))}
							value={inputs.IMEI}

						/>
						<div class="text-danger" id="imeirepeterror" style={{ fontSize: "10px" }}>
							{Validation?.IMEI}
						</div>
						<span class="text-danger" id="imeirepeterror" style={{ fontSize: "10px" }}>
							* Not Allow Duplicate IMEI Value
						</span>
					</div>
					<div class="form-group col-md-3">
						<label for="inputPassword4">
							Device Serial No:<span class="text-danger">*</span>
						</label>
						<input
							class="form-control "
							id="consmrid"
							required=""
							name="consmrid"
							type="text"
							placeholder="Modem Serial No"
							onChange={(e) => (setInputs({ ...inputs, DEVICE_SERIAL_NO: e.target.value }))}
							value={inputs.DEVICE_SERIAL_NO}

						/>
						<div class="text-danger" id="imeirepeterror" style={{ fontSize: "10px" }}>
							{Validation?.DEVICE_SERIAL_NO}
						</div>
						<span class="text-danger" id="imeirepeterror" style={{ fontSize: "10px" }}>
							* Not Allow Duplicate Modem Serial No
						</span>
					</div>
					<div class="form-group col-md-3">
						<label for="inputPassword4">Panel Serial No:</label>
						<input
							class="form-control"
							id="panelserialno"
							name="panelserialno"
							type="text"
							placeholder="Panel Serial No"
							onChange={(e) => (setInputs({ ...inputs, PANEL_SERIAL_NO: e.target.value }))}
							value={inputs.PANEL_SERIAL_NO}

						/>
						<span class="text-danger" id="imeirepeterror" style={{ fontSize: "10px" }}>
							* Not Allow Duplicate Panel Serial No
						</span>
					</div>
					<div class="form-group col-md-3">
						<label for="inputPassword4">
							Max Consumer Assign:
						</label>
						<input
							class="form-control "
							id="deviceIMEI"
							name="deviceIMEI"
							required=""
							type="text"
							placeholder=""
							maxlength="16"
							onChange={(e) => (setInputs({ ...inputs, Consumer_assign: e.target.value }))}
							value={inputs.Consumer_assign}

						/>

					</div>
					{/* <div class="form-group col-md-3" id="grpeui">
							<label for="inputPassword4">Modem Credential: </label>
							<select class="form-control form-select modemtypename anothermodemname" required="" name="modem_profile_id" id="modem_profile_id" onChange={(e) => (setInputs({ ...inputs, CREDENTIALS_ID: e.target.value }))} value={inputs.CREDENTIALS_ID} >
								<option value="">Default (Memighty)</option>
								{ModemCredential && ModemCredential.map((item) => (<option value={item?.unique_id}>{item?.name}</option>))}
							</select>
							<span class="text-danger" id="imeirepeterror" style={{ fontSize: "10px" }}>
								{Validation?.modemId}
							</span>
						</div> */}
				</div>

				<p style={{ marginLeft: "25px" }} class="text-danger">Note : * Indicates Mandatory Fields</p>
				<div class="text-left">
					<input type="submit" name="submit" id="submitvn" class={`btn btn-danger ${flagBtn ? 'btn-progress disabled' : ''} ml-4 mr-3`} value={IsUpdate ? 'Update' : 'Submit'} onClick={onsubmitHandale} />
					<Link class="btn btn-light text-dark" to="/All-GSM">Cancel</Link>
				</div>
			</Modal>
			<Modal title="Update Modem Credential" visible={isModemCredModel} footer="" onCancel={() => {
				setisModemCredModel(false)
				setInputs({ modemId: '', IMEI: '', DEVICE_SERIAL_NO: '', PANEL_SERIAL_NO: '', CREATED_BY: userInfo?.UNIQUE_ID, Consumer_assign: '', CREDENTIALS_ID: '' })
				setUpdate(false)
			}} width={500}>
				<div class="form-row" style={{ marginLeft: "10px" }}>
					<div class="form-group col-md-12" id="grpeui">
						<label for="inputPassword4">Modem Credential: </label>
						<select class="form-control form-select modemtypename anothermodemname" required="" name="modem_profile_id" id="modem_profile_id" onChange={(e) => (setInputs({ ...inputs, CREDENTIALS_ID: e.target.value }))} value={inputs.CREDENTIALS_ID} >
							<option value="">Default (Memighty)</option>
							{ModemCredential && ModemCredential.map((item) => (<option value={item?.unique_id}>{item?.name}</option>))}
						</select>
						<span class="text-danger" id="imeirepeterror" style={{ fontSize: "10px" }}>
							{Validation?.modemId}
						</span>
					</div>
				</div>

				<p style={{ marginLeft: "25px" }} class="text-danger">Note : * Indicates Mandatory Fields</p>
				<div class="text-left">
					<input type="submit" name="submit" id="submitvn" class={`btn btn-danger ${flagBtn ? 'btn-progress disabled' : ''} ml-4 mr-3`} value={'Update'} onClick={onMangaeModemCredential} />
					<Link class="btn btn-light text-dark" to="/All-GSM">Cancel</Link>
				</div>
			</Modal>
			<section class="section">

				<div className="row">
					<div className="col-12">
						<div className="card">
							<div className="card-header ">
								<h4 className="d-flex"><span className='mr-2'>Modem Details</span><BootstrapTooltip title='Table Name is group_modem_details_index' placement='top'><span><i className='fas fa-info-circle info_circle'></i></span></BootstrapTooltip>
									<div class="form-group ml-2 mb-0 mr-2">
										<select
											id="inputState"
											class="form-select form-control"
											name="modem_name"
											onChange={(e) => setModemFilter(e.target.value)}
											value={ModemFilter}
											style={{ width: '300px', height: '35px', padding: '0px 15px' }}
										>
											<option value={'All'}>All</option>
											{ModemData?.map((item) => (<option value={item?.value}>{item?.label}</option>))}
										</select>
									</div></h4>
								<div className="card-header-action">
									<div onClick={() => {
										setisModemDetailsModel(true)
										console.log(inputs)
									}} className="btn btn-info text-white mr-2">
										<BootstrapTooltip title="Add">
											<span>
												<i
													className="fas fa-plus  text-white"
													style={{ fontSize: "16px", paddingLeft: "5px", paddingRight: "5px" }}
												></i>
											</span>
										</BootstrapTooltip>
									</div>
									<button type="button" className="btn btn-info mr-2" onClick={() => setIsModalVisible(true)}>
										<BootstrapTooltip title="Filter">
											<span>
												<i className="fa fa-filter text-white"></i>
											</span>
										</BootstrapTooltip>
									</button>
									<div className="dropdown d-inline mr-2">
										<button
											className="btn btn-info dropdown-toggle mr-2"
											type="button"
											id="dropdownMenuButton3"
											data-toggle="dropdown"
											aria-haspopup="true"
											aria-expanded="false"
											data-placement="bottom"
											title="Download"
										>
											<i className="fa fa-download mr-1"></i>
										</button>
										<div className="dropdown-menu">

											<a onClick={() => onDownloadModemConsumerDetailsByFilter('xlsx')} className="dropdown-item" >
												Excel
											</a>
											<a onClick={() => onDownloadModemConsumerDetailsByFilter('csv')} className="dropdown-item">
												CSV
											</a>
										</div>
									</div>

								</div>
							</div>
							<div className="card-body">

								{ModLoader ? <> <div className='tw-h-screen tw-w-full tw-transition tw-ease-in-out tw-duration-300'>
									<GoogleLoader bodyClassName={'tw-h-[70%]'} />
								</div></> : <div className="d-flex"><div className="table-responsive tw-h-screen tw-max-h-screen">
									<div id="table-1_wrapper" className="dataTables_wrapper no-footer">
										<div className="dataTables_length" id="table_id_length">
											<label>
												Show{" "}
												<select name="table_id_length" aria-controls="table_id" className="" onChange={(e) => (setPageSize(e.target.value))} value={PageSize}>
													<option value={10}>10</option>
													<option value={25}>25</option>
													<option value={50}>50</option>
													<option value={100}>100</option>
												</select>{" "}
												entries
											</label>
										</div>
										<div id="table-1_filter" className="dataTables_filter"><label className="mr-2">Search:</label><MultiInput
											placeholder="search and enter..."
											onChange={onHandalSearchMulti}
											maxItems={null} // Allow any number of items
											onClear={onItemCleared}
											onClearAll={onClearAllItems}
											onHandalSerch={onHandalSerch}
										/>
											{/* <input type="search" className="mr-2" value={InputText} onChange={(e) => { setInputText(e.target.value.trim()) }} onKeyDown={(e) => (e.code === "Enter" ? onHandalSerch(e) : '')} /> */}
											<div className={`ml-2 btn btn-primary ${serachLoader === true ? 'btn-progress disabled' : ''}`} onClick={onHandalSerch} style={{ padding: '0.2rem 0.8rem', top: '0' }}><i className={`fas fa-search `}></i></div></div>
										<table id="table_id" className="tableCustom table table-striped tw-w-full tw-text-sm text-center">
											<thead className="tw-sticky tw-z-50 tw-top-0 tw-text-xs tw-bg-gray-100">
												<tr>
													<th class="GSM_Link">Unique Id</th>
													<th>
														Modem Name
													</th>
													<th>IMEI</th>
													<th>Device Serial No</th>
													<th>Panel Serial No</th>
													<th>Modem Credential</th>
													<th>WIFIMAC</th>
													<th style={{ width: '150px' }}>Test Time Stamp</th>
													<th>Test Result</th>
													<th>Test Data</th>
													<th>Test Report Download</th>
													<th>Copy</th>
													<th>Status</th>

												</tr>
											</thead>
											<tbody>
												{modemDList.length > 0 && modemDList.map((item) => (
													<tr onMouseEnter={() => {
														var element = document.getElementById(item?.C1_SRNO + 'GSM');
														element.classList.add('within');
														element.classList.remove('display-icon');
													}} onMouseLeave={() => {
														var element = document.getElementById(item?.C1_SRNO + 'GSM');
														element.classList.add('display-icon');
														element.classList.remove('within');
													}} style={{ cursor: 'pointer' }}>
														<td className="GSM_Link"><a onClick={() => (UpdateRolesUser(item))} style={{ color: '#1890ff' }}>{item?.C1_SRNO}</a>
															<a id={item?.C1_SRNO + 'GSM'} className='display-icon' onClick={() => (UpdateRolesUser(item))} ><img src={exports} style={{ height: "10px" }} /></a></td>
														<td>
															{item?.MODEM_PROFILE_NAME}
														</td>
														<td>{item?.C4_IMEI}</td>
														<td>{item?.C5_DEVICE_SERIAL_NO}</td>
														<td>{item?.C6_PANEL_SERIAL_NO}</td>
														<td>{item?.credential_name || 'Memighty'}</td>
														<td>{item?.WIFIMAC?.match(/.{2}/g)?.join(':')}</td>
														<td>{item?.TESTED_AT ? moment.utc(item?.TESTED_AT).format("DD-MM-YYYY hh:mm:ss A") : '-'}</td>
														{item && item.TEST_RESULT !== null ? ((item.TEST_RESULT === 1) ? <td><span className="badge badge-success">&#10004;</span></td> : <td><span className="badge badge-danger">&#x2716;</span></td>) : <td>-</td>}
														{console.log(item?.TEST_RESULT)}
														<td className="text-center">
															{item?.TEST_DATA ? <a
																onClick={() => onHandalModal(item?.TEST_DATA, item)}
																class="btn-sm btn-primary btn-sm font-14 text-center"
															>
																<i
																	class="fa fa-info-circle "
																	aria-hidden="true"
																	style={{ color: "white" }}
																></i>
															</a> : '-'}
														</td>
														<td className="text-center">
															
															{(item.TEST_RESULT === 1) ?
															<div className="dropdown d-inline mr-2">
																<button
																	className="btn btn-primary btn-sm dropdown-toggle mr-2"
																	type="button"
																	id="dropdownMenuButton3"
																	data-toggle="dropdown"
																	aria-haspopup="true"
																	aria-expanded="false"
																	data-placement="bottom"
																	title="Download"
																>
																	<i className="fa fa-download mr-1"></i>
																</button>
																<div className="dropdown-menu">

																	<a onClick={() => onDownloadModemConsumerDetailsByFilter('individual', { IMEI: [item?.C4_IMEI] })} className="dropdown-item" >
																		Excel
																	</a>
																	<a onClick={() => onDownloadModemConsumerDetailsByFilterPDF(item?.TEST_DATA, item)} className="dropdown-item">
																		PDF
																	</a>
																</div>
															</div>
															 : '-'}
														</td>
														<td>{(item.TEST_RESULT === 1)?<a
															onClick={() => onHandalCopyModal(item)}
															class="btn-sm btn-info btn-sm font-14 text-center"
														>
															<BootstrapTooltip title={'Copy'}><span><i
																class="fas fa-copy"
																aria-hidden="true"
																style={{ color: "white" }}
															></i></span></BootstrapTooltip>
														</a>:'-'}</td>
														<td className="text-center">
															<div className={`badge ${item.STATUS === 1 ? 'badge-success' : item.STATUS === 2 ? 'badge-dark' : 'badge-danger'} badge-shadow text-white`}>{item.STATUS === 1 ? 'Active' : item.STATUS === 2 ? 'Moved' : 'Inactive'}</div>
														</td>
													</tr>
												))}
											</tbody>
										</table>
									</div>
								</div>
									{isSideExapnded && <div className='tw-flex tw-relative  tw-w-full tw-overflow-auto tw-grow tw-bg-[#f8f8f8]'>
										<div className={classNames('!tw-border-r-gray-300 tw-h-screen', isSideExapnded ? 'animate__fadeInRight_grow border-end tw-w-[100%]' : 'animate__fadeInLeft tw-flex-grow')}>
											<div className='tw-flex tw-flex-col tw-w-full'>
												<div className='tw-w-full tw-flex tw-items-center border-bottom !tw-border-b-gray-300 lg:tw-pl-5 tw-pr-2 tw-py-3 md:tw-px-0'>
													<span className='tw-text-md '> SRNO : <b>{SideDetails?.SRNO}</b></span>
													<div class="ml-auto"></div>


													<div class="dropdown">
														<button class={`btn ${SideDetails?.STATUS == 1 ? 'btn-success' : SideDetails?.STATUS == 0 ? 'btn-danger' : SideDetails?.STATUS == 2 ? 'btn-dark disabled' : SideDetails?.STATUS == 3 ? 'btn-dark' : 'btn-warning'} dropdown-toggle`} type="button" id="dropdownMenuButton1" data-toggle="dropdown" aria-expanded="false" aria-haspopup="true" >
															{SideDetails?.STATUS == 1 ? 'Active' : SideDetails?.STATUS == 0 ? 'InActive' : SideDetails?.STATUS == 2 ? 'Moved' : SideDetails?.STATUS == 3 ? 'Stand By' : 'Pending Configration'}
														</button>
														<div class="dropdown-menu !tw-left-[-50px]" aria-labelledby="dropdownMenuButton1">
															{SideDetails?.STATUS != 1 && SideDetails?.STATUS != 2 ? <a class="dropdown-item text-success" onClick={onActive} >Active</a> : ''}
															{SideDetails?.STATUS != 0 && SideDetails?.STATUS != 2 ? <a class="dropdown-item text-danger" onClick={onInActive}>InActive</a> : ''}
														</div>
													</div>
													<span
														onClick={() => {
															setIsSideExapnded(false)
															setModemStatus({})
														}}
														className='border-start !tw-border-l-gray-300 tw-float-right tw-text-[#777] tw-ml-2 tw-cursor-pointer hover:tw-text-[#212529]'>
														<svg className='tw-h-8 lg:tw-w-10 md:tw-w-7' xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16"> <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" /> </svg>
													</span>
												</div>
											</div>
											<div className='tw-overflow-y-auto tw-pb-10 m-3' style={{ backgroundColor: 'rgb(255, 255, 255)', boxShadow: 'rgba(0, 0, 0, 0.04) 0px 5px 10px 0px' }}>
												<div className='tw-float-right mr-2 mt-2'>

													<button style={{ marginLeft: '5px' }} className="tw-border tw-py-1 tw-px-[0.4rem] tw-text-[#212529] tw-border-solid tw-border-[#ddd] tw-rounded " onClick={() => onUpdateModemDetils()}>
														<i className='fas fa-edit'></i>
													</button>
												</div>
												<div className='tw-px-4 '>
													<div className='tw-grid tw-grid-cols-5 tw-gap-4 tw-mt-4'>

														<div className=''>
															Modem Name :-
														</div>
														<div className='tw-col-span-4'>
															<b className='tw-text-base tw-mr-2'>{SideDetails?.MODEM_PROFILE_NAME} </b>
														</div>
														<div className=''>
															IMEI :-
														</div>
														<div className='tw-col-span-4'>
															<b className='tw-text-base tw-mr-2'>  {SideDetails?.IMEI}</b>
														</div>
														<div className=''>
															Device Serial No :-
														</div>
														<div className='tw-col-span-4'>
															<b className='tw-text-base tw-mr-2'> {SideDetails?.DEVICE_SERIAL_NO} </b>
														</div>
														<div className=''>
															Panel Serial No :-
														</div>
														<div className='tw-col-span-4'>
															<b className='tw-text-base tw-mr-2'>{SideDetails?.PANEL_SERIAL_NO || '-'}</b>
														</div>
														<div className=''>
															Assign Consumer :-
														</div>
														<div className='tw-col-span-4'>
															<b className='tw-text-base tw-mr-2'>{SideDetails?.CONSUMER_ASSIGN || '-'}</b>
														</div>
														<div className=''>
															Modem Status :-
														</div>
														<div className='tw-col-span-4'>
															<b className='tw-text-base tw-mr-2'>{ModemStatus?.MMD_ID_IMEI == SideDetails?.IMEI ? <div className={`mr-2 badge ${ModemStatus?.DEVICE_STATUS == 'online' && ModemStatus?.MMD_ID_IMEI == SideDetails?.IMEI ? 'badge-success' : 'badge-danger'} badge-shadow text-white`}>{ModemStatus?.MMD_ID_IMEI == SideDetails?.IMEI ? ModemStatus?.DEVICE_STATUS || '' : ''}</div> : ''}</b>
														</div>
													</div>
												</div>
											</div>
											<div className='tw-overflow-y-auto tw-pb-4 m-3' style={{ backgroundColor: 'rgb(255, 255, 255)', boxShadow: 'rgba(0, 0, 0, 0.04) 0px 5px 10px 0px' }}>
												<div className='tw-float-right mr-2 mt-2'>

													<button style={{ marginLeft: '5px' }} className="tw-border tw-py-1 tw-px-[0.4rem] tw-text-[#212529] tw-border-solid tw-border-[#ddd] tw-rounded " onClick={() => onUpdateModemCredDetils()}>
														<i className='fas fa-edit'></i>
													</button>
												</div>
												<div className='tw-px-4 '>
													<div className='tw-grid tw-grid-cols-4 tw-gap-4 tw-mt-4'>
														<div className=''>
															Modem Credential :-
														</div>
														<div className='tw-col-span-2'>
															<b className='tw-text-base tw-mr-2'>{SideDetails?.credential_name || 'Memighty'}</b>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>}
								</div>}
								{ModLoader == false && modemDList.length == 0 ? <h4 className="text-center">No Data Found</h4> : ''}
								<><div className='d-flex justify-content-between'>
									<div>Total Number Of Row {numberofPage}</div><Pagination
										className="pagination-bar"
										currentPage={currentPage}
										totalCount={numberofPage || 1}
										pageSize={PageSize}
										onPageChange={(page) => setCurrentPage(page)}
									/></div></>
							</div>
						</div>
					</div>


				</div>
			</section>
		</>
	);
}
