import axios from 'axios'

const isProduction = process.env.NODE_ENV === "production";
const url=isProduction?`${process.env.REACT_APP_BASE_URL}/api`:'http://localhost:5001/api'
const loginurl=isProduction?process.env.REACT_APP_BASE_URL:'http://localhost:5001'
// const loginurl='http://localhost:5001'
// const url='http://localhost:5001/api'
const token=window.localStorage.getItem('token')

const config = () => ({
    headers: {
        Authorization: window.localStorage.getItem('token'),
        Accept: 'application/json',
        'Content-Type': 'application/json',
    },
});

export const LoginUser=async(info)=>{
    try {
const res=await axios.post(`${loginurl}/loginuser`,info)
return { success: true, data: res.data }
    }catch (e) {
        return { success: false, msg: "Something went wrong",err:e};
    }
}

export const addUsersLogin=async(info)=>{
    try {
const res=await axios.post(`${url}/addUsersLogin`,info,config())
return { success: true, data: res.data }
    }catch (e) {
        return { success: false, msg: "Something went wrong",err:e};
    }
}

export const getUsers = async (isfilter,roles,user_id) => {
    try {
        const res = await axios.get(`${url}/getUsers?isfilter=${isfilter}&roles=${roles}&user_id=${user_id}`, config())
return { success: true, data: res.data }
    }catch (e) {
        return { success: false, msg: "Something went wrong",err:e};
    }
}

export const updateUser=async(userId,info)=>{
    try {
const res=await axios.put(`${url}/updateUser/${userId}`,info,config())
return { success: true, msg: "Successfully Added"}
    }catch (e) {
        return { success: false, msg: "Something went wrong",err:e};
    }
}

export const getAccount=async(info)=>{
    try {
const res=await axios.get(`${url}/getAccount`,config())
return { success: true, data: res.data }
    }catch (e) {
        return { success: false, msg: "Something went wrong",err:e};
    }
}
export const getArea=async(info)=>{
    try {
const res=await axios.get(`${url}/getArea?search=${info?.search}&CATEGORY0=${info?.CATEGORY0}&CATEGORY1=${info?.CATEGORY1}&CATEGORY2=${info?.CATEGORY2}&CATEGORY3=${info?.CATEGORY3}&CATEGORY4=${info?.CATEGORY4}&roleSerach=${info?.roleSerach}`,config())
return { success: true, data: res.data }
    }catch (e) {
        return { success: false, msg: "Something went wrong",err:e};
    }
}

export const getCircle=async(info)=>{
    try {
const res=await axios.get(`${url}/getCircle?search=${info?.search}&CATEGORY0=${info?.CATEGORY0}&CATEGORY1=${info?.CATEGORY1}&roleSerach=${info?.roleSerach}`,config())
return { success: true, data: res.data }
    }catch (e) {
        return { success: false, msg: "Something went wrong",err:e};
    }
}

export const getDivision=async(info)=>{
    try {
const res=await axios.get(`${url}/getDivision?search=${info?.search}&CATEGORY0=${info?.CATEGORY0}&CATEGORY1=${info?.CATEGORY1}&CATEGORY2=${info?.CATEGORY2}&roleSerach=${info?.roleSerach}`,config())
return { success: true, data: res.data }
    }catch (e) {
        return { success: false, msg: "Something went wrong",err:e};
    }
}

export const getGa=async(info)=>{
    try {
const res=await axios.get(`${url}/getGa?search=${info?.search}&CATEGORY0=${info?.CATEGORY0}&roleSerach=${info?.roleSerach}`,config())
return { success: true, data: res.data }
    }catch (e) {
        return { success: false, msg: "Something went wrong",err:e};
    }
}

export const getSubdevision=async(info)=>{
    try {
const res=await axios.get(`${url}/getSubdevision?search=${info?.search}&CATEGORY0=${info?.CATEGORY0}&CATEGORY1=${info?.CATEGORY1}&CATEGORY2=${info?.CATEGORY2}&CATEGORY3=${info?.CATEGORY3}&roleSerach=${info?.roleSerach}`,config())
return { success: true, data: res.data }
    }catch (e) {
        return { success: false, msg: "Something went wrong",err:e};
    }
}

export const InsertAccount=async(info)=>{
    try {
const res=await axios.post(`${url}/InsertAccount`,info,config())
return { success: true, data: res.data }
    }catch (e) {
        return { success: false, msg: "Something went wrong",err:e};
    }
}

export const UpdateAccount=async(info,id)=>{
    try {
        const res=await axios.put(`${url}/UpdateAccount/${id}`,info,config())
        return { success: true, data: res.data }
            }catch (e) {
                return { success: false, msg: "Something went wrong",err:e};
            }
}

export const InsertGa=async(info)=>{
    try {
const res=await axios.post(`${url}/InsertGa`,info,config())
return { success: true, data: res.data }
    }catch (e) {
        return { success: false, msg: "Something went wrong",err:e};
    }
}

export const UpdateGa=async(info,id)=>{
    try {
        const res=await axios.put(`${url}/UpdateGa/${id}`,info,config())
        return { success: true, data: res.data }
            }catch (e) {
                return { success: false, msg: "Something went wrong",err:e};
            }
}

export const InsertCircle=async(info)=>{
    try {
const res=await axios.post(`${url}/InsertCircle`,info,config())
return { success: true, data: res.data }
    }catch (e) {
        return { success: false, msg: "Something went wrong",err:e};
    }
}

export const UpdateCircle=async(info,id)=>{
    try {
        const res=await axios.put(`${url}/UpdateCircle/${id}`,info,config())
        return { success: true, data: res.data }
            }catch (e) {
                return { success: false, msg: "Something went wrong",err:e};
            }
}

export const InsertDivision=async(info)=>{
    try {
const res=await axios.post(`${url}/InsertDivision`,info,config())
return { success: true, data: res.data }
    }catch (e) {
        return { success: false, msg: "Something went wrong",err:e};
    }
}

export const UpdateDivision=async(info,id)=>{
    try {
        const res=await axios.put(`${url}/UpdateDivision/${id}`,info,config())
        return { success: true, data: res.data }
            }catch (e) {
                return { success: false, msg: "Something went wrong",err:e};
            }
}

export const InsertSubDevision=async(info)=>{
    try {
const res=await axios.post(`${url}/InsertSubDevision`,info,config())
return { success: true, data: res.data }
    }catch (e) {
        return { success: false, msg: "Something went wrong",err:e};
    }
}

export const UpdateSubDivision=async(info,id)=>{
    try {
        const res=await axios.put(`${url}/UpdateSubDivision/${id}`,info,config())
        return { success: true, data: res.data }
            }catch (e) {
                return { success: false, msg: "Something went wrong",err:e};
            }
}

export const InsertArea=async(info)=>{
    try {
const res=await axios.post(`${url}/InsertArea`,info,config())
return { success: true, data: res.data }
    }catch (e) {
        return { success: false, msg: "Something went wrong",err:e};
    }
}

export const UpdateArea=async(info,id)=>{
    try {
        const res=await axios.put(`${url}/UpdateArea/${id}`,info,config())
        return { success: true, data: res.data }
            }catch (e) {
                return { success: false, msg: "Something went wrong",err:e};
            }
}

export const getAllModem=async(info)=>{
    try {
const res=await axios.get(`${url}/getAllModem`,config())
return { success: true, data: res.data }
    }catch (e) {
        return { success: false, msg: "Something went wrong",err:e};
    }
}

export const getModemFirmware=async(info)=>{
    try {
const res=await axios.get(`${url}/getModemFirmware`,config())
return { success: true, data: res.data }
    }catch (e) {
        return { success: false, msg: "Something went wrong",err:e};
    }
}

export const getModemHardwareVersion=async(info)=>{
    try {
const res=await axios.get(`${url}/getModemHardwareVersion`,config())
return { success: true, data: res.data }
    }catch (e) {
        return { success: false, msg: "Something went wrong",err:e};
    }
}

export const getModemMake=async(info)=>{
    try {
const res=await axios.get(`${url}/getModemMake`,config())
return { success: true, data: res.data }
    }catch (e) {
        return { success: false, msg: "Something went wrong",err:e};
    }
}

export const getModemModelNumber=async(info)=>{
    try {
const res=await axios.get(`${url}/getModemModelNumber`,config())
return { success: true, data: res.data }
    }catch (e) {
        return { success: false, msg: "Something went wrong",err:e};
    }
}

export const getModemProductCode=async(info)=>{
    try {
const res=await axios.get(`${url}/getModemProductCode`,config())
return { success: true, data: res.data }
    }catch (e) {
        return { success: false, msg: "Something went wrong",err:e};
    }
}

export const getModemType=async(info)=>{
    try {
const res=await axios.get(`${url}/getModemType`,config())
return { success: true, data: res.data }
    }catch (e) {
        return { success: false, msg: "Something went wrong",err:e};
    }
}

export const InsertFirmware=async(info)=>{
    try {
        const res=await axios.post(`${url}/InsertFirmware`,info,config())
        return { success: true, data: res.data }
            }catch (e) {
                return { success: false, msg: "Something went wrong",err:e};
            }
}

export const UpdateFirmware=async(info,id)=>{
    try {
        const res=await axios.put(`${url}/UpdateFirmware/${id}`,info,config())
        return { success: true, data: res.data }
            }catch (e) {
                return { success: false, msg: "Something went wrong",err:e};
            }
}

export const Insertmodem_hardware_version=async(info)=>{
    try {
        const res=await axios.post(`${url}/Insertmodem_hardware_version`,info,config())
        return { success: true, data: res.data }
            }catch (e) {
                return { success: false, msg: "Something went wrong",err:e};
            }
}

export const Updatemodem_hardware_version=async(info,id)=>{
    try {
        const res=await axios.put(`${url}/Updatemodem_hardware_version/${id}`,info,config())
        return { success: true, data: res.data }
            }catch (e) {
                return { success: false, msg: "Something went wrong",err:e};
            }
}

export const Insertmodem_model_number=async(info)=>{
    try {
        const res=await axios.post(`${url}/Insertmodem_model_number`,info,config())
        return { success: true, data: res.data }
            }catch (e) {
                return { success: false, msg: "Something went wrong",err:e};
            }
}

export const Updatemodem_model_number=async(info,id)=>{
    try {
        const res=await axios.put(`${url}/Updatemodem_model_number/${id}`,info,config())
        return { success: true, data: res.data }
            }catch (e) {
                return { success: false, msg: "Something went wrong",err:e};
            }
}

export const Insertmodem_make=async(info)=>{
    try {
        const res=await axios.post(`${url}/Insertmodem_make`,info,config())
        return { success: true, data: res.data }
            }catch (e) {
                return { success: false, msg: "Something went wrong",err:e};
            }
}

export const Updatemodem_make=async(info,id)=>{
    try {
        const res=await axios.put(`${url}/Updatemodem_make/${id}`,info,config())
        return { success: true, data: res.data }
            }catch (e) {
                return { success: false, msg: "Something went wrong",err:e};
            }
}
export const Insertmodem_product_code=async(info)=>{
    try {
        const res=await axios.post(`${url}/Insertmodem_product_code`,info,config())
        return { success: true, data: res.data }
            }catch (e) {
                return { success: false, msg: "Something went wrong",err:e};
            }
}

export const Updatemodem_product_code=async(info,id)=>{
    try {
        const res=await axios.put(`${url}/Updatemodem_product_code/${id}`,info,config())
        return { success: true, data: res.data }
            }catch (e) {
                return { success: false, msg: "Something went wrong",err:e};
            }
}

export const Insertmodem_type=async(info)=>{
    try {
        const res=await axios.post(`${url}/Insertmodem_type`,info,config())
        return { success: true, data: res.data }
            }catch (e) {
                return { success: false, msg: "Something went wrong",err:e};
            }
}

export const Updatemodem_type=async(info,id)=>{
    try {
        const res=await axios.put(`${url}/Updatemodem_type/${id}`,info,config())
        return { success: true, data: res.data }
            }catch (e) {
                return { success: false, msg: "Something went wrong",err:e};
            }
}

export const Insertmodem_index=async(info)=>{
    try {
        const res=await axios.post(`${url}/Insertmodem_index`,info,config())
        return { success: true, data: res.data }
            }catch (e) {
                return { success: false, msg: "Something went wrong",err:e};
            }
}

export const Updatemodem_index=async(info,id)=>{
    try {
        const res=await axios.put(`${url}/Updatemodem_index/${id}`,info,config())
        return { success: true, data: res.data }
            }catch (e) {
                return { success: false, msg: "Something went wrong",err:e};
            }
}

export const getAllMeter=async(info)=>{
    try {
const res=await axios.get(`${url}/getAllMeter`,config())
return { success: true, data: res.data }
    }catch (e) {
        return { success: false, msg: "Something went wrong",err:e};
    }
}

export const getmeter_category=async(info)=>{
    try {
const res=await axios.get(`${url}/getmeter_category`,config())
return { success: true, data: res.data }
    }catch (e) {
        return { success: false, msg: "Something went wrong",err:e};
    }
}

export const getmeter_class=async(info)=>{
    try {
const res=await axios.get(`${url}/getmeter_class`,config())
return { success: true, data: res.data }
    }catch (e) {
        return { success: false, msg: "Something went wrong",err:e};
    }
}
export const getmeter_firmware=async(info)=>{
    try {
const res=await axios.get(`${url}/getmeter_firmware`,config())
return { success: true, data: res.data }
    }catch (e) {
        return { success: false, msg: "Something went wrong",err:e};
    }
}

export const getmeter_hardware_protocol=async(info)=>{
    try {
const res=await axios.get(`${url}/getmeter_hardware_protocol`,config())
return { success: true, data: res.data }
    }catch (e) {
        return { success: false, msg: "Something went wrong",err:e};
    }
}

export const getmeter_make=async(info)=>{
    try {
const res=await axios.get(`${url}/getmeter_make`,config())
return { success: true, data: res.data }
    }catch (e) {
        return { success: false, msg: "Something went wrong",err:e};
    }
}

export const getmeter_model_number=async(info)=>{
    try {
const res=await axios.get(`${url}/getmeter_model_number`,config())
return { success: true, data: res.data }
    }catch (e) {
        return { success: false, msg: "Something went wrong",err:e};
    }
}

export const getmeter_software_protocol=async(info)=>{
    try {
const res=await axios.get(`${url}/getmeter_software_protocol`,config())
return { success: true, data: res.data }
    }catch (e) {
        return { success: false, msg: "Something went wrong",err:e};
    }
}

export const getmeter_type=async(info)=>{
    try {
const res=await axios.get(`${url}/getmeter_type`,config())
return { success: true, data: res.data }
    }catch (e) {
        return { success: false, msg: "Something went wrong",err:e};
    }
} 

export const Insertmeter_category=async(info)=>{
    try {
        const res=await axios.post(`${url}/Insertmeter_category`,info,config())
        return { success: true, data: res.data }
            }catch (e) {
                return { success: false, msg: "Something went wrong",err:e};
            }
}

export const Updatemeter_category=async(info,id)=>{
    try {
        const res=await axios.put(`${url}/Updatemeter_category/${id}`,info,config())
        return { success: true, data: res.data }
            }catch (e) {
                return { success: false, msg: "Something went wrong",err:e};
            }
}

export const Insertmeter_class=async(info)=>{
    try {
        const res=await axios.post(`${url}/Insertmeter_class`,info,config())
        return { success: true, data: res.data }
            }catch (e) {
                return { success: false, msg: "Something went wrong",err:e};
            }
}

export const Updatemmeter_class=async(info,id)=>{
    try {
        const res=await axios.put(`${url}/Updatemmeter_class/${id}`,info,config())
        return { success: true, data: res.data }
            }catch (e) {
                return { success: false, msg: "Something went wrong",err:e};
            }
}
export const Insertmeter_make=async(info)=>{
    try {
        const res=await axios.post(`${url}/Insertmeter_make`,info,config())
        return { success: true, data: res.data }
            }catch (e) {
                return { success: false, msg: "Something went wrong",err:e};
            }
}

export const Updatemeter_make=async(info,id)=>{
    try {
        const res=await axios.put(`${url}/Updatemeter_make/${id}`,info,config())
        return { success: true, data: res.data }
            }catch (e) {
                return { success: false, msg: "Something went wrong",err:e};
            }
}

export const Insertmeter_model_number=async(info)=>{
    try {
        const res=await axios.post(`${url}/Insertmeter_model_number`,info,config())
        return { success: true, data: res.data }
            }catch (e) {
                return { success: false, msg: "Something went wrong",err:e};
            }
}

export const Updatemeter_model_number=async(info,id)=>{
    try {
        const res=await axios.put(`${url}/Updatemeter_model_number/${id}`,info,config())
        return { success: true, data: res.data }
            }catch (e) {
                return { success: false, msg: "Something went wrong",err:e};
            }
}

export const Insertmeter_firmware=async(info)=>{
    try {
        const res=await axios.post(`${url}/Insertmeter_firmware`,info,config())
        return { success: true, data: res.data }
            }catch (e) {
                return { success: false, msg: "Something went wrong",err:e};
            }
}

export const Updatemeter_firmware=async(info,id)=>{
    try {
        const res=await axios.put(`${url}/Updatemeter_firmware/${id}`,info,config())
        return { success: true, data: res.data }
            }catch (e) {
                return { success: false, msg: "Something went wrong",err:e};
            }
}

export const Insertmeter_hardware_protocol=async(info)=>{
    try {
        const res=await axios.post(`${url}/Insertmeter_hardware_protocol`,info,config())
        return { success: true, data: res.data }
            }catch (e) {
                return { success: false, msg: "Something went wrong",err:e};
            }
}

export const Updatemeter_hardware_protocol=async(info,id)=>{
    try {
        const res=await axios.put(`${url}/Updatemeter_hardware_protocol/${id}`,info,config())
        return { success: true, data: res.data }
            }catch (e) {
                return { success: false, msg: "Something went wrong",err:e};
            }
}

export const Insertmeter_software_protocol=async(info)=>{
    try {
        const res=await axios.post(`${url}/Insertmeter_software_protocol`,info,config())
        return { success: true, data: res.data }
            }catch (e) {
                return { success: false, msg: "Something went wrong",err:e};
            }
}

export const Updatemeter_software_protocol=async(info,id)=>{
    try {
        const res=await axios.put(`${url}/Updatemeter_software_protocol/${id}`,info,config())
        return { success: true, data: res.data }
            }catch (e) {
                return { success: false, msg: "Something went wrong",err:e};
            }
}

export const Insertmeter_type=async(info)=>{
    try {
        const res=await axios.post(`${url}/Insertmeter_type`,info,config())
        return { success: true, data: res.data }
            }catch (e) {
                return { success: false, msg: "Something went wrong",err:e};
            }
}

export const Updatemmeter_type=async(info,id)=>{
    try {
        const res=await axios.put(`${url}/Updatemmeter_type/${id}`,info,config())
        return { success: true, data: res.data }
            }catch (e) {
                return { success: false, msg: "Something went wrong",err:e};
            }
}

export const Insertmeter_index=async(info)=>{
    try {
        const res=await axios.post(`${url}/Insertmeter_index`,info,config())
        return { success: true, data: res.data }
            }catch (e) {
                return { success: false, msg: "Something went wrong",err:e};
            }
}

export const Updatemeter_index=async(info,id)=>{
    try {
        const res=await axios.put(`${url}/Updatemeter_index/${id}`,info,config())
        return { success: true, data: res.data }
            }catch (e) {
                return { success: false, msg: "Something went wrong",err:e};
            }
}

export const InsertScriptdecodeId=async(info)=>{
    try {
        const res=await axios.post(`${url}/InsertScriptdecodeId`,info,config())
        return { success: true, data: res.data }
            }catch (e) {
                return { success: false, msg: "Something went wrong",err:e};
            }
}

export const UpdatScriptdecodeId=async(info,id)=>{
    try {
        const res=await axios.put(`${url}/UpdatScriptdecodeId/${id}`,info,config())
        return { success: true, data: res.data }
            }catch (e) {
                return { success: false, msg: "Something went wrong",err:e};
            }
}

export const getScriptdecodeId=async(info)=>{
    try {
const res=await axios.get(`${url}/getScriptdecodeId`,config())
return { success: true, data: res.data }
    }catch (e) {
        return { success: false, msg: "Something went wrong",err:e};
    }
}

export const getAllRoles=async(isfilter,roles,user_id)=>{
    try {
const res=await axios.get(`${url}/getAllRoles?isfilter=${isfilter}&roles=${roles}&user_id=${user_id}`,config())
return { success: true, data: res.data }
    }catch (e) {
        return { success: false, msg: "Something went wrong",err:e};
    }
}

export const getModemByID=async(id)=>{
    try {
const res=await axios.get(`${url}/getModemByID/${id}`,config())
return { success: true, data: res.data }
    }catch (e) {
        return { success: false, msg: "Something went wrong",err:e};
    }
}

export const getMeterByID=async(id)=>{
    try {
const res=await axios.get(`${url}/getMeterByID/${id}`,config())
return { success: true, data: res.data }
    }catch (e) {
        return { success: false, msg: "Something went wrong",err:e};
    }
}

export const InsertGsmData=async(info)=>{
    try {
        const res=await axios.post(`${url}/InsertGsmData`,info,config())
        return { success: true, data: res.data }
            }catch (e) {
                return { success: false, msg: "Something went wrong",err:e};
            }
}

export const getGSMData=async(info,data)=>{
    try {
        console.log(data);
const res=await axios.get(`${url}/getGSMData?start=${info?.start}&end=${info.end}`,{
    method: 'GET',
    params: data,
    headers: {
        Authorization: window.localStorage.getItem('token'),
        Accept: 'application/json',
        'Content-Type': 'application/json',
    }
})
return { success: true, data: res.data }
    }catch (e) {
        return { success: false, msg: "Something went wrong",err:e};
    }
}

export const getGSMDataById=async(id)=>{
    try {
const res=await axios.get(`${url}/getGSMDataById/${id}`,config())
return { success: true, data: res.data }
    }catch (e) {
        return { success: false, msg: "Something went wrong",err:e};
    }
}

export const UpdateInGSMmodem=async(info,id)=>{
    try {
        const res=await axios.put(`${url}/UpdateInGSMmodem/${id}`,info,config())
        return { success: true, data: res.data }
            }catch (e) {
                return { success: false, msg: "Something went wrong",err:e};
            }
}

export const getAreaById=async(id)=>{
    try {
const res=await axios.get(`${url}/getAreaById/${id}`,config())
return { success: true, data: res.data }
    }catch (e) {
        return { success: false, msg: "Something went wrong",err:e};
    }
}

export const getCircleById=async(id)=>{
    try {
const res=await axios.get(`${url}/getCircleById/${id}`,config())
return { success: true, data: res.data }
    }catch (e) {
        return { success: false, msg: "Something went wrong",err:e};
    }
}

export const getDivisionById=async(id)=>{
    try {
const res=await axios.get(`${url}/getDivisionById/${id}`,config())
return { success: true, data: res.data }
    }catch (e) {
        return { success: false, msg: "Something went wrong",err:e};
    }
}

export const getGaById=async(id)=>{
    try {
const res=await axios.get(`${url}/getGaById/${id}`,config())
return { success: true, data: res.data }
    }catch (e) {
        return { success: false, msg: "Something went wrong",err:e};
    }
}

export const getSubdevisionById=async(id)=>{
    try {
const res=await axios.get(`${url}/getSubdevisionById/${id}`,config())
return { success: true, data: res.data }
    }catch (e) {
        return { success: false, msg: "Something went wrong",err:e};
    }
}

export const InsertsRoles=async(info)=>{
    try {
const res=await axios.post(`${url}/InsertsRoles`,info,config())
return { success: true, data: res.data }
    }catch (e) {
        return { success: false, msg: "Something went wrong",err:e};
    }
}

export const UpdateRoles=async(info,id)=>{
    try {
const res=await axios.put(`${url}/UpdateRoles/${id}`,info,config())
return { success: true, data: res.data }
    }catch (e) {
        return { success: false, msg: "Something went wrong",err:e};
    }
}

export const getRolesById=async(id)=>{
    try {
const res=await axios.get(`${url}/getRolesById/${id}`,config())
return { success: true, data: res.data }
    }catch (e) {
        return { success: false, msg: "Something went wrong",err:e};
    }
}

export const activeandinactive=async(info,id)=>{
    try {
const res=await axios.post(`${url}/activeandinactive/${id}`,info,config())
return { success: true, data: res.data }
    }catch (e) {
        return { success: false, msg: "Something went wrong",err:e};
    }
}

export const CheckUseConsumer=async(info)=>{
    try {
const res=await axios.post(`${url}/CheckUseConsumer`,info,config())
return { success: true, data: res.data }
    }catch (e) {
        return { success: false, msg: "Something went wrong",err:e};
    }
}


export const Insertservice_provider_index=async(info)=>{
    try {
const res=await axios.post(`${url}/Insertservice_provider_index`,info,config())
return { success: true, data: res.data }
    }catch (e) {
        return { success: false, msg: "Something went wrong",err:e};
    }
}

export const Updateservice_provider_index=async(info,id)=>{
    try {
const res=await axios.put(`${url}/Updateservice_provider_index/${id}`,info,config())
return { success: true, data: res.data }
    }catch (e) {
        return { success: false, msg: "Something went wrong",err:e};
    }
}

export const getServiceProfile=async(id)=>{
    try {
const res=await axios.get(`${url}/getServiceProfile`,config())
return { success: true, data: res.data }
    }catch (e) {
        return { success: false, msg: "Something went wrong",err:e};
    }
}


export const UpdateInLorawanmodem=async(info,id)=>{
    try {
const res=await axios.put(`${url}/UpdateInLorawanmodem/${id}`,info,config())
return { success: true, data: res.data }
    }catch (e) {
        return { success: false, msg: "Something went wrong",err:e};
    }
}


export const getLorawanDataById=async(id)=>{
    try {
const res=await axios.get(`${url}/getLorawanDataById/${id}`,config())
return { success: true, data: res.data }
    }catch (e) {
        return { success: false, msg: "Something went wrong",err:e};
    }
}

export const gsmModemSerchData=async(info)=>{
    try {
const res=await axios.post(`${url}/gsmModemSerchData`,info,config())
return { success: true, data: res.data }
    }catch (e) {
        return { success: false, msg: "Something went wrong",err:e};
    }
}

export const getHeardbeatData=async(info,data)=>{
    try {
const res=await axios.post(`${url}/getHeardbeatData?start=${info?.start}&end=${info.end}`,data,config())
return { success: true, data: res.data }
    }catch (e) {
        return { success: false, msg: "Something went wrong",err:e};
    }
}

export const getAllGSMChartByDate=async(info)=>{
    try {
const res=await axios.post(`${url}/getAllGSMChartByDate`,info,config())
return { success: true, data: res.data }
    }catch (e) {
        return { success: false, msg: "Something went wrong",err:e};
    }
}

export const getCountStatusByGa=async(info)=>{
    try {
const res=await axios.post(`${url}/getCountStatusByGa`,info,config())
return { success: true, data: res.data }
    }catch (e) {
        return { success: false, msg: "Something went wrong",err:e};
    }
}

export const uploadsFiles = async (info, id) => {
    try {
        const res = await axios.post(`${url}/uploads/${id}`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const Taskuploads=async(info)=>{
    try {
const res=await axios.post(`${url}/Taskuploads`,info,config())
return { success: true, data: res.data }
    }catch (e) {
        return { success: false, msg: "Something went wrong",err:e};
    }
}

export const uploadsDeleteFile=async(info)=>{
    try {
const res=await axios.post(`${url}/uploadsDeleteFile`,info,config())
return { success: true, data: res.data }
    }catch (e) {
        return { success: false, msg: "Something went wrong",err:e};
    }
}

export const UserPhotouploads=async(info)=>{
    try {
const res=await axios.post(`${url}/UserPhotouploads`,info,config())
return { success: true, data: res.data }
    }catch (e) {
        return { success: false, msg: "Something went wrong",err:e};
    }
}

export const getBlobImages=async(info)=>{
    try {
const res=await axios.post(`${url}/getBlobImages`,info,config())
return { success: true, data: res.data }
    }catch (e) {
        return { success: false, msg: "Something went wrong",err:e};
    }
}

export const getGenretedAllGSMReport=async(info,data)=>{
    try {
const res=await axios(`${url}/getGenretedAllGSMReport`,{
    method: 'post',
    headers: {
        Authorization: window.localStorage.getItem('token'),
        Accept: 'application/json',
        'Content-Type': 'application/json',
    },
    data:{...info,CATEGORY1_view:data}
})
return { success: true, data: res.data }

    }catch (e) {
        return { success: false, msg: "Something went wrong",err:e};
    }
}

export const getReportBytypeName=async(UserId,roleId,type,info)=>{
    try {
const res=await axios.get(`${url}/getReportBytypeName/${UserId}?type=${type}&roleId=${roleId}&start=${info?.start}&end=${info?.end}`,config())
return { success: true, data: res.data }
    }catch (e) {
        return { success: false, msg: "Something went wrong",err:e};
    }
}

export const gsmModemFilterData=async(date,info)=>{
    try {
const res=await axios.post(`${url}/gsmModemFilterData?start=${date?.start}&end=${date.end}`,info,config())
return { success: true, data: res.data }
    }catch (e) {
        return { success: false, msg: "Something went wrong",err:e};
    }
}
export const getUsersByID=async(id)=>{
    try {
const res=await axios.get(`${url}/getUsersByID/${id}`,config())
return { success: true, data: res.data }
    }catch (e) {
        return { success: false, msg: "Something went wrong",err:e};
    }
}

export const updateUserPassword=async(id,info)=>{
    try {
const res=await axios.post(`${url}/updateUserPassword/${id}`,info,config())
return { success: true, data: res.data }
    }catch (e) {
        return { success: false, msg: "Something went wrong",err:e};
    }
}

export const MeterFileuploads=async(info)=>{
    try {
const res=await axios.post(`${url}/MeterFileuploads`,info,config())
return { success: true, data: res.data }
    }catch (e) {
        return { success: false, msg: "Something went wrong",err:e};
    }
}

export const UpdateComentAndHistory=async(info,id)=>{
    try {
const res=await axios.post(`${url}/UpdateComentAndHistory/${id}`,info,config())
return { success: true, data: res.data }
    }catch (e) {
        return { success: false, msg: "Something went wrong",err:e};
    }
}

export const getCommentAndHistoryById=async(info,id)=>{
    try {
const res=await axios.post(`${url}/getCommentAndHistoryById`,info,config())
return { success: true, data: res.data }
    }catch (e) {
        return { success: false, msg: "Something went wrong",err:e};
    }
}

export const getAttachmentBymeterid=async(id)=>{
    try {
const res=await axios.get(`${url}/getAttachmentBymeterid/${id}`,config())
return { success: true, data: res.data }
    }catch (e) {
        return { success: false, msg: "Something went wrong",err:e};
    }
}

export const GetTableNameWiseChartDataByDate=async(info,id)=>{
    try {
const res=await axios.post(`${url}/GetTableNameWiseChartDataByDate`,info,config())
return { success: true, data: res.data }
    }catch (e) {
        return { success: false, msg: "Something went wrong",err:e};
    }
}

export const LetestCountAndLastDateByTableName=async(info,id)=>{
    try {
const res=await axios.post(`${url}/LetestCountAndLastDateByTableName`,info,config())
return { success: true, data: res.data }
    }catch (e) {
        return { success: false, msg: "Something went wrong",err:e};
    }
}

export const InsertsTags=async(info,id)=>{
    try {
const res=await axios.post(`${url}/InsertsTags`,info,config())
return { success: true, data: res.data }
    }catch (e) {
        return { success: false, msg: "Something went wrong",err:e};
    }
}

export const getTags=async(id)=>{
    try {
const res=await axios.get(`${url}/getTags`,config())
return { success: true, data: res.data }
    }catch (e) {
        return { success: false, msg: "Something went wrong",err:e};
    }
}

export const UpdateTags=async(info,id)=>{
    try {
const res=await axios.put(`${url}/UpdateTags/${id}`,info,config())
return { success: true, data: res.data }
    }catch (e) {
        return { success: false, msg: "Something went wrong",err:e};
    }
}

export const getTagsInGsm=async(info)=>{
    try {
const res=await axios.post(`${url}/getTagsInGsm`,info,config())
return { success: true, data: res.data }
    }catch (e) {
        return { success: false, msg: "Something went wrong",err:e};
    }
}

export const UpdateTagsInGsm=async(info,id)=>{
    try {
const res=await axios.post(`${url}/UpdateTagsInGsm/${id}`,info,config())
return { success: true, data: res.data }
    }catch (e) {
        return { success: false, msg: "Something went wrong",err:e};
    }
}

export const getAllModemTotalGSM=async(info)=>{
    try {
const res=await axios.post(`${url}/getAllModemTotalGSM`,info,config())
return { success: true, data: res.data }
    }catch (e) {
        return { success: false, msg: "Something went wrong",err:e};
    }
}

export const getAllMeterTotalGSM=async(info)=>{
    try {
const res=await axios.post(`${url}/getAllMeterTotalGSM`,info,config())
return { success: true, data: res.data }
    }catch (e) {
        return { success: false, msg: "Something went wrong",err:e};
    }
}

export const getmodemFirmwarTotalGSM=async(info)=>{
    try {
const res=await axios.post(`${url}/getmodemFirmwarTotalGSM`,info,config())
return { success: true, data: res.data }
    }catch (e) {
        return { success: false, msg: "Something went wrong",err:e};
    }
}

export const getAllMapDataInGSM=async(info)=>{
    try {
const res=await axios.post(`${url}/getAllMapDataInGSM`,info,config())
return { success: true, data: res.data }
    }catch (e) {
        return { success: false, msg: "Something went wrong",err:e};
    }
}

export const getAllMapDataInGSMmapView=async(info)=>{
    try {
const res=await axios.post(`${url}/getAllMapDataInGSMmapView`,info,config())
return { success: true, data: res.data }
    }catch (e) {
        return { success: false, msg: "Something went wrong",err:e};
    }
}

export const onGetConsumerDetailsByColumn=async(info)=>{
    try {
const res=await axios.post(`${url}/onGetConsumerDetailsByColumn`,info,config())
return { success: true, data: res.data }
    }catch (e) {
        return { success: false, msg: "Something went wrong",err:e};
    }
}

export const getScriptCodeIdWiseColumnName=async(id)=>{
    try {
const res=await axios.get(`${url}/getScriptCodeIdWiseColumnName/${id}`,config())
return { success: true, data: res.data }
    }catch (e) {
        return { success: false, msg: "Something went wrong",err:e};
    }
}

export const getGSMBufferData=async(info,data)=>{
    try {
const res=await axios.get(`${url}/getGSMBufferData?start=${info?.start}&end=${info.end}`,config())
return { success: true, data: res.data }
    }catch (e) {
        return { success: false, msg: "Something went wrong",err:e};
    }
}

export const RetryGSMBuffer=async(id,tableName)=>{
    try {
const res=await axios.post(`${url}/RetryGSMBuffer/${id}/${tableName}`,{},config())
return { success: true, data: res.data }
    }catch (e) {
        return { success: false, msg: "Something went wrong",err:e};
    }
}

export const cancalGSMBuffer=async(id,tableName)=>{
    try {
const res=await axios.post(`${url}/cancalGSMBuffer/${id}/${tableName}`,{},config())
return { success: true, data: res.data }
    }catch (e) {
        return { success: false, msg: "Something went wrong",err:e};
    }
}

export const getGSMBufferDataAllCountByStatus=async(tableName)=>{
    try {
const res=await axios.get(`${url}/getGSMBufferDataAllCountByStatus/${tableName}`,config())
return { success: true, data: res.data }
    }catch (e) {
        return { success: false, msg: "Something went wrong",err:e};
    }
}

export const GetBuferCountInByStatus=async(id,tableName)=>{
    try {
const res=await axios.get(`${url}/GetBuferCountInByStatus/${id}/${tableName}`,config())
return { success: true, data: res.data }
    }catch (e) {
        return { success: false, msg: "Something went wrong",err:e};
    }
}

export const getScriptCodeIdWiseConfigretionData=async(id,tableName)=>{
    try {
const res=await axios.post(`${url}/getScriptCodeIdWiseConfigretionData/${id}`,tableName,config())
return { success: true, data: res.data }
    }catch (e) {
        return { success: false, msg: "Something went wrong",err:e};
    }
}

export const getConfigretionStatus=async(info)=>{
    try {
const res=await axios.post(`${url}/getConfigretionStatus`,info,config())
return { success: true, data: res.data }
    }catch (e) {
        return { success: false, msg: "Something went wrong",err:e};
    }
}

export const getGSMRSSIInDeshboardAllCountByStatus=async(info)=>{
    try {
const res=await axios.post(`${url}/getGSMRSSIInDeshboardAllCountByStatus`,info,config())
return { success: true, data: res.data }
    }catch (e) {
        return { success: false, msg: "Something went wrong",err:e};
    }
}

export const getGSMBufferInDeshboardAllCountByStatus=async(id,tableName)=>{
    try {
const res=await axios.get(`${url}/getGSMBufferInDeshboardAllCountByStatus/${id}/${tableName}`,config())
return { success: true, data: res.data }
    }catch (e) {
        return { success: false, msg: "Something went wrong",err:e};
    }
}

export const onGetConsumerDetailsByColumnBetween=async(info)=>{
    try {
const res=await axios.post(`${url}/onGetConsumerDetailsByColumnBetween`,info,config())
return { success: true, data: res.data }
    }catch (e) {
        return { success: false, msg: "Something went wrong",err:e};
    }
}

export const getGSMBufferDataByGSMID=async(info,id)=>{
    try {
const res=await axios.get(`${url}/getGSMBufferDataByGSMID/${id}?start=${info?.start}&end=${info.end}&tableName=${info.tableName}&info=${info?.info}`,config())
return { success: true, data: res.data }
    }catch (e) {
        return { success: false, msg: "Something went wrong",err:e};
    }
}

export const getStartAndEndDateByTable=async(info)=>{
    try {
const res=await axios.post(`${url}/getStartAndEndDateByTable`,info,config())
return { success: true, data: res.data }
    }catch (e) {
        return { success: false, msg: "Something went wrong",err:e};
    }
}

export const GetTableNameWiseChartDataByStartDateEndDate=async(info)=>{
    try {
const res=await axios.post(`${url}/GetTableNameWiseChartDataByStartDateEndDate`,info,config())
return { success: true, data: res.data }
    }catch (e) {
        return { success: false, msg: "Something went wrong",err:e};
    }
}

export const getRSSITotalGSM=async(info)=>{
    try {
const res=await axios.post(`${url}/getRSSITotalGSM`,info,config())
return { success: true, data: res.data }
    }catch (e) {
        return { success: false, msg: "Something went wrong",err:e};
    }
}

export const getGSMTotalCountByTS=async(info)=>{
    try {
const res=await axios.post(`${url}/getGSMTotalCountByTS`,info,config())
return { success: true, data: res.data }
    }catch (e) {
        return { success: false, msg: "Something went wrong",err:e};
    }
}


export const createTableByNameAndCol=async(info)=>{
    try {
const res=await axios.post(`${url}/createTableByNameAndCol`,info,config())
return { success: true, data: res.data }
    }catch (e) {
        return { success: false, msg: "Something went wrong",err:e};
    }
}
export const onGetTableName=async(id)=>{
    try {
const res=await axios.get(`${url}/onGetTableName`,config())
return { success: true, data: res.data }
    }catch (e) {
        return { success: false, msg: "Something went wrong",err:e};
    }
}

export const gsm_instantaneous_day_wise_graph_details=async(info)=>{
    try {
const res=await axios.post(`${url}/gsm_instantaneous_day_wise_graph_details`,info,config())
return { success: true, data: res.data }
    }catch (e) {
        return { success: false, msg: "Something went wrong",err:e};
    }
}

export const onModemConfigByGSMConsumer=async(info)=>{
    try {
const res=await axios.post(`${url}/onModemConfigByGSMConsumer`,info,config())
return { success: true, data: res.data }
    }catch (e) {
        return { success: false, msg: "Something went wrong",err:e};
    }
}

export const getConsumerWiseDataInScalerUnitBystatus=async(id)=>{
    try {
const res=await axios.get(`${url}/getConsumerWiseDataInScalerUnitBystatus/${id}`,config())
return { success: true, data: res.data }
    }catch (e) {
        return { success: false, msg: "Something went wrong",err:e};
    }
}

export const getConfigretionCMD_TYPEWiseData=async(info)=>{
    try {
const res=await axios.post(`${url}/getConfigretionCMD_TYPEWiseData`,info,config())
return { success: true, data: res.data }
    }catch (e) {
        return { success: false, msg: "Something went wrong",err:e};
    }
}

export const onGetConsumerIncolumeName=async(info)=>{
    try {
const res=await axios.post(`${url}/onGetConsumerIncolumeName`,info,config())
return { success: true, data: res.data }
    }catch (e) {
        return { success: false, msg: "Something went wrong",err:e};
    }
}

export const getmodemServiceProviderTotalGSM=async(info)=>{
    try {
        const res=await axios.post(`${url}/getmodemServiceProviderTotalGSM`,info,config())
        return { success: true, data: res.data }
            }catch (e) {
                return { success: false, msg: "Something went wrong",err:e};
            }
}

export const getTagsWiseConsumerData=async(info)=>{
    try {
        const res=await axios.post(`${url}/getTagsWiseConsumerData`,info,config())
        return { success: true, data: res.data }
            }catch (e) {
                return { success: false, msg: "Something went wrong",err:e};
            }
}

export const getConfigretionLetestData=async(info)=>{
    try {
const res=await axios.post(`${url}/getConfigretionLetestData`,info,config())
return { success: true, data: res.data }
    }catch (e) {
        return { success: false, msg: "Something went wrong",err:e};
    }
}

export const getGSMRejectedData=async(info)=>{
    try {
const res=await axios.post(`${url}/getGSMRejectedData`,info,config())
return { success: true, data: res.data }
    }catch (e) {
        return { success: false, msg: "Something went wrong",err:e};
    }
}

export const getmodemMeterConnectivityTotalGSM=async(info)=>{
    try {
        const res=await axios.post(`${url}/getmodemMeterConnectivityTotalGSM`,info,config())
        return { success: true, data: res.data }
            }catch (e) {
                return { success: false, msg: "Something went wrong",err:e};
            }
}

export const GetTableNameWiseChartRSSIDataByStartDateEndDate=async(info)=>{
    try {
const res=await axios.post(`${url}/GetTableNameWiseChartRSSIDataByStartDateEndDate`,info,config())
return { success: true, data: res.data }
    }catch (e) {
        return { success: false, msg: "Something went wrong",err:e};
    }
}

export const getmodemConnectivityTotalGSM=async(info)=>{
    try {
        const res=await axios.post(`${url}/getmodemConnectivityTotalGSM`,info,config())
        return { success: true, data: res.data }
            }catch (e) {
                return { success: false, msg: "Something went wrong",err:e};
            }
}

export const getGSM_BANDTotalGSM=async(info)=>{
    try {
        const res=await axios.post(`${url}/getGSM_BANDTotalGSM`,info,config())
        return { success: true, data: res.data }
            }catch (e) {
                return { success: false, msg: "Something went wrong",err:e};
            }
}

export const GetInstallationDateWiseGraphData=async(info)=>{
    try {
        const res=await axios.post(`${url}/GetInstallationDateWiseGraphData`,info,config())
        return { success: true, data: res.data }
            }catch (e) {
                return { success: false, msg: "Something went wrong",err:e};
            }
}

export const getAllGSMEventChartByDate=async(info)=>{
    try {
const res=await axios.post(`${url}/getAllGSMEventChartByDate`,info,config())
return { success: true, data: res.data }
    }catch (e) {
        return { success: false, msg: "Something went wrong",err:e};
    }
}

export const getAllGSMMonthlyChartByDate=async(info)=>{
    try {
const res=await axios.post(`${url}/getAllGSMMonthlyChartByDate`,info,config())
return { success: true, data: res.data }
    }catch (e) {
        return { success: false, msg: "Something went wrong",err:e};
    }
}

export const GetModemStatusByTable=async(info)=>{
    try {
const res=await axios.post(`${url}/GetModemStatusByTable`,info,config())
return { success: true, data: res.data }
    }catch (e) {
        return { success: false, msg: "Something went wrong",err:e};
    }
}

export const GetModemStatusDurationByTable=async(info)=>{
    try {
const res=await axios.post(`${url}/GetModemStatusDurationByTable`,info,config())
return { success: true, data: res.data }
    }catch (e) {
        return { success: false, msg: "Something went wrong",err:e};
    }
}

export const GetModemStatusDurationByTableAndDATE=async(info)=>{
    try {
const res=await axios.post(`${url}/GetModemStatusDurationByTableAndDATE`,info,config())
return { success: true, data: res.data }
    }catch (e) {
        return { success: false, msg: "Something went wrong",err:e};
    }
}

export const GetModemStatusByTableAndDate=async(info)=>{
    try {
const res=await axios.post(`${url}/GetModemStatusByTableAndDate`,info,config())
return { success: true, data: res.data }
    }catch (e) {
        return { success: false, msg: "Something went wrong",err:e};
    }
}

export const getScriptCodeIdWiseConfigretionQuery=async(info)=>{
    try {
const res=await axios.post(`${url}/getScriptCodeIdWiseConfigretionQuery`,info,config())
return { success: true, data: res.data }
    }catch (e) {
        return { success: false, msg: "Something went wrong",err:e};
    }
}

export const getAllGSMChartByDateSlection=async(info)=>{
    try {
const res=await axios.post(`${url}/getAllGSMChartByDateSlection`,info,config())
return { success: true, data: res.data }
    }catch (e) {
        return { success: false, msg: "Something went wrong",err:e};
    }
}

export const GetInstallationDateWiseGraphDataBySelectedDate=async(info)=>{
    try {
const res=await axios.post(`${url}/GetInstallationDateWiseGraphDataBySelectedDate`,info,config())
return { success: true, data: res.data }
    }catch (e) {
        return { success: false, msg: "Something went wrong",err:e};
    }
}

export const getIPVTotalINGSM=async(info)=>{
    try {
const res=await axios.post(`${url}/getIPVTotalINGSM`,info,config())
return { success: true, data: res.data }
    }catch (e) {
        return { success: false, msg: "Something went wrong",err:e};
    }
}

export const getAllGSMMonthlyChartBySelectedDate = async (info) => {
    try {
        const res = await axios.post(`${url}/getAllGSMMonthlyChartBySelectedDate`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}
export const getAllGSMEventChartBySelectedDate = async (info) => {
    try {
        const res = await axios.post(`${url}/getAllGSMEventChartBySelectedDate`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const reportCreacteInTableName = async (info) => {
    try {
        const res = await axios.post(`${url}/reportCreacteInTableName`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getConfigretionCMD_TYPEWisePendding_Data_buffer = async (info) => {
    try {
        const res = await axios.post(`${url}/getConfigretionCMD_TYPEWisePendding_Data_buffer`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getUsersList=async(info)=>{
    try {
const res=await axios.get(`${url}/getUsersList`,config())
return { success: true, data: res.data }
    }catch (e) {
        return { success: false, msg: "Something went wrong",err:e};
    }
}

export const getGSMConsumerList=async(info)=>{
    try {
const res=await axios.post(`${url}/getGSMConsumerList`,info,config())
return { success: true, data: res.data }
    }catch (e) {
        return { success: false, msg: "Something went wrong",err:e};
    }
}


export const InsertsTaskDetails = async (info) => {
    try {
        const res = await axios.post(`${url}/InsertsTaskDetails`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getAllTaskDetails=async(info)=>{
    try {
const res=await axios.post(`${url}/getAllTaskDetails`,info,config())
return { success: true, data: res.data }
    }catch (e) {
        return { success: false, msg: "Something went wrong",err:e};
    }
}

export const getAllTaskDetailsByUniqueId=async(id)=>{
    try {
const res=await axios.get(`${url}/getAllTaskDetailsByUniqueId/${id}`,config())
return { success: true, data: res.data }
    }catch (e) {
        return { success: false, msg: "Something went wrong",err:e};
    }
}

export const UpdateTaskDetails=async(info,id)=>{
    try {
const res=await axios.put(`${url}/UpdateTaskDetails/${id}`,info,config())
return { success: true, data: res.data }
    }catch (e) {
        return { success: false, msg: "Something went wrong",err:e};
    }
}

export const UpdateTaskDetailesComentAndHistory=async(info,id)=>{
    try {
const res=await axios.post(`${url}/UpdateTaskDetailesComentAndHistory/${id}`,info,config())
return { success: true, data: res.data }
    }catch (e) {
        return { success: false, msg: "Something went wrong",err:e};
    }
}

export const getTaskCommentAndHistoryById=async(info,id)=>{
    try {
const res=await axios.post(`${url}/getTaskCommentAndHistoryById`,info,config())
return { success: true, data: res.data }
    }catch (e) {
        return { success: false, msg: "Something went wrong",err:e};
    }
}

export const getEventCountList=async(info,id)=>{
    try {
const res=await axios.post(`${url}/getEventCountList`,info,config())
return { success: true, data: res.data }
    }catch (e) {
        return { success: false, msg: "Something went wrong",err:e};
    }
}

export const getErrorbinAndDuplicatbinCountList=async(info,id)=>{
    try {
const res=await axios.post(`${url}/getErrorbinAndDuplicatbinCountList`,info,config())
return { success: true, data: res.data }
    }catch (e) {
        return { success: false, msg: "Something went wrong",err:e};
    }
}

export const getCategoryNameByUniqId=async(info)=>{
    try {
const res=await axios.post(`${url}/getCategoryNameByUniqId`,info,config())
return { success: true, data: res.data }
    }catch (e) {
        return { success: false, msg: "Something went wrong",err:e};
    }
}
export const getProfilNameByAccountId=async(info)=>{
    try {
const res=await axios.post(`${url}/getProfilNameByAccountId`,info,config())
return { success: true, data: res.data }
    }catch (e) {
        return { success: false, msg: "Something went wrong",err:e};
    }
}

export const FilterIdWiseNewFilterGenerate=async(info)=>{
    try {
const res=await axios.post(`${url}/FilterIdWiseNewFilterGenerate`,info,config())
return { success: true, data: res.data }
    }catch (e) {
        return { success: false, msg: "Something went wrong",err:e};
    }
}

export const gsmModemColumanWiseSerchData=async(info)=>{
    try {
const res=await axios.post(`${url}/gsmModemColumanWiseSerchData`,info,config())
return { success: true, data: res.data }
    }catch (e) {
        return { success: false, msg: "Something went wrong",err:e};
    }
}

export const getAllTaskDetailsByConsumerId=async(info)=>{
    try {
const res=await axios.post(`${url}/getAllTaskDetailsByConsumerId`,info,config())
return { success: true, data: res.data }
    }catch (e) {
        return { success: false, msg: "Something went wrong",err:e};
    }
}

export const getConfigretionCMD_TYPEWisePendding_Count_buffer=async(info)=>{
    try {
const res=await axios.post(`${url}/getConfigretionCMD_TYPEWisePendding_Count_buffer`,info,config())
return { success: true, data: res.data }
    }catch (e) {
        return { success: false, msg: "Something went wrong",err:e};
    }
}

export const getHeatbeatStatusWiseCount=async(info)=>{
    try {
const res=await axios.post(`${url}/getHeatbeatStatusWiseCount`,info,config())
return { success: true, data: res.data }
    }catch (e) {
        return { success: false, msg: "Something went wrong",err:e};
    }
}

export const getModemConsumerDetails=async(info)=>{
    try {
const res=await axios.post(`${url}/getModemConsumerDetails`,info,config())
return { success: true, data: res.data }
    }catch (e) {
        return { success: false, msg: "Something went wrong",err:e};
    }
}

export const InsertModemDetails=async(info)=>{
    try {
const res=await axios.post(`${url}/InsertModemDetails`,info,config())
return { success: true, data: res.data }
    }catch (e) {
        return { success: false, msg: "Something went wrong",err:e};
    }
}

export const UpdateModemDetails=async(info,id)=>{
    try {
const res=await axios.post(`${url}/UpdateModemDetails/${id}`,info,config())
return { success: true, data: res.data }
    }catch (e) {
        return { success: false, msg: "Something went wrong",err:e};
    }
}

export const GetModemTypeWiseIMEI=async(id)=>{
    try {
const res=await axios.get(`${url}/GetModemTypeWiseIMEI/${id}`,config())
return { success: true, data: res.data }
    }catch (e) {
        return { success: false, msg: "Something went wrong",err:e};
    }
}

export const GetIMEIWiseModemData=async(id)=>{
    try {
const res=await axios.get(`${url}/GetIMEIWiseModemData/${id}`,config())
return { success: true, data: res.data }
    }catch (e) {
        return { success: false, msg: "Something went wrong",err:e};
    }
}

export const UpdateInGSMModemDataAndModemTable=async(info,id)=>{
    try {
const res=await axios.post(`${url}/UpdateInGSMModemDataAndModemTable/${id}`,info,config())
return { success: true, data: res.data }
    }catch (e) {
        return { success: false, msg: "Something went wrong",err:e};
    }
}

export const UpdateInGSMmeterDataAndmeterTable=async(info,id)=>{
    try {
const res=await axios.post(`${url}/UpdateInGSMmeterDataAndmeterTable/${id}`,info,config())
return { success: true, data: res.data }
    }catch (e) {
        return { success: false, msg: "Something went wrong",err:e};
    }
}

export const getCountUseToConsumerInIMEI=async(id,update)=>{
    try {
const res=await axios.get(`${url}/getCountUseToConsumerInIMEI/${id}?update=${update?.update}&ID=${update?.ID}`,config())
return { success: true, data: res.data }
    }catch (e) {
        return { success: false, msg: "Something went wrong",err:e};
    }
}

export const UpdateConsumerStatusInMaintanace=async(id)=>{
    try {
const res=await axios.get(`${url}/UpdateConsumerStatusInMaintanace/${id}`,config())
return { success: true, data: res.data }
    }catch (e) {
        return { success: false, msg: "Something went wrong",err:e};
    }
}

export const getHeatbeatStatusWiseCountInGroupDashboard=async(info)=>{
    try {
const res=await axios.post(`${url}/getHeatbeatStatusWiseCountInGroupDashboard`,info,config())
return { success: true, data: res.data }
    }catch (e) {
        return { success: false, msg: "Something went wrong",err:e};
    }
}


export const AddMeterDataINGSM=async(id,info)=>{
    try {
const res=await axios.post(`${url}/AddMeterDataINGSM/${id}`,info,config())
return { success: true, data: res.data }
    }catch (e) {
        return { success: false, msg: "Something went wrong",err:e};
    }
}

export const AddModemDataINGSM=async(id,info)=>{
    try {
const res=await axios.post(`${url}/AddModemDataINGSM/${id}`,info,config())
return { success: true, data: res.data }
    }catch (e) {
        return { success: false, msg: "Something went wrong",err:e};
    }
}

export const SyncScriptIdWiseTableColumn=async(info)=>{
    try {
const res=await axios.post(`${url}/SyncScriptIdWiseTableColumn`,info,config())
return { success: true, data: res.data }
    }catch (e) {
        return { success: false, msg: "Something went wrong",err:e};
    }
}

export const MeterConfigTableCreate=async(info)=>{
    try {
const res=await axios.post(`${url}/MeterConfigTableCreate`,info,config())
return { success: true, data: res.data }
    }catch (e) {
        return { success: false, msg: "Something went wrong",err:e};
    }
}


export const geModemIdWiseColumnName=async(id)=>{
    try {
const res=await axios.post(`${url}/geModemIdWiseColumnName/${id}`,{},config())
return { success: true, data: res.data }
    }catch (e) {
        return { success: false, msg: "Something went wrong",err:e};
    }
}

export const getConsumerOldHistoryTable=async(id)=>{
    try {
const res=await axios.get(`${url}/getConsumerOldHistoryTable/${id}`,config())
return { success: true, data: res.data }
    }catch (e) {
        return { success: false, msg: "Something went wrong",err:e};
    }
}

export const GetTableNameWiseChartInRelayonTime=async(info)=>{
    try {
const res=await axios.post(`${url}/GetTableNameWiseChartInRelayonTime`,info,config())
return { success: true, data: res.data }
    }catch (e) {
        return { success: false, msg: "Something went wrong",err:e};
    }
}

export const GetTableNameWiseChartInRelayonTimeByDate=async(info)=>{
    try {
const res=await axios.post(`${url}/GetTableNameWiseChartInRelayonTimeByDate`,info,config())
return { success: true, data: res.data }
    }catch (e) {
        return { success: false, msg: "Something went wrong",err:e};
    }
}

export const getConfigretionCMD_IDANDTYPEWiseData=async(info)=>{
    try {
const res=await axios.post(`${url}/getConfigretionCMD_IDANDTYPEWiseData`,info,config())
return { success: true, data: res.data }
    }catch (e) {
        return { success: false, msg: "Something went wrong",err:e};
    }
}

export const getConfigretionLetestDataByCmd_id=async(info)=>{
    try {
const res=await axios.post(`${url}/getConfigretionLetestDataByCmd_id`,info,config())
return { success: true, data: res.data }
    }catch (e) {
        return { success: false, msg: "Something went wrong",err:e};
    }
}

export const getIMEIWiseModemColumnName=async(IMEI)=>{
    try {
const res=await axios.get(`${url}/getIMEIWiseModemColumnName/${IMEI}`,config())
return { success: true, data: res.data }
    }catch (e) {
        return { success: false, msg: "Something went wrong",err:e};
    }
}

export const geScriptSRNOWiseColumnName=async(ID)=>{
    try {
const res=await axios.get(`${url}/geScriptSRNOWiseColumnName/${ID}`,config())
return { success: true, data: res.data }
    }catch (e) {
        return { success: false, msg: "Something went wrong",err:e};
    }
}

export const geturls3 = async (info) => {
    try {
        const res = await axios.post(`${url}/geturls3`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}


export const Update_Gsm_consumer_Attachments = async (info, id) => {
    try {
        const res = await axios.put(`${url}/Update_Gsm_consumer_Attachments/${id}`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const Updatemeter_index_attachments = async (id, info) => {
    try {
        const res = await axios.put(`${url}/Updatemeter_index_attachments/${id}`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getmeter_update_data = async (id, info) => {
    try {
        const res = await axios.get(`${url}/getmeter_update_data/${id}`, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getMSSQLlogs = async (info) => {
    try {
        const res = await axios.post(`${url}/getMSSQLlogs`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const GetMssqlCardDetails = async (type) => {
    try {
        const res = await axios.get(`${url}/GetMssqlCardDetails/${type}`, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const Get_consumer_data = async (info) => {
    try {
        const res = await axios.post(`${url}/Get_consumer_data`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const GetMssqlCardDetailsById = async (id) => {
    try {
        const res = await axios.get(`${url}/GetMssqlCardDetailsById/${id}`, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getGSMDataFilterDataIntegration = async (info) => {
    try {
        const res = await axios.post(`${url}/getGSMDataFilterDataIntegration`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const Insert_consumer_data = async (info,id) => {
    try {
        const res = await axios.post(`${url}/Insert_consumer_data/${id}`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}


export const InsertCardDetails = async (info) => {
    try {
        const res = await axios.post(`${url}/InsertCardDetails`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getLastInstantaneouseData = async (info) => {
    try {
        const res = await axios.post(`${url}/getLastInstantaneouseData`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const GetPrimaryConsumerListBySecondaryConsumer = async (info) => {
    try {
        const res = await axios.post(`${url}/GetPrimaryConsumerListBySecondaryConsumer`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const GetCheckPriorityConsumer = async (info) => {
    try {
        const res = await axios.post(`${url}/GetCheckPriorityConsumer`,info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getSecondaryConsumerById = async (id) => {
    try {
        const res = await axios.get(`${url}/getSecondaryConsumerById/${id}`, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getModemConsumerDetailsByFilter = async (info) => {
    try {
        const res = await axios.post(`${url}/getModemConsumerDetailsByFilter`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const DownloadModemConsumerDetailsByFilter = async (info) => {
    try {
        const res = await axios.post(`${url}/DownloadModemConsumerDetailsByFilter`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getModemSearchTextConsumerDetails = async (info) => {
    try {
        const res = await axios.post(`${url}/getModemSearchTextConsumerDetails`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const UpdateLorawanConsumerStatusInMaintanace = async (id) => {
    try {
        const res = await axios.get(`${url}/UpdateLorawanConsumerStatusInMaintanace/${id}`, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const saveFilterToDb = async (info) => {
    try {
        const res = await axios.post(`${url}/saveFilterToDb`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getFavoriteFilters = async (userId) => {
    console.log("userId", userId)
    try {
        const getFilters = await axios.get(`${url}/getFavoriteFilters/${userId}`, config())
        return { success: true, data: getFilters.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getTaskTag = async (status) => {
    try {
        const res = await axios.get(`${url}/getTaskTag?FilterStatus=${status}`, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getFilteredTaskDetails = async (filter) => {
    console.log("Filters Util = ", filter)
    try {
        const res = await axios.post(`${url}/getFilteredTaskDetails`, filter, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}


export const getUsersListForTask = async () => {
    try {
        const res = await axios.get(`${url}/getUsersListForTask`, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const insertTaskTag = async (data) => {
    try {
        const res = await axios.post(`${url}/insertTaskTag`, data, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const updateTaskTag = async (data) => {
    try {
        const res = await axios.post(`${url}/updateTaskTag`, data, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getFilteredConsumerTaskDetails = async (filter) => {
    console.log("Consumer Filters Util = ", filter)
    try {
        const res = await axios.post(`${url}/getFilteredConsumerTaskDetails`, filter, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getmodemDetailsById = async (id) => {
    try {
        const res = await axios.get(`${url}/getmodemDetailsById/${id}`, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}


export const SocketBaseValidUserCheck = async (info) => {
    try {
        const res = await axios.post(`${url}/SocketBaseValidUserCheck`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}


export const CheckValidUser = async (info) => {
    try {
        const res = await axios.post(`${url}/CheckValidUser`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getTotalConsumptionInMeterDashboard = async (info) => {
    try {
        const res = await axios.post(`${url}/getTotalConsumptionInMeterDashboard`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const gsm_daily_load_monitoring_day_wise_graph_details = async (filter) => {
    try {
        const res = await axios.post(`${url}/gsm_daily_load_monitoring_day_wise_graph_details`, filter, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const MonitoringScriptStatus=async()=>{
    try {
const res=await axios.get(`${url}/MonitoringScriptStatus`,config())
return { success: true, data: res.data }
    }catch (e) {
        return { success: false, msg: "Something went wrong",err:e};
    }
}

export const MeterDailyDataScripUpdate=async()=>{
    try {
const res=await axios.get(`${url}/MeterDailyDataScripUpdate`,config())
return { success: true, data: res.data }
    }catch (e) {
        return { success: false, msg: "Something went wrong",err:e};
    }
}

export const onGetMeterdashboardLetestTime=async()=>{
    try {
const res=await axios.get(`${url}/onGetMeterdashboardLetestTime`,config())
return { success: true, data: res.data }
    }catch (e) {
        return { success: false, msg: "Something went wrong",err:e};
    }
}

export const getTotalConsumptionInMeterDashboardBySelectedDate = async (info) => {
    try {
        const res = await axios.post(`${url}/getTotalConsumptionInMeterDashboardBySelectedDate`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getAllTableInfo = async () => {
    try {
        const res = await axios.get(`${url}/getAllTableInfo`, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getAttachmentList = async (data) => {
    try {
        const res = await axios.post(`${url}/getAttachmentList`, data, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}


export const syncFilesToDatabase = async (data) => {
    try {
        const res = await axios.post(`${url}/syncFilesToDatabase`, data, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const Table_Get_All_Country = async (info) => {
    try {
        const res = await axios.get(`${url}/Table_Get_All_Country`, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const Table_Get_All_State_by_Country = async (id) => {
    try {
        const res = await axios.get(`${url}/Table_Get_All_State_by_Country/${id}`, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const Table_Get_All_City_by_State = async (id) => {
    try {
        const res = await axios.get(`${url}/Table_Get_All_City_by_State/${id}`, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}


export const TableNameWiseLast24HoursData = async (data) => {
    try {
        const res = await axios.post(`${url}/TableNameWiseLast24HoursData`, data, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const TableNameWiseLast24HoursDataSelectedDate = async (data) => {
    try {
        const res = await axios.post(`${url}/TableNameWiseLast24HoursDataSelectedDate`, data, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const UpdateConsumerStatusHistory = async (data,id) => {
    try {
        const res = await axios.post(`${url}/UpdateConsumerStatusHistory/${id}`, data, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const GetConsumerStatusHistoryByConsumerId = async (id) => {
    try {
        const res = await axios.get(`${url}/GetConsumerStatusHistoryByConsumerId/${id}`, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const UpdateInGSMMeterData = async (info, id) => {
    try {
        const res = await axios.put(`${url}/UpdateInGSMMeterData/${id}`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}


export const onCreateMeterTableTringgerConfiguration = async (data) => {
    try {
        const res = await axios.post(`${url}/onCreateMeterTableTringgerConfiguration`, data, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getGSMMonthlyConsumption = async (data) => {
    try {
        const res = await axios.post(`${url}/getGSMMonthlyConsumption`, data, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getGSMMonthlyConsumptionByDate = async (data) => {
    try {
        const res = await axios.post(`${url}/getGSMMonthlyConsumptionByDate`, data, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getGSMDailyConsumptionByConsumerId = async (data) => {
    try {
        const res = await axios.post(`${url}/getGSMDailyConsumptionByConsumerId`, data, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const GetReportType = async (data) => {
    try {
        const res = await axios.post(`${url}/GetReportType`, data, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getGenreteGSMMRIReport = async (data) => {
    try {
        const res = await axios.post(`${url}/getGenreteGSMMRIReport`, data, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getGroupWiseConsumerStatusCount = async (data) => {
    try {
        const res = await axios.post(`${url}/getGroupWiseConsumerStatusCount`, data, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const SentAutoConfigData = async (data) => {
    try {
        const res = await axios.post(`${url}/SentAutoConfigData`, data, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const UpdateGsmWebConfigDetails = async (data,id) => {
    try {
        const res = await axios.post(`${url}/UpdateGsmWebConfigDetails/${id}`, data, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getNotificationList=async(info,data)=>{
    try {
const res=await axios.post(`${url}/getNotificationList?start=${info?.start}&end=${info.end}`,{},config())
return { success: true, data: res.data }
    }catch (e) {
        return { success: false, msg: "Something went wrong",err:e};
    }
}

export const getnotification_receiver=async(info,data)=>{
    try {
const res=await axios.post(`${url}/getnotification_receiver?start=${info?.start}&end=${info.end}`,{},config())
return { success: true, data: res.data }
    }catch (e) {
        return { success: false, msg: "Something went wrong",err:e};
    }
}

export const getalarm_template=async(info,data)=>{
    try {
const res=await axios.post(`${url}/getalarm_template?start=${info?.start}&end=${info.end}`,{},config())
return { success: true, data: res.data }
    }catch (e) {
        return { success: false, msg: "Something went wrong",err:e};
    }
}

export const getAll_Active_alarm_template=async(info,data)=>{
    try {
const res=await axios.get(`${url}/getAll_Active_alarm_template`,config())
return { success: true, data: res.data }
    }catch (e) {
        return { success: false, msg: "Something went wrong",err:e};
    }
}

export const GetCDFXmlDataInTable = async (data) => {
    try {
        const res = await axios.post(`${url}/GetCDFXmlDataInTable`, data, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const InsertNotificationReceiver = async (data) => {
    try {
        const res = await axios.post(`${url}/InsertNotificationReceiver`, data, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}


export const UpdateNotificationReceiver = async (data,id) => {
    try {
        const res = await axios.post(`${url}/UpdateNotificationReceiver/${id}`, data, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const ChangeStatusToPendding = async (id) => {
    try {
        const res = await axios.get(`${url}/ChangeStatusToPendding/${id}`, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getAllMapDataInGSMmapViewByFilter = async (info,tablenme) => {
    try {
        const res = await axios.post(`${url}/getAllMapDataInGSMmapViewByFilter`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const GetInstVoltAndCuruntData = async (info,tablenme) => {
    try {
        const res = await axios.post(`${url}/GetInstVoltAndCuruntData`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getUsersByGroups = async (id) => {
    try {
        const res = await axios.post(`${url}/getUsersByGroups/${id}`,{}, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getAllGroups = async (info) => {
    try {
        const res = await axios.post(`${url}/getAllGroups`,{}, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const createGroup = async (info) => {
    try {
        const res = await axios.post(`${url}/createGroup`,info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const updateGroup = async (info,id) => {
    try {
        const res = await axios.post(`${url}/updateGroup/${id}`,info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const updateUserGroupJunction = async (info,id) => {
    try {
        const res = await axios.post(`${url}/updateUserGroupJunction/${id}`,info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getGSMDailyConsumptionByConsumerIdAndSletedate = async (info,id) => {
    try {
        const res = await axios.post(`${url}/getGSMDailyConsumptionByConsumerIdAndSletedate`,info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const createWebTab = async (info,tablenme) => {
    try {
        const res = await axios.post(`${url}/createWebTab`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getAllWebTabs = async (info,tablenme) => {
    try {
        const res = await axios.post(`${url}/getAllWebTabs`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const updateWebTabs = async (info,id) => {
    try {
        const res = await axios.post(`${url}/updateWebTabs/${id}`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const LoraModemFilterData=async(date,info)=>{
    try {
const res=await axios.post(`${url}/LoraModemFilterData?start=${date?.start}&end=${date.end}`,info,config())
return { success: true, data: res.data }
    }catch (e) {
        return { success: false, msg: "Something went wrong",err:e};
    }
}

export const getLorawanData=async(info,data)=>{
    try {
        console.log(data);
const res=await axios.get(`${url}/getLorawanData?start=${info?.start}&end=${info.end}`,{
    method: 'GET',
    params: data,
    headers: {
        Authorization: window.localStorage.getItem('token'),
        Accept: 'application/json',
        'Content-Type': 'application/json',
    }
})
return { success: true, data: res.data }
    }catch (e) {
        return { success: false, msg: "Something went wrong",err:e};
    }
}

export const LoraModemColumanWiseSerchData=async(info)=>{
    try {
const res=await axios.post(`${url}/LoraModemColumanWiseSerchData`,info,config())
return { success: true, data: res.data }
    }catch (e) {
        return { success: false, msg: "Something went wrong",err:e};
    }
}

export const LoraModemSerchData=async(info)=>{
    try {
const res=await axios.post(`${url}/LoraModemSerchData`,info,config())
return { success: true, data: res.data }
    }catch (e) {
        return { success: false, msg: "Something went wrong",err:e};
    }
}

export const getLoraDataById=async(id)=>{
    try {
const res=await axios.get(`${url}/getLoraDataById/${id}`,config())
return { success: true, data: res.data }
    }catch (e) {
        return { success: false, msg: "Something went wrong",err:e};
    }
}

export const getLoraBufferInDeshboardAllCountByStatus=async(id,tableName)=>{
    try {
const res=await axios.get(`${url}/getLoraBufferInDeshboardAllCountByStatus/${id}/${tableName}`,config())
return { success: true, data: res.data }
    }catch (e) {
        return { success: false, msg: "Something went wrong",err:e};
    }
}

export const getLoraEventCountList=async(info,id)=>{
    try {
const res=await axios.post(`${url}/getLoraEventCountList`,info,config())
return { success: true, data: res.data }
    }catch (e) {
        return { success: false, msg: "Something went wrong",err:e};
    }
}

export const getLoraErrorbinAndDuplicatbinCountList=async(info,id)=>{
    try {
const res=await axios.post(`${url}/getLoraErrorbinAndDuplicatbinCountList`,info,config())
return { success: true, data: res.data }
    }catch (e) {
        return { success: false, msg: "Something went wrong",err:e};
    }
}

export const GetLoraTableNameWiseChartDataByDate=async(info,id)=>{
    try {
const res=await axios.post(`${url}/GetLoraTableNameWiseChartDataByDate`,info,config())
return { success: true, data: res.data }
    }catch (e) {
        return { success: false, msg: "Something went wrong",err:e};
    }
}

export const LetestLoraCountAndLastDateByTableName=async(info,id)=>{
    try {
const res=await axios.post(`${url}/LetestLoraCountAndLastDateByTableName`,info,config())
return { success: true, data: res.data }
    }catch (e) {
        return { success: false, msg: "Something went wrong",err:e};
    }
}

export const getLoraLastInstantaneouseData = async (info) => {
    try {
        const res = await axios.post(`${url}/getLoraLastInstantaneouseData`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const GetLoraBuferCountInByStatus=async(id,tableName)=>{
    try {
const res=await axios.get(`${url}/GetLoraBuferCountInByStatus/${id}/${tableName}`,config())
return { success: true, data: res.data }
    }catch (e) {
        return { success: false, msg: "Something went wrong",err:e};
    }
}

export const getRSSITotalLora=async(info)=>{
    try {
const res=await axios.post(`${url}/getRSSITotalLora`,info,config())
return { success: true, data: res.data }
    }catch (e) {
        return { success: false, msg: "Something went wrong",err:e};
    }
}

export const InsertLorawanData=async(info)=>{
    try {
        const res=await axios.post(`${url}/InsertLorawanData`,info,config())
        return { success: true, data: res.data }
            }catch (e) {
                return { success: false, msg: "Something went wrong",err:e};
            }
}

export const GetLoraPrimaryConsumerListBySecondaryConsumer = async (info) => {
    try {
        const res = await axios.post(`${url}/GetLoraPrimaryConsumerListBySecondaryConsumer`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const GetLoraCheckPriorityConsumer = async (info) => {
    try {
        const res = await axios.post(`${url}/GetLoraCheckPriorityConsumer`,info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getCountUseToConsumerInEUI=async(id,update)=>{
    try {
const res=await axios.get(`${url}/getCountUseToConsumerInEUI/${id}?update=${update?.update}&ID=${update?.ID}`,config())
return { success: true, data: res.data }
    }catch (e) {
        return { success: false, msg: "Something went wrong",err:e};
    }
}

export const GetEUIWiseModemData=async(id)=>{
    try {
const res=await axios.get(`${url}/GetEUIWiseModemData/${id}`,config())
return { success: true, data: res.data }
    }catch (e) {
        return { success: false, msg: "Something went wrong",err:e};
    }
}

export const AddModemDataINLora=async(id,info)=>{
    try {
const res=await axios.post(`${url}/AddModemDataINLora/${id}`,info,config())
return { success: true, data: res.data }
    }catch (e) {
        return { success: false, msg: "Something went wrong",err:e};
    }
}

export const getLoraModemConsumerDetails=async(info)=>{
    try {
const res=await axios.post(`${url}/getLoraModemConsumerDetails`,info,config())
return { success: true, data: res.data }
    }catch (e) {
        return { success: false, msg: "Something went wrong",err:e};
    }
}

export const getLoraModemConsumerDetailsByFilter = async (info) => {
    try {
        const res = await axios.post(`${url}/getLoraModemConsumerDetailsByFilter`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const InsertLoraModemDetails=async(info)=>{
    try {
const res=await axios.post(`${url}/InsertLoraModemDetails`,info,config())
return { success: true, data: res.data }
    }catch (e) {
        return { success: false, msg: "Something went wrong",err:e};
    }
}

export const UpdateLoraModemDetails=async(info,id)=>{
    try {
const res=await axios.post(`${url}/UpdateLoraModemDetails/${id}`,info,config())
return { success: true, data: res.data }
    }catch (e) {
        return { success: false, msg: "Something went wrong",err:e};
    }
}
 
export const GetModemTypeWiseEUI=async(id)=>{
    try {
const res=await axios.get(`${url}/GetModemTypeWiseEUI/${id}`,config())
return { success: true, data: res.data }
    }catch (e) {
        return { success: false, msg: "Something went wrong",err:e};
    }
}

export const getLoraModemSearchTextConsumerDetails = async (info) => {
    try {
        const res = await axios.post(`${url}/getLoraModemSearchTextConsumerDetails`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const UpdateInLoramodem=async(info,id)=>{
    try {
        const res=await axios.put(`${url}/UpdateInLoramodem/${id}`,info,config())
        return { success: true, data: res.data }
            }catch (e) {
                return { success: false, msg: "Something went wrong",err:e};
            }
}

export const UpdateInLoraModemDataAndModemTable=async(info,id)=>{
    try {
const res=await axios.post(`${url}/UpdateInLoraModemDataAndModemTable/${id}`,info,config())
return { success: true, data: res.data }
    }catch (e) {
        return { success: false, msg: "Something went wrong",err:e};
    }
}

export const getGroupWiseLoraConsumerStatusCount = async (data) => {
    try {
        const res = await axios.post(`${url}/getGroupWiseLoraConsumerStatusCount`, data, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getLoraCountStatusByGa=async(info)=>{
    try {
const res=await axios.post(`${url}/getLoraCountStatusByGa`,info,config())
return { success: true, data: res.data }
    }catch (e) {
        return { success: false, msg: "Something went wrong",err:e};
    }
}

export const GetLoraInstallationDateWiseGraphDataBySelectedDate=async(info)=>{
    try {
const res=await axios.post(`${url}/GetLoraInstallationDateWiseGraphDataBySelectedDate`,info,config())
return { success: true, data: res.data }
    }catch (e) {
        return { success: false, msg: "Something went wrong",err:e};
    }
}

export const getAllLoraEventChartBySelectedDate = async (info) => {
    try {
        const res = await axios.post(`${url}/getAllLoraEventChartBySelectedDate`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getAllLoraMonthlyChartBySelectedDate = async (info) => {
    try {
        const res = await axios.post(`${url}/getAllLoraMonthlyChartBySelectedDate`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getAllLoraChartByDate=async(info)=>{
    try {
const res=await axios.post(`${url}/getAllLoraChartByDate`,info,config())
return { success: true, data: res.data }
    }catch (e) {
        return { success: false, msg: "Something went wrong",err:e};
    }
}

export const getAllLoraEventChartByDate=async(info)=>{
    try {
const res=await axios.post(`${url}/getAllLoraEventChartByDate`,info,config())
return { success: true, data: res.data }
    }catch (e) {
        return { success: false, msg: "Something went wrong",err:e};
    }
}

export const getAllLoraMonthlyChartByDate=async(info)=>{
    try {
const res=await axios.post(`${url}/getAllLoraMonthlyChartByDate`,info,config())
return { success: true, data: res.data }
    }catch (e) {
        return { success: false, msg: "Something went wrong",err:e};
    }
}

export const getAllModemTotalLora=async(info)=>{
    try {
const res=await axios.post(`${url}/getAllModemTotalLora`,info,config())
return { success: true, data: res.data }
    }catch (e) {
        return { success: false, msg: "Something went wrong",err:e};
    }
}

export const getAllMeterTotalLora=async(info)=>{
    try {
const res=await axios.post(`${url}/getAllMeterTotalLora`,info,config())
return { success: true, data: res.data }
    }catch (e) {
        return { success: false, msg: "Something went wrong",err:e};
    }
}

export const getmodemFirmwarTotalLora=async(info)=>{
    try {
const res=await axios.post(`${url}/getmodemFirmwarTotalLora`,info,config())
return { success: true, data: res.data }
    }catch (e) {
        return { success: false, msg: "Something went wrong",err:e};
    }
}

export const getTagsWiseLoraConsumerData=async(info)=>{
    try {
        const res=await axios.post(`${url}/getTagsWiseLoraConsumerData`,info,config())
        return { success: true, data: res.data }
            }catch (e) {
                return { success: false, msg: "Something went wrong",err:e};
            }
}

export const GetLoraInstallationDateWiseGraphData=async(info)=>{
    try {
        const res=await axios.post(`${url}/GetLoraInstallationDateWiseGraphData`,info,config())
        return { success: true, data: res.data }
            }catch (e) {
                return { success: false, msg: "Something went wrong",err:e};
            }
}

export const getmodemServiceProviderTotalLora=async(info)=>{
    try {
        const res=await axios.post(`${url}/getmodemServiceProviderTotalLora`,info,config())
        return { success: true, data: res.data }
            }catch (e) {
                return { success: false, msg: "Something went wrong",err:e};
            }
}

export const getmodemMeterConnectivityTotalLora=async(info)=>{
    try {
        const res=await axios.post(`${url}/getmodemMeterConnectivityTotalLora`,info,config())
        return { success: true, data: res.data }
            }catch (e) {
                return { success: false, msg: "Something went wrong",err:e};
            }
}


export const getLoraRSSIInDeshboardAllCountByStatus=async(info)=>{
    try {
const res=await axios.post(`${url}/getLoraRSSIInDeshboardAllCountByStatus`,info,config())
return { success: true, data: res.data }
    }catch (e) {
        return { success: false, msg: "Something went wrong",err:e};
    }
}

export const getAllLoraChartByDateSlection=async(info)=>{
    try {
const res=await axios.post(`${url}/getAllLoraChartByDateSlection`,info,config())
return { success: true, data: res.data }
    }catch (e) {
        return { success: false, msg: "Something went wrong",err:e};
    }
}

export const getLoraTotalCountByTS=async(info)=>{
    try {
const res=await axios.post(`${url}/getLoraTotalCountByTS`,info,config())
return { success: true, data: res.data }
    }catch (e) {
        return { success: false, msg: "Something went wrong",err:e};
    }
}

export const getHeatbeatStatusWiseCountInLoraGroupDashboard=async(info)=>{
    try {
const res=await axios.post(`${url}/getHeatbeatStatusWiseCountInLoraGroupDashboard`,info,config())
return { success: true, data: res.data }
    }catch (e) {
        return { success: false, msg: "Something went wrong",err:e};
    }
}

export const getLoraTableData=async(info,data)=>{
    try {
const res=await axios.post(`${url}/getLoraTableData?start=${info?.start}&end=${info.end}`,data,config())
return { success: true, data: res.data }
    }catch (e) {
        return { success: false, msg: "Something went wrong",err:e};
    }
}

export const getAllTaskAnalytics=async(data)=>{
    try {
const res=await axios.post(`${url}/getAllTaskAnalytics`,data,config())
return { success: true, data: res.data }
    }catch (e) {
        return { success: false, msg: "Something went wrong",err:e};
    }
}

export const get_notification_script = async (info) => {
    try {
        const res = await axios.post(`${url}/get_notification_script`,info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getBillingReportData = async (info) => {
    try {
        const res = await axios.post(`${url}/getBillingReportData`,info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const badDataAnalytics = async (info) => {
    try {
        const res = await axios.post(`${url}/badDataAnalytics`,info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const onGetConsumerIncolumeNameWithFilter = async (data) => {
    try {
        const res = await axios.post(`${url}/onGetConsumerIncolumeNameWithFilter`,data, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const onGetLoraConsumerIncolumeNameWithFilter = async (data) => {
    try {
        const res = await axios.post(`${url}/onGetLoraConsumerIncolumeNameWithFilter`,data, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const InsertRemoteMobildeDevice = async (data) => {
    try {
        const res = await axios.post(`${url}/InsertRemoteMobildeDevice`,data, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getRemoteMobileDevice = async (data) => {
    try {
        const res = await axios.get(`${url}/getRemoteMobileDevice`, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const UpdateRemoteMobileDevice = async (data,id) => {
    try {
        const res = await axios.post(`${url}/UpdateRemoteMobileDevice/${id}`,data, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getPortableAllModem=async(info)=>{
    try {
const res=await axios.get(`${url}/getPortableAllModem`,config())
return { success: true, data: res.data }
    }catch (e) {
        return { success: false, msg: "Something went wrong",err:e};
    }
}

export const getPortableModemFirmware=async(info)=>{
    try {
const res=await axios.get(`${url}/getPortableModemFirmware`,config())
return { success: true, data: res.data }
    }catch (e) {
        return { success: false, msg: "Something went wrong",err:e};
    }
}

export const getPortableModemHardwareVersion=async(info)=>{
    try {
const res=await axios.get(`${url}/getPortableModemHardwareVersion`,config())
return { success: true, data: res.data }
    }catch (e) {
        return { success: false, msg: "Something went wrong",err:e};
    }
}

export const getPortableModemMake=async(info)=>{
    try {
const res=await axios.get(`${url}/getPortableModemMake`,config())
return { success: true, data: res.data }
    }catch (e) {
        return { success: false, msg: "Something went wrong",err:e};
    }
}

export const getPortableModemModelNumber=async(info)=>{
    try {
const res=await axios.get(`${url}/getPortableModemModelNumber`,config())
return { success: true, data: res.data }
    }catch (e) {
        return { success: false, msg: "Something went wrong",err:e};
    }
}

export const getPortableModemProductCode=async(info)=>{
    try {
const res=await axios.get(`${url}/getPortableModemProductCode`,config())
return { success: true, data: res.data }
    }catch (e) {
        return { success: false, msg: "Something went wrong",err:e};
    }
}

export const getPortableModemType=async(info)=>{
    try {
const res=await axios.get(`${url}/getPortableModemType`,config())
return { success: true, data: res.data }
    }catch (e) {
        return { success: false, msg: "Something went wrong",err:e};
    }
}

export const InsertPortableFirmware=async(info)=>{
    try {
        const res=await axios.post(`${url}/InsertPortableFirmware`,info,config())
        return { success: true, data: res.data }
            }catch (e) {
                return { success: false, msg: "Something went wrong",err:e};
            }
}

export const UpdatePortableFirmware=async(info,id)=>{
    try {
        const res=await axios.put(`${url}/UpdatePortableFirmware/${id}`,info,config())
        return { success: true, data: res.data }
            }catch (e) {
                return { success: false, msg: "Something went wrong",err:e};
            }
}

export const InsertPortablemodem_hardware_version=async(info)=>{
    try {
        const res=await axios.post(`${url}/InsertPortablemodem_hardware_version`,info,config())
        return { success: true, data: res.data }
            }catch (e) {
                return { success: false, msg: "Something went wrong",err:e};
            }
}

export const UpdatePortablemodem_hardware_version=async(info,id)=>{
    try {
        const res=await axios.put(`${url}/UpdatePortablemodem_hardware_version/${id}`,info,config())
        return { success: true, data: res.data }
            }catch (e) {
                return { success: false, msg: "Something went wrong",err:e};
            }
}

export const InsertPortablemodem_model_number=async(info)=>{
    try {
        const res=await axios.post(`${url}/InsertPortablemodem_model_number`,info,config())
        return { success: true, data: res.data }
            }catch (e) {
                return { success: false, msg: "Something went wrong",err:e};
            }
}

export const UpdatePortablemodem_model_number=async(info,id)=>{
    try {
        const res=await axios.put(`${url}/UpdatePortablemodem_model_number/${id}`,info,config())
        return { success: true, data: res.data }
            }catch (e) {
                return { success: false, msg: "Something went wrong",err:e};
            }
}

export const InsertPortablemodem_make=async(info)=>{
    try {
        const res=await axios.post(`${url}/InsertPortablemodem_make`,info,config())
        return { success: true, data: res.data }
            }catch (e) {
                return { success: false, msg: "Something went wrong",err:e};
            }
}

export const UpdatePortablemodem_make=async(info,id)=>{
    try {
        const res=await axios.put(`${url}/UpdatePortablemodem_make/${id}`,info,config())
        return { success: true, data: res.data }
            }catch (e) {
                return { success: false, msg: "Something went wrong",err:e};
            }
}
export const InsertPortablemodem_product_code=async(info)=>{
    try {
        const res=await axios.post(`${url}/InsertPortablemodem_product_code`,info,config())
        return { success: true, data: res.data }
            }catch (e) {
                return { success: false, msg: "Something went wrong",err:e};
            }
}

export const UpdatePortablemodem_product_code=async(info,id)=>{
    try {
        const res=await axios.put(`${url}/UpdatePortablemodem_product_code/${id}`,info,config())
        return { success: true, data: res.data }
            }catch (e) {
                return { success: false, msg: "Something went wrong",err:e};
            }
}

export const InsertPortablemodem_type=async(info)=>{
    try {
        const res=await axios.post(`${url}/InsertPortablemodem_type`,info,config())
        return { success: true, data: res.data }
            }catch (e) {
                return { success: false, msg: "Something went wrong",err:e};
            }
}

export const UpdatePortablemodem_type=async(info,id)=>{
    try {
        const res=await axios.put(`${url}/UpdatePortablemodem_type/${id}`,info,config())
        return { success: true, data: res.data }
            }catch (e) {
                return { success: false, msg: "Something went wrong",err:e};
            }
}

export const InsertPortablemodem_index=async(info)=>{
    try {
        const res=await axios.post(`${url}/InsertPortablemodem_index`,info,config())
        return { success: true, data: res.data }
            }catch (e) {
                return { success: false, msg: "Something went wrong",err:e};
            }
}

export const UpdatePortablemodem_index=async(info,id)=>{
    try {
        const res=await axios.put(`${url}/UpdatePortablemodem_index/${id}`,info,config())
        return { success: true, data: res.data }
            }catch (e) {
                return { success: false, msg: "Something went wrong",err:e};
            }
}

export const getPortableAllMeter=async(info)=>{
    try {
const res=await axios.get(`${url}/getPortableAllMeter`,config())
return { success: true, data: res.data }
    }catch (e) {
        return { success: false, msg: "Something went wrong",err:e};
    }
}

export const getPortablemeter_category=async(info)=>{
    try {
const res=await axios.get(`${url}/getPortablemeter_category`,config())
return { success: true, data: res.data }
    }catch (e) {
        return { success: false, msg: "Something went wrong",err:e};
    }
}

export const getPortablemeter_class=async(info)=>{
    try {
const res=await axios.get(`${url}/getPortablemeter_class`,config())
return { success: true, data: res.data }
    }catch (e) {
        return { success: false, msg: "Something went wrong",err:e};
    }
}
export const getPortablemeter_firmware=async(info)=>{
    try {
const res=await axios.get(`${url}/getPortablemeter_firmware`,config())
return { success: true, data: res.data }
    }catch (e) {
        return { success: false, msg: "Something went wrong",err:e};
    }
}

export const getPortablemeter_hardware_protocol=async(info)=>{
    try {
const res=await axios.get(`${url}/getPortablemeter_hardware_protocol`,config())
return { success: true, data: res.data }
    }catch (e) {
        return { success: false, msg: "Something went wrong",err:e};
    }
}

export const getPortablemeter_make=async(info)=>{
    try {
const res=await axios.get(`${url}/getPortablemeter_make`,config())
return { success: true, data: res.data }
    }catch (e) {
        return { success: false, msg: "Something went wrong",err:e};
    }
}

export const getPortablemeter_model_number=async(info)=>{
    try {
const res=await axios.get(`${url}/getPortablemeter_model_number`,config())
return { success: true, data: res.data }
    }catch (e) {
        return { success: false, msg: "Something went wrong",err:e};
    }
}

export const getPortablemeter_software_protocol=async(info)=>{
    try {
const res=await axios.get(`${url}/getPortablemeter_software_protocol`,config())
return { success: true, data: res.data }
    }catch (e) {
        return { success: false, msg: "Something went wrong",err:e};
    }
}

export const getPortablemeter_type=async(info)=>{
    try {
const res=await axios.get(`${url}/getPortablemeter_type`,config())
return { success: true, data: res.data }
    }catch (e) {
        return { success: false, msg: "Something went wrong",err:e};
    }
} 

export const InsertPortablemeter_category=async(info)=>{
    try {
        const res=await axios.post(`${url}/InsertPortablemeter_category`,info,config())
        return { success: true, data: res.data }
            }catch (e) {
                return { success: false, msg: "Something went wrong",err:e};
            }
}

export const UpdatePortablemeter_category=async(info,id)=>{
    try {
        const res=await axios.put(`${url}/UpdatePortablemeter_category/${id}`,info,config())
        return { success: true, data: res.data }
            }catch (e) {
                return { success: false, msg: "Something went wrong",err:e};
            }
}

export const InsertPortablemeter_class=async(info)=>{
    try {
        const res=await axios.post(`${url}/InsertPortablemeter_class`,info,config())
        return { success: true, data: res.data }
            }catch (e) {
                return { success: false, msg: "Something went wrong",err:e};
            }
}

export const UpdatePortablemmeter_class=async(info,id)=>{
    try {
        const res=await axios.put(`${url}/UpdatePortablemmeter_class/${id}`,info,config())
        return { success: true, data: res.data }
            }catch (e) {
                return { success: false, msg: "Something went wrong",err:e};
            }
}
export const InserPortabletmeter_make=async(info)=>{
    try {
        const res=await axios.post(`${url}/InserPortabletmeter_make`,info,config())
        return { success: true, data: res.data }
            }catch (e) {
                return { success: false, msg: "Something went wrong",err:e};
            }
}

export const UpdatePortablemeter_make=async(info,id)=>{
    try {
        const res=await axios.put(`${url}/UpdatePortablemeter_make/${id}`,info,config())
        return { success: true, data: res.data }
            }catch (e) {
                return { success: false, msg: "Something went wrong",err:e};
            }
}

export const InsertPortablemeter_model_number=async(info)=>{
    try {
        const res=await axios.post(`${url}/InsertPortablemeter_model_number`,info,config())
        return { success: true, data: res.data }
            }catch (e) {
                return { success: false, msg: "Something went wrong",err:e};
            }
}

export const UpdatePortablemeter_model_number=async(info,id)=>{
    try {
        const res=await axios.put(`${url}/UpdatePortablemeter_model_number/${id}`,info,config())
        return { success: true, data: res.data }
            }catch (e) {
                return { success: false, msg: "Something went wrong",err:e};
            }
}

export const InsertPortablemeter_firmware=async(info)=>{
    try {
        const res=await axios.post(`${url}/InsertPortablemeter_firmware`,info,config())
        return { success: true, data: res.data }
            }catch (e) {
                return { success: false, msg: "Something went wrong",err:e};
            }
}

export const UpdatePortablemeter_firmware=async(info,id)=>{
    try {
        const res=await axios.put(`${url}/UpdatePortablemeter_firmware/${id}`,info,config())
        return { success: true, data: res.data }
            }catch (e) {
                return { success: false, msg: "Something went wrong",err:e};
            }
}

export const InsertPortablemeter_hardware_protocol=async(info)=>{
    try {
        const res=await axios.post(`${url}/InsertPortablemeter_hardware_protocol`,info,config())
        return { success: true, data: res.data }
            }catch (e) {
                return { success: false, msg: "Something went wrong",err:e};
            }
}

export const UpdatePortablemeter_hardware_protocol=async(info,id)=>{
    try {
        const res=await axios.put(`${url}/UpdatePortablemeter_hardware_protocol/${id}`,info,config())
        return { success: true, data: res.data }
            }catch (e) {
                return { success: false, msg: "Something went wrong",err:e};
            }
}

export const InsertPortablemeter_software_protocol=async(info)=>{
    try {
        const res=await axios.post(`${url}/InsertPortablemeter_software_protocol`,info,config())
        return { success: true, data: res.data }
            }catch (e) {
                return { success: false, msg: "Something went wrong",err:e};
            }
}

export const UpdatePortablemeter_software_protocol=async(info,id)=>{
    try {
        const res=await axios.put(`${url}/UpdatePortablemeter_software_protocol/${id}`,info,config())
        return { success: true, data: res.data }
            }catch (e) {
                return { success: false, msg: "Something went wrong",err:e};
            }
}

export const InsertPortablemeter_type=async(info)=>{
    try {
        const res=await axios.post(`${url}/InsertPortablemeter_type`,info,config())
        return { success: true, data: res.data }
            }catch (e) {
                return { success: false, msg: "Something went wrong",err:e};
            }
}

export const UpdatePortablemmeter_type=async(info,id)=>{
    try {
        const res=await axios.put(`${url}/UpdatePortablemmeter_type/${id}`,info,config())
        return { success: true, data: res.data }
            }catch (e) {
                return { success: false, msg: "Something went wrong",err:e};
            }
}

export const InsertPortablemeter_index=async(info)=>{
    try {
        const res=await axios.post(`${url}/InsertPortablemeter_index`,info,config())
        return { success: true, data: res.data }
            }catch (e) {
                return { success: false, msg: "Something went wrong",err:e};
            }
}

export const UpdatePortablemeter_index=async(info,id)=>{
    try {
        const res=await axios.put(`${url}/UpdatePortablemeter_index/${id}`,info,config())
        return { success: true, data: res.data }
            }catch (e) {
                return { success: false, msg: "Something went wrong",err:e};
            }
}


export const getAttachmentByPortablemeterid=async(id)=>{
    try {
const res=await axios.get(`${url}/getAttachmentByPortablemeterid/${id}`,config())
return { success: true, data: res.data }
    }catch (e) {
        return { success: false, msg: "Something went wrong",err:e};
    }
}

export const UpdatePortablemeter_index_attachments = async (id, info) => {
    try {
        const res = await axios.put(`${url}/UpdatePortablemeter_index_attachments/${id}`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getPortablemeter_update_data = async (id, info) => {
    try {
        const res = await axios.get(`${url}/getPortablemeter_update_data/${id}`, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const InsertPoratbleScriptdecodeId=async(info)=>{
    try {
        const res=await axios.post(`${url}/InsertPoratbleScriptdecodeId`,info,config())
        return { success: true, data: res.data }
            }catch (e) {
                return { success: false, msg: "Something went wrong",err:e};
            }
}

export const UpdatePoratbleScriptdecodeId=async(info,id)=>{
    try {
        const res=await axios.put(`${url}/UpdatePoratbleScriptdecodeId/${id}`,info,config())
        return { success: true, data: res.data }
            }catch (e) {
                return { success: false, msg: "Something went wrong",err:e};
            }
}

export const getPoratbleScriptdecodeId = async (id, info) => {
    try {
        const res = await axios.get(`${url}/getPoratbleScriptdecodeId`, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getPoratbleScriptCodeIdWiseColumnName=async(id)=>{
    try {
const res=await axios.get(`${url}/getPoratbleScriptCodeIdWiseColumnName/${id}`,config())
return { success: true, data: res.data }
    }catch (e) {
        return { success: false, msg: "Something went wrong",err:e};
    }
}

export const gePoratbleModemIdWiseColumnName=async(data,id)=>{
    try {
const res=await axios.post(`${url}/gePoratbleModemIdWiseColumnName/${id}`,data,config())
return { success: true, data: res.data }
    }catch (e) {
        return { success: false, msg: "Something went wrong",err:e};
    }
}

export const gePoratbleScriptSRNOWiseColumnName=async(id)=>{
    try {
const res=await axios.get(`${url}/gePoratbleScriptSRNOWiseColumnName/${id}`,config())
return { success: true, data: res.data }
    }catch (e) {
        return { success: false, msg: "Something went wrong",err:e};
    }
}

export const InsertMobileMeterConsumer=async(data)=>{
    try {
const res=await axios.post(`${url}/InsertMobileMeterConsumer`,data,config())
return { success: true, data: res.data }
    }catch (e) {
        return { success: false, msg: "Something went wrong",err:e};
    }
}

export const getMobileMeterConsumerData=async(info,data)=>{
    try {
        console.log(data);
const res=await axios.get(`${url}/getMobileMeterConsumerData?start=${info?.start}&end=${info.end}`,{
    method: 'GET',
    params: data,
    headers: {
        Authorization: window.localStorage.getItem('token'),
        Accept: 'application/json',
        'Content-Type': 'application/json',
    }
})
return { success: true, data: res.data }
    }catch (e) {
        return { success: false, msg: "Something went wrong",err:e};
    }
}

export const getMobileMeterConsumerById=async(id)=>{
    try {
const res=await axios.get(`${url}/getMobileMeterConsumerById/${id}`,config())
return { success: true, data: res.data }
    }catch (e) {
        return { success: false, msg: "Something went wrong",err:e};
    }
}

export const UpdateInMobileMeterConsumermodem=async(data,id)=>{
    try {
const res=await axios.post(`${url}/UpdateInMobileMeterConsumermodem/${id}`,data,config())
return { success: true, data: res.data }
    }catch (e) {
        return { success: false, msg: "Something went wrong",err:e};
    }
}

export const getPortableModemByID=async(id)=>{
    try {
const res=await axios.get(`${url}/getPortableModemByID/${id}`,config())
return { success: true, data: res.data }
    }catch (e) {
        return { success: false, msg: "Something went wrong",err:e};
    }
}

export const GetMeterConsumerStatusHistoryByConsumerId=async(id)=>{
    try {
const res=await axios.get(`${url}/GetMeterConsumerStatusHistoryByConsumerId/${id}`,config())
return { success: true, data: res.data }
    }catch (e) {
        return { success: false, msg: "Something went wrong",err:e};
    }
}

export const UpdateMeterConsumerStatusHistory=async(data,id)=>{
    try {
const res=await axios.post(`${url}/UpdateMeterConsumerStatusHistory/${id}`,data,config())
return { success: true, data: res.data }
    }catch (e) {
        return { success: false, msg: "Something went wrong",err:e};
    }
}

export const gsmMeterConsumerFilterData=async(date,info)=>{
    try {
const res=await axios.post(`${url}/gsmMeterConsumerFilterData?start=${date?.start}&end=${date.end}`,info,config())
return { success: true, data: res.data }
    }catch (e) {
        return { success: false, msg: "Something went wrong",err:e};
    }
}

export const gsmSmartMeterCounsumerSerchData=async(info)=>{
    try {
const res=await axios.post(`${url}/gsmSmartMeterCounsumerSerchData`,info,config())
return { success: true, data: res.data }
    }catch (e) {
        return { success: false, msg: "Something went wrong",err:e};
    }
}

export const getMobileAppConsumerTableData=async(info,data)=>{
    try {
const res=await axios.post(`${url}/getMobileAppConsumerTableData?start=${info?.start}&end=${info.end}`,data,config())
return { success: true, data: res.data }
    }catch (e) {
        return { success: false, msg: "Something went wrong",err:e};
    }
}

export const GetMobileAppTableNameWiseChartDataByDate=async(info,id)=>{
    try {
const res=await axios.post(`${url}/GetMobileAppTableNameWiseChartDataByDate`,info,config())
return { success: true, data: res.data }
    }catch (e) {
        return { success: false, msg: "Something went wrong",err:e};
    }
}

export const LetestCountAndLastDateByMobileAppTableName=async(info,id)=>{
    try {
const res=await axios.post(`${url}/LetestCountAndLastDateByMobileAppTableName`,info,config())
return { success: true, data: res.data }
    }catch (e) {
        return { success: false, msg: "Something went wrong",err:e};
    }
}

export const getMobilAppErrorbinAndDuplicatbinCountList=async(info,id)=>{
    try {
const res=await axios.post(`${url}/getMobilAppErrorbinAndDuplicatbinCountList`,info,config())
return { success: true, data: res.data }
    }catch (e) {
        return { success: false, msg: "Something went wrong",err:e};
    }
}

export const SyncPortableScriptIdWiseTableColumn=async(info)=>{
    try {
const res=await axios.post(`${url}/SyncPortableScriptIdWiseTableColumn`,info,config())
return { success: true, data: res.data }
    }catch (e) {
        return { success: false, msg: "Something went wrong",err:e};
    }
}

export const getUnknownMeterData=async(info,data)=>{
    try {
const res=await axios.post(`${url}/getUnknownMeterData?start=${info?.start}&end=${info.end}`,data||{},config())
return { success: true, data: res.data }
    }catch (e) {
        return { success: false, msg: "Something went wrong",err:e};
    }
}

export const getMyStatusAnalytics = async (id) => {
    try {
        const res = await axios.post(`${url}/getMyStatusAnalytics`,id, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const TaskActivityLogMaintainOnFrontend = async (data) => {
    try {
        const res = await axios.post(`${url}/TaskActivityLogMaintainOnFrontend`, data, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getTaskActivityLogs = async (data) => {
    try {
        const res = await axios.post(`${url}/getTaskActivityLogs`, data, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const GeflowEncodeRegister = async () => {
    try {
        const res = await axios.get(`${url}/GeflowEncodeRegister`, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const UpdateIntegrationConfig = async (info,id) => {
    try {
        const res = await axios.put(`${url}/UpdateIntegrationConfig/${id}`,info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}


export const GenrateReportByTypes = async (data) => {
    try {
        const res = await axios.post(`${url}/GenrateReportByTypes`, data, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}


export const GetStructureById = async (id) => {
    try {
        const res = await axios.get(`${url}/GetStructureById/${id}`, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const GetintegrationCountByStructureId = async (id) => {
    try {
        const res = await axios.get(`${url}/GetintegrationCountByStructureId/${id}`, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const UpdateIntegrationCard = async (info,id) => {
    try {
        const res = await axios.put(`${url}/UpdateIntegrationCard/${id}`,info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const InserIntegrationStructure = async (info) => {
    try {
        const res = await axios.post(`${url}/InserIntegrationStructure`,info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const UpdateIntegrationStructure = async (info,id) => {
    try {
        const res = await axios.put(`${url}/UpdateIntegrationStructure/${id}`,info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const uploadCertificatefiles = async (info, id) => {
    try {
        const res = await axios.post(`${url}/uploadCertificatefiles/${id}`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const GetMqttConnectionStatusByTable = async (info, id) => {
    try {
        const res = await axios.post(`${url}/GetMqttConnectionStatusByTable`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const GetMqttConnectionStatusDurationByTable = async (info, id) => {
    try {
        const res = await axios.post(`${url}/GetMqttConnectionStatusDurationByTable`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}


export const getReportByReportIdWise = async (info, id) => {
    try {
        const res = await axios.post(`${url}/getReportByReportIdWise`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const sentTriggerCardStatusUpdate = async (info, id) => {
    try {
        const res = await axios.get(`${url}/sentTriggerCardStatusUpdate`, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}


export const syncDatabaseTabledata = async (info, id) => {
    try {
        const res = await axios.post(`${url}/syncDatabaseTabledata`,info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getAllCredentialList = async (info, id) => {
    try {
        const res = await axios.post(`${url}/getAllCredentialList`,info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const ModemCredentialManage = async (info, id) => {
    try {
        const res = await axios.post(`${url}/ModemCredentialManage`,info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getModemConsumerDetailsById = async (id) => {
    try {
        const res = await axios.get(`${url}/getModemConsumerDetailsById/${id}`, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const CheckModemStatus = async (id) => {
    try {
        const res = await axios.get(`${url}/CheckModemStatus/${id}`, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getTransciverDocument = async (id) => {
    try {
        const res = await axios.get(`${url}/getTransciverDocument`, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const addTransciverData = async (info, id) => {
    try {
        const res = await axios.post(`${url}/addTransciverData`,info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const updateDocumentByUniqueId = async (info,id) => {
    try {
        const res = await axios.post(`${url}/updateDocumentByUniqueId/${id}`,info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getFilterData = async (info, id) => {
    try {
        const res = await axios.post(`${url}/getFilterData`,info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getAllMeterModemIndex = async (id) => {
    try {
        const res = await axios.get(`${url}/getAllMeterModemIndex`, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const TableNameWisedataload = async (info, id) => {
    try {
        const res = await axios.post(`${url}/TableNameWisedataload`,info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getGSMInstaneouseLoadcmdGenerated = async (info, id) => {
    try {
        const res = await axios.post(`${url}/getGSMInstaneouseLoadcmdGenerated`,info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getAllMeterModelTypesTotalGSM = async (info, id) => {
    try {
        const res = await axios.post(`${url}/getAllMeterModelTypesTotalGSM`,info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getLastTableData = async (info, id) => {
    try {
        const res = await axios.post(`${url}/getLastTableData`,info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getGenreteReportAddInTable = async (info, id) => {
    try {
        const res = await axios.post(`${url}/getGenreteReportAddInTable`,info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const UpdateModemTest_data = async (info, id) => {
    try {
        const res = await axios.post(`${url}/UpdateModemTest_data/${id}`,info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const UpdateModemStatusInConsumerTable = async (info, id) => {
    try {
        const res = await axios.post(`${url}/UpdateModemStatusInConsumerTable`,info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const gsmCommandGenerateGSMConsumer = async (info) => {
    try {
        const res = await axios.post(`${url}/gsmCommandGenerateGSMConsumer`,info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const CommandGenerate = async (info) => {
    try {
        const res = await axios.post(`${url}/CommandGenerate`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getDueDateWiseAnalytics = async (info) => {
    try {
        const res = await axios.post(`${url}/getDueDateWiseAnalytics`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const CheckGSM4ConsumerConnectionStatus = async (id) => {
    try {
        const res = await axios.get(`${url}/CheckGSM4ConsumerConnectionStatus/${id}`, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getTaskAssignedToMeAnalytics = async (info) => {
    try {
        const res = await axios.post(`${url}/getTaskAssignedToMeAnalytics`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getTasAnalyticsGroupWise = async (info) => {
    try {
        const res = await axios.post(`${url}/getTasAnalyticsGroupWise`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const TaskReadStatusChange = async (info, id) => {
    try {
        const res = await axios.post(`${url}/TaskReadStatusChange/${id}`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getAllGroupsOfUser = async (id) => {
    try {
        const res = await axios.post(`${url}/getAllGroupsOfUser/${id}`, {}, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getAllUsersOfGroup = async (id) => {
    try {
        const res = await axios.post(`${url}/getAllUsersOfGroup/${id}`, {}, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getAllTPModem=async(info)=>{
    try {
const res=await axios.get(`${url}/getAllTPModem`,config())
return { success: true, data: res.data }
    }catch (e) {
        return { success: false, msg: "Something went wrong",err:e};
    }
}

export const getTPModemFirmware=async(info)=>{
    try {
const res=await axios.get(`${url}/getTPModemFirmware`,config())
return { success: true, data: res.data }
    }catch (e) {
        return { success: false, msg: "Something went wrong",err:e};
    }
}

export const getTPModemHardwareVersion=async(info)=>{
    try {
const res=await axios.get(`${url}/getTPModemHardwareVersion`,config())
return { success: true, data: res.data }
    }catch (e) {
        return { success: false, msg: "Something went wrong",err:e};
    }
}

export const getTPModemMake=async(info)=>{
    try {
const res=await axios.get(`${url}/getTPModemMake`,config())
return { success: true, data: res.data }
    }catch (e) {
        return { success: false, msg: "Something went wrong",err:e};
    }
}

export const getTPModemModelNumber=async(info)=>{
    try {
const res=await axios.get(`${url}/getTPModemModelNumber`,config())
return { success: true, data: res.data }
    }catch (e) {
        return { success: false, msg: "Something went wrong",err:e};
    }
}

export const getTPModemProductCode=async(info)=>{
    try {
const res=await axios.get(`${url}/getTPModemProductCode`,config())
return { success: true, data: res.data }
    }catch (e) {
        return { success: false, msg: "Something went wrong",err:e};
    }
}

export const getTPModemType=async(info)=>{
    try {
const res=await axios.get(`${url}/getTPModemType`,config())
return { success: true, data: res.data }
    }catch (e) {
        return { success: false, msg: "Something went wrong",err:e};
    }
}


export const getAllTPMeter=async(info)=>{
    try {
const res=await axios.get(`${url}/getAllTPMeter`,config())
return { success: true, data: res.data }
    }catch (e) {
        return { success: false, msg: "Something went wrong",err:e};
    }
}

export const getTPmeter_category=async(info)=>{
    try {
const res=await axios.get(`${url}/getTPmeter_category`,config())
return { success: true, data: res.data }
    }catch (e) {
        return { success: false, msg: "Something went wrong",err:e};
    }
}

export const getTPmeter_class=async(info)=>{
    try {
const res=await axios.get(`${url}/getTPmeter_class`,config())
return { success: true, data: res.data }
    }catch (e) {
        return { success: false, msg: "Something went wrong",err:e};
    }
}
export const getTPmeter_firmware=async(info)=>{
    try {
const res=await axios.get(`${url}/getTPmeter_firmware`,config())
return { success: true, data: res.data }
    }catch (e) {
        return { success: false, msg: "Something went wrong",err:e};
    }
}

export const getTPmeter_hardware_protocol=async(info)=>{
    try {
const res=await axios.get(`${url}/getTPmeter_hardware_protocol`,config())
return { success: true, data: res.data }
    }catch (e) {
        return { success: false, msg: "Something went wrong",err:e};
    }
}

export const getTPmeter_make=async(info)=>{
    try {
const res=await axios.get(`${url}/getTPmeter_make`,config())
return { success: true, data: res.data }
    }catch (e) {
        return { success: false, msg: "Something went wrong",err:e};
    }
}

export const getTPmeter_model_number=async(info)=>{
    try {
const res=await axios.get(`${url}/getTPmeter_model_number`,config())
return { success: true, data: res.data }
    }catch (e) {
        return { success: false, msg: "Something went wrong",err:e};
    }
}

export const getTPmeter_software_protocol=async(info)=>{
    try {
const res=await axios.get(`${url}/getTPmeter_software_protocol`,config())
return { success: true, data: res.data }
    }catch (e) {
        return { success: false, msg: "Something went wrong",err:e};
    }
}

export const getTPmeter_type=async(info)=>{
    try {
const res=await axios.get(`${url}/getTPmeter_type`,config())
return { success: true, data: res.data }
    }catch (e) {
        return { success: false, msg: "Something went wrong",err:e};
    }
} 

export const getAllTPMeterModemIndex = async (id) => {
    try {
        const res = await axios.get(`${url}/getAllTPMeterModemIndex`, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}


export const InsertTPScriptdecodeId=async(info)=>{
    try {
        const res=await axios.post(`${url}/InsertTPScriptdecodeId`,info,config())
        return { success: true, data: res.data }
            }catch (e) {
                return { success: false, msg: "Something went wrong",err:e};
            }
}

export const UpdatTPScriptdecodeId=async(info,id)=>{
    try {
        const res=await axios.put(`${url}/UpdatTPScriptdecodeId/${id}`,info,config())
        return { success: true, data: res.data }
            }catch (e) {
                return { success: false, msg: "Something went wrong",err:e};
            }
}

export const getTPScriptdecodeId=async(info)=>{
    try {
const res=await axios.get(`${url}/getTPScriptdecodeId`,config())
return { success: true, data: res.data }
    }catch (e) {
        return { success: false, msg: "Something went wrong",err:e};
    }
}

export const getTPScriptCodeIdWiseColumnName=async(id)=>{
    try {
const res=await axios.get(`${url}/getTPScriptCodeIdWiseColumnName/${id}`,config())
return { success: true, data: res.data }
    }catch (e) {
        return { success: false, msg: "Something went wrong",err:e};
    }
}


export const getThirdPartyMeterConsumerData=async(info,data)=>{
    try {
        console.log(data);
const res=await axios.get(`${url}/getThirdPartyMeterConsumerData?start=${info?.start}&end=${info.end}`,{
    method: 'GET',
    params: data,
    headers: {
        Authorization: window.localStorage.getItem('token'),
        Accept: 'application/json',
        'Content-Type': 'application/json',
    }
})
return { success: true, data: res.data }
    }catch (e) {
        return { success: false, msg: "Something went wrong",err:e};
    }
}

export const gsmThirdPartyMeterConsumerFilterData=async(date,info)=>{
    try {
const res=await axios.post(`${url}/gsmThirdPartyMeterConsumerFilterData?start=${date?.start}&end=${date.end}`,info,config())
return { success: true, data: res.data }
    }catch (e) {
        return { success: false, msg: "Something went wrong",err:e};
    }
}

export const gsmThirdPartyMeterCounsumerSerchData=async(info)=>{
    try {
const res=await axios.post(`${url}/gsmThirdPartyMeterCounsumerSerchData`,info,config())
return { success: true, data: res.data }
    }catch (e) {
        return { success: false, msg: "Something went wrong",err:e};
    }
}

export const getThirdPartyUnknownMeterData=async(info,data)=>{
    try {
const res=await axios.post(`${url}/getThirdPartyUnknownMeterData?start=${info?.start}&end=${info.end}`,data||{},config())
return { success: true, data: res.data }
    }catch (e) {
        return { success: false, msg: "Something went wrong",err:e};
    }
}

export const getTPMeterByID=async(id)=>{
    try {
const res=await axios.get(`${url}/getTPMeterByID/${id}`,config())
return { success: true, data: res.data }
    }catch (e) {
        return { success: false, msg: "Something went wrong",err:e};
    }
}

export const getTPModemByID=async(id)=>{
    try {
const res=await axios.get(`${url}/getTPModemByID/${id}`,config())
return { success: true, data: res.data }
    }catch (e) {
        return { success: false, msg: "Something went wrong",err:e};
    }
}

export const InsertThirdPartyMeterConsumer=async(data)=>{
    try {
const res=await axios.post(`${url}/InsertThirdPartyMeterConsumer`,data,config())
return { success: true, data: res.data }
    }catch (e) {
        return { success: false, msg: "Something went wrong",err:e};
    }
}

export const getThirdPartyMeterConsumerById=async(id)=>{
    try {
const res=await axios.get(`${url}/getThirdPartyMeterConsumerById/${id}`,config())
return { success: true, data: res.data }
    }catch (e) {
        return { success: false, msg: "Something went wrong",err:e};
    }
}


export const getTPConsumerTableData=async(info,data)=>{
    try {
const res=await axios.post(`${url}/getTPConsumerTableData?start=${info?.start}&end=${info.end}`,data,config())
return { success: true, data: res.data }
    }catch (e) {
        return { success: false, msg: "Something went wrong",err:e};
    }
}

export const GetTPTableNameWiseChartDataByDate=async(info,id)=>{
    try {
const res=await axios.post(`${url}/GetTPTableNameWiseChartDataByDate`,info,config())
return { success: true, data: res.data }
    }catch (e) {
        return { success: false, msg: "Something went wrong",err:e};
    }
}

export const LetestCountAndLastDateByTPTableName=async(info,id)=>{
    try {
const res=await axios.post(`${url}/LetestCountAndLastDateByTPTableName`,info,config())
return { success: true, data: res.data }
    }catch (e) {
        return { success: false, msg: "Something went wrong",err:e};
    }
}

export const getTPErrorbinAndDuplicatbinCountList=async(info,id)=>{
    try {
const res=await axios.post(`${url}/getTPErrorbinAndDuplicatbinCountList`,info,config())
return { success: true, data: res.data }
    }catch (e) {
        return { success: false, msg: "Something went wrong",err:e};
    }
}

export const getTPScriptSRNOWiseColumnName=async(id)=>{
    try {
const res=await axios.get(`${url}/getTPScriptSRNOWiseColumnName/${id}`,config())
return { success: true, data: res.data }
    }catch (e) {
        return { success: false, msg: "Something went wrong",err:e};
    }
}

export const getTPModemIdWiseColumnName=async(info,id)=>{
    try {
const res=await axios.get(`${url}/getTPModemIdWiseColumnName/${id}`,info,config())
return { success: true, data: res.data }
    }catch (e) {
        return { success: false, msg: "Something went wrong",err:e};
    }
}

export const HoursWiseConsumptionGraphBytableName=async(info,id)=>{
    try {
const res=await axios.post(`${url}/HoursWiseConsumptionGraphBytableName`,info,config())
return { success: true, data: res.data }
    }catch (e) {
        return { success: false, msg: "Something went wrong",err:e};
    }
}

export const UpdateInThirdPartyMeterConsumermodem=async(data,id)=>{
    try {
const res=await axios.post(`${url}/UpdateInThirdPartyMeterConsumermodem/${id}`,data,config())
return { success: true, data: res.data }
    }catch (e) {
        return { success: false, msg: "Something went wrong",err:e};
    }
}

export const UpdateThirdPartyMeterConsumerStatusHistory=async(data,id)=>{
    try {
const res=await axios.post(`${url}/UpdateThirdPartyMeterConsumerStatusHistory/${id}`,data,config())
return { success: true, data: res.data }
    }catch (e) {
        return { success: false, msg: "Something went wrong",err:e};
    }
}

export const getPowerSourceTotalGSM = async (info, id) => {
    try {
        const res = await axios.post(`${url}/getPowerSourceTotalGSM`,info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getMeterFirmwarTotalGSM = async (info, id) => {
    try {
        const res = await axios.post(`${url}/getMeterFirmwarTotalGSM`,info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const GetEventCountInBydate = async (info, id) => {
    try {
        const res = await axios.post(`${url}/GetEventCountInBydate`,info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getmeter_obisList=async(info,id)=>{
    try {
const res=await axios.get(`${url}/getmeter_obisList`,config())
return { success: true, data: res.data }
    }catch (e) {
        return { success: false, msg: "Something went wrong",err:e};
    }
}