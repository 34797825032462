import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import socketio from "socket.io-client";
import * as XLSX from 'xlsx';
// export const socket = socketio.connect(`http://localhost:5001/`);
export const socket = socketio.connect(`${process.env.REACT_APP_BASE_URL}/`);
// socket.emit('join', 'Hello World from client');
export const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
  },
}));

function convertToCSV(objArray) {
  var array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
  var str = '';
  for (var i = 0; i < array.length; i++) {
    var line = '';
    for (var index in array[i]) {
      if (line != '') line += ','

      line += array[i][index];
    }

    str += line + '\r\n';
  }

  return str;
}

export const btnColor = ['#4f5ece', '#f59e0b', '#fc544b', '#3abaf4', '#54ca68', '#191d21', '#7c5295', '#4f5ece']


export function exportCSVFile(headers, items, fileTitle) {
  if (headers) {
    items.unshift(headers);
  }

  // Convert Object to JSON
  var jsonObject = JSON.stringify(items);

  var csv = convertToCSV(jsonObject);

  var exportedFilenmae = fileTitle + '.csv' || 'export.csv';

  var blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
  if (navigator.msSaveBlob) { // IE 10+
    navigator.msSaveBlob(blob, exportedFilenmae);
  } else {
    var link = document.createElement("a");
    if (link.download !== undefined) { // feature detection
      // Browsers that support HTML5 download attribute
      var url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", exportedFilenmae);
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }
}

export function exportExcelDownload(data, filename) {
  const worksheet = XLSX.utils.json_to_sheet(data);
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
  XLSX.writeFile(workbook, `${filename}.xlsx`);
}

export const AttachmanyUrl = 'https://ccmsnew.memighty.com:6001/uploadfiles/Attachmants/'
export const ReportUrl = 'https://gums.memighty.com:7001/uploadfiles/Reports/'
export const ReporLoratUrl = 'https://gums.memighty.com:7001/uploadfiles/Lorareport/'
export const UserUrl = 'https://ccmsnew.memighty.com:6001/uploadfiles/Users/'
export const MeterUrl = 'https://ccmsnew.memighty.com:6001/uploadfiles/Meterattachment/'
export const TaskUrl = 'https://ccmsnew.memighty.com:6001/uploadfiles/Task/'

export const EVENT_TYPE_NAME = {
  1: { "CDF_CODE": 1, "CDF_STATUS": 0, "EVENT_ID_NAME": "R-Phase  PT link Missing (Missing Potential)  Occurrence" },
  2: { "CDF_CODE": 1, "CDF_STATUS": 1, "EVENT_ID_NAME": "R-Phase  PT link Missing (Missing Potential)  Restoration" },
  3: { "CDF_CODE": 2, "CDF_STATUS": 0, "EVENT_ID_NAME": "Y-Phase  PT link Missing (Missing Potential)  Occurrence" },
  4: { "CDF_CODE": 2, "CDF_STATUS": 1, "EVENT_ID_NAME": "Y-Phase  PT link Missing (Missing Potential)  Restoration" },
  5: { "CDF_CODE": 3, "CDF_STATUS": 0, "EVENT_ID_NAME": "B-Phase  PT link Missing (Missing Potential)  Occurrence" },
  6: { "CDF_CODE": 3, "CDF_STATUS": 1, "EVENT_ID_NAME": "B-Phase  PT link Missing (Missing Potential)  Restoration" },
  7: { "CDF_CODE": 33, "CDF_STATUS": 0, "EVENT_ID_NAME": "Over Voltage in any Phase - Occurrence" },
  8: { "CDF_CODE": 33, "CDF_STATUS": 1, "EVENT_ID_NAME": "Over Voltage in any Phase - Restoration" },
  9: { "CDF_CODE": 58, "CDF_STATUS": 0, "EVENT_ID_NAME": "Low Voltage in any Phase - Occurrence" },
  10: { "CDF_CODE": 58, "CDF_STATUS": 1, "EVENT_ID_NAME": "Low Voltage in any Phase - Restoration" },
  11: { "CDF_CODE": 14, "CDF_STATUS": 0, "EVENT_ID_NAME": "Voltage Unbalance - Occurrence" },
  12: { "CDF_CODE": 14, "CDF_STATUS": 1, "EVENT_ID_NAME": "Voltage Unbalance - Restoration" },
  51: { "CDF_CODE": 4, "CDF_STATUS": 0, "EVENT_ID_NAME": "Phase  R CT reverse  Occurrence" },
  52: { "CDF_CODE": 4, "CDF_STATUS": 1, "EVENT_ID_NAME": "Phase  R CT reverse  Restoration" },
  53: { "CDF_CODE": 5, "CDF_STATUS": 0, "EVENT_ID_NAME": "Phase  Y CT reverse  Occurrence" },
  54: { "CDF_CODE": 5, "CDF_STATUS": 1, "EVENT_ID_NAME": "Phase  Y CT reverse  Restoration" },
  55: { "CDF_CODE": 6, "CDF_STATUS": 0, "EVENT_ID_NAME": "Phase  B CT reverse  Occurrence" },
  56: { "CDF_CODE": 6, "CDF_STATUS": 1, "EVENT_ID_NAME": "Phase  B CT reverse  Restoration" },
  57: { "CDF_CODE": 24, "CDF_STATUS": 0, "EVENT_ID_NAME": "Phase  R CT Open - Occurrence" },
  58: { "CDF_CODE": 24, "CDF_STATUS": 1, "EVENT_ID_NAME": "Phase  R CT Open - Restoration" },
  59: { "CDF_CODE": 25, "CDF_STATUS": 0, "EVENT_ID_NAME": "Phase  Y CT Open - Occurrence" },
  60: { "CDF_CODE": 25, "CDF_STATUS": 1, "EVENT_ID_NAME": "Phase  Y CT Open - Restoration" },
  61: { "CDF_CODE": 26, "CDF_STATUS": 0, "EVENT_ID_NAME": "Phase  B CT Open - Occurrence" },
  62: { "CDF_CODE": 26, "CDF_STATUS": 1, "EVENT_ID_NAME": "Phase  B CT Open - Restoration" },
  63: { "CDF_CODE": 7, "CDF_STATUS": 0, "EVENT_ID_NAME": "Current Unbalance - Occurrence" },
  64: { "CDF_CODE": 7, "CDF_STATUS": 1, "EVENT_ID_NAME": "Current Unbalance - Restoration" },
  65: { "CDF_CODE": 19, "CDF_STATUS": 0, "EVENT_ID_NAME": "CT Bypass  Occurrence" },
  66: { "CDF_CODE": 19, "CDF_STATUS": 1, "EVENT_ID_NAME": "CT Bypass  Restoration" },
  67: { "CDF_CODE": 11, "CDF_STATUS": 0, "EVENT_ID_NAME": "Over Current in any Phase  Occurrence" },
  68: { "CDF_CODE": 11, "CDF_STATUS": 1, "EVENT_ID_NAME": "Over Current in any Phase  Restoration" },
  101: { "CDF_CODE": 13, "CDF_STATUS": 0, "EVENT_ID_NAME": "Power failure (3 phase)  Occurrence" },
  102: { "CDF_CODE": 13, "CDF_STATUS": 1, "EVENT_ID_NAME": "Power failure (3 phase)  Restoration" },
  151: { "CDF_CODE": 61, "CDF_STATUS": 0, "EVENT_ID_NAME": "Real Time Clock  Date and Time" },
  152: { "CDF_CODE": null, "CDF_STATUS": null, "EVENT_ID_NAME": "Demand Integration Period" },
  153: { "CDF_CODE": null, "CDF_STATUS": null, "EVENT_ID_NAME": "Profile Capture Period" },
  154: { "CDF_CODE": null, "CDF_STATUS": null, "EVENT_ID_NAME": "Single-action Schedule for Billing Dates" },
  155: { "CDF_CODE": null, "CDF_STATUS": null, "EVENT_ID_NAME": "Activity Calendar for Time Zones etc." },
  201: { "CDF_CODE": 27, "CDF_STATUS": 0, "EVENT_ID_NAME": "Influence of Permanent Magnet or AC/ DC Electromagnet - Occurrence" },
  202: { "CDF_CODE": 27, "CDF_STATUS": 1, "EVENT_ID_NAME": "Influence of Permanent Magnet or AC/ DC Electromagnet - Restoration" },
  203: { "CDF_CODE": 28, "CDF_STATUS": 0, "EVENT_ID_NAME": "Neutral Disturbance - HF & DC - Occurrence" },
  204: { "CDF_CODE": 28, "CDF_STATUS": 1, "EVENT_ID_NAME": "Neutral Disturbance - HF & DC - Restoration" },
  205: { "CDF_CODE": 12, "CDF_STATUS": 0, "EVENT_ID_NAME": "Very Low PF - Occurrence" },
  206: { "CDF_CODE": 12, "CDF_STATUS": 1, "EVENT_ID_NAME": "Very Low PF - Restoration" },
  251: { "CDF_CODE": 60, "CDF_STATUS": 0, "EVENT_ID_NAME": "Meter Cover Opening  Occurrence" }
}

export const MENULIST = [
  {
    key: 1,
    name: 'Dashboard',
    id: 'MENU1',
    view: false,
    children: [
      {
        key: 11,
        name: 'All',
        id: 'MENU1_1',
        view: false
      },
      {
        key: 12,
        name: 'GSM Modem',
        id: 'MENU1_2',
        view: false
      },
      {
        key: 13,
        name: 'Lorawan Modem',
        id: 'MENU1_3',
        view: false
      }
    ]
  },
  {
    key: 2,
    name: 'Group',
    id: 'MENU2',
    view: false
  },
  {
    key: 3,
    name: 'Consumer',
    id: 'MENU3',
    view: false,
    children: [
      {
        key: 16,
        name: 'GSM Modem',
        id: 'MENU3_1',
        view: false
      },
      {
        key: 17,
        name: 'Lorawan Modem',
        id: 'MENU3_2',
        view: false
      },
      {
        key: 18,
        name: 'Portable Device',
        id: 'MENU3_3',
        view: false,
        children: [
          {
            key: 30,
            name: 'Mobile App',
            id: 'MENU3_3_1',
            view: false
          }
        ]
      },
      {
        key: 19,
        name: 'Polling',
        id: 'MENU3_4',
        view: false
      },
      {
        key: 20,
        name: 'Wi-mesh',
        id: 'MENU3_5',
        view: false
      },
      {
        key: 32,
        name: 'Third Party Serves',
        id: 'MENU3_6',
        view: false
      }
    ]
  },
  {
    key: 26,
    name: 'Roles & Users',
    id: 'MENU11',
    view: false,
    children: [
      {
        key: 27,
        name: 'User',
        id: 'MENU11_1',
        view: false
      },
      {
        key: 28,
        name: 'Roles',
        id: 'MENU11_2',
        view: false
      },
      {
        key: 29,
        name: 'Users Group',
        id: 'MENU11_3',
        view: false
      }

    ]
  },
  {
    key: 4,
    name: 'Modem Triggers',
    id: 'MENU4',
    view: false,
    children: [
      {
        key: 21,
        name: 'GSM Modem',
        id: 'MENU4_1',
        view: false
      },
      {
        key: 22,
        name: 'Lorawan Modem',
        id: 'MENU4_2',
        view: false
      },
    ]
  },
  {
    key: 5,
    name: 'Notification Manger',
    id: 'MENU5',
    view: false
  },
  {
    key: 6,
    name: 'Tags',
    id: 'MENU6',
    view: false
  },
  {
    key: 7,
    name: 'Integration',
    id: 'MENU7',
    view: false,
    children: [
      {
        key: 23,
        name: 'Api',
        id: 'MENU7_1',
        view: false
      },
      {
        key: 24,
        name: 'Plugin',
        id: 'MENU7_2',
        view: false
      }
    ]
  },
  {
    key: 8,
    name: 'Release Notes',
    id: 'MENU8',
    view: false
  },
  {
    key: 9,
    name: 'Web Tabs',
    id: 'MENU9',
    view: false
  },
  {
    key: 10,
    name: 'Bad Data Analytics',
    id: 'MENU10',
    view: false
  },{
    key: 31,
    name: 'Create Table',
    id: 'MENU31',
    view: false
  }]